const ArrowBack = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
            <g id="Enmascarar_grupo_176" data-name="Enmascarar grupo 176" transform="translate(-402 -741)" clip-path="url(#clip-path)">
                <g id="Grupo_1517" data-name="Grupo 1517" transform="translate(405.189 744.193)">
                    <g id="Grupo_1516" data-name="Grupo 1516" transform="translate(0 -0.003)" clip-path="url(#clip-path-2)">
                        <path id="Trazado_1786" data-name="Trazado 1786" d="M47.694,36.6A11.087,11.087,0,1,1,36.607,47.691,11.087,11.087,0,0,1,47.694,36.6" transform="translate(-35.884 -35.881)" fill="#c7c7ff" />
                        <path id="Trazado_1787" data-name="Trazado 1787" d="M11.81,0a11.81,11.81,0,1,0,11.81,11.81A11.823,11.823,0,0,0,11.81,0m0,22.174A10.364,10.364,0,1,1,22.174,11.807,10.379,10.379,0,0,1,11.81,22.171" transform="translate(0 0.003)" />
                        <path id="Trazado_1788" data-name="Trazado 1788" d="M373.03,268.6a.719.719,0,0,0-1.022,0l-5.784,5.784a.719.719,0,0,0,0,1.022l5.784,5.784a.723.723,0,1,0,1.022-1.022l-5.273-5.273,5.273-5.273a.719.719,0,0,0,0-1.022" transform="translate(-358.781 -263.087)" />
                    </g>
                </g>
            </g>
        </svg>

    );
}

export default ArrowBack;