import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import Triangulo from '../assets/Triangulo'
import TrianguloAbajoLogo from '../assets/TrianguloAbajoLogo'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Dropdown = ({options,selected,setSelected, className,disabled,optionsMaxH='max-h-56'}) => {
  


  return (
    <Listbox value={selected} onChange={setSelected} disabled={disabled}>
      {({ open }) => (
        <>
          <div className={"relative"+ className}>
            <Listbox.Button className={" relative w-full cursor-pointer rounded-md border-gray-300 py-2 pl-3 pr-10 text-left shadow-sm focus:border-md-blue focus:outline-none focus:ring-1 focus:ring-md-blue"}>
              <span className="flex items-center">
                <span className="ml-1.5 block truncate">{selected.name}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <TrianguloAbajoLogo fill={"#d5d5d5"} className="h-6 w-6 text-md-gray" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className={` z-[100] w-auto fixed overflow-y-scroll overflow-x-hidden ${optionsMaxH} mt-1  rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                {options.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-md-blue' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block ')}
                          >
                            {option.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-md-blue',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

export default Dropdown