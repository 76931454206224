import React,{ useState, useEffect } from 'react'

import { Loading,MessageToast,DinamicDropdown, Idioma, InfoI } from '../components';

import { useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import DatePicker from 'react-datepicker';

import { getToken } from '../fetch';

import { msalConfig, urlBase } from '../authConfig';

import moment from 'moment'
import 'moment/locale/es'
import UpdateReportLogo from '../assets/UpdateReportLogo';


const ComparisonPlanning = () => {
    const {idClient,idRS, clientRSOptions, accessToken,language,setToken,setContextBank,setContextAccount,setContextFile,setContextShowState,setContextDateRange,setContextSubcategoryId,contextSubcategoryId,userAccess,setContextRSID, mainCurrency, numberFormat, sessionID} = useStateContext()
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();

    const [data,setData] = useState([])
	const [typeAccount, setTypeAccount] = useState({name: Idioma.filtros.tipo[language], id: ''});
	const [newTypeAccount, setNewTypeAccount] = useState([]);
    const [bank,setBank] = useState({name:Idioma.transacciones.configuracion.banco[language], id: ''});
    const [BankOptions,setBankOptions] = useState([]);
    const [account,setAccount] = useState({name:Idioma.transacciones.configuracion.cuentaBanco[language], id: ''});
    const [accounts,setAccounts] = useState([{name:Idioma.transacciones.configuracion.cuentaBanco[language], id: ''}]);
    const [info,setInfo] = useState([])
	const [catalogo, setCatalogo] = useState({name: 'Catalogo L2', id: 2});
	const [catalogos, setCatalogos] = useState([{name: 'Catalogo L0', id: 0}, {name: 'Catalogo L1', id: 1}, {name: 'Catalogo L2', id:2}]);
	const [proyectionStartIndex, setProyectionStartIndex] = useState();
	const [fileURL,setFileURL] = useState(null);
	const [fileLoading,setFileLoading] = useState(false);
	const [optionTime, setOptionTime] = useState(1);
	const [selectedForex, setSelectedForex] = useState({name: Idioma.transacciones.configuracion.moneda[language], id: ''});
	const [forexOptions, setForexOptions] = useState([]);
	const [jsonReport,setJsonReport] = useState()
	const [comparisonRange, setComparisonRange] = useState([null,null])
	const [highlightRow,setHighlightRow] = useState(-1);
	const [ errorSign, setErrorSign ] = useState(false);
	const [ yesAccount, setYesAccount ] = useState(false);
	const [currentFx, setCurrentFx] = useState(0);
	const [cashFlowLegend,setCashFlowLegend] = useState(`${Idioma.filtros.todasCuentas[language]} - MXN`)
	const [categoriesL1,setCategoriesL1] = useState([])
	const [categoriesL0,setCategoriesL0] = useState([])
	const [conciliationsCat, setConciliationCat] = useState([])
	const [noBank, SetNoBank] = useState(true)
	const [errorMessage, setErrorMessage] = useState()
	const [openLineageModal,setOpenLineageModal] = useState(false)
	const [lineageData,setLineageData] = useState([])
	const [accountsDict,setAccountsDict] = useState({})
	const [banksDict,setBanksDict] = useState({})
	const [categoriesDict,setCategoriesDict] = useState({})
	const [excelLineageData,setExcelLineageData] = useState({})
	const [redirectFiltersData,setRedirectFiltersData] = useState()
	const [openCredit,setOpenCredit] = useState(false)
	const [filters, setFilters] = useState()
	const [dataModal, setDataModal] = useState([]);
	const [idRString, setIdRString] = useState('');
	const [RSID, setRSID] = useState({name:Idioma.filtros.empresa[language]})
	const [idRsOptions, setIdRsOptions] = useState([])

	useEffect(() => {
		if(filters!==undefined && filters!==null){
			if(JSON.parse(filters).length>5){
				setDateFilter([new Date(Date.parse(JSON.parse(filters)[5][0])), new Date(Date.parse(JSON.parse(filters)[5][1]))])
			}
			setPreFilter(JSON.parse(filters)[0])
			setPlanningDate(new Date(JSON.parse(filters)[1]))
			setGroupBy(JSON.parse(filters)[2])
			setScenario(JSON.parse(filters)[3])
			if(JSON.parse(filters)[4].length!==0){
				setRSID(JSON.parse(filters)[4])
			}
			sessionStorage.setItem('planningFilters', filters)
		}
	}, [filters])

	useEffect(()=>{
		if(jsonReport!==undefined && jsonReport!==null){
			let aux = {}
			jsonReport[0].forEach(item =>{
				if(!aux[item.CategoryL0]){
					aux[item.CategoryL0] = {'total_planning': item.total_planning, 'total_transactions': item.total_transactions/*, 'var': 0, 'diff': 0*/}
					aux[item.CategoryL0][item.CategoryL1] = {'total_planning': item.total_planning, 'total_transactions': item.total_transactions/*, 'var': 0, 'diff': 0*/}
					aux[item.CategoryL0][item.CategoryL1][item.CategoryL2] = {'total_planning': item.total_planning, 'total_transactions': item.total_transactions, 'var': item.var, 'diff': item.diff}
				}else if(!aux[item.CategoryL0][item.CategoryL1]){
					aux[item.CategoryL0]['total_planning'] += item.total_planning
					aux[item.CategoryL0]['total_transactions'] += item.total_transactions
					//aux[item.CategoryL0]['var'] += item.var
					//aux[item.CategoryL0]['diff'] += item.diff
					aux[item.CategoryL0][item.CategoryL1] = {'total_planning': item.total_planning, 'total_transactions': item.total_transactions/*, 'var': 0, 'diff': 0*/}
					aux[item.CategoryL0][item.CategoryL1][item.CategoryL2] = {'total_planning': item.total_planning, 'total_transactions': item.total_transactions, 'var': item.var, 'diff': item.diff}
				}else if(!aux[item.CategoryL0][item.CategoryL1][item.CategoryL2]){
					aux[item.CategoryL0]['total_planning'] += item.total_planning
					aux[item.CategoryL0]['total_transactions'] += item.total_transactions
					//[item.CategoryL0]['var'] += item.var
					//aux[item.CategoryL0]['diff'] += item.diff
					aux[item.CategoryL0][item.CategoryL1]['total_planning'] += item.total_planning
					aux[item.CategoryL0][item.CategoryL1]['total_transactions'] += item.total_transactions
					//aux[item.CategoryL0][item.CategoryL1]['var'] += item.var
					//aux[item.CategoryL0][item.CategoryL1]['diff'] += item.diff
					aux[item.CategoryL0][item.CategoryL1][item.CategoryL2] = {'total_planning': item.total_planning, 'total_transactions': item.total_transactions, 'var': item.var, 'diff': item.diff}
				}
			})
			let auxObjcL0 = {}
			let auxObjcL1 = {}
			//aux[item.CategoryL0]['var'] += item.var
			//aux[item.CategoryL0]['diff'] += item.diff
			//aux[item.CategoryL0][item.CategoryL1]['var'] += item.var
			//aux[item.CategoryL0][item.CategoryL1]['diff'] += item.diff
			Object.keys(aux).map(value => auxObjcL0[value] = 0)
			Object.keys(aux).map(catL0 => Object.keys(aux[catL0]).filter(value => value!='total_planning' && value!='total_transactions' && value!='var' && value!='diff').map(value => auxObjcL1[value] = 0))
			setCategoriesL0(auxObjcL0)
			setCategoriesL1(auxObjcL1)
			Object.keys(auxObjcL0).map(catL0 => Object.keys(aux[catL0]).filter(value => value!='total_planning' && value!='total_transactions' && value!='var' && value!='diff').map(catL1 => aux[catL0][catL1]['var'] = (aux[catL0][catL1]['total_planning'] && aux[catL0][catL1]['total_transactions']) ? Math.round(((aux[catL0][catL1]['total_transactions'] - aux[catL0][catL1]['total_planning'])/aux[catL0][catL1]['total_planning'])*100) : null))
			Object.keys(auxObjcL0).map(catL0 => aux[catL0]['var'] = (aux[catL0]['total_planning'] && aux[catL0]['total_transactions']) ? Math.round(((aux[catL0]['total_transactions']-aux[catL0]['total_planning'])/aux[catL0]['total_planning'])*100) : null)
			
			Object.keys(auxObjcL0).map(catL0 => Object.keys(aux[catL0]).filter(value => value!='total_planning' && value!='total_transactions' && value!='var' && value!='diff').map(catL1 => aux[catL0][catL1]['diff'] = (aux[catL0][catL1]['total_transactions'] && aux[catL0][catL1]['total_planning']) ? Math.round(aux[catL0][catL1]['total_transactions']-aux[catL0][catL1]['total_planning']) : null))
			Object.keys(auxObjcL0).map(catL0 => aux[catL0]['diff'] = (aux[catL0]['total_transactions'] && aux[catL0]['total_planning']) ? Math.round(aux[catL0]['total_transactions']-aux[catL0]['total_planning']) : null)
			setData(aux)
			
			let auxDatesPlanning = jsonReport[1].filter( type => type.data_source==='P').map(date => moment(new Date(date.date_d).setDate(new Date(date.date_d).getDate() + 1)).locale(language).format(language==='en' ?'MMM/DD/YYYY':'DD/MMM/YYYY').replace('.',''))
			let auxDatesReal = jsonReport[1].filter( type => type.data_source==='T').map(date => moment(new Date(date.date_d).setDate(new Date(date.date_d).getDate() + 1)).locale(language).format(language==='en' ?'MMM/DD/YYYY':'DD/MMM/YYYY').replace('.',''))
			
			setComparisonRange([auxDatesReal.length>=1 ? auxDatesReal: null, auxDatesPlanning.length>=1 ? auxDatesPlanning : null])
			
			sessionStorage.setItem('jsonPlanningComparison'+idRS,JSON.stringify(jsonReport))
		}

	},[jsonReport])

	const handleExpandCategoryL0 = (datum) => {
		let aux = {...categoriesL0}
		aux[datum] = categoriesL0[datum]===0 ? 1 : 0
		setCategoriesL0(aux)
	}

	const handleExpandCategoryL1 = (datum) => {
		let aux = {...categoriesL1}
		aux[datum] = categoriesL1[datum]===0 ? 1 : 0
		setCategoriesL1(aux)
	}

    //eliminar input de clientID, debe estar automatizado y tomar esa informacion de la base de datos
    const fade='bg-gradient-to-r from-md-blue to-md-light-blue text-white text-xl font-extrabold rounded' 

	const [preFilterOptions,setPreFilterOptions] = useState([{name: Idioma.filtros.LTM[language], id: 0}, {name: Idioma.filtros.esteAnio[language], id: 1}, {name: Idioma.filtros.anioAnterior[language], id: 2}, {name: Idioma.filtros.ultimosTresMeses[language], id: 9}, {name: Idioma.filtros.esteMes[language], id: 5}, {name: Idioma.filtros.mesAnterior[language], id: 6}, {name: Idioma.filtros.estaSemana[language], id: 7}, {name: Idioma.filtros.semanaAnterior[language], id: 8}])
	const [preFilter,setPreFilter] = useState({name: Idioma.filtros.ultimosTresMeses[language], id: 9})
	const [groupByOptions, setGroupByOptions] = useState([{name: Idioma.filtros.anio[language], id: 'Y',  value: 364}, {name: Idioma.filtros.trimestre[language], id: 'Q', value: 91}, {name: Idioma.filtros.mes[language], id: 'M', value: 28}, {name: Idioma.filtros.semana[language], id: 'W', value: 7}, {name: Idioma.filtros.dia[language], id: 'D', value: 1}])
	const [groupBy, setGroupBy] = useState({name: Idioma.filtros.mes[language], id: 'M', value: 28})
	const [scenarioOptions, setScenarioOptions] = useState([])
	const [scenario, setScenario] = useState({name: Idioma.planeacion.escenario[language]})

	const groupByDynamic = () => {
		if (getDateRange()[0]<7 && getDateRange()[0]!==null){
			setGroupBy({name: Idioma.filtros.dia[language], id: 'D', value: 1})
			setGroupByOptions([{name: Idioma.filtros.dia[language], id: 'D', value: 1}])
		} else if (getDateRange()[0]<28){
			if(groupBy.value>7){
				setGroupBy({name: Idioma.filtros.semana[language], id: 'W', value: 7})
			}
			setGroupByOptions([{name: Idioma.filtros.semana[language], id: 'W', value: 7}, {name: Idioma.filtros.dia[language], id: 'D', value: 1}])
		} else if (getDateRange()[0]<91){
			if(groupBy.value>28){
				setGroupBy({name: Idioma.filtros.mes[language], id: 'M', value: 28})
			}
			setGroupByOptions([{name: Idioma.filtros.mes[language], id: 'M', value: 28}, {name: Idioma.filtros.semana[language], id: 'W', value: 7}, {name: Idioma.filtros.dia[language], id: 'D', value: 1}])
		} else if (getDateRange()[0]<364){
			if(groupBy.value>91){
				setGroupBy({name: Idioma.filtros.mes[language], id: 'M', value: 28})
			}
			setGroupByOptions([{name: Idioma.filtros.trimestre[language], id: 'Q', value: 91}, {name: Idioma.filtros.mes[language], id: 'M', value: 28}, {name: Idioma.filtros.semana[language], id: 'W', value: 7}])
		} else {
			if(groupBy.value<28){
				setGroupBy({name: Idioma.filtros.mes[language], id: 'M', value: 28})
			  }
			setGroupByOptions([{name: Idioma.filtros.anio[language], id: 'Y',  value: 364}, {name: Idioma.filtros.trimestre[language], id: 'Q', value: 91}, {name: Idioma.filtros.mes[language], id: 'M', value: 28}])
		}
	}

	const dateToString = (day, month, year) => {
		if(day<10){
		day = '0'+day.toString()
		}else{
			day = day.toString()
		}
		if(month<10){
			month = '0'+month.toString()
		}else{
			month = month.toString()
		}
		let fecha = (year+month+day);
		return fecha
	}

	const getDateRange = () => {
		if (startDate==null || endDate==null){
		return [null, [null, null]]
		} else {
		let diff = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)
		let year = (startDate.getFullYear()).toString()
		let month = startDate.getMonth()+1
		let day = startDate.getDate()
		let year2 = (endDate.getFullYear()).toString()
		let month2 = endDate.getMonth()+1
		let day2 = endDate.getDate()
		return [diff, [dateToString(day, month, year),dateToString(day2, month2, year2)]]
		}
	}

	const getLTM = () => {
		let newStartDate = new Date()
		newStartDate.setMonth(newStartDate.getMonth() - 12)
		newStartDate.setDate(1)
		let year = (newStartDate.getFullYear()).toString()
		let month = newStartDate.getMonth()  + 1
		let day = newStartDate.getDate()
		let newEndDate = new Date()
		newEndDate.setDate(0)
		let year2 = newEndDate.getFullYear().toString()
		let month2 = newEndDate.getMonth()+1
		let day2 = newEndDate.getDate()
		return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]])//Fecha final LTM
	}

	const getLY = () => {
		let newStartDate = new Date()
		newStartDate.setMonth(0)
		newStartDate.setDate(1)
		newStartDate.setFullYear(newStartDate.getFullYear()-1)
		let year = (newStartDate.getFullYear()).toString()
		let month = newStartDate.getMonth() + 1
		let day = newStartDate.getDate()
		let newEndDate = new Date()
		newEndDate.setMonth(0)
		newEndDate.setDate(0)
		let year2 = newEndDate.getFullYear().toString()
		let month2 = newEndDate.getMonth() + 1
		let day2 = newEndDate.getDate()
		return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final Last Year
	}

	const getTY = () => {
		let newStartDate = new Date()
		newStartDate.setMonth(0)
		newStartDate.setDate(1)
		let year = (newStartDate.getFullYear()).toString()
		let month = newStartDate.getMonth() + 1
		let day = newStartDate.getDate()
		let newEndDate = new Date()
		newEndDate.setFullYear(newEndDate.getFullYear()+1)
		newEndDate.setMonth(0)
		newEndDate.setDate(0)
		let year2 = newEndDate.getFullYear().toString()
		let month2 = newEndDate.getMonth() + 1
		let day2 = newEndDate.getDate()
		return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final This Year
	}

	const getL3M = () => {
		let newStartDate = new Date()
		newStartDate.setMonth(newStartDate.getMonth()-3)
		newStartDate.setDate(1)
		let year = (newStartDate.getFullYear()).toString()
		let month = newStartDate.getMonth() + 1
		let day = newStartDate.getDate()
		let newEndDate = new Date()
		newEndDate.setDate(0)
		let year2 = newEndDate.getFullYear().toString()
		let month2 = newEndDate.getMonth() + 1
		let day2 = newEndDate.getDate()
		return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final Last Quarter
	}

	/*const getLQTR = () => {
		let newStartDate = new Date()
		newStartDate.setMonth((Math.floor(newStartDate.getMonth()/3)-1)*3)
		newStartDate.setDate(1)
		let year = (newStartDate.getFullYear()).toString()
		let month = newStartDate.getMonth() + 1
		let day = newStartDate.getDate()
		let newEndDate = new Date()
		newEndDate.setMonth(Math.floor(newEndDate.getMonth()/3)*3)
		newEndDate.setDate(0)
		let year2 = newEndDate.getFullYear().toString()
		let month2 = newEndDate.getMonth() + 1
		let day2 = newEndDate.getDate()
		return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final Last Quarter
	}

	const getTQTR = () => {
		let newStartDate = new Date()
		newStartDate.setMonth(Math.floor(newStartDate.getMonth()/3)*3)
		newStartDate.setDate(1)
		let year = (newStartDate.getFullYear()).toString()
		let month = newStartDate.getMonth() + 1
		let day = newStartDate.getDate()
		let newEndDate = new Date()
		newEndDate.setDate(newEndDate.getDate()-1)
		let year2 = newEndDate.getFullYear().toString()
		let month2 = newEndDate.getMonth() + 1
		let day2 = newEndDate.getDate()
		return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final This Quarter
	}*/

	const getLM = () => {
		let newStartDate = new Date()
		newStartDate.setMonth(newStartDate.getMonth()-1)
		newStartDate.setDate(1)
		let year = (newStartDate.getFullYear()).toString()
		let month = newStartDate.getMonth() + 1
		let day = newStartDate.getDate()
		let newEndDate = new Date()
		newEndDate.setDate(0)
		let year2 = newEndDate.getFullYear().toString()
		let month2 = newEndDate.getMonth() + 1
		let day2 = newEndDate.getDate()
		return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final Last Month
	}

	const getTM = () => {
		let newStartDate = new Date()
		newStartDate.setDate(1)
		let year = (newStartDate.getFullYear()).toString()
		let month = newStartDate.getMonth() + 1
		let day = newStartDate.getDate()
		let newEndDate = new Date()
		let year2 = newEndDate.getFullYear().toString()
		let month2 = newEndDate.getMonth() + 1
		let day2 = newEndDate.getDate()
		return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final This Month
	}

	const getLW = () => {
		let newStartDate = new Date();
		//newStartDate.setTime(newStartDate.getTime() - 8 * 24 * 60 * 60 * 1000) //7 dias
		newStartDate.setDate(newStartDate.getDate() - (newStartDate.getDay() + 6) % 7 - 7) //Lunes pasado
		let year = (newStartDate.getFullYear()).toString()
		let month = newStartDate.getMonth() + 1
		let day = newStartDate.getDate()
		let newEndDate =  new Date()
		//newEndDate.setTime(newEndDate.getTime() - 1 * 24 * 60 * 60 * 1000) //7 dias
		newEndDate.setDate(newEndDate.getDate() - (newEndDate.getDay() + 6) % 7 - 1) //Lunes pasado
		let year2 = newEndDate.getFullYear().toString()
		let month2 = newEndDate.getMonth() + 1
		let day2 = newEndDate.getDate()
		return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final Last Week
	}

	const getTW = () => {
		let newStartDate = new Date();
		//newStartDate.setTime(newStartDate.getTime() - 8 * 24 * 60 * 60 * 1000) //7 dias
		newStartDate.setDate(newStartDate.getDate() - (newStartDate.getDay() + 6) % 7) //Lunes pasado
		let year = (newStartDate.getFullYear()).toString()
		let month = newStartDate.getMonth() + 1
		let day = newStartDate.getDate()
		let newEndDate =  new Date()
		let year2 = newEndDate.getFullYear().toString()
		let month2 = newEndDate.getMonth() + 1
		let day2 = newEndDate.getDate()
		return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final This Week
	}

	const getProjection = (endDate) => {
		let newDate = new Date(endDate);
		newDate.setMonth(newDate.getMonth()-3)
		newDate.setDate(1)
		if(newDate<startDate){
			newDate=startDate
		}
		let year = (newDate.getFullYear()).toString()
		let month = newDate.getMonth() + 1
		let day = newDate.getDate()
		return([newDate,dateToString(day, month, year)])
	}

	const [ltmFilter,setLtmFilter] = useState(getLTM());
	const [l3mFilter, setL3mFilter] = useState(getL3M())
	const [lyFilter,setLyFilter] = useState(getLY());
	//const [lqtrFilter,setLqtrFilter] = useState(getLQTR());
	const [lmFilter,setLmFilter] = useState(getLM());
	const [lwFilter,setLwFilter] = useState(getLW());
	const [tyFilter,setTyFilter] = useState(getTY());
	//const [tqtrFilter,setTqtrFilter] = useState(getTQTR());
	const [tmFilter,setTmFilter] = useState(getTM());
	const [twFilter,setTwFilter] = useState(getTW());
	const [dateFilter,setDateFilter] = useState(l3mFilter[0]);
	const [startDate, endDate] = dateFilter;
	const [projectionFilter,setProjectionFilter] = useState(getProjection(endDate));
	const [planningDate, setPlanningDate] = useState(projectionFilter[0])
	
	const handleDateChange = (event) => {
		setDateFilter(event)
	}

	useEffect(() => {
		if(preFilter.id!=10){
			if (preFilter.id==0) {
				setDateFilter(ltmFilter[0])
			} else if (preFilter.id==1) {
				setDateFilter(tyFilter[0])
			} else if (preFilter.id==2) {
				setDateFilter(lyFilter[0])
			} else if (preFilter.id==5) {
				setDateFilter(tmFilter[0])
			} else if (preFilter.id==6) {
				setDateFilter(lmFilter[0])
			} else if (preFilter.id==7) {
				setDateFilter(twFilter[0])
			} else if (preFilter.id==8) {
				setDateFilter(lwFilter[0])
			} else if (preFilter.id==9) {
				setDateFilter(l3mFilter[0])
			}
		}
	},[preFilter])

	useEffect(() => {
		groupByDynamic()

		if(dateFilter!=ltmFilter[0] && dateFilter!=lyFilter[0] /*&& dateFilter!=lqtrFilter[0]*/ && dateFilter!=lmFilter[0] && dateFilter!=lwFilter[0] && dateFilter!=tyFilter[0]/* && dateFilter!=tqtrFilter[0]*/ && dateFilter!=tmFilter[0] && dateFilter!=twFilter[0] && dateFilter!=l3mFilter[0]){
			setPreFilter({name: Idioma.filtros.personalizado[language], id: 10})
		}
		setPlanningDate(getProjection(endDate)[0])
	},[dateFilter])

	const [requestParamas, setRequestParams] = useState([getDateRange()[1][0], getDateRange()[1][1], dateToString(planningDate.getDate(), planningDate.getMonth()+1, planningDate.getFullYear()), groupBy.id]);

	useEffect(() => {
		setRequestParams([getDateRange()[1][0], getDateRange()[1][1],dateToString(planningDate.getDate(), planningDate.getMonth()+1, planningDate.getFullYear()),groupBy.id])
	},[dateFilter, planningDate ,groupBy, scenario])

	const savedReport = () => {
		let filtersAux =  sessionStorage.getItem('planningFilters')
		let reportAux =  sessionStorage.getItem('jsonPlanningComparison'+idRS)
		setFilters(filtersAux)
		setJsonReport(JSON.parse(reportAux))
	}

	const getScenarios = () => {
		let headers = new Headers()
		const bearer = `Bearer ${accessToken}`;
		headers.append("Authorization", bearer);
		headers.append("Content-Type",'application/json')
		let body = {
			"clientID": idClient,
			logsInfo: {
			  origin:'Comparison planning',
			  sessionID:sessionID 
			}
		  }
		if(idRS){
			body.idRS = idRS
		}else if (RSID.id){
			body.rsID = RSID.id
		}
		fetch(`${urlBase}/planning/getScenarios`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((res)=>res.json())
        .then((aux)=>{
            let newData = []
            for(let i=0;i<aux.length;i++){
              newData[i]={
                  name:aux[i].name,
                  id:aux[i].idScenario
              }
            }
            setScenarioOptions(newData)
			if(newData.length===0){
				setScenario({name: Idioma.planeacion.escenario[language]})
				setErrorMessage(Idioma.planeacion.scenarioError[language])
			}
        })
	}

	useEffect(()=>{
		
        if(!isAuthenticated){
            navigate('/')
            return
        }
		getToken().then((token)=>{setToken(token)})

		let headers = new Headers()

		const bearer = `Bearer ${accessToken}`;
		headers.append("Authorization", bearer);
		headers.append("Content-Type",'application/json')
		let body = {
			"clientID": idClient,
			logsInfo: {
			  origin:'Comparison planning',
			  sessionID:sessionID 
			}
		}
		if(idRS){
			body.idRS=idRS
		}
		fetch(`${urlBase}/getRS`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        })
        .then((res)=>res.json())
        .then((aux)=>{
			let newRSOptions = []
			newRSOptions[0] = Idioma.filtros.empresa[language]
			aux.map((rs) => {
				newRSOptions[rs.idRS]=rs.rsName
			})
			setIdRsOptions(newRSOptions)
        })
		fetch(`${urlBase}/getCategories`,{
			method: 'POST',
			headers: headers,
			body: JSON.stringify({
			  "clientID": idClient,
			  "lowest": 1,
			  showHidden: true,
			  showCreditFinancing:true,
			  forReport:true,
			  logsInfo: {
				origin:'Comparison planning',
				sessionID:sessionID 
			  }
		  })
		  })
			.then((response) => response.json())
			.then((aux1) => {
			  let aux = aux1.categories
			  let notUse = aux1.notUse
			  let catDict = {}
			  for(let i = 0;i<aux.length;i++){
				catDict[aux[i].category] = {
				  "id": aux[i].idCategory,
				  "isActive" : aux[i].isActive === true,
				  "notUse" : notUse[aux[i].idCategory] === true
				}
				for(let j = 0;j<aux[i].subcategories.length;j++){
				  catDict[`${aux[i].category}${aux[i].subcategories[j].category}`] = {
					"id": aux[i].subcategories[j].idCategory,
					"isActive" : aux[i].subcategories[j].isActive === true,
					"idFather" : aux[i].subcategories[j].idCategoryFather,
					"notUse" : notUse[aux[i].subcategories[j].idCategory] === true
				  }
				}
			  }
			  
			  setCategoriesDict(catDict)
		  });
		  getScenarios()
		window.clarity("set", "report", "reportEnter")
    },[]);

	const genReport = () => {
		setErrorSign(false)
		setJsonReport()
		let headers = new Headers()

		const bearer = `Bearer ${accessToken}`;
		headers.append("Authorization", bearer);
		headers.append("Content-Type",'application/json')

		let body = {
			"clientID": idClient,
			"startDate":  requestParamas[0],
			"endDate":  requestParamas[1],
			"scenarioID": scenario.id,
			logsInfo: {
			  origin:'Comparison planning',
			  sessionID:sessionID 
			}
			}
			if(idRS){
				body.idRS = String(idRS)
			}else if (RSID.id){
				body.idRS = String(RSID.id)
			}else{
				body.idRS = ""
			}
		fetch(`${urlBase}/planning/getComparison`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
		.then(res => res.json())
		.then(res =>  {
			if('error' in res === false){
				if(preFilter.id===10){
					setFilters(JSON.stringify([preFilter, planningDate, groupBy, scenario, RSID, [startDate, endDate]]))
				}else{
					setFilters(JSON.stringify([preFilter, planningDate, groupBy, scenario, RSID]))
				}
				setJsonReport(res);
				setTimeout(() => {
				}, 500);
				setErrorSign(false)
			}else{
				setErrorMessage(res['error'])
			}
			return res
		} )
		.catch(function(err) {
			console.log('Failed to fetch page: ',err);
		});
	}


	useEffect(() => {
		if(((scenarioOptions.length>0 && !scenario.id) || (!scenarioOptions.find(item => item.id === scenario.id) && scenarioOptions.length>0))){
			setScenario(scenarioOptions[0])
		}else if(sessionStorage.getItem('jsonPlanningComparison'+idRS)){
			savedReport()
		}else{
			if(scenarioOptions.length>0 && scenario.id){
				genReport()
			} 
		}
	},[scenarioOptions, scenario])

	useEffect(() => {
		if (typeof errorMessage!=="undefined"){
			setErrorSign(true)
		}
	},[errorMessage])

	useEffect(() => {
		if (errorSign===false){
			setErrorMessage(undefined)
			if(sessionStorage.getItem('jsonPlanningComparison'+idRS)){
				savedReport()
			}else{
				setFilters(null)
			}
		}
	},[errorSign])

	useEffect( () => {
		if(filters!==undefined && idRsOptions.length>1){
		  let data = [];
		  let ids = Object.keys(idRsOptions)
		  let names = Object.values(idRsOptions)

		  for( let i = 0; i < ids.length; i++ ) {
			data.push({name: names[i], id: Number(ids[i])})
		  }
  
		  setDataModal(data)
		  setRSID({name:Idioma.filtros.empresa[language]})
		}
	}, [idRsOptions] );

    return (
        <div className='ml-[5%] mt-6 mr-10'>
			<div className='flex gap-2 2xl:gap-4 mb-2'>
			{!idRS && dataModal.length>1 &&
            <DinamicDropdown
				options={dataModal}
				selected={RSID}
				className={'text-p11-2xl w-[80px] h-button-4 2xl:text-p9-2xl 2xl:w-button-3 2xl:h-button-3'}
				setSelected={setRSID}
			/>
			}
				<DinamicDropdown
					options={scenarioOptions}
					selected={scenario}
					className={'text-p11-2xl w-[120px] h-button-4 2xl:text-p9-2xl 2xl:w-button-3 2xl:h-button-3'}
					setSelected={setScenario}
				/>
				<DinamicDropdown
					options={preFilterOptions}
					selected={preFilter}
					className={'text-p11-2xl w-[120px] h-button-4 2xl:text-p9-2xl 2xl:w-button-3 2xl:h-button-3'}
					setSelected={setPreFilter}
				/>
				<div className='col-span-2 w-button-3 2xl:w-button-2 z-50'>
				<DatePicker 
					className={' h-button-4 2xl:h-button-3 focus:outline-none cursor-default border-1 border-neutral-1 hover:border-light-purple-4 focus:border-light-purple-4 rounded-button text-p11-2xl 2xl:text-p9-2xl text-center'} 
					onChange={(date) => handleDateChange(date)} 
					selectsRange startDate={startDate} 
					endDate={endDate}
					dateFormat={Idioma.formatoFecha[language]}
					locale={language}
					showYearDropdown
					fixedHeight
				/>
				</div>
				<button className={``} disabled={ idRsOptions.length===0 || scenario.id ? false : true} onClick={genReport}><UpdateReportLogo fill={idRsOptions.length===0 || scenario.id ? '#00C67E':'#DBDBDB'} stroke={idRsOptions.length===0 || scenario.id ? '#00C67E':'#DBDBDB'}/></button>
			</div>
			
			{jsonReport||errorSign ? null:<div className='mt-4'><Loading/></div>}
            {/*<div className='mt-2'  dangerouslySetInnerHTML={{ __html: data ? data : null }} />*/}
			{(jsonReport && data) && <div className={`mt-4 pb-0.5 max-h-[74vh] perfect-scrollbar overflow-scroll`}>
					<table onMouseLeave={()=>setHighlightRow(-1)} className=' mr-4 pr-1'>
						<thead>
						<tr className='z-[10] sticky top-0 h-[40px] bg-white'>
							<th onMouseEnter={()=>setHighlightRow(-1)} >
								<div className='text-p9-2xl text-left'>
								<p className='mb-1'>{comparisonRange[0] ? Idioma.planeacion.comparacionReport.datosReales[language]+': '+ (comparisonRange[0].length>1 ? comparisonRange[0][0] + ' - ' + comparisonRange[0][1] : comparisonRange[0]) : null}</p>
								<p>{comparisonRange[1] ? Idioma.planeacion.comparacionReport.datosProyeccion[language]+': '+ (comparisonRange[1].length>1 ? comparisonRange[1][0] + ' - ' + comparisonRange[1][1] : comparisonRange[1]) : null}</p>
								</div>
							</th>
							<th onMouseEnter={()=>setHighlightRow(0)} className={`min-w-[80px] 2xl:min-w-[120px]`}>
								<div className={`mx-3 flex items-center justify-center min-w-[60px] 2xl:min-w-[80px] h-[35px] rounded-button text-p10-2xl 2xl:text-p9-2xl ${0==highlightRow ? ' bg-light-purple-4 ':'bg-light-purple-5'}`}>
									{Idioma.planeacion.comparacionReport.real[language]}
								</div>
							</th>
							<th onMouseEnter={()=>setHighlightRow(1)} className={`min-w-[80px] 2xl:min-w-[120px]`}>
								<div className={`mx-3 flex items-center justify-center min-w-[60px] 2xl:min-w-[80px] h-[35px] rounded-button text-p10-2xl 2xl:text-p9-2xl ${1==highlightRow ? ' bg-light-purple-4 ':'bg-light-purple-5'}`}>
									{Idioma.planeacion.comparacionReport.proyeccion[language]}
								</div>
							</th>
							<th onMouseEnter={()=>setHighlightRow(2)} className={`min-w-[80px] 2xl:min-w-[120px]`}>
								<div className={`mx-3 flex items-center justify-center min-w-[60px] 2xl:min-w-[80px] h-[35px] rounded-button text-p10-2xl 2xl:text-p9-2xl ${2==highlightRow ? ' bg-light-purple-4 ':'bg-light-purple-5'}`}>
									{Idioma.planeacion.comparacionReport.var[language]} %
								</div>
							</th>
							<th onMouseEnter={()=>setHighlightRow(3)} className={`min-w-[80px] 2xl:min-w-[120px]`}>
								<div className={`mx-3 flex items-center justify-center min-w-[60px] 2xl:min-w-[80px] h-[35px] rounded-button text-p10-2xl 2xl:text-p9-2xl ${3==highlightRow ? ' bg-light-purple-4 ':'bg-light-purple-5'}`}>
									{Idioma.planeacion.comparacionReport.var[language]} $
								</div>
							</th>
						</tr>
						</thead>
					<tbody>
						<tr className='h-4'>
							<td onMouseEnter={()=>setHighlightRow(-1)}>
								{' '}
							</td>
							<td onMouseEnter={()=>setHighlightRow(0)}>
								{' '}
							</td>
							<td onMouseEnter={()=>setHighlightRow(1)}>
									{' '}
								</td>
							<td onMouseEnter={()=>setHighlightRow(2)}>
								{' '}
							</td>
							<td onMouseEnter={()=>setHighlightRow(3)}>
								{' '}
							</td>
						</tr>
						{Object.keys(data).map((dataL0, indexL0)=>(
							<>
							<tr className={`bg-gray-6 text-black sticky top-[40px] z-10 hover:z-[11] `}>
								<td onMouseEnter={()=>setHighlightRow(-1)} className={`sticky left-0 text-h13-2xl 2xl:text-h11-2xl flex items-center pr-5`}>
								<span className='mx-2'><InfoI texto={dataL0===Idioma.reporte.flujoOperativo[language]?Idioma.reporte.infoFlujoOperativo[language]:dataL0===Idioma.reporte.flujoInversion[language]?Idioma.reporte.infoFlujoInversion[language]:dataL0===Idioma.reporte.flujoFinanciamiento[language]?Idioma.reporte.infoFlujoFinanciamiento[language]:dataL0===Idioma.reporte.MovimientoEntreCuentas[language]?Idioma.reporte.infoMovimientosCuentas[language]:dataL0===Idioma.reporte.cuentasCredito[language]?Idioma.reporte.infoCuentasCredito[language]:null}/></span>{dataL0}
								</td>
								<td className={` tabular-nums text-right text-p10-2xl `}>
									{/*!Math.round(parseFloat(data[dataL0].total_transactions)) ? '--' : Math.round(parseFloat(data[dataL0].total_transactions)) <0 ? `${numberFormat===0 ?'(':''}${Math.round((parseFloat(data[dataL0].total_transactions))*(numberFormat===0?-1:1)).toLocaleString("en-US")}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(data[dataL0].total_transactions))).toLocaleString('en-US')==='0' ? '--' :  Math.round(parseFloat(data[dataL0].total_transactions)).toLocaleString('en-US')*/}
								</td>
								<td className={` tabular-nums text-right text-p10-2xl `}>
									{/*!Math.round(parseFloat(data[dataL0].total_planning)) ? '--' : Math.round(parseFloat(data[dataL0].total_planning)) <0 ? `${numberFormat===0 ?'(':''}${Math.round((parseFloat(data[dataL0].total_planning))*(numberFormat===0?-1:1)).toLocaleString("en-US")}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(data[dataL0].total_planning))).toLocaleString('en-US')==='0' ? '--' :  Math.round(parseFloat(data[dataL0].total_planning)).toLocaleString('en-US')*/}
								</td>
								<td className={` tabular-nums text-right text-p10-2xl `}>
									{/*!Math.round(parseFloat(data[dataL0].var)) ? '--' : Math.round(parseFloat(data[dataL0].var)) <0 ? `${numberFormat===0 ?'(':''}${Math.round((parseFloat(data[dataL0].var))*(numberFormat===0?-1:1)).toLocaleString("en-US")}${numberFormat===0 ?')%':'%'}` : Math.round(Math.abs(parseFloat(data[dataL0].var))).toLocaleString('en-US')==='0' ? '--' :  `${Math.round(parseFloat(data[dataL0].var)).toLocaleString('en-US')}%`*/}
								</td>
								<td className={` tabular-nums text-right text-p10-2xl `}>
									{/*!Math.round(parseFloat(data[dataL0].diff)) ? '--' : Math.round(parseFloat(data[dataL0].diff)) <0 ? `${numberFormat===0 ?'(':''}${Math.round((parseFloat(data[dataL0].diff))*(numberFormat===0?-1:1)).toLocaleString("en-US")}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(data[dataL0].diff))).toLocaleString('en-US')==='0' ? '--' :  Math.round(parseFloat(data[dataL0].diff)).toLocaleString('en-US')*/}
								</td>
							</tr>
							{Object.keys(data[dataL0]).filter(value => value!='total_planning' && value!='total_transactions' && value!='var' && value!='diff').map((dataL1, indexL1)=>(
								<>

								{categoriesL1[dataL1]===0 ? 
								<>
								<tr className='text-left hover:bg-light-purple-6 group'>
									<td onClick={()=>handleExpandCategoryL1(dataL1)} className='sticky cursor-pointer left-0 group-hover:bg-light-purple-4 bg-light-purple-6 text-p10-2xl 2xl:text-p8-2xl flex items-center pr-5 hover:z-[11]'>
									<span className='mx-2'>+</span> <span className='mx-2 invisible'>-</span>{dataL1}{dataL1===Idioma.reporte.ingresoCredito[language]?<span className='mx-2'><InfoI texto={Idioma.reporte.infoIngresosCredito[language]}/></span>:null}{dataL1===Idioma.reporte.egresoCredito[language]?<span  className='mx-2'><InfoI texto={Idioma.reporte.infoEgresosCredito[language]}/></span>:null}	
									</td>
									<td onMouseEnter={()=>setHighlightRow(0)} className={` tabular-nums text-right text-p10-2xl ${0==highlightRow ? ' bg-light-purple-6 ':''} `}>
										{!Math.round(parseFloat(data[dataL0][dataL1].total_transactions)) ? '--' : Math.round(parseFloat(data[dataL0][dataL1].total_transactions)) <0 ? `${numberFormat===0 ?'(':''}${Math.round((parseFloat(data[dataL0][dataL1].total_transactions))*(numberFormat===0?-1:1)).toLocaleString("en-US")}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(data[dataL0][dataL1].total_transactions))).toLocaleString('en-US')==='0' ? '--' :  Math.round(parseFloat(data[dataL0][dataL1].total_transactions)).toLocaleString('en-US')}
									</td>
									<td onMouseEnter={()=>setHighlightRow(1)} className={` tabular-nums text-right text-p10-2xl ${1==highlightRow ? ' bg-light-purple-6 ':''} `}>
										{!Math.round(parseFloat(data[dataL0][dataL1].total_planning)) ? '--' : Math.round(parseFloat(data[dataL0][dataL1].total_planning)) <0 ? `${numberFormat===0 ?'(':''}${Math.round((parseFloat(data[dataL0][dataL1].total_planning))*(numberFormat===0?-1:1)).toLocaleString("en-US")}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(data[dataL0][dataL1].total_planning))).toLocaleString('en-US')==='0' ? '--' :  Math.round(parseFloat(data[dataL0][dataL1].total_planning)).toLocaleString('en-US')}
									</td>
									<td onMouseEnter={()=>setHighlightRow(2)} className={` tabular-nums text-right text-p10-2xl ${2==highlightRow ? ' bg-light-purple-6 ':''} `}>
										{!Math.round(parseFloat(data[dataL0][dataL1].var)) ? '--' : Math.round(parseFloat(data[dataL0][dataL1].var)) <0 ? `${numberFormat===0 ?'(':''}${Math.round((parseFloat(data[dataL0][dataL1].var))*(numberFormat===0?-1:1)).toLocaleString("en-US")}${numberFormat===0 ?')%':'%'}` : Math.round(Math.abs(parseFloat(data[dataL0][dataL1].var))).toLocaleString('en-US')==='0' ? '--' :  `${Math.round(parseFloat(data[dataL0][dataL1].var)).toLocaleString('en-US')}%`}
									</td>
									<td onMouseEnter={()=>setHighlightRow(3)} className={` tabular-nums text-right text-p10-2xl ${3==highlightRow ? ' bg-light-purple-6 ':''} `}>
										{!Math.round(parseFloat(data[dataL0][dataL1].diff)) ? '--' : Math.round(parseFloat(data[dataL0][dataL1].diff)) <0 ? `${numberFormat===0 ?'(':''}${Math.round((parseFloat(data[dataL0][dataL1].diff))*(numberFormat===0?-1:1)).toLocaleString("en-US")}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(data[dataL0][dataL1].diff))).toLocaleString('en-US')==='0' ? '--' :  Math.round(parseFloat(data[dataL0][dataL1].diff)).toLocaleString('en-US')}
									</td>
								</tr>
								</>
								:<><tr className='  text-left hover:bg-light-purple-6 group'>
								<td onClick={()=>handleExpandCategoryL1(dataL1)} className='sticky cursor-pointer left-0 group-hover:bg-light-purple-4 bg-light-purple-6 text-p10-2xl 2xl:text-p8-2xl flex items-center pr-5'>
									<span className='mx-2 invisible'>+</span> <span className='mx-2'>-</span>{dataL1} {dataL1===Idioma.reporte.ingresoCredito[language]?<span className='mx-2'><InfoI texto={Idioma.reporte.infoIngresosCredito[language]}/></span>:null}{dataL1===Idioma.reporte.egresoCredito[language]?<span  className='mx-2'><InfoI texto={Idioma.reporte.infoEgresosCredito[language]}/></span>:null}
								</td>
								<td onMouseEnter={()=>setHighlightRow(0)} className={` tabular-nums text-right text-p10-2xl ${0==highlightRow ? ' bg-light-purple-6 ':''} `}>
									{!Math.round(parseFloat(data[dataL0][dataL1].total_transactions)) ? '--' : Math.round(parseFloat(data[dataL0][dataL1].total_transactions)) <0 ? `${numberFormat===0 ?'(':''}${Math.round((parseFloat(data[dataL0][dataL1].total_transactions))*(numberFormat===0?-1:1)).toLocaleString("en-US")}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(data[dataL0][dataL1].total_transactions))).toLocaleString('en-US')==='0' ? '--' :  Math.round(parseFloat(data[dataL0][dataL1].total_transactions)).toLocaleString('en-US')}
								</td>
								<td onMouseEnter={()=>setHighlightRow(1)} className={` tabular-nums text-right text-p10-2xl ${1==highlightRow ? ' bg-light-purple-6 ':''} `}>
									{!Math.round(parseFloat(data[dataL0][dataL1].total_planning)) ? '--' : Math.round(parseFloat(data[dataL0][dataL1].total_planning)) <0 ? `${numberFormat===0 ?'(':''}${Math.round((parseFloat(data[dataL0][dataL1].total_planning))*(numberFormat===0?-1:1)).toLocaleString("en-US")}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(data[dataL0][dataL1].total_planning))).toLocaleString('en-US')==='0' ? '--' :  Math.round(parseFloat(data[dataL0][dataL1].total_planning)).toLocaleString('en-US')}
								</td>
								<td onMouseEnter={()=>setHighlightRow(2)} className={` tabular-nums text-right text-p10-2xl ${2==highlightRow ? ' bg-light-purple-6 ':''} `}>
									{!Math.round(parseFloat(data[dataL0][dataL1].var)) ? '--' : Math.round(parseFloat(data[dataL0][dataL1].var)) <0 ? `${numberFormat===0 ?'(':''}${Math.round((parseFloat(data[dataL0][dataL1].var))*(numberFormat===0?-1:1)).toLocaleString("en-US")}${numberFormat===0 ?')%':'%'}` : Math.round(Math.abs(parseFloat(data[dataL0][dataL1].var))).toLocaleString('en-US')==='0' ? '--' :  `${Math.round(parseFloat(data[dataL0][dataL1].var)).toLocaleString('en-US')}%`}
								</td>
								<td onMouseEnter={()=>setHighlightRow(3)} className={` tabular-nums text-right text-p10-2xl ${3==highlightRow ? ' bg-light-purple-6 ':''} `}>
									{!Math.round(parseFloat(data[dataL0][dataL1].diff)) ? '--' : Math.round(parseFloat(data[dataL0][dataL1].diff)) <0 ? `${numberFormat===0 ?'(':''}${Math.round((parseFloat(data[dataL0][dataL1].diff))*(numberFormat===0?-1:1)).toLocaleString("en-US")}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(data[dataL0][dataL1].diff))).toLocaleString('en-US')==='0' ? '--' :  Math.round(parseFloat(data[dataL0][dataL1].diff)).toLocaleString('en-US')}
								</td>
							</tr>

							{Object.keys(data[dataL0][dataL1]).filter(value => value!='total_planning' && value!='total_transactions' && value!='var' && value!='diff').map((dataL2, indexL2)=>(
								<tr className=' text-left hover:bg-light-purple-6 group'>
									<td onMouseEnter={()=>setHighlightRow(-1)} className='sticky pl-7 left-0 group-hover:bg-light-purple-4 bg-light-purple-6 text-p10-2xl 2xl:text-p8-2xl pr-5' >
									<span className=' invisible mx-2'>+</span> <span className='mx-2 invisible'>-</span>{dataL2}
									</td>
									<td onMouseEnter={()=>setHighlightRow(0)} className={` tabular-nums text-right text-p10-2xl ${0==highlightRow ? ' bg-light-purple-6 ':''} `}>
										{!Math.round(parseFloat(data[dataL0][dataL1][dataL2].total_transactions)) ? '--' : Math.round(parseFloat(data[dataL0][dataL1][dataL2].total_transactions)) <0 ? `${numberFormat===0 ?'(':''}${Math.round((parseFloat(data[dataL0][dataL1][dataL2].total_transactions))*(numberFormat===0?-1:1)).toLocaleString("en-US")}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(data[dataL0][dataL1][dataL2].total_transactions))).toLocaleString('en-US')==='0' ? '--' :  Math.round(parseFloat(data[dataL0][dataL1][dataL2].total_transactions)).toLocaleString('en-US')}
									</td>
									<td onMouseEnter={()=>setHighlightRow(1)} className={` tabular-nums text-right text-p10-2xl ${1==highlightRow ? ' bg-light-purple-6 ':''} `}>
										{!Math.round(parseFloat(data[dataL0][dataL1][dataL2].total_planning)) ? '--' : Math.round(parseFloat(data[dataL0][dataL1][dataL2].total_planning)) <0 ? `${numberFormat===0 ?'(':''}${Math.round((parseFloat(data[dataL0][dataL1][dataL2].total_planning))*(numberFormat===0?-1:1)).toLocaleString("en-US")}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(data[dataL0][dataL1][dataL2].total_planning))).toLocaleString('en-US')==='0' ? '--' :  Math.round(parseFloat(data[dataL0][dataL1][dataL2].total_planning)).toLocaleString('en-US')}
									</td>
									<td onMouseEnter={()=>setHighlightRow(2)} className={` tabular-nums text-right text-p10-2xl ${2==highlightRow ? ' bg-light-purple-6 ':''} `}>
										{!Math.round(parseFloat(data[dataL0][dataL1][dataL2].var)) ? '--' : Math.round(parseFloat(data[dataL0][dataL1][dataL2].var)) <0 ? `${numberFormat===0 ?'(':''}${Math.round((parseFloat(data[dataL0][dataL1][dataL2].var))*(numberFormat===0?-1:1)).toLocaleString("en-US")}${numberFormat===0 ?')%':'%'}` : Math.round(Math.abs(parseFloat(data[dataL0][dataL1][dataL2].var))).toLocaleString('en-US')==='0' ? '--' :  `${Math.round(parseFloat(data[dataL0][dataL1][dataL2].var)).toLocaleString('en-US')}%`}
									</td>
									<td onMouseEnter={()=>setHighlightRow(3)} className={` tabular-nums text-right text-p10-2xl ${3==highlightRow ? ' bg-light-purple-6 ':''} `}>
										{!Math.round(parseFloat(data[dataL0][dataL1][dataL2].diff)) ? '--' : Math.round(parseFloat(data[dataL0][dataL1][dataL2].diff)) <0 ? `${numberFormat===0 ?'(':''}${Math.round((parseFloat(data[dataL0][dataL1][dataL2].diff))*(numberFormat===0?-1:1)).toLocaleString("en-US")}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(data[dataL0][dataL1][dataL2].diff))).toLocaleString('en-US')==='0' ? '--' :  Math.round(parseFloat(data[dataL0][dataL1][dataL2].diff)).toLocaleString('en-US')}
									</td>
								</tr>
							))}
							
							<tr className='h-4'>
							<td className='sticky bg-light-purple-6 left-0'>
								{' '}
							</td>
							<td onMouseEnter={()=>setHighlightRow(0)} className={` tabular-nums text-right ${0==highlightRow ? ' bg-light-purple-6 ':''}`}>
								{' '}
							</td>
							<td onMouseEnter={()=>setHighlightRow(1)} className={` tabular-nums text-right ${1==highlightRow ? ' bg-light-purple-6 ':''}`}>
								{' '}
							</td>
							<td onMouseEnter={()=>setHighlightRow(2)} className={` tabular-nums text-right ${2==highlightRow ? ' bg-light-purple-6 ':''}`}>
								{' '}
							</td>
							<td onMouseEnter={()=>setHighlightRow(3)} className={` tabular-nums text-right ${3==highlightRow ? ' bg-light-purple-6 ':''}`}>
								{' '}
							</td>
						</tr>
						</>}
								
								</>//cierra l1
							))}
							<tr className={`bg-gray-6 text-black sticky top-[40px] z-10 hover:z-[11] `}>
								<td onMouseEnter={()=>setHighlightRow(-1)} className={`sticky left-0 text-h13-2xl 2xl:text-h11-2xl flex items-center pr-5`}>
								<span className='ml-6'>{Idioma.reporte.total[language]} {dataL0}</span>
								</td>
								<td className={` tabular-nums text-right text-p10-2xl font-bold`}>
									{!Math.round(parseFloat(data[dataL0].total_transactions)) ? '--' : Math.round(parseFloat(data[dataL0].total_transactions)) <0 ? `${numberFormat===0 ?'(':''}${Math.round((parseFloat(data[dataL0].total_transactions))*(numberFormat===0?-1:1)).toLocaleString("en-US")}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(data[dataL0].total_transactions))).toLocaleString('en-US')==='0' ? '--' :  Math.round(parseFloat(data[dataL0].total_transactions)).toLocaleString('en-US')}
								</td>
								<td className={` tabular-nums text-right text-p10-2xl font-bold`}>
									{!Math.round(parseFloat(data[dataL0].total_planning)) ? '--' : Math.round(parseFloat(data[dataL0].total_planning)) <0 ? `${numberFormat===0 ?'(':''}${Math.round((parseFloat(data[dataL0].total_planning))*(numberFormat===0?-1:1)).toLocaleString("en-US")}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(data[dataL0].total_planning))).toLocaleString('en-US')==='0' ? '--' :  Math.round(parseFloat(data[dataL0].total_planning)).toLocaleString('en-US')}
								</td>
								<td className={` tabular-nums text-right text-p10-2xl font-bold`}>
									{!Math.round(parseFloat(data[dataL0].var)) ? '--' : Math.round(parseFloat(data[dataL0].var)) <0 ? `${numberFormat===0 ?'(':''}${Math.round((parseFloat(data[dataL0].var))*(numberFormat===0?-1:1)).toLocaleString("en-US")}${numberFormat===0 ?')%':'%'}` : Math.round(Math.abs(parseFloat(data[dataL0].var))).toLocaleString('en-US')==='0' ? '--' :  `${Math.round(parseFloat(data[dataL0].var)).toLocaleString('en-US')}%`}
								</td>
								<td className={` tabular-nums text-right text-p10-2xl font-bold`}>
									{!Math.round(parseFloat(data[dataL0].diff)) ? '--' : Math.round(parseFloat(data[dataL0].diff)) <0 ? `${numberFormat===0 ?'(':''}${Math.round((parseFloat(data[dataL0].diff))*(numberFormat===0?-1:1)).toLocaleString("en-US")}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(data[dataL0].diff))).toLocaleString('en-US')==='0' ? '--' :  Math.round(parseFloat(data[dataL0].diff)).toLocaleString('en-US')}
								</td>
							</tr>
							<tr className='h-4'>
							<td className='sticky bg-light-purple-6 left-0'>
								{' '}
							</td>
							<td onMouseEnter={()=>setHighlightRow(0)} className={` tabular-nums text-right ${0==highlightRow ? ' bg-light-purple-6 ':''}`}>
								{' '}
							</td>
							<td onMouseEnter={()=>setHighlightRow(1)} className={` tabular-nums text-right ${1==highlightRow ? ' bg-light-purple-6 ':''}`}>
								{' '}
							</td>
							<td onMouseEnter={()=>setHighlightRow(2)} className={` tabular-nums text-right ${2==highlightRow ? ' bg-light-purple-6 ':''}`}>
								{' '}
							</td>
							<td onMouseEnter={()=>setHighlightRow(3)} className={` tabular-nums text-right ${3==highlightRow ? ' bg-light-purple-6 ':''}`}>
								{' '}
							</td>
						</tr>
							</>
						))}
						
						
					</tbody>
					</table>
				</div>}
				<div className='absolute bottom-[40px] right-[40px] z-[101]'>
					{errorSign ?  <MessageToast type="error" title={Idioma.transacciones.mensajeError[language]} message={errorMessage} setSign={setErrorSign}/> : null}
				</div>
        </div>
    )
}



export default ComparisonPlanning