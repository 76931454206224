import React, { useEffect } from "react";
import * as echarts from 'echarts/core';
import ReactEChartsCore from "echarts-for-react/lib/core";
import { LineChart } from "echarts/charts";
import { GridComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { UniversalTransition } from "echarts/features";
import { useLocation } from "react-router-dom";
import { useState } from 'react';
import { useTable, useSortBy, useBlockLayout } from 'react-table';
import { FixedSizeList } from "react-window";
import Triangulo from "../assets/Triangulo";
import moment from "moment";
import { DashboardFilters, scrollbarWidth} from '../components';
import { useStateContext } from "../contexts/ContextProvider";
import Idioma from "../components/Idioma";
import fiscalWeeks from "../components/fiscalWeek";
import { urlBase } from "../authConfig";
import { DateFomatterDBFormat } from '../utils/DateFormater';
import formatterDates3Letters from "../utils/datesRefactor";
import { useIsAuthenticated } from "@azure/msal-react";
import { getToken } from '../fetch';
import { useNavigate } from "react-router-dom";

const TotalSubPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [showlabels, setShowLabels] = useState();
    let info = location.state?.data;
    let dates = location.state?.dates;
    let big1 = location.state?.big1;
    let big2 = location.state?.big2;
    let big3 = location.state?.big3;
    let divider = location.state?.divider;
    let upDown = location.state?.upDown;
    const [data, setData] = useState([]);
    const [arr, setArr] =useState(location.state?.arrId)
    const [catDic, setCatDic] = useState(location.state?.catDic)
    const [states, setState] = useState({
        info: info,
        dates: dates,
        big1, big1, 
        big2: big2,
        big3: big3,
        upDown: upDown,
        divider: divider,
    });
    const [width, setWidth] = useState(1);
    const isAuthenticated = useIsAuthenticated();

    const [requestParams, setRequestParams] = useState([null,null,null,null,null]);

    const { idClient, accessToken, idRS, setContextCategoriesIds, setContextBank, setContextAccount, setContextRSID, setContextFile, setContextShowState, setContextDateRange, language, numberFormat, userAccess, sessionID } = useStateContext();
    const [hover1, setHover1] = useState(false);
    const [hover2, setHover2] = useState(false);
    const [hover3, setHover3] = useState(false);

    useEffect(() => {
        if(requestParams[0]!==null){
            getCategories()
        }
    }, [requestParams]);

    useEffect(() => {
      if(upDown && sessionStorage.getItem('showLabels3')) {
        setShowLabels(sessionStorage.getItem('showLabels3'));
      }else {
        setShowLabels(sessionStorage.getItem('showLabels6'));
      }
    }, []);

    useEffect(() => {
      if(!isAuthenticated){
          navigate('/')
          return
      }
  getToken().then((token)=>{setToken(token)})
  }, []);

    useEffect(() => {
      if(states.upDown && requestParams[1] != null){
        getDataUpper();
      } else if( !states.upDown && requestParams[1] != null){
        getChartLineDataExample();
      }
    }, [catDic]);

    useEffect(() => {
        if(requestParams[0]!==null){
            getInfoTransactions()
        }
    }, [arr]);

    const getDataUpper = async() => {
      let headers = new Headers();
      const bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);
      headers.append('Content-Type', 'application/json');

      let body = {
        clientID: idClient,
        startDate: requestParams[1], 
        endDate: requestParams[2],
        groupType: requestParams[3],
        categoryGroup: requestParams[0],
        language: language,
        logsInfo: {
          origin: upDown === true ? 'Resumen - Flujo neto de efectivo' : 'Resumen - Caja neta',
          sessionID: sessionID
        }
      };

      if(idRS) {
        body.idRS = idRS.toString();
      }else if(requestParams[4]){
        body.idRS = requestParams[4].toString();
      }else {
        body.idRS = '';
      }

      const response = await fetch(`${urlBase}/chart/UppersideCharts`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
      });

      if(!response.ok) {
        throw new Error('Network response was no ok');
      }

      if(response.status === 200) {
        const aux = await response.json();

          if(requestParams[3] === 2) {
            let nuevoWeekArray = [];
            for(let i = 0; i < aux.dayListLabels.length; i++){
              let fullWeekInfo = aux.dayListLabels[i].split(" ");
              let year = fullWeekInfo[1];
              let numberWeek = fullWeekInfo[0].split("S")[1];
              nuevoWeekArray.push(dateFormatter(fiscalWeeks[year][numberWeek][language]));
            }
            aux.realDate = nuevoWeekArray;
          }

        setState( prevState => ({
          ...prevState,
            info: aux.dotCharInfo,
            realInfo: aux.realDotChartInfo,
            dates: aux.dayListLabels,
            realDate: aux.realDate,
            big1: aux.entradasEfectivo,
            big1Real: aux.entradasEfectivoHover,
            big2: aux.outflowCash,
            big2Real: aux.salidasEfectivoHover,
            big3: aux.totalCash,
            big3Real: aux.totalCashflowHover,
            divider: aux.dividerSign
        }) )
      }
    }

    const formatDatesTitle2 = ( date ) => {
      if( !date ) {
        return;
      }

      let monthDict;

      if(language === 'es') {
        monthDict = {
          "01": 'enero', 
          "02": 'febrero',
          "03": 'marzo',
          "04": 'abril',
          "05": 'mayo',
          "06": 'junio',
          "07": 'julio',
          "08": 'agosto',
          "09": 'septiembre',
          "10": 'octubre', 
          "11": 'noviembre', 
          "12": 'diciembre'
        }
      }else {
        monthDict = {
          "01": 'january',
          "02": 'february',
          "03": 'march',
          "04": 'april',
          "05": 'may',
          "06": 'june',
          "07": 'july',
          "08": 'august',
          "09": 'september',
          "10": 'october',
          "11": 'november',
          "12": 'december'
      }
      }



      const currentDate = new Date();
      let currentFormatted = currentDate.toISOString().slice(0, 10).replace(/-/g, "");

      if(date > currentFormatted){
        date = currentFormatted;
      }

      const year = date.slice(0, 4);
      const month = date.slice(4, 6);
      const day = date.slice(6, 9);

      if(language === 'es') {
        return `al ${day} de ${monthDict[month]} de ${year}`;
      }else {
        return `as of ${monthDict[month]} ${day}, ${year}`;
      }
    }

    const fechas = {
      'es': {
          'enero': 1,
          'febrero': 2,
          'marzo': 3,
          'abril': 4,
          'mayo': 5,
          'junio': 6,
          'julio': 7,
          'agosto': 8,
          'septiembre': 9,
          'octubre': 10,
          'noviembre': 11,
          'diciembre': 12
      },
      'en': {
          'january': 1,
          'february': 2,
          'march': 3,
          'april': 4,
          'may': 5,
          'june': 6,
          'july': 7,
          'august': 8,
          'september': 9,
          'october': 10,
          'november': 11,
          'december': 12
      }
  };
  

  const dateFormatter = ( date ) => {
    let fechaDividida
    if(language === 'es') {
        fechaDividida = date.split(" ");
        return `${parseInt(fechaDividida[1]) < 10 ? "0" + fechaDividida[1] : fechaDividida[1]}/${fechas[language][fechaDividida[3]]}`
    }
    fechaDividida = date.split(" ");
    return `${fechas[language][fechaDividida[1]]}/ ${parseInt(fechaDividida[2]) < 10 ? "0" + fechaDividida[2] : fechaDividida[2]}`
}

    const getChartLineDataExample = async() => {
      let headers = new Headers();
      const bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);
      headers.append('Content-Type', 'application/json');

      let body = {
          clientID: idClient,
          startDate: requestParams[1],
          endDate: requestParams[2],
          groupType: requestParams[3],
          language: language,
          logsInfo: {
            origin: upDown === true ? 'Resumen - Flujo neto de efectivo' : 'Resumen - Caja neta',
            sessionID: sessionID
          }
      }

      if(idRS) {
          body.idRS = idRS.toString();
      }else if(requestParams[4]) {
          body.idRS = requestParams[4].toString();
      }else {
          body.idRS = '';
      }

      const response = await fetch(`${urlBase}/chart/customeFilterChart`, {
          method: 'POST', 
          headers: headers,
          body: JSON.stringify(body)
      })

      if(!response.ok) {
          throw new Error('Network response was no ok');
      }

      if(response.status === 200){
          const aux = await response.json();
          if(requestParams[3] === 2) {
            let nuevoWeekArray = [];
            for(let i = 0; i < aux.dayListLabels.length; i++){
              let fullWeekInfo = aux.dayListLabels[i].split(" ");
              let year = fullWeekInfo[1];
              let numberWeek = fullWeekInfo[0].split("S")[1];
              nuevoWeekArray.push(dateFormatter(fiscalWeeks[year][numberWeek][language]));
            }
            aux.realDate = nuevoWeekArray;
          } 

          setState( prevState => ({
            ...prevState,
              info: aux.total,
              realInfo: aux.totalReal,
              dates: aux.dayListLabels,
              realDate: aux.realDate,
              big1: aux.bigDebit,
              big2: aux.bitCredit,
              big3: aux.totalCreditDebit,
              big1Real:  aux.bigDebitReal,
              big2Real: aux.bigCreditReal,
              big3Real: aux.totalCreditDebitReal,
              divider: aux.dividerSign
          }) );
      }
  }

    const getDataDown = async() => {
      let headers = new Headers();
      const bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);
      headers.append('Content-Type', 'application/json');

      let body = {
        clientID: idClient,
        startDate: requestParams[1], 
        endDate: requestParams[2],
        groupType: 3, 
        categoryGroup: requestParams[0],
        logsInfo: {
          origin: upDown === true ? 'Resumen - Flujo neto de efectivo' : 'Resumen - Caja neta',
          sessionID: sessionID
        }
      };

      if(idRS) {
        body.idRS = idRS.toString();
      }else if(requestParams[4]) {
        body.idRS = requestParams[4].toString();
      }else {
        body.idRS = '';
      }

      const response = await fetch(`${urlBase}/chart/downSideCharts`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
      });

      if(!response.ok) {
        throw new Error('Network response was no ok');
      }

      if(response.status === 200) {
        const aux = await response.json()

        setState( prevState => ({
          ...prevState,
            info: aux.total,
            dates: aux.dayListLabels,
            big1: aux.bigDebit,
            big2: aux.bitCredit,
            big3: aux.totalCreditDebit,
            divider: aux.dividerSign
        }) );
      }
    }

    const getCategories = async() => {
        let headers = new Headers()
  
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json');
  
        fetch(`${urlBase}/getCategories`,{
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
            "clientID": idClient,
            "lowest": 0,
            showHidden: true,
            showCreditFinancing:true,
            forReport:true,
            logsInfo: {
              origin: upDown === true ? 'Resumen - Flujo neto de efectivo' : 'Resumen - Caja neta',
              sessionID: sessionID
            }
          })
        }).then((response) => response.json())
          .then((aux1) => {
            let aux = aux1.categories
            let notUse = aux1.notUse
            let catDict = {}
  
            let flows = {"+": [], "-" : []};
  
            for(let i = 0;i<aux.length;i++){
              for(let j = 0; j < aux[i].subcategories.length; j++){
                catDict[aux[i].subcategories[j].idCategory] = {
                  "name": aux[i].subcategories[j].category,
                  "id": aux[i].subcategories[j].idCategory,
                  "isActive" : aux[i].subcategories[j].isActive === true,
                }
                for(let k = 0;k<aux[i].subcategories[j].subcategories.length;k++){
                  catDict[aux[i].subcategories[j].subcategories[k].idCategory] = {
                  "name": aux[i].subcategories[j].subcategories[k].category,
                  "id": aux[i].subcategories[j].subcategories[k].idCategory,
                  "isActive" : aux[i].subcategories[j].subcategories[k].isActive === true,
                  "idFather" : aux[i].subcategories[j].subcategories[k].idCategoryFather,
                  }
  
                  if(aux[i].orderNumber === 1 && requestParams[0] === 1){
                    flows[aux[i].subcategories[j].subcategories[k].flowType] = [...flows[aux[i].subcategories[j].subcategories[k].flowType], aux[i].subcategories[j].subcategories[k].idCategory]
                  }
  
                  if(aux[i].orderNumber === 2 && requestParams[0] === 2){
                    flows[aux[i].subcategories[j].subcategories[k].flowType] = [...flows[aux[i].subcategories[j].subcategories[k].flowType], aux[i].subcategories[j].subcategories[k].idCategory]
                  }
  
                  if(aux[i].orderNumber === 3 && requestParams[0] === 3){
                    flows[aux[i].subcategories[j].subcategories[k].flowType] = [...flows[aux[i].subcategories[j].subcategories[k].flowType], aux[i].subcategories[j].subcategories[k].idCategory]
                  }
  
                  if(requestParams[0] === 0 && (aux[i].orderNumber === 1 || aux[i].orderNumber === 2 || aux[i].orderNumber === 3)){
                    flows[aux[i].subcategories[j].subcategories[k].flowType] = [...flows[aux[i].subcategories[j].subcategories[k].flowType], aux[i].subcategories[j].subcategories[k].idCategory]
                  }
                }
              }
            }
            setArr(flows['+'].concat(flows['-']))
            setCatDic(catDict) 
          });
    }

    const titlesFormat = (date1, date2) => {
      // Diccionario de meses en español e inglés
      const monthDict = language === 'es' ? {
        "01": 'ene', "02": 'feb', "03": 'mar', "04": 'abr', "05": 'may', "06": 'jun',
        "07": 'jul', "08": 'ago', "09": 'sep', "10": 'oct', "11": 'nov', "12": 'dic'
      } : {
        "01": 'Jan', "02": 'Feb', "03": 'Mar', "04": 'Apr', "05": 'May', "06": 'Jun',
        "07": 'Jul', "08": 'Aug', "09": 'Sep', "10": 'Oct', "11": 'Nov', "12": 'Dec'
      };
    
      // Función auxiliar para formatear una fecha individual
      const formatDate = (date) => {
        const year = date.slice(0, 4);
        const month = date.slice(4, 6);
        const day = date.slice(6, 9);
        return language === 'es' ? `${day}/${monthDict[month]}/${year}` : `${monthDict[month]}/${day}/${year}`;
      };
    
      // Validación de las fechas
      if (!date1 || !date2) return "Invalid dates";
    
      // Formatear ambas fechas
      const formattedDate1 = formatDate(date1);
      const formattedDate2 = formatDate(date2);

      if(!states.upDown) {
        return language === 'es' ? `al ${formattedDate2}` : `to ${formattedDate2}`;
      }
      return language === 'es' ? `del ${formattedDate1} al ${formattedDate2}` : `from ${formattedDate1} to ${formattedDate2}`;
    };

    const formatDatesTitle = ( date ) => {
        if( !date ) {
            return;
        }
  
        const year = date.slice(0, 4);
        const month = date.slice(4, 6);
        const day = date.slice(6, 9);
  
        return `${month}-${day}-${year}`;
    }

    const getInfoTransactions = () => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append('Content-Type', 'application/json');
        let body = {
            clientID: idClient,
            startDate: formatDatesTitle(requestParams[1]),
            endDate: formatDatesTitle(requestParams[2]),
            showState: "classified",
            logsInfo: {
              origin: upDown === true ? 'Resumen - Flujo neto de efectivo' : 'Resumen - Caja neta',
              sessionID: sessionID
            }
        };

        if(idRS) {
            body.idRS = idRS.toString();
        }else if(requestParams[4] && requestParams[4].length > 0) {
            body.rsID = requestParams[4];
        }

        body.categories = arr;

        fetch(`${urlBase}/getLineageTransactions`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
            let newData = []
            let newExcelData = []
            for(let i = 0;i<aux.length;i++){
                newData[i] = {
                    'bank':aux[i].bank,
                    'date':aux[i].date_d.slice(0,10),
                    'id':aux[i].idTransaction,
                    'context':aux[i].context,
                    'reference':aux[i].references_c,
                    'inflow':aux[i].inflow ,
                    'outflow':aux[i].outflow,
                    'category':catDic[catDic[aux[i].idCategory]?.idFather]?.name || '',
                    'subcategory':catDic[aux[i].idCategory]?.name || '',
                    'exchange':aux[i].exchange || aux[i].defaultFX ,
                    "updated":null,
                    'accountType':aux[i].accountType==='D'?'Débito':aux[i].accountType==='C' ? 'Crédito' :'NA',
                    'signature':aux[i].transactionSignature,
                    'notes': aux[i].notes,
                    'idAccount':aux[i].accountAlias || aux[i].accountNumber,
                    'currency': aux[i].currency,
                    'rsName':aux[i].rsName,
                    'extraText': aux[i].extraText
                }
                newExcelData[i] = {
                    'Empresa':aux[i].rsName,
                    'Banco':aux[i].bank,
                    'Cuenta':aux[i].accountAlias || aux[i].accountNumber,
                    'Fecha':aux[i].date_d.slice(0,10),
                    'Concepto':aux[i].context,
                    'Abono':aux[i].inflow ,
                    'Cargo':aux[i].outflow,
                    'Categoría':catDic[catDic[aux[i].idCategory]?.idFather]?.name || '',
                    'Subcategoría':catDic[aux[i].idCategory]?.name || '',
                    'Moneda': aux[i].currency,
                    'FX':aux[i].exchange || aux[i].defaultFX ,
                    'Notas': aux[i].notes,
                    'Información adicional':''
                }
                let jsonAux = JSON.parse(aux[i].extraText || "{}")
                let jsonKeys = Object.keys(jsonAux)
                for(let j=0;j<jsonKeys.length;j++){
                    newExcelData[i]['Información adicional'] = `${newExcelData[i]['Información adicional']}${jsonKeys[j]}:${jsonAux[jsonKeys[j]]}${jsonKeys.length!==j+1 ? '\n':''}`
                }
            }
            setData(newData);
            //setExcelLineageData(newExcelData)
        });
    }

    const genLineageCell = (cell,row) => {
		if(cell.column.id==='exchange'){
			return (
				<div className='text-right relative tabular-nums w-[100%] lg:text-h13-2xl md:text-h13-2xl sm:text-[8px]'>
				<span>{cell.value ? (parseFloat(cell.value)).toLocaleString("en-US", {
				  style: "currency",
				  currency: "USD",
				  currencyDisplay: 'code',
				  minimumSignificantDigits:'4'}).slice(4) : cell.value==0 ? '--' : ''}</span>
				</div>
			)
		}else if(cell.column.id==='inflow'){
			return (
				<div className='text-right relative tabular-nums w-[100%] pr-4 lg:text-h13-2xl md:text-h13-2xl sm:text-[8px]'>
				<span>{cell.value ? (parseFloat(cell.value)).toLocaleString("en-US", {
				  style: "currency",
				  currency: "USD",
				  currencyDisplay: 'code'}).slice(4) : '--'}</span>
				</div>
			)
		}else if(cell.column.id==='outflow'){
			return (
				<div className='text-right relative tabular-nums w-[100%] pr-4 lg:text-h13-2xl md:text-h13-2xl sm:text-[8px]'>
        <span>{cell.value ? `${numberFormat===0?'(':'-'}${(parseFloat(cell.value)).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    currencySign: "accounting",
                    currencyDisplay: 'code'
                    }).slice(4)}${numberFormat===0?')':''}` : '--' }</span>
				</div>
			)
		}else if(cell.column.id==='date'){
			return <div className='w-[100%] lg:text-h13-2xl md:text-h13-2xl sm:text-[8px]'>{language === 'es' ? moment(cell.value).locale(language).format('D/MMM/YYYY').replace('.','') : moment(cell.value).locale(language).format('MMM/D/YYYY').replace('.','') }</div> 
		}else if(cell.column.id === 'extraText'){
			if(row.original.extraText){
			  let json = JSON.parse(row.original.extraText)
			  let aux = Object.keys(json) 
			  let auxText = ``
			  for(let i=0;i<aux.length;i++){
				auxText =auxText+ `${aux[i]} : ${json[aux[i]]},\n`
			  } 
			  return <div className='w-[100%] text-left truncate lg:text-h13-2xl md:text-h13-2xl sm:text-[8px]' title={auxText}>{auxText}</div>
			}else{
			  return <div className=' w-[100%] text-left truncate lg:text-h13-2xl md:text-h13-2xl sm:text-[8px]'>{cell.value}</div>    
			} 
			
		}else{
			return (
				<div className=' w-[100%] lg:text-h13-2xl md:text-h13-2xl sm:text-[8px]' title={cell.value}>
					{cell.value?.length>50 ? cell.value?.slice(0,50) + '...' : cell.value}
				</div>
			)
		}
		
	}

  useEffect(() => {
    const currentScreen = window.innerWidth;
    if (currentScreen <= 1366 && currentScreen >= 1281) {
      setWidth(2);
    } else if (currentScreen <= 1280 && currentScreen >= 1125) {
      setWidth(3);
    } else if(currentScreen <= 1024 && currentScreen >= 901){
      setWidth(4);
    }else {
        setWidth(1);
    }
  }, [])

    const scrollBarSize = React.useMemo(() => scrollbarWidth(), [])
	const defaultColumn = React.useMemo(
        () => ({
        minWidth: 10, // minWidth is only used as a limit for resizing
        width: 120, // width is used for both the flex-basis and flex-grow
        maxWidth: 1800, // maxWidth is only used as a limit for resizing
        }),
        []
    )

    const handlerDatesTransactions = ( date )  => {
        if(!date) {
            return;
        }

        const year = date.slice(0, 4);
        const month = date.slice(4, 6);
        const day = date.slice(6, 9);

        return new Date(year, parseInt(month, 10) - 1,day);
    }
    const columns = React.useMemo(
        () => [
          ...(requestParams[4] && requestParams[4].length > 0
              ? [{
                      Header: Idioma.transacciones.configuracion.empresa[language],
                      accessor: 'rsName',
                      className: 'lg:text-h13-2xl md:text-h13-2xl sm:text-[9px]'
                  }]
              : [] ),
          {
              Header: Idioma.transacciones.configuracion.banco[language],
              accessor: 'bank',
              className: 'lg:text-h13-2xl md:text-h13-2xl sm:text-[9px] sm:font-bold',
              width: width === 4 ? 70 : 200
          },
          {
              Header: Idioma.transacciones.configuracion.cuentaBanco[language],
              accessor: 'idAccount',
              className: 'lg:text-h13-2xl md:text-h13-2xl sm:text-[9px] sm:font-bold',
              width: 200
          },
          {
              Header: Idioma.transacciones.configuracion.fecha[language],
              accessor: 'date',
              sortType:'basic',
              width: width === 4 ? 70 : 100,
              className: 'text-left lg:text-h13-2xl md:text-h13-2xl sm:text-[9px] sm:font-bold',
          },
          {
              Header: Idioma.transacciones.configuracion.concepto[language],
              accessor: 'context',
              className: 'text-left sm:text-[9px] sm:font-bold',
              width: width === 1 ? 350 : width === 2 ? 100 : width === 4 ? 30 : 50
          },
          {
              Header: Idioma.transacciones.configuracion.abono[language],
              className: 'sm:text-[9px] sm:font-bold',
              filter: 'between',
              accessor: 'inflow',
          },
          {
              Header: Idioma.transacciones.configuracion.cargo[language],
              className: 'sm:text-[9px] sm:font-bold',
              filter: 'between',
              width: width === 4 ? 70 : 100,
              accessor: 'outflow',
          },
          {
              Header: Idioma.transacciones.configuracion.categoria[language],
              className: 'sm:text-[9px] sm:font-bold',
              accessor: 'category',
              width: document.documentElement.clientHeight * 0.20,
          },
          {
              Header: Idioma.transacciones.configuracion.subcategoria[language],
              className: 'sm:text-[9px] sm:font-bold',
              accessor: 'subcategory',
              width: width === 1 ? 250 : width === 2 ? 100 : 100,
          },
          {
              Header: Idioma.transacciones.configuracion.moneda[language],
              className: 'sm:text-[9px] sm:font-bold',
              accessor: 'currency',
          },
      ],
        [requestParams]
      )

    const redirectLineage = () => {
		setContextBank({name:'Banco'})
		setContextAccount({name: 'Cuenta de banco'})
		if(requestParams[4] !== null && !idRS){
			setContextRSID(requestParams[4])
		}else{
			setContextRSID([])
		}
        setContextCategoriesIds(arr);
		setContextFile({name:'Seleccionar Archivo',percentage:''})
		setContextShowState('classified')
		setContextDateRange([handlerDatesTransactions(requestParams[1]), handlerDatesTransactions(requestParams[2])])
		navigate('/classification')
	}

	const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        totalColumnsWidth,
        prepareRow,
        allColumns,
        state,
        setSortBy,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
      } = useTable(
        {
          columns,
          data,
          autoResetPage: false,
          defaultColumn, // Be sure to pass the defaultColumn option
          autoResetSortBy:false
        },
        useSortBy,
        useBlockLayout,
      )

	  const RenderRow = React.useCallback(
        (rows) => ({ index, style }) => {
          const row = rows[index];
          prepareRow(row);
          return (
            <div
            {...row.getRowProps({
              style
            })}
            >
              {row.cells.map((cell) => {
                return (
                  <div><td {...cell.getCellProps()} className='w-[100%] '>{genLineageCell(cell,row)}</td></div>
                );
              })}
            </div>
          );
        },
        [prepareRow,data]
      );

    const handleEnterhover = ( boxNumber ) => {
      if(boxNumber === 1) {
        setHover1(true);
      }else if(boxNumber === 2) {
        setHover2(true);
      }else {
        setHover3(true);
      }
    }

    const handleLeavehover = ( boxNumber ) => {
      if(boxNumber === 1) {
        setHover1(false);
      }else if(boxNumber === 2) {
        setHover2(false);
      }else {
        setHover3(false);
      }
    }

    let xAxisData = states && requestParams[3] === 2 ? states.realDate : (info ? states.dates : []);

    let options = states ? {
      trigger: 'axis',
      axisPointer: {
          type: 'shadow'
      },
      tooltip: {
          formatter: (params) => {
              let index = params.dataIndex;
  
              if (typeof index !== 'number') {
                  index = states.dates.findIndex(month => month.startsWith(index));
              }
  
              let month = (typeof index === 'number' && states.dates[index]) ? states.dates[index] : 'Indice no valido';
  
              let result;
              if(requestParams[3] === 2) {
                let newValues = month.split(" ");
                let week = newValues[0].split('S')[1];
                result = formatterDates3Letters(fiscalWeeks[newValues[1]][week][language], language) + " " + newValues[1] + '<br/>';
              }else {
                result = `${month} <br/>`;
              }
              let amount = new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  currencySign: numberFormat === 0 ? 'accounting' : 'standard',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
              }).format(states.realInfo[index]);
              let valueWithoutSign = amount.replace(/\$/g, '');
              result += `${params.marker} ${valueWithoutSign}`;
              return result;
          }
      },
      grid: {
          left: 0,
          containLabel: true
      },
      xAxis: {
          type: 'category',
          data: xAxisData,
          axisLabel: {
            rich: {
              large: {
                fontSize: 20,
                fontWeight: 'bold',
              }
            },
          }
      },
      yAxis: {
          type: 'value'
      },
      series: [
          {
              data: states.info,
              type: 'line',
              label: {
                show: showlabels,
                position: 'top',
                formatter: (params) => new Intl.NumberFormat('en-US', {maximumFractionDigits: 1, minimumIntegerDigits: 1}).format(params.value)
              }
          }
      ],
      color: '#8829FF'
  } : {};


  const toggle = (event) => {
    const newState = event.target.checked;
    setShowLabels(newState);
    sessionStorage.setItem('showLabels3', newState);
  };
    return(
        <div className="w-full pl-[5%]">
          <div className="flex flex-wrap items-start">
            <DashboardFilters setRequestParams={setRequestParams} innerGraph={true} flowFilter={upDown}/>
            <div className='flex ml-5 items-center'>
            <div>
                <label for="default-toggle1" class="inline-flex mt-1 relative items-center cursor-pointer ml-6">
                    <input type="checkbox" checked={showlabels} onClick={toggle}  id="default-toggle1" class="sr-only peer"/>
                    <div class="2xl:w-11 w-8 2xl:h-6 h-4 rounded-full peer bg-md-white border-tertiary peer-checked:border-md-white border-1 peer-checked:bg-tertiary  peer-checked:after:translate-x-full after:content-[''] after:absolute 2xl:peer-checked:after:top-[4px] peer-checked:after:top-[2px] 2xl:peer-checked:after:left-[8px] peer-checked:after:left-[5px] 2xl:after:top-[4px] after:top-[2px] 2xl:after:left-[4px] after:left-[2px] after:bg-tertiary peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full 2xl:after:h-4 after:h-3 2xl:after:w-4 after:w-3 after:transition-all"></div>
                </label>
              </div>
            <div className='w-40 ml-5'>
              {showlabels 
              ? (
                <h2>{Idioma.resumen.desactivarLabels[language]}</h2>
              ) 
              : (
                <h2>{Idioma.resumen.activarLabels[language]}</h2>
              )
              }
            </div>
          </div> 
          </div>
            <div className="w-[63%] xl:w-[80%] lg:w-[90%] md:w-[85%] flex mt-[15px] sm:w-[85%] xs:w-[80%] xxs:w-[90%] mobileL:grid mobileL:grid-cols-1 mobileL:w-full mobileM:grid mobileM:grid-cols-1 mobileM:w-full mobileS:grid mobileS:grid-cols-1 mobileS:w-full">
              <div className="bg-light-purple-5 w-[292px] rounded-main p-5 mr-[43px] lg:w-[260px] md:w-[30%] sm:w-[30%] xs:w-[30%] xxs:w-[30%] mobileL:w-[95%] mobileL:mb-5 mobileM:w-[95%] mobileM:mb-5 mobileS:w-[95%] mobileS:mb-5">
                <h3 className="text-p5-2xl xl:text-p6-2xl lg:text-p6-2xl md:text-p8-2xl sm:text-p8-2xl xs:text-p9-2xl xxs:text-p10-2xl mobileL:text-p6-2xl text-purple-1 mb-1">
                  {Idioma.resumen.entradaEfectivo[language]}
                </h3>
                <p
                  className="text-[40px] xl:text-h2-2xl lg:text-h5-2xl md:text-[27px] sm:text-[25px] xs:text-h8-2xl xxs:text-h10-2xl mobileL:text-h5-2xl mobileS:text-h5-2xl font-bold text-purple-1"
                  onMouseEnter={() => handleEnterhover(1)}
                  onMouseLeave={() => handleLeavehover(1)}
                >
                  {new Intl.NumberFormat('en-US', {
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    style: 'currency',
                    currencySign: numberFormat === 0 ? 'accounting' : 'standard',
                  }).format(states.big1)} 
                  <span className="text-4xl font-normal sm:text-2xl xs:text-xl xxs:text-base">{states.divider}</span>
                </p>
                {hover1 && (
                  <div className="absolute w-[121px] p-2 bg-slate-50 border border-gray-300 shadow-2xl rounded-md z-50">
                    <div className="text-xl">
                      <h2 className="text-[10px] text-center">{DateFomatterDBFormat(requestParams[2], language)}</h2>
                      <p className="text-center text-sm font-bold">
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          currencySign: numberFormat === 0 ? 'accounting' : 'standard',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(states.big1Real)}
                      </p>
                    </div>
                  </div>
                )}
                <p className="text-p8-2xl text-purple-1 sm:text-[10px] xs:text-[10px] xxs:text-[10px] mobileL:text-[12px]">
                  {titlesFormat(requestParams[1], requestParams[2])}
                </p>
              </div>
                <div className="bg-light-purple-4 w-[292px] rounded-main p-5 mr-[43px] lg:w-[260px] md:w-[30%] sm:w-[30%] xs:w-[30%] xxs:w-[30%] mobileL:w-[95%] mobileL:mb-5 mobileM:w-[95%] mobileM:mb-5 mobileS:w-[95%] mobileS:mb-5">
                    <h3 className="text-p5-2xl xl:text-p6-2xl lg:text-p6-2xl md:text-p8-2xl sm:text-p8-2xl xs:text-p9-2xl xxs:text-p10-2xl mobileL:text-p6-2xl text-purple-1 mb-1">{Idioma.resumen.salidaEfectivo[language]}</h3>
                    <p className="text-[40px] xl:text-h2-2xl lg:text-h5-2xl md:text-[27px] sm:text-[25px] xs:text-h8-2xl xxs:text-h10-2xl mobileL:text-h5-2xl mobileS:text-h5-2xl font-bold text-purple-1" onMouseEnter={() => handleEnterhover(2)} onMouseLeave={() =>handleLeavehover(2)}>{new Intl.NumberFormat('en-US', {currency: 'USD', minimumFractionDigits: 0, style: 'currency', currencySign: numberFormat === 0 ? 'accounting' : 'standard'}).format(states.big2)} <span className='text-4xl font-normal sm:text-2xl xs:text-xl xxs:text-base'>{states.divider}</span></p>
                    {hover2 && (
                      <div className="absolute w-[121px] p-2 bg-slate-50 border border-gray-300 shadow-2xl rounded-md z-50">
                        <div className="text-xl">
                          <h2 className="text-[10px] text-center">{DateFomatterDBFormat(requestParams[2], language)}</h2>
                          <p className="text-center text-sm font-bold">
                            {new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: 'USD',
                              currencySign: numberFormat === 0 ? 'accounting' : 'standard',
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(states.big2Real)}
                          </p>
                        </div>
                      </div>
                    )}
                    <p className='text-p8-2xl text-purple-1 sm:text-[10px] xs:text-[10px] xxs:text-[10px] mobileL:text-[12px]'>{titlesFormat(requestParams[1], requestParams[2])}</p>
                </div>
                <div className="bg-light-purple-3 w-[292px] rounded-main p-5 lg:w-[260px] md:w-[30%] sm:w-[30%] xs:w-[30%] xxs:w-[30%] mobileL:w-[95%] mobileM:w-[95%] mobileS:w-[95%]">
                    <h3 className="text-p5-2xl xl:text-p6-2xl lg:text-p6-2xl md:text-p8-2xl sm:text-p8-2xl xs:text-p9-2xl xxs:text-p10-2xl mobileL:text-p6-2xl text-neutral-5 mb-1">{Idioma.resumen.flujoNetoEfectivo[language]}</h3>
                    <p className="text-[40px] xl:text-h2-2xl lg:text-h5-2xl md:text-[27px] sm:text-[25px] xs:text-h8-2xl xxs:text-h10-2xl mobileL:text-h5-2xl mobileS:text-h5-2xl font-bold text-neutral-5 md:text-p3-2xl sm:text-p3-2xl" onMouseEnter={() => handleEnterhover(3)} onMouseLeave={() =>handleLeavehover(3)}>{new Intl.NumberFormat('en-US', {currency: 'USD', minimumFractionDigits: 0, style: 'currency', currencySign: numberFormat === 0 ? 'accounting' : 'standard'}).format(states.big3)} <span className='text-4xl font-normal sm:text-2xl xs:text-xl xxs:text-base'>{states.divider}</span></p>
                    {hover3 && (
                      <div className="absolute w-[121px] p-2 bg-slate-50 border border-gray-300 shadow-2xl rounded-md z-50">
                        <div className="text-xl">
                          <h2 className="text-[10px] text-center">{DateFomatterDBFormat(requestParams[2], language)}</h2>
                          <p className="text-center text-sm font-bold">
                            {new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: 'USD',
                              currencySign: numberFormat === 0 ? 'accounting' : 'standard',
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(states.big3Real)}
                          </p>
                        </div>
                      </div>
                    )}
                    <p className='text-p8-2xl text-neutral-6 sm:text-[10px] xs:text-[10px] xxs:text-[10px] mobileL:text-[12px]'>{titlesFormat(requestParams[1], requestParams[2])}</p>
                </div>
            </div>
            {upDown ? 
              <h3 className="text-h8-2xl mt-10 lg:text-h11-2xl md:text-h12-2x sm:text-h9-2xl">{Idioma.resumen.graficasArriba.evolucionFlujoEfectivo[language]}</h3>
            : 
              <h3 className="text-h8-2xl mt-10 lg:text-h11-2xl md:text-h12-2xl sm:text-h9-2xl">{Idioma.resumen.graficasAbajo.evolucionCajaNeta[language]}</h3>
            }
            <div className="w-[100%] ml-32 lg:mt-[5px] md:mt-[0px] sm:w-full">
                <ReactEChartsCore 
                    echarts={echarts}
                    option={options}
                    notMerge
                    lazyUpdate
                    theme={"theme_name"}
                    style={{ width: '70%', height: states.upDown ? '350px' : '450px' }}
                />
            </div>
            {states.upDown && idClient && userAccess &&
              <>
                {userAccess.access2 &&                
                  <div>
                      <div className="w-[95%] flex justify-between">
                          <h2 className="text-xl text-h6-2xl lg:text-h11-2xl md:text-h9-2xl">{Idioma.resumen.graficasArriba.detalleTransacciones[language]}</h2>
                          <button className="bg-light-purple-3 p-2 text-white rounded-button sm:w-[180px] sm:h-[35px] sm:text-p8-2xl" onClick={() => redirectLineage()}>{Idioma.resumen.graficasArriba.abrirTransacciones[language]}</button>
                      </div>
                      <div className="relative overflow-x-auto thin-scrollbar">
                        <table {...getTableProps()} className="table">
                            <thead>
                                {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()} className={' py-2 '}>
                                    {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()} className={column.className + '  '}>
                                        {<div className='text-[10px] mb-2'>{column.canFilter && column.render('Filter')}</div>}
                                        {
                                        <p className={`flex pr-2 lg:text-h13-2xl md:text-h13-2xl 2xl:text-[16px] items-center cursor-pointer ${column.id=='inflow'||column.id=='outflow'||column.id=='exchange'?' flex justify-end':''}`} onClick={()=>{
                                            const desc =
                                            column.isSortedDesc === true ?
                                            undefined :
                                            column.isSortedDesc === false ?
                                            true :
                                            false ;
                                            if(desc===undefined){
                                            setSortBy([/*...sortees*/])
                                            }else if(desc===true){
                                            setSortBy([{id:column.id,desc:desc}/*,...sorteesTrue*/])
                                            }else{
                                            setSortBy([{id:column.id,desc:desc}/*,...sortees*/])
                                            }
                                            
                                            
                                        }} >{column.Header}
                                        <span className='ml-1'>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                            ? <Triangulo/>
                                            : <div className='rotate-180'><Triangulo/></div>
                                            : <></>}
                                            
                                            
                                        </span></p>
                                        }
                                    </th>
                                    ))}
                                </tr>
                                ))}
                            </thead>
        
                            <tbody {...getTableBodyProps()} className='text-p9-2xl'>
                                <FixedSizeList
                                height={document.documentElement.clientHeight*.30}
                                itemCount={rows.length}
                                itemSize={35}
                                width={totalColumnsWidth+scrollBarSize}
                                >
                                {RenderRow(rows)}
                                </FixedSizeList>
                            </tbody>
                        </table>
                      </div>
                  </div>
                }              
              </>
            }
        </div>
    );
}


export default TotalSubPage;