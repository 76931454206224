import React from "react";
import { useMsal } from "@azure/msal-react";
import {loginRequest} from '../authConfig'
import imageGris from '../assets/imageGris.png'
import imageBlue from '../assets/imageBlue.png'
import { ContextProvider, useStateContext } from "../contexts/ContextProvider";
import LoginUserLogo from "../assets/LoginUserLogo";

function handleLogin(instance) {
    instance.loginPopup(loginRequest).catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will open a popup for login
 */
const SignInButton = ({text}) => {
    const { instance } = useMsal();
    const {currentMode} = useStateContext()
    return (
        <button  variant="secondary" className={" w-button-1 h-button-1 flex justify-center items-center rounded-button bg-light-purple-3 "} onClick={() => handleLogin(instance)}>
            <span className=" text-b3-2xl  font-normal text-white">{text}</span>
        </button>
    );
}

export default SignInButton