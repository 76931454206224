import React from 'react'

const PaymentLogo = () => {
    return (
        <svg id="uuid-53485301-8ab0-49bc-96a6-0fb6ef794165" data-name="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.21 33.97">
        <defs>
            <style>
            {`
                .uuid-44e3adea-ee8e-45ca-9247-1301fe1dd0dc {
                    fill: #d3d3d3;
                }
            `}
            </style>
        </defs>
            <path class="uuid-44e3adea-ee8e-45ca-9247-1301fe1dd0dc" d="M40.86,0H3.35C1.5,0,0,1.5,0,3.35v27.26c0,1.85,1.5,3.35,3.35,3.35h37.5c1.85,0,3.35-1.5,3.35-3.35V3.35c0-1.85-1.5-3.35-3.35-3.35ZM3.35,2.07h37.5c.71,0,1.29.58,1.29,1.29v4.16H2.07V3.35c0-.71.58-1.29,1.29-1.29ZM40.86,31.9H3.35c-.71,0-1.29-.58-1.29-1.29V12.96h40.08v17.65c0,.71-.58,1.29-1.29,1.29Z" />
            <path class="uuid-44e3adea-ee8e-45ca-9247-1301fe1dd0dc" d="M14.65,20.1c-1.26,0-2.36.62-3.05,1.57-.68-.95-1.79-1.57-3.05-1.57-2.08,0-3.76,1.68-3.76,3.76s1.68,3.76,3.76,3.76c1.26,0,2.36-.62,3.05-1.57.68.95,1.79,1.57,3.05,1.57,2.08,0,3.76-1.68,3.76-3.76s-1.68-3.76-3.76-3.76Z" />
        </svg>
    );
}

export default PaymentLogo;