import { useNavigate } from 'react-router-dom';

const Success = () => {
    const navigate = useNavigate();
    return(
        <div className="w-full text-center justify-center items-center">
            <span className="text-8xl">🎉</span>
            <h2 className="text-center font-bold text-3xl mt-10">Su pago ha si efectuado, bienvenido a MD Cash</h2>
        </div>
    );
}

export default Success;