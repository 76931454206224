import React from 'react'

const ReportOrder2 = (props) => {
  return (
    <svg id="uuid-bdb0f40f-db36-4cd2-93db-b62d7b8e687b" data-name="Capa_1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <path fill='#9f9f9f' d="M28.53,0H1.47C.66,0,0,.66,0,1.47v27.05c0,.81.66,1.47,1.47,1.47h27.05c.81,0,1.47-.66,1.47-1.47V1.47c0-.81-.66-1.47-1.47-1.47Z"/>
    <g>
      <rect fill='#fff' x="5.4" y="21.42" width="12.92" height="1.56"/>
      <rect fill='#fff' x="5.4" y="16.61" width="15.51" height="1.56"/>
      <rect fill='#fff' x="5.4" y="11.82" width="17.08" height="1.56"/>
      <rect fill='#fff' x="5.4" y="7.02" width="19.19" height="1.56"/>
    </g>
  </svg>
  )
}

export default ReportOrder2