import "@paybook/sync-widget/dist/widget.css";
import SyncWidget from "@paybook/sync-widget";
import { urlBase } from "../authConfig";
import { useStateContext } from "../contexts/ContextProvider";
import React,{ useState, useEffect } from "react";
import Idioma from "./Idioma";

const SyncfyWidget = ({token, updateFunc}) => {

    const {language,sessionID,accessToken,idClient} = useStateContext();

    const [syncWidget, setSyncWidget] = useState(null)

    if (!syncWidget){
        let params = {
            token: token,
            element: ".pb-w-sync_modal-container",
            config: {
                locale: language,
                entrypoint: {
                    country: 'MX',
                    siteOrganizationType: '56cf5728784806f72b8b4568'
                },

                navigation: {
                    displayStatusInToast: true,
                    socketTimeout: 1200000
                }
            }
        };
        setSyncWidget(new SyncWidget(params))
    }

    const SyncLogs = async(extraData) => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');

        let body = {
            clientID: idClient,
            extraData: extraData,
            logsInfo: {
				origin:'SyncfyWidget',
				sessionID:sessionID 
			  },
			}

        fetch(`${urlBase}/sync/createLog`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then(response => response.json())
        .catch(err => console.log(err));
    }

    const open = () => {
        if(syncWidget){
            syncWidget.open();
            syncWidget.$on("success", () => {
                updateFunc()
            });
            syncWidget.$on("error", (credential, jobError) => {
                console.log('SyncfyErrorCredential', credential)
                console.log('SyncfyError', jobError)
                SyncLogs({'SyncfyErrorCredential': credential, 'SyncfyError': jobError})
            // ... do something when there is some error in the synchronization of credentials  ...
            // ... jobError is not send if the socket timeouts, for socket errors use socket-error event ...
            });
            syncWidget.$on("401", () => {
                console.log('SyncfyError401')
                SyncLogs('SyncfyError401')
            // ... do something when user session is unauthorized.
            // i.e. refresh user session.
            });
            syncWidget.$on("api-error", (statusCode, apiError) => {
                console.log('SyncfyErrorStatus', statusCode)
                console.log('SyncfyErrorAPI', apiError)
                SyncLogs({'SyncfyErrorStatus': statusCode, 'SyncfyErrorAPI': apiError})
            // ... do something on api error.
            // i.e. refresh user session.
            });
            syncWidget.$on("socket-error", (socketError) => {
                console.log('SyncfyErrorSocket', socketError)
                SyncLogs({'SyncfyErrorSocket': socketError})
            // ... do something on socket error.
            });
        }
    }

    return (
        <button className="bg-purple-1 main flex items-center justify-center w-[185px] 2xl:w-[230px] h-[52px] p-4 rounded-button" onClick={open} disabled={!token}>
            <h3 className="text-white text-h10-2xl 2xl:text-h7-2xl text-center">{Idioma.Syncfy.vincular[language]}</h3>
        </button>
    )
}

export default SyncfyWidget