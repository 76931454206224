import { useEffect, useState } from 'react'
import { DinamicDropdown, Idioma} from '../components';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useStateContext } from '../contexts/ContextProvider';
import { urlBase } from '../authConfig';

const DashboardFilters = ({setRequestParams, groupFilter=true, flowFilter=true, innerGraph=false}) => {

  const {idClient, accessToken, idRS, language, sessionID} = useStateContext()
  
  //Opciones de flujos
  const [flowOptions, setFlowOptions] = useState([{name: Idioma.filtros.flujoTotal[language], id: 0}, {name:  Idioma.filtros.flujoOperativo[language], id: 1}, {name:  Idioma.filtros.flujoInversion[language], id: 2}, {name:  Idioma.filtros.flujoFinanciamiento[language], id: 3}])
  //Flujo seleccionado
  const [flow, setFlow] = useState({name:  Idioma.filtros.flujoTotal[language], id: 0})
  //Opciones rango de tiempo 
  const [preFilterOptions,setPreFilterOptions] = useState(innerGraph ? [{name:  Idioma.filtros.LTM[language], id: 0}, {name: Idioma.filtros.esteAnio[language], id: 1}, {name: Idioma.filtros.anioAnterior[language], id: 2}, {name: Idioma.filtros.ultimosTresMeses[language], id: 9}, {name: Idioma.filtros.esteMes[language], id: 5}, {name: Idioma.filtros.mesAnterior[language], id: 6}, {name: Idioma.filtros.estaSemana[language], id: 7}, {name: Idioma.filtros.semanaAnterior[language], id: 8}] : [{name:  Idioma.filtros.LTM[language], id: 0}, {name: Idioma.filtros.esteAnio[language], id: 1}, {name: Idioma.filtros.anioAnterior[language], id: 2}, {name: Idioma.filtros.ultimosTresMeses[language], id: 9}, {name: Idioma.filtros.esteMes[language], id: 5}, {name: Idioma.filtros.mesAnterior[language], id: 6}])
  //Rango de tiempo selec
  const [preFilter,setPreFilter] = useState({name:  Idioma.filtros.LTM[language], id: 0})
  //Filtros de comparacion (tiempo de comparacion)
  const [comparisonFilterOptions,setComparisonFilterOptions] = useState([{name: 'Ninguno', id: -1}, {name: 'Año anterior', id: 1}/* , {name: 'Trimestre anterior', id: 2}, {name: 'Mes anterior', id: 3}, {name: 'Semana anterior', id: 4} */])
  const [comparisonFilter, setComparisonFilter] = useState({name: 'Año anterior', id: 1})
  //Agrupacion de datos
  const [groupByOptions, setGroupByOptions] = useState([{name: Idioma.filtros.anio[language], id: 5,  value: 364}, {name: Idioma.filtros.trimestre[language], id: 4, value: 91}, {name: Idioma.filtros.mes[language], id: 3, value: 28}, {name: Idioma.filtros.semana[language], id: 2, value: 7}, {name: Idioma.filtros.dia[language], id: 1, value: 1}])
  const [groupBy, setGroupBy] = useState({name: Idioma.filtros.mes[language], id: 3, value: 28})

  //Logica de tamano de rangos
  const groupByDynamic = () => {
    //caso menor a semana
    if (getDateRange()[0]<7 && getDateRange()[0]!==null){
      setGroupBy({name: Idioma.filtros.dia[language], id: 1, value: 1})
      setGroupByOptions([{name: Idioma.filtros.dia[language], id: 1, value: 1}])
    } else if (getDateRange()[0]<28){
      //caso menor a mes
      if(groupBy.value>7){
        setGroupBy({name: Idioma.filtros.semana[language], id: 2, value: 7})
      }
      setGroupByOptions([{name: Idioma.filtros.semana[language], id: 2, value: 7}, {name: Idioma.filtros.dia[language], id: 1, value: 1}])
    } else if (getDateRange()[0]<91){
      //caso menor a trimestre
      if(groupBy.value>28){
        setGroupBy({name: Idioma.filtros.mes[language], id: 3, value: 28})
      }
      setGroupByOptions([{name: Idioma.filtros.mes[language], id: 3, value: 28}, {name: Idioma.filtros.semana[language], id: 2, value: 7}, {name: Idioma.filtros.dia[language], id: 1, value: 1}])
    } else if (getDateRange()[0]<364){
      if(groupBy.value>91){
        setGroupBy({name: Idioma.filtros.mes[language], id: 3, value: 28})
      }
      setGroupByOptions([{name: Idioma.filtros.trimestre[language], id: 4, value: 91}, {name: Idioma.filtros.mes[language], id: 3, value: 28}, {name: Idioma.filtros.semana[language], id: 2, value: 7}])
    } else {
      if(groupBy.value<28){
        setGroupBy({name: Idioma.filtros.mes[language], id: 3, value: 28})
      }
      setGroupByOptions([{name: Idioma.filtros.anio[language], id: 5,  value: 364}, {name: Idioma.filtros.trimestre[language], id: 4, value: 91}, {name: Idioma.filtros.mes[language], id: 3, value: 28}, {name: Idioma.filtros.semana[language], id: 2, value: 7}])
    }
  }

  //fecha a string para enviar a las graficas
  const dateToString = (day, month, year) => {
    if(day<10){
      day = '0'+day.toString()
    }else{
        day = day.toString()
    }
    if(month<10){
        month = '0'+month.toString()
    }else{
        month = month.toString()
    }
    let fecha = (year+month+day);
    return fecha
  }

  //rango de dias seleccionados, regresa [diferencia de dias, [la fecha inicial, fecha final]]
  const getDateRange = () => {
    if (startDate==null || endDate==null){
      return [null, [null, null]]
    } else {
      let diff = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)
      let year = (startDate.getFullYear()).toString()
      let month = startDate.getMonth()+1
      let day = startDate.getDate()
      let month2
      if(endDate.getDate()===1){
        endDate.setMonth(endDate.getMonth()+1)
        endDate.setDate(0)
        month2 = endDate.getMonth()
      }else{
        month2 = endDate.getMonth()+1
      }
      let year2 = (endDate.getFullYear()).toString()
      let day2 = endDate.getDate()
      return [diff, [dateToString(day, month, year),dateToString(day2, month2, year2)]]
    }
  }

  //calculo ultimos 12 mese apartir de hoy
  const getLTM = () => {
    let newStartDate = new Date()
    newStartDate.setMonth(newStartDate.getMonth() - 12)
    newStartDate.setDate(1)
    let year = (newStartDate.getFullYear()).toString()
    let month = newStartDate.getMonth()  + 1
    let day = newStartDate.getDate()
    let newEndDate = new Date()
    newEndDate.setDate(0)
    let year2 = newEndDate.getFullYear().toString()
    let month2 = newEndDate.getMonth()+1
    let day2 = newEndDate.getDate()
    return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]])//Fecha final LTM
  }

  //calculo anio pasado
  const getLY = () => {
    let newStartDate = new Date()
    newStartDate.setMonth(0)
    newStartDate.setDate(1)
    newStartDate.setFullYear(newStartDate.getFullYear()-1)
    let year = (newStartDate.getFullYear()).toString()
    let month = newStartDate.getMonth() + 1
    let day = newStartDate.getDate()
    let newEndDate = new Date()
    newEndDate.setMonth(0)
    newEndDate.setDate(0)
    let year2 = newEndDate.getFullYear().toString()
    let month2 = newEndDate.getMonth() + 1
    let day2 = newEndDate.getDate()
    return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final Last Year
  }

  //calculo del 1 de enero a hoy
  const getTY = () => {
    let newStartDate = new Date()
    newStartDate.setMonth(0)
    newStartDate.setDate(1)
    let year = (newStartDate.getFullYear()).toString()
    let month = newStartDate.getMonth() + 1
    let day = newStartDate.getDate()
    let newEndDate = new Date()
    newEndDate.setFullYear(newEndDate.getFullYear()+1)
		newEndDate.setMonth(0)
		newEndDate.setDate(0)
    let year2 = newEndDate.getFullYear().toString()
    let month2 = newEndDate.getMonth() + 1
    let day2 = newEndDate.getDate()
    return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final This Year
  }

  //ultimos 3 meses sin este mes
  const getL3M = () => {
    let newStartDate = new Date()
    newStartDate.setMonth(newStartDate.getMonth()-3)
    newStartDate.setDate(1)
    let year = (newStartDate.getFullYear()).toString()
    let month = newStartDate.getMonth() + 1
    let day = newStartDate.getDate()
    let newEndDate = new Date()
		newEndDate.setDate(0)
    let year2 = newEndDate.getFullYear().toString()
    let month2 = newEndDate.getMonth() + 1
    let day2 = newEndDate.getDate()
    return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final Last Quarter
  }

  //calcular cuartos de anio
  /*const getLQTR = () => {
    let newStartDate = new Date()
    newStartDate.setMonth((Math.floor(newStartDate.getMonth()/3)-1)*3)
    newStartDate.setDate(1)
    let year = (newStartDate.getFullYear()).toString()
    let month = newStartDate.getMonth() + 1
    let day = newStartDate.getDate()
    let newEndDate = new Date()
    newEndDate.setMonth(Math.floor(newEndDate.getMonth()/3)*3)
    newEndDate.setDate(0)
    let year2 = newEndDate.getFullYear().toString()
    let month2 = newEndDate.getMonth() + 1
    let day2 = newEndDate.getDate()
    return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final Last Quarter
  }

  const getTQTR = () => {
    let newStartDate = new Date()
    newStartDate.setMonth(Math.floor(newStartDate.getMonth()/3)*3)
    newStartDate.setDate(1)
    let year = (newStartDate.getFullYear()).toString()
    let month = newStartDate.getMonth() + 1
    let day = newStartDate.getDate()
    let newEndDate = new Date()
    newEndDate.setDate(newEndDate.getDate()-1)
    let year2 = newEndDate.getFullYear().toString()
    let month2 = newEndDate.getMonth() + 1
    let day2 = newEndDate.getDate()
    return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final This Quarter
  }*/

  //calcular mes anterio del 1 dia al ultimo
  const getLM = () => {
    let newStartDate = new Date()
    newStartDate.setMonth(newStartDate.getMonth()-1)
    newStartDate.setDate(1)
    let year = (newStartDate.getFullYear()).toString()
    let month = newStartDate.getMonth() + 1
    let day = newStartDate.getDate()
    let newEndDate = new Date()
    newEndDate.setDate(0)
    let year2 = newEndDate.getFullYear().toString()
    let month2 = newEndDate.getMonth() + 1
    let day2 = newEndDate.getDate()
    return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final Last Month
  }

  //del primero a hoy del mes
  const getTM = () => {
    let newStartDate = new Date()
    newStartDate.setDate(1)
    let year = (newStartDate.getFullYear()).toString()
    let month = newStartDate.getMonth() + 1
    let day = newStartDate.getDate()
    let newEndDate = new Date()
    newEndDate.setMonth(newEndDate.getMonth()+1)
    newEndDate.setDate(0)
    let year2 = newEndDate.getFullYear().toString()
    let month2 = newEndDate.getMonth() + 1
    let day2 = newEndDate.getDate()
    return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final This Month
  }

  //semana pasada empezando en lunes
  const getLW = () => {
    let newStartDate = new Date();
    //newStartDate.setTime(newStartDate.getTime() - 8 * 24 * 60 * 60 * 1000) //7 dias
    newStartDate.setDate(newStartDate.getDate() - (newStartDate.getDay() + 6) % 7 - 7) //Lunes pasado
    let year = (newStartDate.getFullYear()).toString()
    let month = newStartDate.getMonth() + 1
    let day = newStartDate.getDate()
    let newEndDate =  new Date()
    //newEndDate.setTime(newEndDate.getTime() - 1 * 24 * 60 * 60 * 1000) //7 dias
    newEndDate.setDate(newEndDate.getDate() - (newEndDate.getDay() + 6) % 7 - 1) //Lunes pasado
    let year2 = newEndDate.getFullYear().toString()
    let month2 = newEndDate.getMonth() + 1
    let day2 = newEndDate.getDate()
    return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final Last Week
  }

  //del lunea a hoy
  const getTW = () => {
    let newStartDate = new Date();
    //newStartDate.setTime(newStartDate.getTime() - 8 * 24 * 60 * 60 * 1000) //7 dias
    newStartDate.setDate(newStartDate.getDate() - (newStartDate.getDay() + 6) % 7) //Lunes pasado
    let year = (newStartDate.getFullYear()).toString()
    let month = newStartDate.getMonth() + 1
    let day = newStartDate.getDate()
    let newEndDate =  new Date()
    let year2 = newEndDate.getFullYear().toString()
    let month2 = newEndDate.getMonth() + 1
    let day2 = newEndDate.getDate()
    return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final This Week
  }

  //inicializo todos los rangos de filtros
  const [ltmFilter,setLtmFilter] = useState(getLTM());
  const [l3mFilter, setL3mFilter] = useState(getL3M())
  const [lyFilter,setLyFilter] = useState(getLY());
  //const [lqtrFilter,setLqtrFilter] = useState(getLQTR());
  const [lmFilter,setLmFilter] = useState(getLM());
  const [lwFilter,setLwFilter] = useState(getLW());
  const [tyFilter,setTyFilter] = useState(getTY());
  //const [tqtrFilter,setTqtrFilter] = useState(getTQTR());
  const [tmFilter,setTmFilter] = useState(getTM());
  const [twFilter,setTwFilter] = useState(getTW());
  //selector de fechas
  const [dateFilter,setDateFilter] = useState(ltmFilter[0]);
  const [startDate, endDate] = dateFilter;

  /*const getPeriodComparison = () => {
    if (startDate==null || endDate==null){
      return [[null, null], [null, null]]
    } else {
      let newStartDate = new Date(startDate.getTime());
      let x = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)
      newStartDate.setTime(newStartDate.getTime() - (x + 1) * 24 * 60 * 60 * 1000) //x dias
      let year = (newStartDate.getFullYear()).toString()
      let month = newStartDate.getMonth() + 1
      let day = newStartDate.getDate()
      let newEndDate =  new Date(endDate.getTime())
      newEndDate.setTime(newEndDate.getTime() - (x + 1) * 24 * 60 * 60 * 1000) //x dias
      let year2 = newEndDate.getFullYear().toString()
      let month2 = newEndDate.getMonth() + 1
      let day2 = newEndDate.getDate()
      return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final Last Period
    }
  }*/

  const getComparisonDateRange = () => {
    if (comparisonStartDate==null || comparisonEndDate==null){
      return [null, null]
    } else {
      let year = (comparisonStartDate.getFullYear()).toString()
      let month = comparisonStartDate.getMonth()+1
      let day = comparisonStartDate.getDate()
      let year2 = (comparisonEndDate.getFullYear()).toString()
      let month2 = comparisonEndDate.getMonth()+1
      let day2 = comparisonEndDate.getDate()
      return [dateToString(day, month, year),dateToString(day2, month2, year2)]
    }
  }

  const getLyComparison = () => {
    if (startDate==null || endDate==null){
      return [[null, null], [null, null]]
    } else {
      let newStartDate = new Date(startDate.getTime())
      newStartDate.setFullYear(newStartDate.getFullYear() - 1)
      let year = (newStartDate.getFullYear()).toString()
      let month = newStartDate.getMonth() + 1
      let day = newStartDate.getDate()
      let newEndDate = new Date(endDate.getTime())
      newEndDate.setFullYear(newEndDate.getFullYear() - 1)
      let year2 = newEndDate.getFullYear().toString()
      let month2 = newEndDate.getMonth() + 1
      let day2 = newEndDate.getDate()
      return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final comparacion Last Year 
    }
  }
  
/*   const getLqtrComparison = () => {
    if (startDate==null || endDate==null){
      return [[null, null], [null, null]]
    } else {
      let newStartDate = new Date(startDate.getTime())
      newStartDate.setMonth((Math.floor(newStartDate.getMonth()/3)-1)*3)
      newStartDate.setDate(1)
      let year = (newStartDate.getFullYear()).toString()
      let month = newStartDate.getMonth() + 1
      let day = newStartDate.getDate()
      let newEndDate = new Date(endDate.getTime())
      newEndDate.setMonth(Math.floor(newEndDate.getMonth()/3)*3)
      newEndDate.setDate(0)
      let year2 = newEndDate.getFullYear().toString()
      let month2 = newEndDate.getMonth() + 1
      let day2 = newEndDate.getDate()
      return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final comparacion Last Quarter
    }
  } */

/*   const getLmComparison = () => {
    if (startDate==null || endDate==null){
      return [[null, null], [null, null]]
    } else {
      let newStartDate = new Date(startDate.getTime())
      newStartDate.setMonth(newStartDate.getMonth()-1)
      newStartDate.setDate(1)
      let year = (newStartDate.getFullYear()).toString()
      let month = newStartDate.getMonth() + 1
      let day = newStartDate.getDate()
      let newEndDate = new Date(endDate.getTime())
      newEndDate.setDate(0)
      let year2 = newEndDate.getFullYear().toString()
      let month2 = newEndDate.getMonth() + 1
      let day2 = newEndDate.getDate()
      return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final Last Month
    }
  } */

/*   const getLwComparison = () => {
    if (startDate==null || endDate==null){
      return [[null, null], [null, null]]
    } else {
      let newStartDate = new Date(startDate.getTime());
      newStartDate.setTime(newStartDate.getTime() - 8 * 24 * 60 * 60 * 1000) //7 dias
      //newStartDate.setDate(newStartDate.getDate() - (newStartDate.getDay() + 6) % 7 - 7) //Lunes pasado
      let year = (newStartDate.getFullYear()).toString()
      let month = newStartDate.getMonth() + 1
      let day = newStartDate.getDate()
      let newEndDate =  new Date(endDate.getTime())
      newEndDate.setTime(newEndDate.getTime() - 1 * 24 * 60 * 60 * 1000) //7 dias
      //newEndDate.setDate(newEndDate.getDate() - (newEndDate.getDay() + 6) % 7 - 1) //Lunes pasado
      let year2 = newEndDate.getFullYear().toString()
      let month2 = newEndDate.getMonth() + 1
      let day2 = newEndDate.getDate()
      return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final Last Week
    }
  } */

  //const [periodComparison, setPeriodComparison] = useState(getPeriodComparison());
  const [lyComparison, setLyComparison] = useState(getLyComparison());
  //const [lqtrComparison, setLqtrComparison] = useState(getLqtrComparison());
  //const [lmComparison, setLmComparison] = useState(getLmComparison());
  //const [lwComparison, setLwComparison] = useState(getLwComparison());
  const [comparisonDate, setComparisonDate] = useState(lyComparison[0]);
  const [comparisonStartDate, comparisonEndDate] = comparisonDate;
  const noComparison = [new Date("August 01, 2000 00:00:00"), new Date("February 25, 2001 00:00:00")]
  
  const handleDateChange = (event) => {
    setDateFilter(event)
  }

  const [filters, setFilters] = useState()
  const [idRsOptions, setIdRsOptions] = useState([])
  const [dataModal, setDataModal] = useState([]);
  const [RSID, setRSID] = useState([])


  //hace que el selector de fechas selecione las fechas correspondientesz al prefiltro
  useEffect(() => {
    if(preFilter.id!=10){
      if (preFilter.id==0) {
        setDateFilter(ltmFilter[0])
      } else if (preFilter.id==1) {
        setDateFilter(tyFilter[0])
      } else if (preFilter.id==2) {
        setDateFilter(lyFilter[0])
      } else if (preFilter.id==5) {
        setDateFilter(tmFilter[0])
      } else if (preFilter.id==6) {
        setDateFilter(lmFilter[0])
      } else if (preFilter.id==7) {
        setDateFilter(twFilter[0])
      } else if (preFilter.id==8) {
        setDateFilter(lwFilter[0])
      } else if (preFilter.id==9) {
        setDateFilter(l3mFilter[0])
      }
    }
  },[preFilter])

  useEffect(() => {
    //para que no se cambie la agrupacion se comento
    //groupByDynamic()

    if(dateFilter!=ltmFilter[0] && dateFilter!=lyFilter[0] /*&& dateFilter!=lqtrFilter[0]*/ && dateFilter!=lmFilter[0] && dateFilter!=lwFilter[0] && dateFilter!=tyFilter[0]/* && dateFilter!=tqtrFilter[0]*/ && dateFilter!=tmFilter[0] && dateFilter!=twFilter[0] && dateFilter!=l3mFilter[0]){
      setPreFilter({name: Idioma.filtros.personalizado[language], id: 10})
      setComparisonFilter({name: Idioma.filtros.anioAnterior[language], id: 1})
    }

    /*if (comparisonFilter.id==0){
      setComparisonDate(getPeriodComparison()[0])
    } else*/ 
    if (comparisonFilter.id==1){
      setComparisonDate(getLyComparison()[0])
    } else if (comparisonFilter.id==-1){
      setComparisonDate(noComparison)
    }// else if (comparisonFilter.id==2){
    //  setComparisonDate(getLqtrComparison()[0])
    //} else if (comparisonFilter.id==3){
    //  setComparisonDate(getLmComparison()[0])
    //} else if (comparisonFilter.id==4){
    //  setComparisonDate(getLwComparison()[0])
    //}
  },[dateFilter])

  useEffect(() => {
    //setPeriodComparison(getPeriodComparison())
    setLyComparison(getLyComparison())
    /* setLqtrComparison(getLqtrComparison())
    setLmComparison(getLmComparison())
    setLwComparison(getLwComparison()) */
    /*if (comparisonFilter.id==0){
      setComparisonDate(getPeriodComparison()[0])
    } else*/ 
    if (comparisonFilter.id==1){
      setComparisonDate(getLyComparison()[0])
    }  else if (comparisonFilter.id==-1){
      setComparisonDate(noComparison)
    }// else if (comparisonFilter.id==2){
    //  setComparisonDate(getLqtrComparison()[0])
    //} else if (comparisonFilter.id==3){
    //  setComparisonDate(getLmComparison()[0])
    //} else if (comparisonFilter.id==4){
    //  setComparisonDate(getLwComparison()[0])
    //}
  },[comparisonFilter])

  const [applyFilter, setApplyFilter] = useState(false)
  const [firstRender, setFirstRender] = useState(true)
  const [ready2Render, setReady2Render] = useState(false)

      useEffect(() => {
      let headers = new Headers()
      const bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);
      headers.append("Content-Type",'application/json')
      let body = {
        "clientID": idClient,
        logsInfo: {
          origin: 'Resumen',
          sessionID: sessionID
      }
      }
      fetch(`${urlBase}/getRS`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
      })
      .then((res)=>res.json())
      .then((aux)=>{
        let newRSOptions = []
        aux.map((rs) => {
          newRSOptions[rs.idRS]=rs.rsName
        })
        setIdRsOptions(newRSOptions)
      })
      if(firstRender){
        setApplyFilter(false)
      }
      if(sessionStorage.getItem('dashboardFilters'+idClient)){
        let filtersAux =  sessionStorage.getItem('dashboardFilters'+idClient)
		    setFilters(filtersAux)
        if(JSON.parse(filtersAux).length>4){
          setDateFilter([new Date(Date.parse(JSON.parse(filtersAux)[4][0])), new Date(Date.parse(JSON.parse(filtersAux)[4][1]))])
        }
        setFlow(JSON.parse(filtersAux)[0])
        setPreFilter(JSON.parse(filtersAux)[1])
        setGroupBy(JSON.parse(filtersAux)[2])
        setRSID(JSON.parse(filtersAux)[3])
      }else{
        setFirstRender(false)
        setFilters(null)
        setRequestParams([flow.id, getDateRange()[1][0], getDateRange()[1][1], groupBy.id,null])
      }
    },[])

    const applyButton = () => {
      setApplyFilter(false)
    }

    useEffect(() => {
      if(!firstRender && ready2Render){
        setApplyFilter(true)
      }
    },[dateFilter, flow, groupBy, RSID])

    useEffect(() => {
      if(!applyFilter && !firstRender){
        setRequestParams([flow.id, getDateRange()[1][0], getDateRange()[1][1],groupBy.id,RSID.map((rs) => rs.id)])
        if(!innerGraph){
          if (preFilter.id===10){
            sessionStorage.setItem('dashboardFilters'+idClient, JSON.stringify([flow, preFilter ,groupBy, RSID, [startDate, endDate]]))
          }else{
            sessionStorage.setItem('dashboardFilters'+idClient, JSON.stringify([flow, preFilter, groupBy, RSID]))
          }
        }
      }
    },[applyFilter])

    useEffect(()=>{
      if(firstRender){
        setFirstRender(false)
      }else{
        setReady2Render(true)
      }
    },[firstRender])

    useEffect(() => {
      if(ready2Render){
        setRequestParams([flow.id, getDateRange()[1][0], getDateRange()[1][1], groupBy.id, RSID.map((rs) => rs.id)])
      }
    },[ready2Render])

    useEffect( () => {
        if(filters!==undefined){
          let data = [];
          let ids = Object.keys(idRsOptions)
          let names = Object.values(idRsOptions)
          let auxCheck = []
          if(RSID.length!==0){
            auxCheck = RSID.map((rs) => rs.id)
          }else{
            auxCheck = ids.map(id => Number(id))
          }
          for( let i = 0; i < ids.length; i++ ) {
            if(!auxCheck.includes(Number(ids[i]))){
              data.push({name: names[i], id: Number(ids[i]), aplied: false})
            }else{
              data.push({name: names[i], id: Number(ids[i]), aplied: true})
            }
          }
  
          setDataModal(data)
          if(data.length>0){
            setRSID(data.filter((rsid) => rsid.aplied))
          } 
        }
        }, [idRsOptions] );

  return (
    <>
    <div className='flex flex-col gap-3 mb-2'>
      <div className='flex flex-wrap gap-3 mb-'>
        {!idRS && dataModal.length>0 &&
            <DinamicDropdown
            options={dataModal}
            selected={RSID}
            className={'text-p9-2xl w-button-3 h-button-3'}
            setSelected={setRSID}
            multi={true}
            rsIdSelector={true}
          />
          }
          {flowFilter && <DinamicDropdown
            options={flowOptions}
            selected={flow}
            className={'text-p9-2xl w-button-2 h-button-3'}
            setSelected={setFlow}
          />}
          <DinamicDropdown
            options={preFilterOptions}
            selected={preFilter}
            className={'text-p9-2xl w-button-3 h-button-3'}
            setSelected={setPreFilter}
          />
          <div className={ innerGraph ? 'w-button-2' : 'w-button-3'}>
          <DatePicker
            className={' h-button-3 focus:outline-none cursor-default border-1 border-neutral-1 hover:border-light-purple-4 focus:border-light-purple-4 rounded-button text-p9-2xl text-center'} 
            onChange={(date) => handleDateChange(date)} 
            selectsRange 
            startDate={startDate} 
            endDate={endDate}
            dateFormat={innerGraph ? Idioma.formatoFecha[language] : "MMM-yy"}
            locale={language}
            showMonthYearPicker={!innerGraph}
            showYearDropdown={innerGraph}
            fixedHeight
          />
          </div>
          {groupFilter && <>
            <h3 className='py-1 mt-2 text-center text-p9-2xl whitespace-nowrap'>{Idioma.filtros.agrupar[language]}</h3>
          <DinamicDropdown
            options={groupByOptions}
            selected={groupBy}
            className={'text-p9-2xl w-button-4 h-button-3'}
            setSelected={setGroupBy}
          />
          </>}
        <button className={`text-p8-2xl w-button-4 h-button-3 rounded-button ${(RSID.length!==0 || idRsOptions.length===0) && (!firstRender && applyFilter) ? 'bg-light-purple-3 text-white' : 'bg-gray-5 text-white'}`} disabled={(RSID.length!==0 || idRsOptions.length===0) && (!firstRender && applyFilter) ? false : true} onClick={applyButton}>{Idioma.filtros.aplicar[language]}</button>
      </div>
        <div className=' mb-2 '>
          {!idRS && dataModal.length>0 && dataModal.length < 5 &&
            <DinamicDropdown
            options={dataModal}
            selected={RSID}
            className={'text-p9-2xl h-button-3 w-[80]'}
            setSelected={setRSID}
            multi={true}
            rsIdLabels={true}
          />
          }
        </div>
        </div>
    </>
  )
}

export default DashboardFilters