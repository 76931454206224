import React from "react";
//{es:'',en:''},
const Idioma = {
    formatoFecha: {es: 'dd/MM/yyyy', en:'MM/dd/yyyy'},
    meses: {
        ene: {es: "Enero", en: "January"},
        feb: {es: "Febrero", en: "February"},
        mar: {es: "Marzo", en: "March"},
        abr: {es: "Abril", en: "April"},
        may: {es: "Mayo", en: "May"},
        jun: {es: "Junio", en: "June"},
        jul: {es: "Julio", en: "July"},
        ago: {es: "Agosto", en: "August"},
        sep: {es: "Septiembre", en: "September"},
        oct: {es: "Octubre", en: "October"},
        nov: {es: "Noviembre", en: "November"},
        dic: {es: "Diciembre", en: "December"}
    },
    MMM: {
        ene: {es: "Ene", en: "Jan"},
        feb: {es: "Feb", en: "Feb"},
        mar: {es: "Mar", en: "Mar"},
        abr: {es: "Abr", en: "Apr"},
        may: {es: "May", en: "May"},
        jun: {es: "Jun", en: "Jun"},
        jul: {es: "Jul", en: "Jul"},
        ago: {es: "Ago", en: "Aug"},
        sep: {es: "Sep", en: "Sep"},
        oct: {es: "Oct", en: "Oct"},
        nov: {es: "Nov", en: "Nov"},
        dic: {es: "Dic", en: "Dec"}
    },
    dias: {
        lun:{es:'Lunes',en:'Monday'},
        mar:{es:'Martes',en:'Tuesday'},
        mie:{es:'Miercoles',en:'Wednesday'},
        jue:{es:'Jueves',en:'Thursday'},
        vie:{es:'Viernes',en:'Friday'},
        sab:{es:'Sabado',en:'Saturday'},
        dom:{es:'Domingo',en:'Sunday'},
    },
    nums:{
        '1s':{es:'primer',en:'first'},
        '2s':{es:'segundo',en:'second'},
        '3s':{es:'tercer',en:'third'},
        '4s':{es:'cuarto',en:'fourth'},
        ultimo:{es:'último',en:'last'},
    },
    welcomeScreen:{
        texto1 :            {es:'¡Hola',en:'Hello'},
        texto2 :            {es:'que gusto volver a verte!',en:'how nice to see you again!'},
        texto3 :            {es:'Iniciar sesión',en:'Log in'},
        texto4:             {es:'Selecciona con que empresa quieres comenzar.',en:'Select which company you want to start with.'},
        navegador:          {es:'Se recomienda usar el navegador Chrome para una experiencia completa de la herramienta', en:'Use Chrome for a complete expirence of the website'},
    },
    sideBar: {
        resumen:            {es:'Resumen',en:'Summary'},
        transacciones:      {es:'Transacciones',en:'Transactions'},
        reporte:            {es:'Reporte',en:'Report'},
        cuentasYSaldos:     {es:'Cuentas y saldos',en:'Accounts and balances'},
        catalogo:           {es:'Catálogo de cuentas',en:'Chart of accounts'},
        hola:               {es:'Hola',en:'Hi'},
        admin:              {es:'Administración',en:'Admin'},
        planeacion:         {es: 'Planeación', en: 'Planning'},
        cerrar:             {es:'Cerrar sesión',en:'Log out'},
    },
    navbar: {
        home: {es:'Inicio', en:'Home'},
        summary: {es: 'Resumen', en: 'Summary'},
        subirTRansacciones: {es: 'Subir transacciones', en: 'Upload transactions'},
        transactions: {es: 'Transacciones', en: 'Transactions'},
        editarEscenarios: {es: 'Editar escenarios', en: 'Edit scenarios'},
        subirPlaneacion: {es: 'Subir planeación', en: 'Upload planning'},
        planeacion:    {es: 'Planeación', en: 'Planning'},
        comparacion: {es: 'Comparación', en: 'Comparison'},
        flujoEfectivo: {es: 'Estado flujo de efectivo', en: 'Cash flow report'},
        cuentasySaldos: {es: 'Cuentas y saldos bancarios', en: 'Bank accounts and balances'},
        administracion: {es: 'Administración', en: 'Administration'},
        transacciones: {es: 'Transacciones', en: 'Transactions'},
        catalogoDeCuentas: {es: 'Catálogo de cuentas', en: 'Chart of Accounts'},
        empresas: {es: 'Empresas', en: 'Companies'},
        verificacionSaldos: {es: 'Verificación de saldos', en: 'Balance verification'},
        entradaDeEfectivo: {es: 'Entrada de efectivo', en: 'Cash Inflow'},
        salidaDeEfectivo: {es: 'Salida de efectivo', en: 'Cash Outflow'},
        flujoDeEfectivo: {es: 'Flujo neto de efectivo', en: 'Cash Flow'},
        balanceDeBancos: {es: 'Saldo en bancos', en: 'Bank Balance'},
        saldosTarjetasDeCredito: {es: 'Saldo tarjetas de crédito', en: 'Credit Card Balances'},
        cajaNeta: {es: 'Caja neta (Bancos - TdC)', en: 'Net Cash (Banks - Credit Cards)'},
        adminSubtitulo: {es: 'Agrega, edita o elimina tus usuarios y empresas.', en: 'Add, edit or delete your users and companies.'},
        catalogoSubtitulo: {es:'Edita o agrega categorías. Mueve o cambia el orden de las categorías.',en:'Edit or add categories. Move or change the order of the categories.'}
    },
    filtros: {
        todasCuentas:           {es:'Todas las cuentas',en:'All accounts'},
        todos:                  {es:'Todos',en:'All'},
        tipo:                   {es:'Tipo',en:'Type'},
        min:                    {es:'Mínimo',en:'Minimum'},
        max:                    {es:'Máximo',en:'Maximum'},
        LTM:                    {es:'Últimos 12 meses',en:'Last 12 months'},
        esteAnio:               {es:'Este año',en:'Current year'},
        anioAnterior:           {es:'Año anterior',en:'Last year'},
        ultimosTresMeses:       {es:'Últimos 3 meses',en:'Last 3 months'},
        esteMes:                {es:'Este mes',en:'Current month'},
        mesAnterior:            {es:'Mes anterior',en:'Last month'},
        estaSemana:             {es:'Esta semana',en:'Current week'},
        semanaAnterior:         {es:'Semana anterior',en:'Last week'},
        personalizado:          {es:'Personalizado',en:'Custom'},
        ninguno:                {es:'Ninguno',en:'None'},
        anioAnterior:           {es:'Año anterior',en:'Last year'},
        mes:                    {es:'Mes',en:'Month'},
        trimestre:              {es:'Trimestre', en:'Quarter'},
        anio:                   {es:'Año',en:'Year'},
        semana:                 {es:'Semana',en:'Week'},
        dia:                    {es:'Día',en:'Day'},
        agrupar:                {es:'Agrupar por',en:'Group by'},
        comparar:               {es:'comparar con',en:'Compare with'},
        empresa:                {es:'Empresa',en:'Company'},
        flujoTotal:             {es:'Flujo total',en:'Total flow'},
        flujoOperativo:         {es:'Flujo operativo',en:'Operational flow'},
        flujoInversion:         {es:'Flujo inversión',en:'Investment flow'},
        flujoFinanciamiento:    {es:'Flujo financiamiento',en:'Financing flow'},
        aplicar:                {es:'Aplicar', en:'Apply'},
        proyeccion:             {es:'Iniciar proyecciones desde', en:'Begin projections on'},
        noExistente:            {es:'Valor no existente.', en:'There are no matches.'},
    },
    resumen: {
        entradaEfectivo: {es: 'Entrada de efectivo', en: 'Cash inflow'},
        salidaEfectivo: {es: 'Salida de efectivo', en: 'Cash outflow'},
        flujoEfectivo: {es: 'Flujo neto de efectivo', en: 'Net Cash Flow'},
        balanceBancos: {es: 'Saldo en bancos', en: 'Bank balance'},
        activarLabels: {es: 'Activar etiquetas', en: 'Activate labels'},
        desactivarLabels: {es: 'Desactivar etiquetas', en: 'Deactivate labels'},
        saldoCredito: {es: 'Saldo tarjetas de crédito', en: 'Credit card balances'},
        cajaNetaBancos: {es: 'Caja neta (Bancos-TdC)', en: 'Net cash (Banks-Credit)'},
        flujoNetoEfectivo: {es: 'Flujo neto de efectivo', en: 'Net cash flow'},
        graficasArriba: {
            entradaEfectivoConcepto: {es: 'Entrada de efectivo por concepto', en: 'Cash inflow by concept'},
            salidaEfectivoConceptos: {es: 'Salidas de efectivo por concepto', en: 'Cash outflows by concept'},
            evolucionFlujoEfectivo: {es: 'Evolución flujo de efectivo', en: 'Cash flow evolution'},
            detalleTransacciones: {es: 'Detalle transacciones', en: 'Transaction detail'},
            abrirTransacciones: {es: 'Abrir en transacciones', en: 'Open in transactions'},
        },
        graficasAbajo: {
            evolucionBalanceBancos: {es: 'Evolución del saldo en bancos', en: 'Bank balance evolution'},
            evolucionSaldosTarjetasCredito: {es: 'Evolución de saldos en tarjetas de crédito', en: 'Credit card balance evolution'},
            evolucionCajaNeta: {es: 'Evolución caja neta', en: 'Net cash evolution'},
            cuentas: {es: 'Cuentas', en: 'Accounts'},
        }, 
        iconI: {
                entradasEfectivo: {es: 'Son los ingresos en efectivo a la empresa, sin contar los movimientos entre cuentas de la misma empresa.', en: 'These are the cash receipts to the company, excluding movements between accounts of the same company.'},
                salidasEfectivo: {es: 'Son los egresos en efectivo de cuentas de la empresa, sin contar los movimientos entre cuentas de la misma empresa.', en: 'These are the cash disbursements from company accounts, excluding movements between accounts of the same company.'},
                flujoEfectivo: {es: 'Es la diferencia entre el dinero que entra y sale de la empresa en cada periodo.', en: 'It is the difference between the money that enters and leaves the company in each period.'},
                saldoBancos: {es: 'Es la suma de los saldos de las cuentas de débito al final de cada periodo.', en: 'It is the sum of the balances of the debit accounts at the end of each period.'},
                saldoTarjetasCredito: {es: 'La suma de adeudo a las tarjetas de crédito al final de cada periodo.', en: 'The sum of credit card debt at the end of each period.'},
                cajaNeta: {es: 'Es el resultado del saldo en tarjetas de débito menos el saldo en las tarjetas de crédito.', en: 'It is the result of the debit card balance minus the credit card balance.'}
        }
    },
    transacciones: {
        subirTransacciones:         {es:'Subir transacciones',en:'Upload transactions'},
        procesoAutoclass:           {es:'Transacciones en proceso de autoclasificación',en:'Autoclassifying transactions'},
        autoclasificar:             {es:'Autoclasificar',en:'Autoclass'},
        transActualizadas:          {es:'Transacciones actualizadas correctamente',en:'Updated transactions'},
        textoCopiado:               {es:'Texto copiado',en:'Copied'},
        transNoActualizadas:        {es:'Transacciones no actualizadas',en:'Non updated transactions'},
        mensajeError:               {es:'¡Ups! Parece que hubo un error...',en:"Oops! Looks like there is an error"},
        bloqueError1:               {es:'Transacciones no se han',en:"Transactions didn't"},
        bloqueError2:               {es:'actualizado correctamente',en:"update correctly"},
        autoError:                  {es:'Error al autoclasificar',en:'Autoclassification error'},
        errorSubcategorias:         {es:'Se necesita el Catálogo de cuentas para continuar',en:'The Chart of accounts is needed to continue'},
        limpiarTransacciones:       {es:'Limpiar transacciones (Remover subcategoría)',en:'Clean transactions (Remove subcategory)'},
        anadirTransaccionModal: {
            tituloModal:            {es:'Añadir transacción',en:'Add transaction'},
            banco:                  {es:'Banco',en:'Bank'},
            cuenta:                 {es:'Cuenta',en:'Account'},
            fecha:                  {es:'Fecha',en:'Date'},
            concepto:               {es:'Concepto',en:'Description'},
            abono:                  {es:'Abono',en:'Inflow'},
            cargo:                  {es:'Cargo',en:'Outflow'},
            subCategoria:           {es:'Subcategoría',en:'Subcategory'},
            guardar:                {es:'Guardar cambios',en:'Save changes'},
            cancelar:               {es:'Cancelar',en:'Cancel'},
        },
        desgloseTransaccion: {
            tituloModal:            {es:'Desglose de transacción',en:'Split transaction'},
            seleccioneTransaccion:  {es:'Selecciona una transacción a desglosar',en:'Select a transaction to split'},
            dividir:                {es:'Dividir',en:'Split'},
            porAsignar:             {es:'Por asignar',en:'To be assigned'},
            cantidad:               {es:'Cantidad',en:'Quantity'},
            agregarMas:             {es:'Agregar más',en:'Add more'},
            guardar:                {es:'Guardar cambios',en:'Save changes'},
            cancelar:               {es:'Cancelar',en:'Cancel'},
            informacion:            {es:'Información',en:'Information'},
            monto:                  {es:'Monto',en:'Amount'},
            borrar:                 {es:'Borrar',en:'Erase'},
            cargando:               {es:'Cargando',en:'Loading'},
        },
        eliminarTransaccion:        {es:'Eliminar transacción',en:'Delete transaction'},
        barraBusqueda:              {es:'Buscar...',en:'Search...'},
        exportarExcel:              {es:'Exportar a Excel',en:'Export to Excel'},
        editarPreferencias:         {es:'Editar Favoritos',en:'Set Favorites'},
        editarFiltros:              {es:'Editar Filtros',en:'Set Filters'},
        configuracion: {
            tituloModal:            {es:'Preferencias', en:'Preferences',},
            empresa:                {es:'Empresa',en:'Company'},
            banco:                  {es:'Banco',en:'Bank'},
            cuentaBanco:            {es:'Cuenta de banco',en:'Bank account'},
            fecha:                  {es:'Fecha',en:'Date'},
            concepto:               {es:'Concepto', en:'Description'},
            abono:                  {es:'Abono', en:'Inflow'},
            cargo:                  {es:'Cargo',en:'Outflow'},
            categoria:              {es:'Categoría',en:'Category'},
            subcategoria:           {es:'Subcategoría',en:'Subcategory'},
            moneda:                 {es:'Moneda',en:'Currency'},
            fx:                     {es:'FX',en:'FX'},
            creditoDebito:          {es:'Crédito/Débito',en:'Credit/Debit'},
            clientesProvedores:     {es:'Clientes/Proveedores',en:'Customers/Vendors'},
            notas:                  {es:'Notas',en:'Notes'},
            informacionAdicional:   {es:'Información adicional',en:'Additional information'},
            empresaDrop:            {es:'Empresa', en:'Company'}, 
            bancoDrop:              {es:'Banco',en:'Bank'},
            cuentaDrop:             {es:'Cuenta',en:'Account'},
            archivoDrop:            {es:'Archivo',en:'File'},
            fechaDrop:              {es:'Fecha',en:'Date'},
            aplicarBoton:           {es:'Aplicar',en:'Apply'},
            seEliminara:            {es:'Se eliminará',en:'To be deleted'},
        },
        filtradoPor:                {es:'Filtrado por',en:'Filtered by'},
        empresas:                   {es:'empresas',en:'companies'},
        categorias:                 {es:'categorias',en:'categories'},
        filtros: {
            todos:                  {es:'Todos',en:'All'},
            min:                    {es:'Mínimo',en:'Min'},
            max:                    {es:'Máximo',en:'Max'},
            eliminarFiltros:        {es:'Eliminar filtros',en:'Clear filters'},
        },
        showState:{
            nonClassified:          {es:'No Clasificadas',en:'Not classified'},
            classified:             {es:'Clasificadas',en:'Classified'},
            auto:                   {es:'Autoclasificadas',en:'Autoclassified'},
            divided:                {es:'Divididas',en:'Split'},
            synced:                {es:'Vinculadas',en:'Linked'},
            all:                    {es:'Todas',en:'All'},
        },
        papelera:                   {es:'Papelera',en:'Trash'},
        modalPapelera: {
            transaccionesEliminadas: {es: 'Transacciones eliminadas recientemente', en: 'Recently deleted transactions'},
            avisoTransaccionesPapelera: {es: 'Las transacciones que lleven más de 30 días en la papelera se eliminarán automáticamente. ', en: 'Transactions in the bin for more than 30 days will be automatically deleted. '},
            botonVaciarPapelera: {es: 'Vaciar papelera ahora.', en: 'Empty bin now.'},
            restaurar: {es: 'Restaurar transacciones', en: 'Restore transactions'},
            eliminarDefinitivo: {es: 'Eliminar definitivamente', en: 'Delete permanently'},
            eliminarPapeleraTitulo: {es: 'Confirmación para eliminar transacciones', en: 'Confirmation to delete transactions'},
            eliminarPapeleraTexto1: {es: 'Esta acción afectará a las ', en: 'This action will affect the '},
            eliminarPapeleraTexto2: {es: ' transacciones en Papelera. ¿Seguro que quieres continuar?', en: ' transactions in the bin. Are you sure you want to continue?'},
        },
        modales:{
            favoritos:                  {es:'Favoritos',en:'Favorites'},
            seleccionarSubcategoria:    {es:'Seleccionar subcategoría',en:'Select subcategory'},
            filtros:                    {es:'Filtros',en:'Filters'},
            mandarAPapeleraTexto1:      {es: 'Esta acción mandará a las ', en: 'This action will send the '},
            mandarAPapeleraTexto2:      {es: ' transacciones a Papelera. ¿Seguro que quieres continuar?', en: ' transactions to the bin. Are you sure you want to continue?'},
            limpiarTransaccionTitulo:   {es: 'Confirmación para limpiar transacciones', en: 'Confirmation to clean transactions'},
            limpiarTransaccionTexto1:   {es: 'Esta acción limpiara las subcategorías de ', en: 'This action will clean the subcategories of'},
            limpiarTransaccionTexto2:   {es: ' transacciones. ¿Seguro que quieres continuar?', en: ' transactions. Are you sure you want to continue?'},
            mandarAMLTitulo:            {es: 'Confirmación para autoclasificación', en: 'Confirmation to autoclass'},
            mandarAMLTexto1:            {es: 'Esta acción autoclasificará ', en: 'This action will autoclass '},
            mandarAMLTexto2:            {es: ' transacciones. ¿Seguro que quieres continuar?', en: ' transactions. Are you sure you want to continue?'},
            continuar:                  {es:'Continuar',en:'Continue'}
        },
        equivalente:                {es:'equivalente',en:'equivalent'},
        credito:                    {es:'Crédito',en:'Credit'},
        debito:                     {es:'Débito',en:'Debit'},
        seleccionarArchivo:         {es:'Seleccionar archivo',en:'Select file'},
        transaccionesSeleccionadas: {es: 'Transacciones seleccionadas', en: 'Selected transactions'},
        aplicarClasificacion:       {es: 'Aplicar clasificación', en: 'Apply classification'},
        clasificacionMultiple:      {es: 'Clasificación múltiple', en: 'Multiple classification'},
        buscando:                   {es: 'Buscando', en: 'Searching'},
        noTransacciones:            {es: 'No se encontraron transacciones', en: 'Transactions not found'},
        usarFiltros:                {es: 'Cambia los filtros para buscar transacciones', en: 'Change the filters to look for transactions'},
    },
    subirTransacciones: {
        infoArchivos: {es: 'Información de archivos', en: 'File information'},
        llenarCampos: {es: 'Llene los campos solicitados antes de subir el archivo', en: 'Fill in the requested fields before uploading the file'},
        filesRecientes: {es: 'Transacciones más recientes...', en: 'Most recent transactions...'},
        dropEmpresa: {es: 'Selecciona una empresa', en: 'Select a company'},
        dropBanco: {es: 'Selecciona un banco', en: 'Select a bank'},
        plantillas: {es: 'Plantillas', en: 'Templates'},
        dropPlantilla: {es: 'Selecciona una plantilla', en: 'Select a template'},
        estado: {es: 'Estado', en: 'Status'},
        dropFile1: {es: 'Suelta un archivo para', en: 'Drop a file to'},
        dropFile2: {es: 'subirlo a MD Cash', en: 'upload to MD Cash'},
        archivo: {es: 'Archivo', en: 'File'},
        regresar: {es: 'Regresar a transacciones', en: 'Return to transactions'},
        plantillaGenerica: {es: 'Descarga la plantilla genérica', en: 'Download the generic template'},
        generica: {es: 'Genérica', en: 'Generic'},
        crear: {es: 'Crear nueva plantilla', en: 'Create new template'},
        editar: {es: 'Editar plantilla actual', en: 'Edit current template'},
        plantillaUtilizar: {es: 'Plantilla a utilizar', en: 'Template to use'},
        previa: {es: 'Vista previa de la plantilla', en: 'Template preview'},
        subir: {es: 'Subir', en: 'Upload'},
        buscar: {es: 'Buscar', en: 'Search'},
        tipoArchivo: {es: 'Revisa que el tipo de archivo y formato sea el correcto (.xlsx, .csv)', en:'Check that the file type and format are correct (.xlsx, .csv)'},
        columnasExtra: {es: 'Se detectaron columnas nuevas en el catalogo', en:'New columns detected in your catalog'},
        reasignaColumnas: {es: 'Porfavor selecciona de nuevo las columnas de informacion adicional en tu plantilla', en:'Please reassign additional columns in your template'},
        modalPlantilla: {
            nombre: {es: 'Nombre de plantilla:', en: 'Template name:'},
            nueva: {es: 'Nueva plantilla', en: 'New template'},
            datos: {es: 'Personaliza los datos que necesites en cada columna:', en: 'Customize the data you need in each column:'},
            vistaPrevia: {es: 'Esta es la vista previa de las transacciones cargadas:', en: 'This is the preview of the loaded transactions:'},
            dropEncabezado: {es: 'Selecciona la fila que será el encabezado:', en: 'Select the row that will be the header:'},
            fila: {es: 'Fila ', en: 'Row '},
            encabezado: {es: 'Encabezado', en: 'Header'},
            columnas: {es: 'Columnas de plantilla', en: 'Template columns'},
            datosMostrados: {es: '*Los datos mostrados son del archivo seleccionado', en: '*The displayed data is from the selected file'},
            ninguno: {es: 'Ninguno', en: 'None'},
            cargoAbonos: {es: 'Entradas/(Salidas)', en: 'Inflow/(Outflow)'},
            abonoCargos: {es: '(Entradas)/Salidas', en: '(Inflow)/Outflow'},
            extras: {es: 'Extras', en: 'Extras'},
            errorTitle: {es: 'Las siguientes acciones deben realizarse antes de continuar', en: 'The following actions must be taken before continuing'},
            errorExistente: {es: 'ya existe una plantilla con este nombre', en: 'there is already a template with this name'},
            errorNombre: {es: 'asignar un nombre a la plantilla', en: 'assign a name to the template'},
            errorFecha: {es: 'seleccionar columna de fechas', en: 'select a date column'},
            errorConcepto: {es: 'seleccionar columna de conceptos', en: 'select a concept column'},
            errorAbonos: {es: 'seleccionar columna de abonos', en: 'select an inflow column'},
            errorCargos: {es: 'seleccionar columna de cargos', en: 'select an outflow column'}
        },
        modalDuplicados: {
            alertaDuplicados1: {es: 'Se encontraron ', en: 'There were found'},
            alertaDuplicados2: {es: ' transacciones duplicadas', en: ' duplicate transactions'},
            alertaPregunta: {es: '¿Qué deseas hacer?', en: 'What would you like to do?'},
            subirDuplicados: {es: 'Subir de todas formas', en: 'Upload anyway'},
            verDuplicados: {es: 'Ver detalles', en: 'View details'},
            detalleDuplicados: {es: 'Detalle de transacciones duplicadas', en: 'Details of duplicate transactions'},
            seleccion: {es: 'Seleccione las transacciones que deseas guardar:', en: 'Select the transactions you wish to save:'},
            existente: {es: 'Archivo existente', en: 'Existing file'},
            actual: {es: 'Archivo actual', en: 'Current file'},
            noArchivo: {es: 'Sin Archivo', en: 'Without file'},
            enArchivo: {es: 'Dentro del archivo', en: 'Existing in the file'},
            enMDCash: {es: 'Existentes en MD Cash', en: 'Existing in MD Cash'},
            errorNoFecha: {es: 'No se encontró una fecha en la transacción', en: 'No date found in transaction'},
            errorFecha: {es: 'Se encontró una fecha no válida en la transacción', en: 'Invalid date in transaction'},
            errorFechaFuturo: {es: 'Se encontró una fecha a futuro en la transacción', en: 'Future date in transaction'},
            errorFechaPasado: {es: 'Se encontró una fecha antes del incio de la cuenta en la transacción', en: 'Date before the initial date in transaction'},
            errorFechaRevisar: {es: ', revise la fecha o su formato', en: ', check the date or its format'},
            errorConcepto1: {es: 'La transacción ', en: 'Transaction '},
            errorConcepto2: {es: ' no tiene concepto', en: ' lacks a concept'},
            errorMovimientos1: {es: 'La transacción ', en: 'Transaction '},
            errorMovimientos2: {es: ' no tiene abono ni cargo', en: ' has neither inflow nor outflows'},
            errorMovimientosNoValidos1: {es: 'La transacción ', en: 'Transaction '},
            errorMovimientosNoValidos2: {es: ' no tiene abono ni cargo en formatos validos', en: ' has neither inflow nor outflows on valid formats'},
            errorMovimientosNoExistentes1: {es: 'La transacción ', en: 'Transaction '},
            errorMovimientosNoExistentes2: {es: ' no tiene movimientos', en: ' has no movements'},
            errorMovimientosExistentes1: {es: 'La transacción ', en: 'Transaction '},
            errorMovimientosExistentes2: {es: ' tiene abono y cargo', en: ' has both inflow and outflow'},
            errorCSV: {es: 'Hubo un problema con el archivo, o la plantilla seleccionada no muestra datos', en: 'There was a problem with the file, or the selected template does not have data'},
            errorInesperado: {es:'Lo siento, hubo un problema inesperado', en:'We are sorry, there was an unexpected error'},
            errorTransaccion: {es:'No se encontraron transacciones', en:'No transactions found'},
            confirmacionGuardar1: {es: 'Confirmación para subir', en: 'Confirmation to upload'},
            confirmacionGuardar2: {es: 'transacciones duplicadas', en: 'duplicate transactions'},
            confirmacionDuplicados1: {es: 'Se cargarán ', en: ' will be uploaded'},
            confirmacionDuplicados2: {es: ' de las ', en: ' of the '},
            confirmacionDuplicados3: {es: ' transacciones duplicadas, ', en: ' duplicate transactions, '},
            confirmacionPregunta: {es: '¿desea continuar?', en: 'Do you wish to continue?'},
            regresar: {es: 'Regresar', en: 'Return'}
        },
        modalPapelera: {
            archivosEliminados: {es: 'Archivos eliminadas recientemente', en: 'Recently deleted files'},
            avisoArchivosPapelera: {es: 'Los archivos que lleven más de 30 días en la papelera se eliminarán automáticamente. ', en: 'Files in the bin for more than 30 days will be automatically deleted. '},
            botonVaciarPapelera: {es: 'Vaciar papelera ahora.', en: 'Empty bin now.'},
            restaurar: {es: 'Restaurar archivos', en: 'Restore files'},
            eliminarDefinitivo: {es: 'Eliminar definitivamente', en: 'Delete permanently'},
            eliminarPapeleraTitulo: {es: 'Confirmación para eliminar archivos', en: 'Confirmation to delete files'},
            eliminarPapeleraTexto1: {es: 'Esta acción afectará a ', en: 'This action will affect '},
            eliminarPapeleraTexto2: {es: ' archivos en Papelera. ¿Seguro que quieres continuar?', en: ' files in the bin. Are you sure you want to continue?'},
            archivosSeleccionados: {es: 'Archivos seleccionados', en: 'Selected files'},
        },
        exitoSubirTitulo: {es: 'El archivo se ha subido exitosamente!', en: 'The file has been successfully uploaded!'},
        exitoSubirTexto1: {es: 'Se procesaron ', en: 'There were '},
        exitoSubirTexto2: {es: ' transacciones', en: ' transactions uploaded'},
        papeleraArchivoTitulo: {es: 'Se enviara a la papelera el archivo: ', en: 'The file will be send to the bin: '},
        eliminarArchivoTexto1: {es: 'con las ', en: 'with the '},
        eliminarArchivoTexto2: {es: ' transacciones que contiene,', en: ' transactions it contains,'},
        eliminarArchivoPregunta: {es: '¿desea continuar?', en: 'Do you wish to continue?'},
        eliminarPlatilla: {es:"Se eliminará la plantilla: ", en:"The following template will be deleted: "},
        accionirreversible: {es: 'Esta acción es irreversible.', en: 'This action is irreversible.'},
        eliminar: {es: 'Eliminar', en: 'Delete'},
        cancelar:{es:'Cancelar',en:'Cancel'},
        advertenciaTamano:{es:'El archivo puede ser muy grande', en:'The file is too big'},
        advertenciaTamano2:{es:'El archivo es demasiado grande', en:'The file is too big'},
        advertenciaMensaje:{es:'Esto puede causar un tiempo de espera al momento de cargarlo', en:'Uploading could take longer than expected'},
        advertenciaMensaje2:{es:'Esto puede causar un problema al cargar transacciones, intente cargar archivos con un maximo de 10,000 transacciones', en:'Uploading could cause a problem, try using files with a maximum of 10,000 transactions'},
        clasificadasTexto1: {es: ' y se lograron clasificar ', en: ' and '},
        clasificadasTexto2: {es: ' transacciones ', en: ' transactions were successfully classifield '},
    },
    reporte: {
        ordenCatalogo:          {es:'Orden por catalogo  ',en:'Ordered by catalog'},
        ordenTotal:          {es:'Orden por cantidad  ',en:'Ordered by amount'},
        actualizarReporte:          {es:'Actualizar reporte',en:'Update report'},
        exportarExcel:              {es:'Exportar a Excel',en:'Export to Excel'},
        descargarExcel:             {es:'Descargar a Excel',en:'Download Excel'},
        fxExpresado:                {es:'Reporte expresado en ',en:'Report expressed in '},
        flujoOperativo:             {es:'Flujo operativo',en:'Operating cash flow'},
        flujoInversion:             {es:'Flujo inversión',en:'Investing cash flow'},
        flujoFinanciamiento:        {es:'Flujo financiamiento',en:'Financing cash flow'},
        MovimientoEntreCuentas:     {es:'Movimiento entre cuentas',en:'Transfers between accounts'},
        cuentasDebito:             {es:'Cuentas nuevas débito',en:'New debit accounts'},
        ingresoDebito:             {es:'Ingresos débito',en:'Debit income'},
        egresoDebito:              {es:'Egresos débito',en:'Debit expenses'},
        cuentasCredito:             {es:'Cuentas nuevas crédito',en:'New credit accounts'},
        ingresoCredito:             {es:'Ingresos crédito',en:'Credit income'},
        egresoCredito:              {es:'Egresos crédito',en:'Credit expenses'},
        perdidaUtilidad:            {es:'Utilidad (pérdida) cambiaria ',en:'Currency exchange gain (loss)'},
        balanceInicial: {es: 'Saldo inicial de caja', en: 'Initial cash balance'},
        balanceEfectivo: {es: 'Saldo de efectivo', en: 'Cash balance'},
        flujoNeto: {es: 'Flujo neto de efectivo', en: 'Net cash flow'},
        balanceFinal: {es: 'Saldo final de caja', en: 'Final cash balance'},
        flujoAntesFinanciamiento: {es: 'Flujo antes de financiamiento', en: 'Cash flow before financing'},
        cuentasNuevasDebito: {es: 'Cuentas nuevas débito', en: 'New accounts debit'},
        cuentasNuevasCredito: {es: 'Cuentas nuevas crédito', en: 'New accounts credit'},
        saldoCredito: {es: 'Saldo tarjetas de crédito', en: 'Credit card balance'},
        saldoInicialCredito: {es: 'Saldo inicial tarjetas de crédito', en: 'Credit card initial balance'},
        movimientosCredito: {es: 'Movimientos tarjetas de crédito', en: 'Credit card movements'},
        saldoFinalCredito: {es: 'Saldo final tarjetas de crédito', en: 'Credit card final balance'},
        sinClasificar: {es: 'Transacciones sin clasificar', en: 'No-classified transactions'},
        total: {es:'Total',en:'Total'},
        subcatLineage: {es:'transacciones con la subcategoría:', en:'transactions with the subcategory:'},
        noSubcatLineage: {es:'transacciones sin clasificar', en:'transactions without subcategory'},
        infoFlujoOperativo: {es:'Es el flujo de efectivo generado por las entradas y salidas de efectivo por las actividades principales de una empresa.', en:'It is the cash flow generated by the cash inflows and outflows from the main activities of a company.'},
        infoFlujoInversion: {es:'Este flujo de efectivo generado por las entradas y salidas de efectivo por inversiones o venta de activos de largo plazo.', en:'This cash flow is generated by cash inflows and outflows from investments or sale of long-term assets.'},
        infoFlujoFinanciamiento: {es:'Es el flujo de efectivo generado por las entradas y salidas de efectivo por las actividades financieras de una empresa, como la obtención de préstamos, la emisión de acciones o el pago de dividendos.', en:"It is the cash flow generated by cash inflows and outflows from a company's financial activities, such as borrowing, issuing shares or paying dividends."},
        infoMovimientosCuentas: {es:'Son las entradas y salidas de efectivo entre cuentas de débito de la misma empresa.',en:'These are the cash inflows and outflows between debit accounts of the same company.'},
        infoCuentasCredito: {es:'Son los pagos realizados y recibidos por abonos a las tarjetas de crédito. En caso de ser distinto a cero significa que las salidas de las cuentas de debido para pagar las tarjetas de crédito son distintos a los movimientos en las tarjetas de crédito reconocidos como recepción de pago.',en:'These are the payments made and received for credit card payments. If it is other than zero, it means that the outflows from the due accounts to pay the credit cards are different from the movements in the credit cards recognized as payment receipts.'},
        infoIngresosCredito: {es:'Son los ingresos de efectivo reconocidos en las tarjetas de crédito que disminuye la deuda.',en:'These are cash receipts recognized on credit cards that reduce debt.'},
        infoEgresosCredito: {es:'Son las salidas de efectivo de las cuentas de crédito que se envían a las cuentas de crédito, que disminuyen el saldo de las cuentas de débito.',en:'These are cash outflows from credit accounts that are sent to credit accounts, which decrease the balance of debit accounts.'},
        infoFlujoAntesFinanciamiento: {es:'También conocido como  flujo de caja libre, es el efectivo que una empresa genera antes de tener  en cuenta los efectos de financiamiento.',en:'Also known as free cash flow, it is the cash a company generates before taking into account financing effects.'},
        infoPerdidaUtilidad: {es:'Son las pérdidas o utilidades  generadas por las variaciones en el tipo de cambio en los saldos y movimientos  bancarios en moneda extranjera. La pérdida cambiaria ocurre cuando la  moneda extranjera se deprecia frente a la moneda local, mientras que la  utilidad cambiaria se produce cuando la moneda extranjera se aprecia.',en:'These are the losses or profits generated by variations in the exchange rate on bank balances and transactions in foreign currency. The exchange loss occurs when the foreign currency depreciates against the local currency, while the exchange gain occurs when the foreign currency appreciates.'},
    },
    cuentasYSaldos: {
        agregarCuentas:             {es:'Agregar cuenta',en:'Add account'},
        alternativaCuentas: {es: 'Alternativa a más cuentas', en: 'Alternative to more accounts'},
        agregarCuentasModal: {
            banco:                  {es:'Banco',en:'Bank'},
            empresa:                {es:'Empresa',en:'Company'},
            cuenta:                 {es:'Cuenta',en:'Account'},
            alias:                  {es:'Alias',en:'Alias'},
            moneda:                 {es:'Moneda',en:'Currency'}, 
            valorInicial:           {es:'Valor inicial',en:'Initial balance'},
            fechaInicial:           {es:'Fecha Inicial',en:'Begin date'},
            fechaCorte:             {es:'Fecha de corte',en:'Cutoff day'},
            tipoCuenta:             {es:'Tipo de cuenta',en:'Account type'},
            flujoFinanciamiento: {es: 'Flujo de financiamiento', en: 'Financing flow'},
            ingreso: {es: 'Ingreso', en: 'Income'},
            egreso: {es: 'Egreso', en: 'Expense'},
            reiniciarValores: {es: 'Reiniciar valores', en: 'Reset values'},
            agregarCuentas: {es: 'Agregar cuentas', en: 'Add accounts'},
            placeholder: {
                account: {es: 'Nombre de la cuenta', en: 'Account Name'},
                banco: {es: 'Banco', en: 'Bank'},
                aliasDeLaCuenta: {es: 'Alias de la cuenta', en: 'Account alias'},
                currency: {es: 'Currency', en: 'Currency'},
            },
        },
        modificarPreModal: {
            numeroDeCuenta: {es: 'Número de cuenta', en: 'Account number'},
            moneda: {es: 'Moneda', en: 'Currency'},
            valorInicial: {es: 'Valor inicial', en: 'Initial value'},
            fechaInicial: {es: 'Fecha inicial', en: 'Start date'},
            tipoDeCuenta: {es: 'Tipo de cuenta', en: 'Account type'},
            razonSocial:                {es:'Razón social',en:'Business name'},
            fechaCorte:             {es:'Fecha de Corte',en:'End date'},
        },
        modificarCuentaModal: {
            banco:                  {es:'Banco',en:'Bank'},
            modificarCuentas: {es: 'Modificar cuentas', en: 'Modify accounts'},
            cuenta:                 {es:'Cuenta',en:'Account'},
            alias:                  {es:'Alias',en:'Alias'},
            moneda:                 {es:'Moneda',en:'Currency'},
            valorInicial:           {es:'Valor inicial',en:'Initial balance'},
            fechaCorte:             {es:'Fecha de Corte',en:'End date'},
            fechaInicial:           {es:'Fecha inicial',en:'Begin date'},
            tipoCuenta:             {es:'Tipo de cuenta',en:'Account type'},
            flujoFinanciamiento: {es: 'Flujo de financiamiento', en: 'Financing flow'},
            activado:               {es:'Activado',en:'Active'},
            desactivado: {es: 'Desactivado', en: 'Disabled'},
            actualizarDatos:        {es:'Actualizar Datos',en:'Update data'},
            empresa:                {es:'Empresa',en:'Company'},
            ingreso: {es: 'Ingreso', en: 'Income'},
            egreso: {es: 'Egreso', en: 'Expense'},
        },
        deleteModal : {
            estaSeguro: {es: 'Confirmación para eliminar cuenta', en: 'Account deletion confirmation'},
            advertenciaBorrar: {es: 'Esta acción no podrá deshacerse', en: 'This action cannot be undone'},
            seguroContinuar: {es: '¿Seguro que quieres continuar?', en: 'Are you sure you want to continue?'},
            confirmar: {es: 'Confirmar', en: 'Confirm'},
            cancelar: {es: 'Cancelar', en: 'Cancel'},
            errorAlBorrarCuenta: {es: 'Error al borrar cuenta', en: 'Error deleting account'},
            noSePuedeBorrar: {es: 'No se puede borrar esta cuenta porque tiene transacciones o archivos en papelera.', en: 'This account cannot be deleted because it has transactions or files in the trash.'}
        },
        cuentasNegativas: {es: 'No se puede tener números negativos en cuentas de débito', en: 'No negative numbers are allowed in debit accounts.'},
        verificacionSaldos:         {es:'Verificación saldos',en:'Balance verification'},
        fecha:                      {es:'Fecha',en:'Date'},
        debito:                     {es:'Débito',en:'Debit'},
        razonSocial:                {es:'Empresa',en:'Company'},
        banco:                      {es:'Banco',en:'Bank'},
        cuenta:                     {es:'Cuenta', en:'Account'}, 
        alias:                      {es:'Alias', en:'Alias'}, 
        moneda:                     {es:'Moneda', en:'Currency'}, 
        ultimoMovimiento:           {es:'Último movimiento', en:'Last transaction'}, 
        saldoCuentas:               {es:'Saldo Cuentas',en:'Account balance'},
        tipoCambio:                 {es:'Tipo de cambio', en:'Exchange rate'}, 
        saldoEquivalenteMXN:        {es:'Saldo en',en:'Balance in'},
        credito:                    {es:'Crédito',en:'Credit'},
        aliasCuenta: {es: 'Alias de la cuenta', en: 'Account alias'},
        reiniciar: {es: 'Reiniciar valores', en: 'Reset values'},
        ingresos: {es: 'Ingresos', en: 'Income'},
        ingresosFinanciamiento: {es: 'Ingresos financiamiento', en: 'Financing income'},
        egresos: {es: 'Egresos', en: 'Expenses'},
        ultimoMovimiento: {es: 'Último movimiento', en: 'Last transaction'},
        saldo: {es: 'Saldo Cuenta', en: 'Account balance'},
        eliminar: {es: 'Eliminar cuenta', en: 'Delete account'},
        editar: {es: 'Editar', en: 'Edit'},
        error: {es: 'Ha habido un problema para registrar la cuenta, intente más tarde', en: 'There was a problem registering the account, please try later'},
        error2: {es: 'La cuenta que quiere registrar ya existe, intente con otra cuenta', en: 'The account you want to register already exists, try another account'},
        errorRazonSocial: {es: 'Seleccione una razón social por favor', en: 'Please select a company'},
        duplicado: {es: 'Ceunta duplicada', en: 'Duplicate account'},
        tituloExito: {es: "Cuenta registrada con éxito", en: "Account successfully registered"},
        Cuidado: {
            es: "Cuidado",
            en: "Warning"
            },
        mensajeAdvertencia: {
            es: "Si haces esta acción podría tardar hasta dos horas recalcular la utilidad cambiaria.",
            en: "If you perform this action, it could take up to two hours to recalculate the exchange gain."
        },
        mensajeExito: {es: "La cuenta que estaba intentando registrar se ha registrado con éxito.", en: "The account you were trying to register has been successfully registered."},
        iIcon: {
            Razonsocial: {
                es: "Nombre de la empresa a la que pertenece la cuenta bancaria.",
                en: "Name of the company to which the bank account belongs."
            },
            Cuenta: {
                es: "Número o descripción de la cuenta bancaria.",
                en: "Number or description of the bank account."
            },
            Alias: {
                es: "Nombre para identificar la cuenta bancaria para fácil identificación.",
                en: "Name to identify the bank account for easy identification."
            },
            Moneda: {
                es: "Moneda de la cuenta bancaria.",
                en: "Currency of the bank account."
            },
            Ultimomovimiento: {
                es: "Fecha del último movimiento clasificado de la cuenta bancaria.",
                en: "Date of the last classified movement of the bank account."
            },
            SaldoCuenta: {
                es: "Saldo de la cuenta al final del día seleccionado en la moneda de la cuenta bancaria.",
                en: "Balance of the account at the end of the selected day in the currency of the bank account."
            },
            TipodeCambio: {
                es: "Tipo de cambio del día seleccionado que se usa para convertir la moneda de la cuenta bancaria a la moneda utilizada para reportar.",
                en: "Exchange rate of the selected day used to convert the currency of the bank account to the reporting currency."
            },
            Saldoequivalente: {
                es: "Saldo de la cuenta al final del día seleccionado convertido a la moneda utilizada para reportar.",
                en: "Equivalent balance of the account at the end of the selected day converted to the reporting currency."
            }
        }
    },
    catalogoCuentas: {
        flujo:                      {es:'Flujo',en:'Cash flow'},
        categoria:                  {es:'Categoría',en:'Category'},
        subcategoria:               {es:'Subcategoría',en:'Subcategory'},
        clientes:                   {es:'Clientes',en:'Customers'},
        cliente:                    {es:'cliente',en:'customer'},
        proveedores:                {es:'Proveedores',en:'Vendors'},
        proveedor:                  {es:'proveedor',en:'vendor'},
        guardar:                    {es:'Guardar cambios',en:'Save changes'},
        cancelar:                   {es:'Cancelar',en:'Cancel'},
        flujoPositivo:              {es:'Flujo positivo',en:'Positive cash flow'},
        flujoNegativo:              {es:'Flujo negativo',en:'Negative cash flow'},
        agregarCategoria:           {es:'Agregar categoría',en:'Add category'},
        agregarSubcategoria:        {es:'Agregar subcategoría',en:'Add subcategory'},
        agregarCliente:             {es:'Agregar cliente',en:'Add customer'},
        agregarProveedor:           {es:'Agregar proveedor',en:'Add vendor'},
        agregar:                    {es:'Agregar',en:'Add'},
        expandir:                   {es:'Expandir',en:'Expand'},
        contraer:                   {es:'Contraer',en:'Shrink'},
        flujoOperativo:             {es:'Flujo operativo',en:'Operating cash flow'},
        flujoInversion:             {es:'Flujo inversión',en:'Investing cash flow'},
        flujoFinanciamiento:        {es:'Flujo financiamiento',en:'Financing cash flow'},
        MovimientoEntreCuentas:     {es:'Movimiento entre cuentas',en:'Transfers between accounts'},
        cuentasCredito:             {es:'Cuentas crédito',en:'Credit accounts'},
        columnasAdicionales:        {es:'Columnas adicionales',en:'Additional columns'},
        nombreExistente:            {es:'La columna con el siguiente nombre ya existe',en:'There is already a column with this name'},
        nombreInvalido:             {es:'El siguiente nombre no es valido para una columna',en:'There following name is not valid for a column'},
        cuentasUtilizando:          {es:' cuenta(s) estan utilizando esta columna en sus plantillas',en:' account(s) are using this column in their templates'},
        modalCambios:{               
            titulo: {es:'¡Ups! Parece que tienes ',en:'¡Oops! Seems like you have'},
            titulo2: {es:'cambios sin guardar.',en:'unsaved changes.'},
            texto: {es:'Si continuas sin guardar, estos cambios no se guardarán y podrían perderse.', en:'If you continue without saving, these changes will be lost.'},
            mantener: {es:'Mantener en pantalla', en:'Stay'},
        },
        categoriaNombreDuplicado: {
            es:'Error, ya tienes este nombre de categoría registrado.',
            en:'Error, you already register this category name'
        },
        subcategoriaNombreDuplicado: {
            es:'Error, ya tienes este nombre de subcategoría registrado.',
            en:'Error, you already register this subcategory name'
        },
        entrarModoEmpresas: {
            es:'Los cambios se verán reflejados en el catálogo de cuentas global.',
            en:'The changes will be reflected in the global chart of accounts.'
        }
    },
    verificacionSaldos: {
        banco:                      {es:'Banco',en:'Bank'},
        cuentas:                    {es:'Cuentas',en:'Accounts'},
        alias:                      {es:'Alías',en:'Alías'},
        moneda:                     {es:'Moneda',en:'Currency'},
        totalEquivalentePesos:      {es:'Total equivalente en pesos',en:'Debit accounts (Bank statement)'}, 
        diferencias:                {es:'Diferencias',en:'Debit accounts (MD Cash)'}, 
        editar:                     {es:'Editar',en:'Edit'},
        fechas: {es: 'Fechas', en: 'Dates'},
        saldosReales: {es: 'Saldos reales', en: 'Actual balances'},
        debito: {es: 'Débito', en: 'Debit'},
        diferencias: {es: 'Diferencia vs MDcash', en: 'Difference vs MDcash'},
        ultimaMod: {es:'Última modificación', en:'Last edited'},
        ingreseSaldo: {es:'Ingrese saldo esperado', en:'Enter expected balance'},
        lastTransaction: {es: 'Ultima transacion: ', en: 'Last transaction: '},
        verificado: {es:'Verificado', en:'Verified'},

    },
    administracion: {
        usuarios:                   {es:'Usuarios',en:'Users'},
        empresas:                   {es:'Empresas',en:'Companies'},
        facturacion:               {es:'Facturación',en:'Billing'},
        habilitarEmpresas:          {es:'Habilitar Empresas',en:'Enable companies'},
        anadirEmpresas:             {es:'Añadir empresa',en:'Add company'},
        anadirUsuarios:             {es:'Añadir usuario',en:'Add user'},
        anadirBanco:             {es:'Añadir banco',en:'Add bank'},
        anadirCliente:              {es:'Añadir nuevo cliente',en:'Add new client'},
        modalEditar:{
            titulo: {es: 'Editar usuario', en: 'Edit user'},
            nombre: {es: 'Nombre', en: 'Name'},
            correo: {es: 'Correo', en: 'Email'},
            rol: {es: 'Rol', en: 'Role'},
            permisos: {es: 'Selecciona a que permisos tiene acceso este usuario', en: 'Select which permissions this user has access to'},
            permiso1: {es: 'Editar permisos de otros usuarios', en: 'Edit other users permissions'},
            permiso2: {es: 'Ver detalle de Transacciones', en: 'View transaction detail'},
            permiso3: {es: 'Cargar, editar y clasificar Transacciones', en: 'Load, edit, and classify transactions'},
            permiso4: {es: 'Abrir cuentas y cambiar saldo inicial', en: 'Open accounts and change initial balance'},
            permiso5: {es: 'Cambiar Catalogo de cuentas', en: 'Change chart of accounts'},
            permiso6: {es: 'Planeación', en: 'Planning'},
            permiso7: {es: 'Syncfy', en: 'Syncfy'},
            permiso8: {es: 'STRIPE', en: 'STRIPE'},
            permiso9: {es: 'Visualizar reporte', en: 'View report'},
            
            eliminar: {es: 'Eliminar usuario', en: 'Delete user'},
            eliminarAcceso: {es: 'Eliminar este acceso', en: 'Delete this access'},
            eliminarTodosAccesos: {es: 'Eliminar todos los accesos', en: 'Delete all access'},
        },
        modalCliente: {
            industria: {es: 'Industria', en: 'Industry'},
            fotoPerfil: {es: 'Foto perfil', en: 'Profile picture'},
            idioma: {es: 'Idioma', en: 'Language'},
            moneda: {es: 'Moneda base', en: 'Base currency'},
            negativos: {es: 'Formato de números negativos', en: 'Format for negative numbers'},
            agregar: {es: 'Añadir cliente', en: 'Add client'},
            subtitulo: {es: 'Se asignarán todas las cuentas de banco a la 1er empresa', en: 'All bank accounts will be assigned to the first company'}
        },
        modalEliminar: {
            titulo: {es: '¿Estás seguro de que quieres eliminar a este usuario?', en: 'Are you sure you want to delete this user?'},
            confirmar: {es: 'Una vez que confirmes esta acción no podrá deshacerse', en: 'Once you confirm this action it cannot be undone'},
            
        },
        modalEmpresas:{
            titulo: {es: 'Editar empresa', en: 'Edit company'},
            alias:  {es: 'Alias', en: 'Alias'},
            tax: {es: 'Tax ID', en: 'Tax ID'},
        },
    },
    iconI:{
        categoria: {es:'Agrupación de subcategorías de movimientos bancarios.',en:'Grouping of subcategories of bank movements.'},
        subcategoria: {es:'Tipo de movimiento para agrupar los movimientos bancarios según su origen o destino.',en:'Movement type to group bank movements according to their origin or destination.'},
        fx: {es:'Tipo de cambio que se aplica para convertir a la moneda de reporte seleccionada. Se utiliza el tipo de cambio publicado por el Banco de México, sin embargo, el usuario puede cambiar el tipo de cambio por transacción.',en:'Exchange rate that is applied to convert to the selected reporting currency. The exchange rate published by the Bank of Mexico is used, however, the user can change the exchange rate per transaction.'},
        abono: {es:'Son las entradas de efectivo a la cuenta, que representa una adición de fondos a la cuenta.',en:'These are cash inflows to the account, which represents an addition of funds to the account.'},
        cargo: {es:'Son las salidas de efectivo o pago hecho por la empresa, que representa una disminución de fondos de la cuenta.',en:'These are cash outflows or payments made by the company, which represent a decrease in funds in the account.'},

    },
    planeacion:{
        planeacion:{es:'Planeación',en:'Planning'},
        real:{es: 'Real', en:'Real'},
        escenario:{es:'Escenario',en:'Scenario'},
        seleccionaEscenario:{es:'Selecciona el escenario',en:'Select a scenario'},
        seleccionaRecurrencia:{es:'Selecciona la recurrencia',en:'Select recurrence'},
        seleccionaClienteProveedor:{es:'Selecciona Cliente/Proveedor',en:'Select Customers/Vendors'},
        seleccionaEmpresa:{es:'Selecciona la empresa',en:'Select a company'},
        recurrencia:{es:'Recurrencia',en:'Recurrence'},
        comparacion:{es:'Revisar comparación',en:'Review comparison'},
        transacciones:{es:'Ver transacciones',en:'See transactions'},
        copiarAOtroEscenario:{es:'Copiar a otro escenario',en:'Copy to another scenario'},
        irAlReporte:{es:'Ir al reporte',en:'Report'},
        ninguno:{es:'Ninguno',en:'None'},
        errorTipo: {es:'No se logro identificar el tipo de movimiento en la transaccion: ',en:'Invalid transaction type in line: '},
        errorMoneda: {es:'No se logro identificar el tipo de moneda en la transaccion: ',en:'Invalid currency type in line: '},
        errorCambio: {es:'El tipo de cambio especificado no es valido en la transaccion: ',en:'Invalid exchange rate in line: '},
        cambiosPendientes: {es: 'Se detectaron cambios pendientes en el reporte',en:'Pending changes were detected'},
        favorActualizar: {es: 'Se recargara el reporte para mostrar la informacion actualizada',en:'The report will reload to show updated information'},
        modalPlantilla:{
            errorTipo: {es:'seleccionar columna de Credito/Debito',en:'select a Credit/Debit column'},
            errorMoneda: {es:'seleccionar columna de moneda',en:'select a currency column'},
        },
        scenarioError:{es:'No se encontraron escenarios validos',en:'No valid scenarios were found'},
        modalAnadir:{
            titulo:{es:'Añadir escenario',en:'Add scenario'},
        },
        modalEditar:{
            titulo:     {es:'Editar escenarios',en:'Edit scenarios'},
            fechaIni:   {es:'Fecha inicial',en:'Initial date'},
            fechaFin:   {es:'Fecha final',en:'Final date'},
            creadoPor:  {es:'Creado por',en:'Created by'},
            notas:      {es:'Notas',en:'Notes'},
            anadirNotas:{es:'Añadir notas',en:'Add notes'}
        },
        comparacionReport:{
            real:{es:'Real', en:'Real'},
            proyeccion:{es:'Proyección', en:'Projection'},
            var:{es:'Variación', en:'Variation'},
            datosReales: {es:'Datos reales',en:'Real data'},
            datosProyeccion: {es:'Datos proyección',en:'Projection data'},
        },
        opcionesRecurrencia:{
            noSeRepite:{es:'No se repite',en:'Does not repeat'},
            diario:{es:'Diario',en:'Daily'},
            semanal:{es:'Semanalmente los ',en:'Weekly on '},
            mensual:{es:'Mensualmente en el ',en:'Monthly on the '},
            personalizado:{es:'Personalizado',en:'Custom'},
            termina:{es:'Termina',en:'Ends'},
            cuandoTermina:{es:'En',en:'On'},
            despuesDe:{es:'Despues de',en:'After'},
            repeticiones:{es:'repeticiones',en:'ocurrences'},
        },
    },
    trabajoEnProceso:{es:'Proximamente',en:'Work in progress'},
    errorAlgoSalioMal: {es:'Algo salio mal',en:'Something went wrong'},
    consolidado: {es:'Consolidado',en:'Consolidated'},
    centroDeControl: {es:'Centro de control',en:'Control center'},
    pago: {es: 'Pago', en: 'Payment'},
    centroDeControlSub: {es:'Conoce la productividad de tu equipo.',en:'Know the productivity of your team.'},
    paginaControl: {
        columnas:{
            user:       {es:'Usuario',en:'User'},
            email:      {es:'Correo',en:'Email'},
            rol:        {es:'Rol',en:'role'},
            lastAct:    {es:'Última actividad',en:'Last action'},
            time:       {es:'Tiempo',en:'Time'},
            client:     {es:'Cliente',en:'Client'},
            name:       {es:'Nombre',en:'Name'},
            alias:      {es:'Alias',en:'Alias'},
            taxID:      {es:'Tax ID',en:'Tax ID'},
            pago:      {es:'Pago',en:'Payment'},
            accessTo:     {es:'Clientes asociados',en:'Associated clients'},
            internoExterno:     {es:'Usuario Interno MD/Externo',en:'Internal MD user/external'},
            fechaRegistro:     {es:'Fecha de registro',en:'Date of register'},
            externalUsers:     {es:'Usuarios externos activos',en:'External active users'},
            numAccounts:      {es:'# de cuentas bancarias',en:'# of bank accounts'},
            fechaCreacion:      {es:'Fecha de creación',en:'Creation date'},
            ultimaEdicion:      {es:'Última edición',en:'Last edition'},
            numRS:      {es:'# de empresas',en:'# of Companies'},
            industry:   {es:'Industria',en:'Industry'},
            language:   {es:'Idioma',en:'Language'},
            numberFormat:   {es:'Formato de número',en:'Number format'},
            transactions:   {es:'Transacciones editadas',en:'Updated transactions'},
            sesiones:   {es:'Sesiones iniciadas',en:'Sessions'},
            interno:   {es:'Interno',en:'Internal'},
            externo:   {es:'Externo',en:'External'},

        },
        cliente:        {es:'Cliente',en:'Client'},
        editarCliente:  {es:'Editar cliente',en:'Edit client'},
        editarUsuario:  {es:'Editar usuario',en:'Edit user'},
        anadirBanco:    {
            titulo:         {es:'Añadir banco',en:'Add bank'},
            tituloEditar:   {es:'Editar banco',en:'Edit bank'},
            nombre:         {es:'Nombre',en:'Name'},
            imagen:         {es:'Imagen',en:'Image'},
            activo:         {es:'Activo',en:'Active'}
        },
        errores:{
            accesoExistente:{es:'Este usuario ya cuenta con este acceso',en:'This user already has this access'}
        },
        agregarEmpresa: {es:'Agregar empresa',en:'Add company'},
        agregarAcceso: {es:'Agregar acceso',en:'Add access'},
        transicionAEmpresas: {es:'Habilitar empresas',en:'Enable companies'},
        pagado: {es:'Corriente',en:'Current'},
        noPagado: {es:'Vencido',en:'Overdue'},
    },
    paginaTyC:{
        titulo:     {es:'Términos y condiciones',en:'Terms and conditions'}
    },
    paginaPrivacyPolicy:{
        titulo:     {es:'Política de Privacidad',en:'Privacy Policy'}
    },
    toastsBasicos:{
        aviso: {es:'Aviso',en:'Warning'}
    },
    Syncfy:{
        vinculados: {es:'Vinculados',en:'Linked'},
        vincular: {es:'Vincular banco',en:'Link to bank'},
        noCuentas: {es:'No hay cuentas vinculadas en este banco',en:'No linked accounts on this bank'},
        seleccioneCuenta: {es:'Seleccione una cuenta',en:'Select an account'},
        eliminarCuenta: {es:'Eliminar vinculación',en:'Delete linked account'},
        mensajeEliminar: {es:'Esta cuenta solo se eliminara la vinculación, junto con las cuentas que utilicen las mismas credenciales',en:'This account will only delete the link to the account, alongside other accounts that have the same credentials'},
        cargarTransacciones: {es:'Vinculación',en:'Linked account'},
        advertenciaDesvincular: {es:'Desvinculación finalizada', en: 'Accounts unlinked successfully'},
        cuentasDesvinculadas1: {es:'Se desvincularon ', en: ''},
        cuentasDesvinculadas2: {es:' cuentas', en: 'accounts were unlinked'},
        cuentasVinculada: {es:'Cuenta vinculada', en: 'Linked account'},
        aliasVinculada: {es:'Alias vinculado', en: 'Linked alias'},
        vinculacion: {es:'Vinculado', en: 'Link'},
        ultimaSinc: {es:'Última sincronización', en: 'Last sync'},
        noVinculada: {es:'No vinculado', en: 'Not linked'},
    }
};
//{es:'',en:''}
export default Idioma