import React from 'react'
import SuccessCheck from '../assets/SuccessCheck';
import CloseLogo from '../assets/CloseLogo';
import ExclamationLogo from '../assets/ExclamationLogo';
import InfoLogo from '../assets/InfoLogo';
import ErrorLogo from '../assets/ErrorLogo';

const MessageToast = ({type, title, message, setSign=null, id=null, close=null}) => {

    let textColors = "text-red-1"
    let borderColors = "border-red-1"
    let icon = (<div className='mx-5'><ErrorLogo fill="#FF003F" stroke="#ffffff"/></div>)
    switch(type){
        case "error":
            textColors = "text-red-1"
            borderColors = "border-red-1"
            icon = (<div className='mx-5'><ErrorLogo fill="#FF003F" stroke="#ffffff"/></div>)
            break;
        case "warning":
            textColors = "text-yellow-1"
            borderColors = "border-yellow-1"
            icon = (<div className='mx-5'><ExclamationLogo fill="#F0BD11" stroke="#ffffff"/></div>)
            break;
        case "success":
            textColors = "text-green-1";
            borderColors = "border-green-1";
            icon = (<div className='mx-5'><SuccessCheck fill="#00C67E" stroke="#ffffff"/></div>)
            break;
        case "information":
            textColors = "text-light-purple-1";
            borderColors = "border-light-purple-1";
            icon = (<div className='mx-5'><InfoLogo fill="#2121BE" stroke="#ffffff"/></div>)
            break;
    }

    const handleSign = () => {
        if(id){
            close(id)
        }else{
            setSign(false)
        }
    }

  return (
    <div className={`shadow-high-elevation border-l-[22px] ${borderColors} rounded-button mt-5 w-[450px] bg-white z-[9000]`} role="alert">
        <div className="flex items-center py-4">
                {icon}
            <div>
                <p className={`${textColors} text-h10-2xl`}>{title}</p>
                <p className="text-neutral-3 text-p8-2xl">{message}</p>
            </div>
            <div className='cursor-pointer fill-white stroke-neutral-3 ml-auto mr-5' onClick={() => handleSign()}>
            <CloseLogo stroke="#ADADAD"/>
        </div>
        </div>
    </div>
  )
}

export default MessageToast