import React, { useEffect, useState } from 'react'
import {NavLink} from 'react-router-dom'
import { useStateContext } from '../contexts/ContextProvider';
import { HomeIcon, ListBulletIcon, ChartPieIcon, TableCellsIcon, CreditCardIcon, BuildingLibraryIcon, DocumentIcon } from '@heroicons/react/24/solid';
import Logo from '../assets/Logo';
import Logout from '../assets/Logout'
import Prueba from '../assets/Prueba';
import { useMsal } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';
import logoSidebar from '../assets/logoSidebar.svg';
import LogoSidebar from '../assets/LogoSidebar'
import ResumenLogo from '../assets/resumenLogo'
import TransaccionesLogo from '../assets/transaccionesLogo'
import ReporteLogo from '../assets/ReporteLogo';
import CuentasLogo from '../assets/CuentasLogo';
import { urlBase } from "../authConfig";
import LogOutLogo from '../assets/LogOutLogo.js'
import CatalogoLogo from '../assets/CatalogoLogo';
import SettingsLogo from '../assets/SettingsLogo';
import AjustesLogo from '../assets/AjustesLogo';
import UsuariosLogo from '../assets/UsuariosLogo';
import LogoSidebarWhite from '../assets/LogoSidebarWhite';
import {Idioma} from '../components';
import PlanningLogo from '../assets/PlanningLogo.js';
import LogoSideBarSmall from '../assets/LogoSideBarSmall.js';
import MDCashLogo1 from '../assets/MDCashLogo1.js';
import TransaccionesLogoNotAnimated from '../assets/transaccionesLogoNotAnimated.js';
import UpdateReportLogo from '../assets/UpdateReportLogo';
import RedTriangle from '../assets/RedTriangle.js';
import { addActivityListeners, removeActivityListeners } from './inactivityTimer.js';


const Sidebar = () => {

  const navigate = useNavigate()
  const {activeMenu,setActiveMenu,setMenu,setClient,clientName,userInfo,userAccess,idRS,language, accessToken, idClient, sessionID, notClassified, setRs
  } = useStateContext();
  const [isInactive , setIsInactive] = useState(false)
  const [links,setLinks] = useState([])
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  function handleLogout(instance) {
      instance.logoutPopup().then(()=>{
        setClient('');
        setRs('');
        sessionStorage.clear();
        localStorage.clear();
        removeActivityListeners();
      })
      .catch(e => {
          console.error(e);
      });
      navigate('/');
  }
  const { instance } = useMsal();
  const icon = 'h-6 w-6 text-md-blue 2xl:scale-100 scale-75'

  useEffect(() => {
    const handleInactive = () => {
      setIsInactive(true);
      handleLogout(instance);
      sessionStorage.clear()
      if(isInactive) {
        alert('Session timeout');
      }
    }

    addActivityListeners(handleInactive, 900000);
  }, [instance]);

  const getNotClassified = async() => {
    let header = new Headers();
        
    const bearer = `Bearer ${accessToken}`;
    header.append("Authorization", bearer);
    header.append("Content-Type",'application/json');

    fetch(`${urlBase}/getNotClassified`, {
        method: 'POST',
        headers: header,
        body: JSON.stringify({
            "clientID": idClient,
            logsInfo: {
                origin: 'Cuentas y Saldos',
                sessionID: sessionID
            }
        })
    })
    .then(res => res.json())
    .then((aux) => {
      if(aux.length > 0) {
      }
    });
  }

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 1024);
    };

    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  useEffect(()=> {
    if(userAccess){
      let aux = [{
        title: 'Tablero',
        links: [
          {
            name: Idioma.sideBar.resumen[language],
            link: 'summary',
            icon:function(props){return <ResumenLogo fill={props.fill}/>},
          },         
        ],
      }]
      if(userAccess.access2 == true ){
        aux[0].links[aux[0].links.length] = {
          name: Idioma.sideBar.transacciones[language],
          link: 'classification',
          icon:function(props){
            return notClassified ? (
              <TransaccionesLogo fill={props.fill} transaccionesPend={props.transaccionesPend} />
            ) : 
            (
              <TransaccionesLogoNotAnimated fill={props.fill} />
            )
          },
        }
      }
      if(userAccess.access9 == true ){

        aux[0].links[aux[0].links.length] = {
          name: Idioma.sideBar.reporte[language],
          link: 'report',
          icon:function(props){return <ReporteLogo fill={props.fill}/>},
        }
      }
      if(userAccess.access4 == true ){
        aux[0].links[aux[0].links.length] = {
          name: Idioma.sideBar.cuentasYSaldos[language],
          link: 'balance',
          icon:function(props){ return<CuentasLogo fill={props.fill}/>},
        }
      }

      if(userAccess.access5 == true ){
        aux[0].links[aux[0].links.length] = {
          name: Idioma.sideBar.catalogo[language],
          link: 'subcategories',
          icon:function(props){ return<CatalogoLogo fill={props.fill}/>},
        }
      }
      if(userAccess.access6 == true ){
        aux[0].links[aux[0].links.length] = {
          name: Idioma.sideBar.planeacion[language],
          link: 'planning',
          icon:function(props){return <PlanningLogo fill={props.fill}/>},
        }
      }
      /* *
      if(userAccess.access7 == true ){
        aux[0].links[aux[0].links.length] = {
          name: 'Syncfy',
          link: 'Syncfy',
          icon:function(props){return <UpdateReportLogo fill={props.fill}/>},
        }
      }
      if(userAccess.access1 == true && !idRS){
        aux[0].links[aux[0].links.length] = {
          name: 'Empresas',
          link: 'rs',
          icon:function(props){ return<AjustesLogo fill={props.fill}/>},
        }
      }/**/
      /*if(userAccess.access1 == true ){
        aux[0].links[aux[0].links.length] = {
          name: 'Ajustes',
          link: 'admin',
          icon:function(props){ return<AjustesLogo fill={props.fill}/>},
        }
      }*/
      
      /*if(userAccess.access4 == true ){ //definir catalogo de cuentas
        aux[0].links[aux[0].links.lenght] = {
          name: 'Cuentas y saldos',
          link: 'balance',
          icon:function(props){ return<CuentasLogo fill={props.fill}/>},
        }
      }*/
      setLinks(aux)
    }
    
  },[userAccess])
  


  const activeLink = 'min-h-[30px] mt-4 2xl:mt-10 flex items-center gap-5 dark:text-white text-[12px] 2xl:text-[16px] text-tertiary font-bold dark:bg-dark-selected-gray underline lg:mb-[30px] md:mb-[28px] sm:items-center sm:justify-center sm:mb-[40px] xxs:flex xxs:justify-center xxs:items-center mobileL:flex mobileL:justify-center mobileL:items-center mobileM:flex mobileM:justify-center mobileM:items-center mobileS:flex mobileS:justify-center mobileS:items-center xs:flex xs:justify-center xs:items-center';
  const normalLink = 'min-h-[30px] mt-4 2xl:mt-10 flex items-center gap-5 dark:text-white text-[12px] 2xl:text-[16px] text-white dark:text-gray-200 dark:hover:bg-dark-selected-gray sm:mb-[40px]'
  return (
    <div className='min-h-full max-h-screen xl:max-h-screen md:w-[200px] xs:max-w-[100px] lg:max-w-full xl:max-w-full sm:max-w-[125px] overflow-auto bg-neutral flex flex-col justify-between xs:overflow-hidden xs:relative'>
      <div className='px-[10%] '>
        {notClassified}
        <div className='2xl:pt-12 pt-10 sm:flex sm:justify-center sm:items-center xs:flex xs:justify-center xs:items-center mobileL:hidden mobileM:hidden mobileS:hidden xxs:flex xxs:justify-center xxs:items-center'>
            {isSmallScreen  ? <LogoSideBarSmall /> :<MDCashLogo1/>}
          </div>
          <p className='2xl:mt-[50px] md:mt-16 text-h6-2xl mt-2 xl:mt-10 2xl:text-[1.5rem] text-[1rem] text-tertiary mobileL:hidden mobileM:hidden mobileS:hidden'>
            {isSmallScreen ? (
              <span className='text-h6-2xl text-white flex justify-center items-center'>
                ¡{Idioma.sideBar.hola[language]}!
              </span>
            ) : (
              <>
                ¡{Idioma.sideBar.hola[language]}, <span className='text-white text-h6-2xl font-bold'>{userInfo.name.split(' ')[0]}!</span>
              </>
            )}
          </p>
        <div className='justify-end'>
            {links.map((item) => (
                <div className=' 2xl:text-[16px] lg:mt-[30px] sm:mt-[70px] 2xl:mt-[70px] xl:mt-[70px] mt-[26px] xs:mt-[70px]'>
                {item.links.map((link) => {
                  return(
                    <NavLink
                        to={`./${link.link}`}
                        key={link.name}
                        onClick={()=>{}}
                        className={ ({isActive})=> isActive || (link.link==='classification' && window.location.pathname==='/upload') || (link.link.startsWith(window.location.pathname.split('/')[1])) ? activeLink : normalLink + 'flex flex-row lg:mb-[30px] md:mb-[28px] sm:items-center sm:justify-center sm:mb-[40px] xs:flex xs:justify-center xs:items-center xxs:flex xxs:justify-center xxs:items-center mobileL:flex mobileL:justify-center mobileL:items-center mobileM:flex mobileM:justify-center mobileM:items-center mobileS:flex mobileS:justify-center mobileS:items-center relative'}
                        >
                            <span className='flex justify-center text-p6-2xl items-center min-w-[10px] w-[29px] text-center'>
                              {link.icon({ 
                                fill: window.location.pathname === `/${link.link}` || 
                                      (link.link === 'classification' && window.location.pathname === '/upload') || 
                                      (link.link.startsWith(window.location.pathname.split('/')[1])) 
                                      ? '#FFF5F7' 
                                      : link.name === 'Transacciones' && '#ffffff',
                                transaccionesPend: link.name === 'Transacciones' && notClassified // Asegúrate de pasar esta prop aquí
                              })}
                            </span>
                            <span className={`text-p16-2xl md:text-p10-2xl sm:text-p11-2x sm:hidden xs:hidden xxs:hidden mobileL:hidden mobileM:hidden mobileS:hidden`}>
                                {link.name}
                          </span>
                          <span>{notClassified && link.name === 'Transacciones' ? <RedTriangle /> : null}</span>
                        </NavLink>  )     
                  })}
                
                </div>
            ))}
        </div>
      </div>
      <div>

        {userAccess && userAccess.access1 &&
        <div className='px-[10%] py-2 2xl:py-8'>
        <NavLink
          to={`./users`}
          key={'Ajustes'}
          onClick={()=>{}}
          className={({isActive})=> isActive ? activeLink : normalLink + 'flex flex-row xs:flex-col xs:items-center xs:justify-center sm:flex sm:justify-center sm:items-center xxs:flex xxs:justify-center xxs:items-center mobileL:flex mobileL:justify-center mobileL:items-center mobileM:flex mobileM:justify-center mobileM:items-center mobileS:flex mobileS:justify-center mobileS:items-center'}
          >
              <span className='min-w-[30px] text-center flex justify-center'><AjustesLogo fill={window.location.pathname===`/users` || ('users'==='classification' && window.location.pathname==='/upload') ?'#9191f4': '#ffffff'}/></span>
              <span className='text-p16-2xl md:text-p10-2xl sm:text-p11-2x sm:hidden xs:hidden xxs:hidden mobileL:hidden mobileM:hidden mobileS:hidden'>
                  {Idioma.sideBar.admin[language]}
              </span>
          </NavLink>
        </div>
        }
        
        <div className='bg-purple-1 flex items-center px-[10%] flex-row sm:flex sm:justify-center xs:flex xs:justify-center xxs:flex xxs:justify-center xxs:items-center mobileL:flex mobileL:justify-center mobileL:items-center mobileM:flex mobileM:justify-center mobileM:items-center mobileS:flex mobileS:justify-center mobileS:items-center'>
            <button onClick={() => handleLogout(instance)} className={'overflow-visible min-h-[30px] py-8 flex items-center gap-5 dark:text-white text-[12px] 2xl:text-[16px] text-white'}>
              <LogOutLogo/>
              <span className='sm:hidden xs:hidden xxs:hidden mobileL:hidden mobileM:hidden mobileS:hidden'>
                 {Idioma.sideBar.cerrar[language]}
              </span>
            </button>
          </div>
          </div>
    </div>
  )
}

export default Sidebar