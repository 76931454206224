import "@paybook/sync-widget/dist/widget.css";
import SyncWidget from "@paybook/sync-widget";
import { urlBase } from "../authConfig";
import { useStateContext } from "../contexts/ContextProvider";
import React,{ useState, useEffect } from "react";
import moment from 'moment'
import { DinamicDropdown, Idioma, ReusableTable } from "../components";
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import { useIsAuthenticated } from "@azure/msal-react";
import { getToken } from '../fetch';

const Syncfy = () => {
    const {idClient, accessToken, setToken, language, numberFormat, idRS} = useStateContext();
    const [syncfyToken, setSyncfyToken] = useState('');
    const [syncWidget, setSyncWidget] = useState(null)
    const [allRealAccounts, setAllRealAccounts] = useState([])
    const [realAccounts, setRealAccounts] = useState([])
    const [account, setAccount] = useState({'name': Idioma.Syncfy.seleccioneCuenta[language]});
    const [linkedAccount, setLinkedAccount] = useState({'name': "Seleccione una cuenta"})
    const [syncfyAccount, setSyncfyAccount] = useState({'name': "Cuenta"});
    const [syncfyAccountOptions, setSyncfyAccountOptions] = useState([]);
    const [organization, setOrganization] = useState({'name': "Organizacion"});
    const [organizationOptions, setOrganizationOptions] = useState([]);
    const [currentOrgs, setCurrentOrgs] = useState([]);
    const [allAccounts, setAllAccounts] = useState([]);
    const [data, setData] = useState([]);
    const [linkModal, setlinkModal] = useState(false);

    const navigate = useNavigate();

    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        
  
        
        authSyncfy()
      },[]);
	
	const handleDateChange = (event) => {
		setDateFilter(event)
	}

    const getL30 = () => {
		let newStartDate = new Date()
		newStartDate.setDate(new Date().getDate() - 30)
		let newEndDate = new Date()
		return([newStartDate, newEndDate]) //Fecha final Last Month
	}

    const [l30Filter,setL30Filter] = useState(getL30());
    const [dateFilter,setDateFilter] = useState(l30Filter);
	const [startDate, endDate] = dateFilter;

    useEffect(()=>{
        if(syncfyToken){
            getRealAccounts()
        }
    },[syncfyToken]);

    useEffect(()=>{
        if(currentOrgs.length>0){
            getOrganizationCat()
        }

    },[currentOrgs]);

    useEffect(()=>{
        if(organization.id){
            let aux = []
            allAccounts.forEach(account => {
                if(account.org_id == organization.id){
                    aux.push(account)
                }
            })
            if(aux.length>1){
                aux.unshift({"name": Idioma.Syncfy.seleccioneCuenta[language]})
            }
            setSyncfyAccountOptions(aux)
            setSyncfyAccount(aux[0])
        }else{
            setSyncfyAccountOptions(allAccounts.slice())
        }
    },[organization]);

    useEffect(()=>{
        if(organization.id){
            let aux = []
            console.log(allRealAccounts)
            allRealAccounts.forEach(account => {
                if(account.org_id == organization.id){
                    aux.push(account)
                }
            })
            if(aux.length>1){
                aux.unshift({"name": Idioma.Syncfy.seleccioneCuenta[language]})
            }
            console.log(aux)
            setRealAccounts(aux)
            setAccount(aux[0])
        }else{
            setRealAccounts(allRealAccounts.slice())
        }
    },[organization]);

    const TransactionTableColumns = React.useMemo(
        () => [
        {
            Header: 'ID',
            accessor: 'id_transaction',
            className: 'text-left text-h10-2xl',
        },
        {
            Header: Idioma.transacciones.configuracion.fecha[language],
            accessor: 'dt_transaction',
            className: 'text-left text-h10-2xl',
        },
        {
            Header: Idioma.transacciones.configuracion.concepto[language],
            accessor: 'description', // accessor is the "key" in the data
            className: 'text-left text-h10-2xl',
        },
        {
            Header: Idioma.transacciones.desgloseTransaccion.monto[language],
            accessor: 'amount',
            className: 'text-right text-h10-2xl',
        },
        ],
        []
    )

    const createCellOfTypeTransaction = (cell,row) => {
        if(cell.column.id === 'dt_transaction'){
          return (<>
            <div className=' text-neutral-1 text-left text-p9-2xl pb-1 tabular-nums'>{moment(formatter(cell.value)).locale(language).format(language==='en' ?'MMM/DD/YYYY':'DD/MMM/YYYY').replace('.','')}</div>
            </>)
        } else if(cell.column.id === 'amount'){
            if(cell.value>0){
                return (
                    <div className='text-right relative tabular-nums w-[100%]'>
                    <span>{cell.value ? (parseFloat(cell.value)).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      currencyDisplay: 'code'}).slice(4) : '--'}</span>
                    </div>
                )
            }else{
                return (
                    <div className='text-right relative tabular-nums w-[100%]'>
                        <span>{cell.value ? `${numberFormat===0?'(':'-'}${(parseFloat(cell.value)).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        currencyDisplay: 'code'
                        }).slice(4)}${numberFormat===0?')':''}` : '--'}</span>
                    </div>
                )
            }
        }else{
          return (<>
            <div className='text-left text-neutral-1 text-p9-2xl pb-1 tabular-nums'>{cell.value}</div>
            </>)
        }
      }

    const getData = async() => {
        let headers = new Headers();

        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        fetch(`${urlBase}/sync/getTransactions`,{
           method: 'POST',
           headers: headers,
           body: JSON.stringify({
               clientID: idClient,
               syncfyOrganizationID: organization.id,
               syncfyAccountID: account.id,
               startDate: startDate,
               endDate: endDate,
               token: syncfyToken
           })
        }).then(response => response.json())
            .then(aux => {
                let newArray = [];
                for(let i = 0; i < aux.length; i++) {
                    newArray.push(aux[i]);
                }
                setData(newArray);
            });
    }

    const createWidget = (token) => {
        if (!syncWidget){
            let params = {
                token: token,
                element: ".pb-w-sync_modal-container",
                config: {
                    locale: 'es',
                    entrypoint: {
                        country: 'MX',
                        siteOrganizationType: '56cf5728784806f72b8b4568'
                    },
                    navigation: {
                        displayStatusInToast: true
                    }
                }
            };
            setSyncWidget(new SyncWidget(params))
        }

    }

    const open = () => {
        if(syncWidget){
            syncWidget.open();
            syncWidget.$on("closed", () => {
                getSyncfyAccounts()
              });
        }
    }

    const authSyncfy = async() => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        fetch(`${urlBase}/sync/authUser`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                'clientID': idClient,
            })
        })
        .then(response => response.json())
        .then(aux => {
            if(aux.token){
                setSyncfyToken(aux.token);
                createWidget(aux.token)
            }
        }).catch(err => console.log(err));
    }

    const creaSesion = async() => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        fetch(`${urlBase}/sync/authUser`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                'clientID': idClient,
            })
        })
        .then(response => response.json())
        .then(aux => {
            open(aux.token);
        }).catch(err => console.log(err));
    }

    const getOrganizationCat = async() => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        fetch(`${urlBase}/sync/getOrganizationCat`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                'clientID': idClient,
                'token': syncfyToken
            })
        })
        .then(response => response.json())
        .then(aux => {
            let aux2=[]
            aux.forEach(org => {
                if( currentOrgs.includes(org.id_site_organization)){
                    aux2.push({'name': org.name, 'id': org.id_site_organization})
                }
            })
            if(aux2.length>1){
                aux2.unshift({"name": "Todas los bancos"})
            }
            if(aux2.length>0){
                setOrganization(aux2[0])
            }
            console.log(aux2)
            setOrganizationOptions(aux2)
        }).catch(err => console.log(err));
    }

    const getSyncfyAccounts = async() => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        fetch(`${urlBase}/sync/getAccounts`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                'clientID': idClient,
                'token': syncfyToken
            })
        })
        .then(response => response.json())
        .then(aux => {
            let aux2=[]
            let aux3=[]
            aux.forEach(account => {
                aux2.push({'name': account.name, 'id': account.id_account, 'org_id': account.id_site_organization})
                aux3.push(account.id_site_organization)
            })
            if(aux2.length>1){
                aux2.unshift({"name": Idioma.Syncfy.seleccioneCuenta[language]})
            }
            if(aux2.length>0){
                setSyncfyAccount(aux2[0])
            }
            setSyncfyAccountOptions(aux2)
            setAllAccounts(aux2)
            setCurrentOrgs(aux3)
        }).catch(err => console.log(err));
    }

    const deleteSyncfyAccount = async() => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        fetch(`${urlBase}/sync/deleteAccount`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                'clientID': idClient,
                'token': syncfyToken,
                'syncfyAccountID':syncfyAccount.id
            })
        })
        .then(response => response.json())
        .then(aux => {
            console.log(aux)
            getSyncfyAccounts()
        }).catch(err => console.log(err));
    }

    const getRealAccounts = () => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        fetch(`${urlBase}/getAccounts`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                'clientID': idClient,
            })
        })
        .then(response => response.json())
        .then(aux => {
			let aux2 = []
            let aux3 = []
			for (const property in aux) {
				for(let i=0;i<aux[property].accounts.length;i++){
                    if(aux[property].accounts[i].idSyncfy){
                        aux2.push({"name":aux[property].accounts[i].accountAlias, "id":aux[property].accounts[i].idSyncfy, 'realID': aux[property].accounts[i].accountID,  'org_id':aux[property].idSyncfy})
                        if(!aux3.includes(aux[property].idSyncfy)){
                            aux3.push(aux[property].idSyncfy)
                        }
                    }
				}
			}
            if(aux2.length>1){
                aux2.unshift({"name": Idioma.Syncfy.seleccioneCuenta[language]})
            }
            if(aux2.length>0){
                setAccount[aux2[0]]
            }
            setRealAccounts(aux2)
            setAllRealAccounts(aux2)
            setCurrentOrgs(aux3)
        }).catch(err => console.log(err));
    }

    const createUser = async() => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        fetch(`${urlBase}/sync/crate`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                'clientID': idClient
            })
        })
        .then(response => response.json())
        .then(aux => {
            console.log(aux)
        }).catch(err => console.log(err));
    }

    const exportData = async() => {
        console.log(account)
        let headers = new Headers();

        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        fetch(`${urlBase}/sync/exportSyncfy`,{
           method: 'POST',
           headers: headers,
           body: JSON.stringify({
               clientID: idClient,
               syncfyOrganizationID: organization.id,
               syncfyAccountID: account.id,
               accountID: account.realID,
               startDate: startDate,
               endDate: endDate,
               token: syncfyToken
           })
        }).then(response => response.json())
            .then(aux => {
                
            });
    }

    const linkAccounts = () => {
        let headers = new Headers();

        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');

        let body = {
            clientID: idClient,
            syncfyAccountID: linkedAccount.id,
            accountID: account.id
        }

        if(idRS){
            body.idRS = idRS
        }

        fetch(`${urlBase}/updateAccount`,{
           method: 'POST',
           headers: headers,
           body: JSON.stringify(body)
        }).then(response => response.json())
            .then(aux => {
                handleLinkModal(false)
            });
    }

    const handleLinkModal = (state) => {
        if(state && realAccounts.length==0){
            getRealAccounts()
        }
        setlinkModal(state)
    }

    const formatter = (timestamp) => {
        const transactionDate = new Date(timestamp * 1000);
        const formattedDate = `${transactionDate.getDate()}/${
            transactionDate.getMonth() + 1
        }/${transactionDate.getFullYear()} `//${transactionDate.getHours().toString().padStart(2, '0')}:${transactionDate.getMinutes().toString().padStart(2, '0')}:${transactionDate.getSeconds().toString().padStart(2, '0')};
        return transactionDate;
    }

    return(
        <>
            {linkModal && <div className='fixed top-0 right-0 bottom-0 w-screen z-[1000] h-screen'>
              <div className=' fixed top-0 right-0 bottom-0 left-[15%] w-screen z-10 h-screen opacity-75 bg-gray-6'>
              </div>
              <div className='bg-white z-10 absolute top-[calc(50%)] left-[55%] w-[533px] h-[381px] rounded-main -translate-x-[50%] -translate-y-[50%] p-10 text-p5-2xl text-center'>
              <p className='text-h5-2xl mt-11'>{Idioma.subirTransacciones.modalDuplicados.alertaDuplicados1[language]}  {Idioma.subirTransacciones.modalDuplicados.alertaDuplicados2[language]}</p>
              <p className=' mt-5'>{Idioma.subirTransacciones.modalDuplicados.alertaPregunta[language]}</p>
              <div>
                <DinamicDropdown
                    options={realAccounts}
                    selected={account}
                    setSelected={setAccount}
                    className={` w-text-field h-button-4`}
                    disabled={realAccounts.length<1}
                />
              </div>
              <div>
                <DinamicDropdown
                    options={syncfyAccountOptions}
                    selected={linkedAccount}
                    setSelected={setLinkedAccount}
                    className={` w-text-field h-button-4`}
                    disabled={syncfyAccountOptions.length<1}
                />
              </div>
              <div>
                <button className={`mt-3 text-white rounded-button w-[200px] h-button-3 bg-purple-1 text-b3-2xl`} onClick={() => handleLinkModal((prev)=>!prev)}>{Idioma.subirTransacciones.cancelar[language]}</button>
              </div>
              <div>
                <button className='mt-3 text-light-purple-3 text-b3-2xl' onClick={linkAccounts} >{Idioma.subirTransacciones.modalDuplicados.verDuplicados[language]}</button>
              </div>
              </div>
            </div>}
            <div className="my-5">
            <button className="bg-md-blue text-gray-100 w-48 rounded-full h-10 mt-5" onClick={createUser}>Crear Syncfy</button>
            {/*<button className="bg-md-blue text-gray-100 w-48 rounded-full h-10 mt-5" onClick={authSyncfy}>Session Syncfy</button>*/}
            {/*<button className="mx-5 text-p7-2xl w-button-2 h-button-2 rounded-button bg-purple-2 text-white" onClick={open}>Abrir Syncfy</button>*/}
            <button className={`mx-5 text-p7-2xl w-button-2 h-button-2 rounded-button ${account.id ? 'bg-purple-2 text-white' : 'bg-gray-5 text-white'}`} onClick={getData} disabled={!account.id}>{Idioma.planeacion.transacciones[language]}</button>
            {/*<button className={`mx-5 text-p7-2xl w-button-2 h-button-2 rounded-button ${account.id ? 'bg-purple-2 text-white' : 'bg-gray-5 text-white'}`} onClick={deleteSyncfyAccount} disabled={!account.id}>Eliminar cuenta</button>*/}
            <button className={`mx-5 text-p7-2xl w-button-2 h-button-2 rounded-button ${data.length>0 && account.id ? 'bg-purple-2 text-white' : 'bg-gray-5 text-white'}`} onClick={exportData} disabled={data.length<1 || !account.id}>{Idioma.transacciones.subirTransacciones[language]}</button>
            {/*<button className="bg-md-blue text-gray-100 w-48 rounded-full h-10 mt-5" onClick={() => handleLinkModal((prev)=>!prev)} disabled={syncfyAccountOptions.length<1}>Vincular cuentas</button>*/}
            </div>
            <div className="z-[11] flex sticky gap-3 ml-5">
            <DinamicDropdown
                options={organizationOptions}
                selected={organization}
                setSelected={setOrganization}
                className={` w-text-field h-button-4`}
                disabled={organizationOptions.length<1}
            />
            <DinamicDropdown
                options={allRealAccounts}
                selected={account}
                setSelected={setAccount}
                className={` w-text-field h-button-4`}
                disabled={allRealAccounts.length<1}
            />
            <div className="col-span-2 ">
            <DatePicker 
					className={' h-button-4 focus:outline-none cursor-default border-1 border-neutral-1 hover:border-light-purple-4 focus:border-light-purple-4 rounded-button text-p11-2xl 2xl:text-p9-2xl text-center'} 
					onChange={(date) => handleDateChange(date)} 
					selectsRange startDate={startDate} 
					endDate={endDate}
					dateFormat={Idioma.formatoFecha[language]}
					locale={language}
					showYearDropdown
					fixedHeight
                    disabled={allRealAccounts.length<1}
				/>
            </div>
            </div>


            <div className='overflow-auto perfect-scrollbar max-h-[350px] min-h-[100px] m-5'>
                <ReusableTable columns={TransactionTableColumns} data={data} createCellOfType={createCellOfTypeTransaction}/>
            </div>
            {/*<div>
                <ul>
                    {data.map((item, index) => {
                        return(
                            
                            <>
                                <li key={index}>
                                    <p>Transacción: {item.id_transaction}</p>
                                    <p>Descripción: {item.description}</p>
                                    <p>Monto: {item.amount} {item.currency}</p>
                                    <p>Fecha: {formatter(item.dt_transaction)}</p>
                                </li>
                                <br />
                            </>
                        );
                    })}
                </ul>
            </div>*/}
        </>
    );
}

export default Syncfy;
