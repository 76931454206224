import React,{useState,useEffect,useRef} from 'react'
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy, useRowSelect, useBlockLayout,useResizeColumns } from 'react-table'
import {matchSorter} from 'match-sorter'
import {AdjustmentsHorizontalIcon,PlusCircleIcon ,FunnelIcon} from '@heroicons/react/24/outline'
import Scissors from '../assets/Scissors'
import { ClassificationDropdown, DinamicDropdown, Dropdown, ExcelExport, FileDropdown, Idioma, InfoI, Loading, MessageToast, scrollbarWidth, Styles, DynamicExcelExport, MessageHandler} from '../components';
import { FixedSizeList, VariableSizeList } from 'react-window'
import moment from 'moment'
import 'moment/locale/es'
import {AiOutlineArrowRight, AiOutlineCloseCircle, AiOutlineSave} from 'react-icons/ai'
import { TbCopy } from "react-icons/tb";
import TransaccionesLogoNotAnimated from '../assets/transaccionesLogoNotAnimated'

import DatePicker from 'react-datepicker'
import es from 'date-fns/locale/es'
import { useStateContext } from '../contexts/ContextProvider';

import { useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';


import { getToken } from '../fetch';

import { urlBase } from '../authConfig';


import "react-datepicker/dist/react-datepicker.css";
import Triangulo from '../assets/Triangulo';
import SmallPreferencesLogo from '../assets/SmallPreferencesLogo';
import ScissorsLogo from '../assets/ScissorsLogo';
import ThrashCanLogo from '../assets/ThrashCanLogo';
import MultiTransactionLogo from '../assets/MultiTransactionLogo';
import SearchLogo from '../assets/SearchLogo';
import SettingsLogo from '../assets/SettingsLogo'
import AddSingleTransactionLogo from '../assets/AddSingleTransactionLogo'
import ResetLogo from '../assets/ResetLogo'
import ClassificationExcelExport from '../components/ClassificationExcelExport'
import RestoreRBinLogo from '../assets/RestoreRBinLogo'
import WarningLogo from '../assets/WarningLogo'
import FiltersLogo from '../assets/FiltersLogo'
import AutoclassLogo from '../assets/AutoclassLogo'
import CheckedLogo from '../assets/CheckedLogo'
import TrianguloAbajoLogo from '../assets/TrianguloAbajoLogo'
import XMark from '../assets/XMark'
import CleaningLogo from '../assets/CleaningLogo'

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])
    return (
      <div className='flex items-center justify-center h-full mt-[1px]'>
        <input className='relative peer shrink-0 appearance-none w-[10px] h-[10px] border-0 bg-gray-6 checked:bg-light-purple-3 checked:border-0' type="checkbox" ref={resolvedRef} {...rest} />
        <svg
          className="
            absolute 
            w-[10px] h-[10px] 
            hidden peer-checked:block pointer-events-none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#fff"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <polyline points="20 6 9 17 4 12"></polyline>
        </svg>
      </div>
    )
  }
)

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)
  const {language} = useStateContext()
  const globalFilterRef = useRef()

  return (
    <div className='flex items-center bg-white h-[52px] w-[270px] rounded-button relative border-1 border-neutral-1'>
      <div onClick={()=>globalFilterRef.current.focus()} className= ' cursor-text bg-light-purple-3 h-button-1 flex items-center justify-center w-[52px] absolute object-contain border-1 border-light-purple-3 -right-[1px] rounded-button -top-[1px] dark:text-white dark:bg-secondary-dark-bg '>
        <SearchLogo/>
      </div>
      {' '}
      <input
        ref={globalFilterRef}
        placeholder={Idioma.transacciones.barraBusqueda[language]}
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        style={{
          border: '0',
        }}
        className='dark:bg-secondary-dark-bg bg-transparent font-medium pl-2 w-full h-[52px] focus:outline-none 2xl:text-p5-2xl text-[16px] '
      />
    </div>
  )
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length
  const {language} = useStateContext()

  return (
    <input
      value={filterValue || ''}
      className='dark:bg-secondary-dark-bg p-2 bg-white rounded-button w-[calc(100%-16px)] 2xl:text-p9-2xl text-[12px] h-button-4 border-1 border-neutral-1 placeholder:text-neutral-1'
      placeholder={Idioma.transacciones.configuracion.concepto[language]}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
    />
  )
}

function NotesColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      className='dark:bg-secondary-dark-bg p-2 bg-white rounded-button w-[calc(100%-16px)] 2xl:text-p9-2xl text-[12px] h-button-4 border-1 border-neutral-1 placeholder:text-neutral-1'
      placeholder=''
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
    />
  )
}
// Define a default UI for filtering
function DisabledFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <div><p className=' invisible'>null</p></div>
  )
}


function multiSelectFilterFn(rows, id, filterValues) {

  if(filterValues.length>0){
    return rows.filter(r => {
      let value = r.values[id]
      let flag = false
      for(let i=0;i<filterValues.length;i++){
        flag = value.includes(filterValues[i].id) || flag
      }
      return flag
    })
  }else{
    return rows
  }
}


// This is a custom filter UI for selecting
// a unique option from a list
function MultiSelectColumnFilter({
  column: { filterValue = [], setFilter, preFilteredRows, id },
}) {
  const {language} = useStateContext()

  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const optionsAux = new Set()
    preFilteredRows.forEach(row => {
      optionsAux.add(row.values[id])
    })
    let aux=[...optionsAux.values()]
    let aux2 = []
    for(let i=0;i<aux.length;i++){
      aux2[i] = {
        name:aux[i],
        id:aux[i]
      }
    }
    
    return aux2
  }, [id,preFilteredRows])

  const changeSelectionHandler = (event) => {
    if(event.length>0){
      let lastSelected = event.pop()
      for(let i=0;i<event.length;i++){
        if(lastSelected.id===event[i].id){
          event.splice(i,1)
          setFilter(event)
          return
        }
      }
      setFilter([...event,lastSelected])
    }else{
      setFilter([])
    }
  }

  // Render a multi-select box
  return (
    <div className=''>
      
      <DinamicDropdown
				options={options}
				selected={filterValue}
				className={'relative peer dark:bg-secondary-dark-bg appearance-none text-neutral-1 p-2 bg-white rounded-button w-[calc(100%-16px)] 2xl:text-p9-2xl text-[12px] h-button-4  placeholder:text-neutral-1'}
				setSelected={changeSelectionHandler}
				multi={true}
        placeHolder={Idioma.transacciones.filtros.todos[language]}
			/>
    </div>
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])
  const {language} = useStateContext()

  // Render a multi-select box
  return (
    <div className=''><select
      value={filterValue || Idioma.transacciones.filtros.todos[language]}
      className='relative peer dark:bg-secondary-dark-bg appearance-none text-neutral-1 p-2 bg-white rounded-button w-[calc(100%-16px)] 2xl:text-p9-2xl text-[12px] h-button-4 border-1 border-neutral-1 placeholder:text-neutral-1'
      onChange={e => {
        setFilter(e.target.value || "")
      }}
    >
      <option value={""}>{Idioma.transacciones.filtros.todos[language]} </option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
    <span className='absolute top-[12px] right-[31px] pointer-events-none'>
      <TrianguloAbajoLogo/>
    </span>
    </div>
  )
}

function SubcategorySelectFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])
  const {language} = useStateContext()


  // Render a multi-select box
  return (
    <select
      value={filterValue || Idioma.transacciones.filtros.todos[language]}
      className='dark:bg-secondary-dark-bg p-2 bg-white rounded-button w-[95%] 2xl:text-p9-2xl text-[12px] h-button-4 border-1 border-neutral-1 placeholder:text-neutral-1'
      onChange={e => {
        setFilter(e.target.value || "")
      }}
    >
      <option value={""}>{Idioma.transacciones.filtros.todos[language]} </option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={e => {
          setFilter(parseInt(e.target.value, 10))
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  )
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])
  const {language} = useStateContext()
  return (
    <div
      className='flex h-button-4 w-[calc(100%-16px)] '
    >
      <input
        value={filterValue[0] || ''}
        type='number'
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [val ? parseFloat(val, 10) : undefined, old[1]])
        }}
        placeholder={Idioma.transacciones.filtros.min[language]}
        className='w-1/2 mr-2 p-2 bg-white rounded-button 2xl:text-p9-2xl text-[12px] h-button-4 border-1 border-neutral-1 placeholder:text-neutral-1'
      /><input
        value={filterValue[1] || ''}
        type='number'
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [old[0], val ? parseFloat(val, 10) : undefined])
        }}
        className='w-1/2 mr-0 p-2 bg-white rounded-button 2xl:text-p9-2xl text-[12px] h-button-4 border-1 border-neutral-1 placeholder:text-neutral-1'
        placeholder={Idioma.transacciones.filtros.max[language]}
      />
    </div>
  )
}

function dateBetweenFilterFn(rows, id, filterValues) {
  const sd = filterValues[0] ? filterValues[0] : undefined
  const ed = filterValues[1] ? filterValues[1] : undefined

  if (ed || sd) {
    return rows.filter(r => {
      let dateArr = r.values[id].split('-')
      const cellDate = new Date(dateArr[0],dateArr[1]-1,dateArr[2])
      
      if (ed && sd) {
        return cellDate >= sd && cellDate <= ed
      } else if (sd){
        return cellDate >= sd
      } else if (ed){
        return cellDate <= ed
      }
    })
  } else {
    return rows
  }
}

function DateRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id }
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length
      ? new Date(preFilteredRows[0].values[id])
      : new Date(0);
    let max = preFilteredRows.length
      ? new Date(preFilteredRows[0].values[id])
      : new Date(0);

    preFilteredRows.forEach((row) => {
      const rowDate = new Date(row.values[id]);

      min = rowDate <= min ? rowDate : min;
      max = rowDate >= max ? rowDate : max;
    });

    return [min, max];
  }, [id, preFilteredRows]);

  const {language} = useStateContext()
  const [start, end] = filterValue;

  return (
    <div className='w-[calc(100%-16px)]'>
      <DatePicker
        onChange={(e) => {
          const val = e;
          setFilter(e);
        }}
        startDate={start}
        endDate={end}
        selectsRange
        fixedHeight
        locale={language}
        className='p-2 bg-white rounded-button w-full 2xl:text-p9-2xl text-[12px] h-button-4 border-1 border-neutral-1 placeholder:text-neutral-1'
        placeholderText={Idioma.transacciones.configuracion.fecha[language]}
        showYearDropdown
        dropdownMode="select"
        isClearable
        dateFormat={Idioma.formatoFecha[language]}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}


// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'

const DeletedTransactionsTable = ({data,recycleBinLoading,setSelectedDeletedTransactions}) => {
  const {language,numberFormat} = useStateContext()
  const textNormal = 'font-bold text-left '
  const textNumber = 'font-bold text-right '
  const textCategory = 'font-bold text-left '
  const columns = React.useMemo(
    () => [/*
      {
        id:'index',
        Header: ' ',
        maxWidth:40,
        minWidth:20,
        width:40,
        className:'text-center',
        accessor: (row, i) => i+1,
      },*/{
        Header: Idioma.transacciones.configuracion.seEliminara[language],
        accessor: 'deleted',
        width:120,
        sortType:'basic',
        Filter:DateRangeColumnFilter,
        filter:dateBetweenFilterFn,
        className:textNormal
      },
      {
        Header: Idioma.transacciones.configuracion.empresa[language],
        accessor: 'rsName',
        width:130,
        Filter: SelectColumnFilter,
        className:textNormal + '',
      },
      {
        Header: Idioma.transacciones.configuracion.banco[language],
        accessor: 'bank',
        width:120,
        Filter: SelectColumnFilter,
        className:textNormal + 'pl-1 rounded-l-[7px]',
      },
      {
        Header: Idioma.transacciones.anadirTransaccionModal.cuenta[language],
        accessor: 'idAccount',
        width:120,
        Filter: SelectColumnFilter,
        className:textNormal + '',
      },
      {
        Header: Idioma.transacciones.configuracion.fecha[language],
        accessor: 'date',
        width:120,
        sortType:'basic',
        Filter:DateRangeColumnFilter,
        filter:dateBetweenFilterFn,
        className:textNormal
      },/*
      {
        Header: 'ID',
        accessor: 'id', 
      },*/
      {
        Header: Idioma.transacciones.configuracion.concepto[language],
        accessor: 'context',
        width:document.documentElement.clientWidth*.15,
        className:textNormal
      },
      {
        Header: Idioma.transacciones.configuracion.abono[language],
        accessor: 'inflow',
        width:140,
        Filter: NumberRangeColumnFilter,
        filter: 'between',
        className:textNumber
      },
      {
        Header: Idioma.transacciones.configuracion.cargo[language],
        accessor: 'outflow',
        width:140,
        Filter: NumberRangeColumnFilter,
        filter: 'between',
        className:textNumber 
      },
      {
        Header: Idioma.transacciones.configuracion.categoria[language],
        accessor: 'category',
        Filter: SelectColumnFilter,
        width:document.documentElement.clientHeight*.20,
        className:textCategory + ' pl-2 '
      },
      {
        Header: Idioma.transacciones.configuracion.subcategoria[language],
        accessor: 'subcategory',
        width:document.documentElement.clientHeight*.30,
        Filter: SelectColumnFilter,
        className:textCategory + ' pl-2 '
      },{
        Header: Idioma.transacciones.configuracion.moneda[language],
        accessor: 'currency',
        Filter: SelectColumnFilter,
        className:textNormal + '',
      },
      {
        Header: Idioma.transacciones.configuracion.fx[language],
        accessor: 'exchange',
        width:80,
        Filter: NumberRangeColumnFilter,
        filter: 'between',
        className:textNumber  
      },{
        Header: Idioma.transacciones.configuracion.creditoDebito[language],
        accessor: 'accountType',
        width:140,
        Filter: SelectColumnFilter,
        className:textNormal + ''
      },{
        Header: Idioma.transacciones.configuracion.clientesProvedores[language],
        accessor: 'clientSupplier',
        width:document.documentElement.clientWidth*.20,
        Filter: SelectColumnFilter,
        className:textCategory + ''
      },{
        Header: Idioma.transacciones.configuracion.notas[language],
        accessor: 'notes',
        width:140,
        Filter: NotesColumnFilter,
        className:textNormal + ' pr-4'
      },
    ],
    []
  )
  

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
      minWidth: 10, // minWidth is only used as a limit for resizing
    width: 100, // width is used for both the flex-basis and flex-grow
    maxWidth: 1800, // maxWidth is only used as a limit for resizing
    }),
    []
  )

  const createCellOfType = (cell,row) => {
		if(cell.column.id==='exchange'){
			return (
				<div className='text-right relative tabular-nums w-[100%]'>
				<span>{cell.value ? (parseFloat(cell.value)).toLocaleString("en-US", {
				  style: "currency",
				  currency: "USD",
				  currencyDisplay: 'code',
				  minimumSignificantDigits:'4'}).slice(4) : cell.value==0 ? '--' : ''}</span>
				</div>
			)
		}else if(cell.column.id==='inflow'){
			return (
				<div className='text-right relative tabular-nums w-[100%]'>
				<span>{cell.value ? (parseFloat(cell.value)).toLocaleString("en-US", {
				  style: "currency",
				  currency: "USD",
				  currencyDisplay: 'code'}).slice(4) : '--'}</span>
				</div>
			)
		}else if(cell.column.id==='outflow'){
			return (
				<div className='text-right relative tabular-nums w-[100%]'>
				<span>{cell.value ? `${numberFormat===0?'(':'-'}${(parseFloat(cell.value)).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            currencySign: "accounting",
            currencyDisplay: 'code'
            }).slice(4)}${numberFormat===0?')':''}` : '--'}</span>
				</div>
			)
		}else if(cell.column.id === 'selection'){
      return  <div className='w-[100%] flex items-center'>
      <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    </div>}
    else if(cell.column.id==='date' || cell.column.id==='deleted'){
			return <div className='w-[100%]'>{moment(cell.value).locale(language).format(language==='en' ?'MMM/DD/YYYY':'DD/MMM/YYYY').replace('.','')}</div> 
		}else{
			return (
				<div className=' w-[100%] truncate' title={cell.value}>
					{cell.value}
				</div>
			)
		}
		
	}

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const scrollBarSize = React.useMemo(() => scrollbarWidth(), [])

  


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    totalColumnsWidth,
    prepareRow,
    setAllFilters,
    selectedFlatRows,
    allColumns,
    state,
    setSortBy,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      autoResetGlobalFilter: false,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      autoResetSortBy:false,
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    useRowSelect,
    useBlockLayout,
    useResizeColumns,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          width:45,
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div className='flex items-center'>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div className='flex items-center h-full'>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
      
    }
  )

  const RenderRow = React.useCallback(
    (rows) => ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <div
         className={`${row.isSelected && 'bg-light-purple-6'}`}
        {...row.getRowProps({
          style
        })}
        >
          {row.cells.map((cell) => {
            return (
              <div className=''><td {...cell.getCellProps()} className='w-[100%] '>{createCellOfType(cell,row)}</td></div>
            );
          })}
        </div>
      );
    },
    [prepareRow,data]
  );

  useEffect(()=>{
    setSelectedDeletedTransactions(selectedFlatRows)
  },[selectedFlatRows])
  
  return (
  <div className=' block overflow-scroll thin-scrollbar'>
   <table {...getTableProps()} className="table ">
					<thead>
						{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()} className={' '}>
							{headerGroup.headers.map(column => (
							<th {...column.getHeaderProps()} className={column.className + '  '}>
								 {<div className='text-[10px] mb-2'>{column.canFilter && column.render('Filter')}</div>}
								{column.id=='selection' ?
                            <>
                            <div className=' scale-90 pb-1 pr-1 cursor-pointer invisible' ><ResetLogo/></div>
                              {column.render('Header')} </>:
								<p className={`flex pr-2 text-[12px] 2xl:text-[16px] items-center cursor-pointer ${column.id=='inflow'||column.id=='outflow'||column.id=='exchange'?' flex justify-end':''}`} onClick={()=>{
									const desc =
									column.isSortedDesc === true ?
									undefined :
									column.isSortedDesc === false ?
									true :
									false ;
									if(desc===undefined){
									  setSortBy([/*...sortees*/])
									}else if(desc===true){
									setSortBy([{id:column.id,desc:desc}/*,...sorteesTrue*/])
									}else{
									  setSortBy([{id:column.id,desc:desc}/*,...sortees*/])
									}
								  }} >{column.Header}
								  <span className='ml-1'>
								  {column.isSorted
									? column.isSortedDesc
									  ? <Triangulo/>
									  : <div className='rotate-180'><Triangulo/></div>
									: <></>}
								</span></p>
								}
                {/*
								<div
                  {...column.getResizerProps()}
                  className={` inline-block bg-light-purple-3 w-[5px] h-full absolute right-1 top-0 translate-x-[50%] z-1 touch-none ${
                    column.isResizing ? 'isResizing' : ''
                  }`}
                />*/}
							</th>
							))}
							
						</tr>
						))}
					</thead>

					<tbody {...getTableBodyProps()} className='text-[12px] 2xl:text-[16px]'>
						<FixedSizeList
						height={document.documentElement.clientHeight*.30}
						itemCount={rows.length}
						itemSize={35}
						width={totalColumnsWidth+scrollBarSize}
						>
						{RenderRow(rows)}
						</FixedSizeList>
					</tbody>
					</table></div>)
}

const ClassificationScreen = () => {

    const {idClient,bank,setContextBank,account,setContextAccount,file,setContextFile,showState,setContextShowState,accessToken,setToken,startDate,endDate,
      dateRange,setContextSubcategoryId,contextSubcategoryId,
      setContextDateRange,userAccess,idRS,setContextRSID,contextRSID,mainCurrency,language,numberFormat,contextCategoriesId,setContextCategoriesIds,sessionID} = useStateContext()

    const navigate = useNavigate();

    const isAuthenticated = useIsAuthenticated();

    const [categories,setCategories] = useState([])
    const [categoriesDict,setCategoriesDict] = useState({})
    const [inflowCategories,setInflowCategories] = useState([])
    const [outflowCategories,setOutflowCategories] = useState([])

    const [subcategories,setSubcategories] = useState([])
    
    const [rsID,setRSID] = useState({name:Idioma.transacciones.configuracion.empresa[language]})
    const [rsOptions,setRSOptions] = useState([])
    const [rsDict,setRSDict] = useState({})
    
    const [data,setData] = useState([])
    const [subcategoriesData,setSubcategoriesData] = useState([])
    const [indexToUpdate,setIndexToUpdate] = useState(-1)
    const isFirstRender = useRef(true);
    const [filesData,setFilesData] = useState([])


    const [openRulesModal,setOpenRulesModal] = useState(false)
    const [openPreferencesModal,setOpenPreferencesModal] = useState(false)
    const [openFiltersModal,setOpenFiltersModal] = useState(false)
    const [openTransactionModal,setOpenTransactionModal] = useState(false)

    const [transactionQuantities,setTransactionQuantities] = useState([])
    const [selectedTransaction,setSelectedTransaction] = useState([])
    const [transactionId,setTransactionId] = useState(null)
    const [transactionsContainer,setTransactionsContainer] = useState(null)
    const [ruleName,setRuleName] = useState('')
    const [ruleText,setRuleText] = useState('')
    const [ruleType,setRuleType] = useState({name:`${Idioma.transacciones.configuracion.cargo[language]}/${Idioma.transacciones.configuracion.cargo[language]}`})
    const [ruleType2,setRuleType2] = useState({name:'Concepto/Monto'})
    const [ruleType3,setRuleType3] = useState({name:'Contiene/No Contiene/Es Exacto'})
    const [bankRule,setBankRule] = useState({name:Idioma.transacciones.configuracion.banco[language]})
    const [ruleSubcategories,setRuleSubcategories] = useState([])
    const [ruleSubcategory,setRuleSubcategory] = useState({name:'Subcategoría'})
    const [ruleCategory,setRuleCategory] = useState({name:'Categoría'})



    const [bankActive,setBankActive] = useState(true)
    const [accountActive,setAccountActive] = useState(false)
    const [rsActive,setRSActive] = useState(false)
    const [dateActive,setDateActive] = useState(true)
    const [contextActive,setContextActive] = useState(true)
    const [inflowActive,setInflowActive] = useState(true)
    const [outflowActive,setOutflowActive] = useState(true)
    const [currencyActive,setCurrencyActive] = useState(false)
    const [fxActive,setFXActive] = useState(false)
    const [accountTypeActive,setAccountTypeActive] = useState(false)
    const [notesActive,setNotesActive] = useState(false)
    const [clientSupplierActive,setClientSupplierActive] = useState(false)
    const [additionalInfoActive,setAdditionalInfoActive] = useState(false)
    const [extraColumnsActive,setExtraColumnsActive] = useState({})


    const [filtersActive,setFiltersActive] = useState(false)

    const [forexValue,setForexValue] = useState(null)

    const [selectedSubcategory,setSelectedSubcategory] = useState()

    const [isLoading,setIsLoading] = useState(true)
    const [isMultiUpdateLoading,setIsMultiUpdateLoading] = useState(false)

    const [openSendToRecycleBinWarning,setOpenSendToRecycleBinWarning] = useState(false)
    const [openSendToMLWarning,setOpenSendToMLWarning] = useState(false)
    const [openCleanTransactionsWarning,setOpenCleanTransactionsWarning] = useState(false)
    
    

    //const [bank,setBank] = useState({name:'Banco'});
    const [BankOptions,setBankOptions] = useState([]);
    //const [account,setAccount] = useState({name:Idioma.transacciones.configuracion.cuentaBanco[language]});
    const [accounts,setAccounts] = useState([]);
    const [accountsDict,setAccountsDict] = useState({})
    const [info,setInfo] = useState()

    const [updateCounter,setUpdateCounter] = useState(0)
    const [showTransactionsUpdated, setShowTransactionsUpdated] = useState(false)
    const [updatedTransactions,setUpdatedTransactions] = useState(0)
    const [showTransactionsError,setShowTransactionsError] = useState(false)
    const [unaffectedTransactions,setUnaffectedTransactions] = useState(0)

    const [openDeleteTransactionsWarning,setOpenDeleteTransactionsWarning] = useState(false)
    const [errorSign,setErrorSign] = useState(false)
    const [errorMessage,setErrorMessage] = useState('')

    const [toastList, setToastList] = useState([])


    const [openAddTransaction,setOpenAddTransaction] = useState(false)
    const [bankAddT,setBankAddT] = useState({name:Idioma.transacciones.configuracion.banco[language]})
    const [accountsAddT,setAccountsAddT] = useState([]);
    const [accountAddT,setAccountAddT] = useState({name: Idioma.transacciones.configuracion.cuentaBanco[language]})
    const [dateAddT,setDateAddT] = useState(new Date());
    const [minDateAddT,setMinDateAddT] = useState(new Date(2000,0,1))
    const [conceptAddT,setConceptAddT] = useState('')
    const [inflowAddT,setInflowAddT] = useState()
    const [outflowAddT,setOutflowAddT] = useState()
    const [categoryAddT,setCategoryAddT] = useState()
    const [splitTransactionLoading, setSplitTransactionLoading] = useState(false)
    const [addTransactionLoading, setAddTransactionLoading] = useState(false)
    const [selectedInflow, setSelectedInflow] = useState(false)
    const [selectedOutflow, setSelectedOutflow] = useState(false)
    const [containerCounter, setContainerCounter] = useState(false)

    const [clientOptions,setClientOptions] = useState([])
    const [supplierOptions,setSupplierOptions] = useState([])
    const [clientSupplierDict,setClientSupplierDict] = useState({})

    const [totals,setTotals] = useState({})

    const [openDeletedTransactions,setOpenDeletedTransactions] = useState(false)
    const [deletedData,setDeletedData] = useState([])
    const [selectedDeletedTransactions,setSelectedDeletedTransactions] = useState([])
    const [recycleBinLoading,setRecycleBinLoading] = useState(false)

    const [additionalColumns,setAdditionalColumns] = useState({})

    const [MLInfo,setMLInfo] = useState({})
    const [isAutoClassing,setIsAutoClassing] = useState(false)
    const [amountAutoClassing,setAmountAutoClassing] = useState(0)
    const hasEditionAcess = userAccess && userAccess.access3 ? 1 : 0


    const textNormal = 'font-bold text-left'
    const textNumber = 'font-bold text-right '
    const textCategory = 'font-bold text-left '

    const initialColumns = [/*
    {
      id:'index',
      Header: ' ',
      maxWidth:40,
      minWidth:20,
      width:40,
      className:'text-center',
      accessor: (row, i) => i+1,
    },*/
    {
      Header: Idioma.transacciones.configuracion.empresa[language],
      accessor: 'rsName',
      width:130,
      Filter: MultiSelectColumnFilter,
      filter: multiSelectFilterFn,
      className:textNormal + '',
    },
    {
      Header: Idioma.transacciones.configuracion.banco[language],
      accessor: 'bank',
      Filter: MultiSelectColumnFilter,
      filter: multiSelectFilterFn,
      className:textNormal + '',
    },
    {
      Header: Idioma.transacciones.anadirTransaccionModal.cuenta[language],
      accessor: 'idAccount',
      Filter: MultiSelectColumnFilter,
      filter: multiSelectFilterFn,
      className:textNormal + '',
    },
    {
      Header: Idioma.transacciones.configuracion.fecha[language],
      accessor: 'date',
      width:120,
      sortType:'basic',
      Filter:DateRangeColumnFilter,
      filter:dateBetweenFilterFn,
      className:textNormal
    },/*
    {
      Header: 'ID',
      accessor: 'id', 
    },*/
    {
      Header: Idioma.transacciones.configuracion.concepto[language],
      accessor: 'context',
      width:document.documentElement.clientWidth*.15,
      className:textNormal
    },
    {
      Header: Idioma.transacciones.configuracion.abono[language],
      accessor: 'inflow',
      width:140,
      Filter: NumberRangeColumnFilter,
      filter: 'between',
      className:textNumber
    },
    {
      Header: Idioma.transacciones.configuracion.cargo[language],
      accessor: 'outflow',
      width:140,
      Filter: NumberRangeColumnFilter,
      filter: 'between',
      className:textNumber 
    },
    {
      Header: Idioma.transacciones.configuracion.categoria[language],
      accessor: 'category',
      Filter: MultiSelectColumnFilter,
      filter: multiSelectFilterFn,
      width:document.documentElement.clientHeight*.20,
      className:textCategory + ' pl-2 '
    },
    {
      Header: Idioma.transacciones.configuracion.subcategoria[language],
      accessor: 'subcategory',
      width:document.documentElement.clientHeight*.30,
      Filter: MultiSelectColumnFilter,
      filter: multiSelectFilterFn,
      className:textCategory + ' pl-2 '
    },{
      Header: Idioma.transacciones.configuracion.moneda[language],
      accessor: 'currency',
      Filter: MultiSelectColumnFilter,
      filter: multiSelectFilterFn,
      className:textNormal + '',
    },
    {
      Header: Idioma.transacciones.configuracion.fx[language],
      accessor: 'exchange',
      width:90,
      Filter: NumberRangeColumnFilter,
      filter: 'between',
      className:textNumber +' pr-2 '
    },{
      Header: Idioma.transacciones.configuracion.creditoDebito[language],
      accessor: 'accountType',
      width:140,
      Filter: SelectColumnFilter,
      className:textNormal + ''
    },{
      Header: Idioma.transacciones.configuracion.clientesProvedores[language],
      accessor: 'clientSupplier',
      width:document.documentElement.clientHeight*.20,
      Filter: MultiSelectColumnFilter,
      filter: multiSelectFilterFn,
      className:textCategory + ''
    },{
      Header: Idioma.transacciones.configuracion.notas[language],
      accessor: 'notes',
      width:140,
      Filter: NotesColumnFilter,
      className:textNormal + ''
    },/*{
      Header: Idioma.transacciones.configuracion.informacionAdicional[language],
      accessor: 'extraText',
      width:document.documentElement.clientWidth*.15,
      Filter: NotesColumnFilter,
      className:textNormal + ' '
    }*/
  ]


    const AddTransactionDisabled = !(bankAddT.id && accountAddT.id && (outflowAddT || inflowAddT))

    useEffect(()=>{
      if(accountAddT.minDate){
        setMinDateAddT(new Date(accountAddT.minDate.getTime() + accountAddT.minDate.getTimezoneOffset()*60*1000))
      }
    },[accountAddT])

    useEffect(() => {
        if(!isAuthenticated){
            navigate('/')
            return
        }
        getToken().then((token)=>{setToken(token)})
        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
  
        if(localStorage.getItem('columnPreferences')){
          
          let aux = localStorage.getItem('columnPreferences')
          let aux1 = JSON.parse(aux)
          setBankActive(aux1['bankActive'])
          setAccountActive(aux1['accountActive'])
          setRSActive(aux1['rsActive'])
          setDateActive(aux1['dateActive'])
          setContextActive(aux1['contextActive'])
          setInflowActive(aux1['inflowActive'])
          setOutflowActive(aux1['outflowActive'])
          setFXActive(aux1['fxActive'])
          setAccountTypeActive(aux1['accountTypeActive'])
          setNotesActive(aux1['notesActive'])
          setClientSupplierActive(aux1['clientSupplierActive'])
          setCurrencyActive(aux1['currencyActive'])
          //setAdditionalInfoActive(aux1['additionalInfoActive'])
          if(!aux1['rsActive']){
            allColumns[0+hasEditionAcess].toggleHidden()
          }
          if(!aux1['bankActive']){
            allColumns[1+hasEditionAcess].toggleHidden()
          }
          if(!aux1['accountActive']){
            allColumns[2+hasEditionAcess].toggleHidden()
          }
          if(!aux1['dateActive']){
            allColumns[3+hasEditionAcess].toggleHidden()
          }
          if(!aux1['contextActive']){
            allColumns[4+hasEditionAcess].toggleHidden()
          }
          if(!aux1['inflowActive']){
            allColumns[5+hasEditionAcess].toggleHidden()
          }
          if(!aux1['outflowActive']){
            allColumns[6+hasEditionAcess].toggleHidden()
          }
          if(!aux1['currencyActive']){
            allColumns[9+hasEditionAcess].toggleHidden()
          }
          if(!aux1['fxActive']){
            allColumns[10+hasEditionAcess].toggleHidden()
          }
          if(!aux1['accountTypeActive']){
            allColumns[11+hasEditionAcess].toggleHidden()
          }
          if(!aux1['clientSupplierActive']){
            allColumns[12+hasEditionAcess].toggleHidden()
          }
          if(!aux1['notesActive']){
            allColumns[13+hasEditionAcess].toggleHidden()
          }
          /*if(!aux1['additionalInfoActive']){
            allColumns[14+hasEditionAcess].toggleHidden()
          }*/
        }else{
          allColumns[0+hasEditionAcess].toggleHidden() //Empresa
          allColumns[2+hasEditionAcess].toggleHidden() //cuenta de banco
          allColumns[9+hasEditionAcess].toggleHidden() //moneda
          allColumns[10+hasEditionAcess].toggleHidden() //fx
          allColumns[11+hasEditionAcess].toggleHidden() //credito o debito
          allColumns[12+hasEditionAcess].toggleHidden() //clientes proveedores
          allColumns[13+hasEditionAcess].toggleHidden() //notas
          //allColumns[14+hasEditionAcess].toggleHidden() //informacion adicional
        }
        let body = {
          "clientID": idClient,
          logsInfo: {
            origin:'Transactions',
            sessionID:sessionID
          }
        }
        if(idRS){
          body.idRS = idRS
        }
        fetch(`${urlBase}/getAccounts`,{
                  method: 'POST',
                  headers: headers,
                  body: JSON.stringify(body)
                })
                  .then((response) => response.json())
                  .then((aux) => {
                    let newData=[]
                    let aux4 = {}
                    for (const property in aux) {
                        newData[property] = aux[property]
                        for(let i=0;i<aux[property].accounts.length;i++){
                          aux4[aux[property].accounts[i].accountID]=aux[property].accounts[i].accountAlias
                        }
                    }
                    let aux2 = Object.getOwnPropertyNames(aux);
                    let aux3 = []
                    for(let i=0;i<aux2.length;i++){
                        aux3[aux3.length] = {'name':aux2[i] , 'id':aux[aux2[i]].idBank}
                    }
                    setAccountsDict(aux4)
                    setBankOptions(aux3)
                    setInfo(newData)
                    
                });
  
        fetch(`${urlBase}/getCategories`,{
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
            "clientID": idClient,
            "lowest": 1,
            showHidden: true,
            logsInfo: {
              origin:'Transactions',
            sessionID:sessionID
            }
        })
        })
          .then((response) => response.json())
          .then((aux) => {
            let catDict = {}
            let newData = []
            let inflowCategoriesData = []
            let outflowCategoriesData = []
            let moreData = []
            for(let i = 0;i<aux.length;i++){
              newData[i] = {
                'name' : aux[i].category,
                'idCategory' : aux[i].idCategory,
                'index': i
              }
              if(aux[i].flowType=='+'){
                inflowCategoriesData[i] = newData[i]
              }else {
                outflowCategoriesData[i] = newData[i]
              }
              catDict[aux[i].idCategory] = {
                "name": aux[i].category,
                "isActive" : aux[i].isActive === true
              }
              moreData[i]=[]
              for(let j = 0;j<aux[i].subcategories.length;j++){
                if(aux[i].subcategories[j].isActive){
                  moreData[i][moreData[i].length] = {
                    'name':aux[i].subcategories[j].category,
                    'idSubCategory':aux[i].subcategories[j].idCategory,
                    'idFather': aux[i].idCategory
                  }
                }
                catDict[aux[i].subcategories[j].idCategory] = {
                  "name": aux[i].subcategories[j].category,
                  "isActive" : aux[i].subcategories[j].isActive === true,
                  "idFather" : aux[i].subcategories[j].idCategoryFather
                }
              }
            }
            if(moreData.length===0){
                newToast('error', Idioma.transacciones.mensajeError[language], Idioma.transacciones.errorSubcategorias[language])
                setIsLoading(false)
            }
            setInflowCategories(inflowCategoriesData)
            setOutflowCategories(outflowCategoriesData)
            setCategories(newData)
            setSubcategories(moreData)
            setCategoriesDict(catDict)
        });
        body={
            "clientID": idClient,
        }
        if(idRS){
            body.idRS=idRS
        }
        fetch(`${urlBase}/getClientSupplier`,{
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body)
        })
          .then((response) => response.json())
          .then((aux) => {
            setClientOptions(aux.clients)
            setSupplierOptions(aux.suppliers)
            let auxDict = {}
            for(let i=0;i<aux.clients.length;i++){
              auxDict[aux.clients[i].idClientSupplier] = {
                'name':aux.clients[i].clientSupplierName,
                "isActive" : aux.clients[i].isActive === true,
              }
            }
            for(let i=0;i<aux.suppliers.length;i++){
              auxDict[aux.suppliers[i].idClientSupplier] = {
                'name':aux.suppliers[i].clientSupplierName,
                "isActive" : aux.suppliers[i].isActive === true,
              }
            }
            setClientSupplierDict(auxDict)
            
        });
        fetch(`${urlBase}/getRS`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((res)=>res.json())
        .then((aux)=>{
            let newData = []
            let auxDict = {}
            for(let i=0;i<aux.length;i++){
              newData[i]={
                  name:aux[i].rsAlias,
                  id:aux[i].idRS
              }
              auxDict[aux[i].idRS] = {
                name:aux[i].rsAlias
              }
            }
            setRSOptions(newData)
            setRSDict(auxDict)
            if(aux.length==0 && localStorage.getItem('columnPreferences')){
              let aux1 = localStorage.getItem('columnPreferences')
              aux1=JSON.parse(aux1)
              if(aux1['rsActive']==true){
                  allColumns[0+hasEditionAcess].toggleHidden()
              }
            }
        })
        fetch(`${urlBase}/getColumns`,{
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body)
        })
          .then((response) => response.json())
          .then((aux) => {            
            //console.log('columnas aux',aux[0])
            setAdditionalColumns(aux[0])
        });
        fetch(`${urlBase}/getMLInfo`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body)
        })
        .then((res)=>res.json())
        .then((aux)=>{
            setMLInfo(aux)
        })
        getFileData()
      return () => {
      }
    },[])

    useEffect(()=>{
      localStorage.setItem('columnPreferences',JSON.stringify({
        'bankActive':bankActive,
        'accountActive':accountActive,
        'rsActive':rsActive,
        'dateActive':dateActive,
        'contextActive':contextActive,
        'inflowActive':inflowActive,
        'outflowActive':outflowActive,
        'fxActive':fxActive,
        'accountTypeActive':accountTypeActive,
        'notesActive':notesActive,
        'clientSupplierActive':clientSupplierActive,
        'currencyActive':currencyActive,
        'additionalInfoActive':additionalInfoActive
      }))
    },[bankActive,accountActive,rsActive,dateActive,contextActive,inflowActive,outflowActive,fxActive,accountTypeActive,notesActive,clientSupplierActive,currencyActive,additionalInfoActive])

    

    const getFileData = async () => {

      let headers = new Headers()

      const bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);
      headers.append("Content-Type",'application/json')
      let body = {
        "clientID": idClient,
        logsInfo: {
          origin:'Transactions',
            sessionID:sessionID
        }
      }
      if(idRS){
        body.idRS = idRS
      }
      fetch(`${urlBase}/getFiles`,{
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
      })
        .then((response) => response.json())
        .then((aux) => {
          let newData=[]
          for(let i=0;i<aux.length;i++){
            newData[i]={
              bank: aux[i].bank,
              account: aux[i].accountAlias,
              file: aux[i].inputLocation,
              id: aux[i].idFile,
              name: aux[i].inputName.slice(37),
              totalTransactions: aux[i].total,
              classifiedTransactions: aux[i].categorized,
              percentage: aux[i].total==0 ? 'Error' : aux[i].categorized==0 ? 0 : aux[i].categorized/aux[i].total*100 ,

            }
          }
          setFilesData(newData)
        });
    }

    const getTransactions = (type) => {
      setData([])
      setIsLoading(true)
      getToken().then((token)=>{setToken(token)})
      let headers = new Headers()

      const bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);
      headers.append("Content-Type",'application/json')
      let body = {
        "clientID": idClient,
        "showState":showState,
        "currency":mainCurrency,
        logsInfo: {
          origin:'Transactions',
            sessionID:sessionID
        }
      }
      if(type==='file' || (type==='' && file.id)){
        body.fileID = file.id
      }
      if(type==='account' || (type==='' && account.id)){
        body.accountID = account.id
      }
      if(contextSubcategoryId>0){
        body.categoryID = contextSubcategoryId
      }
      if(contextRSID.length>0){
        body.rsIDs = contextRSID
      }
      if(contextCategoriesId.length>0){
        body.categories = contextCategoriesId
      }
      if(type==='dates' || (type==='' && startDate && endDate)){
        let year = startDate.getFullYear().toString()
        let month = startDate.getMonth()+1
        let day = startDate.getDate()
        if(day<10){
            day = '0'+day.toString()
        }else{
            day = day.toString()
        }
        if(month<10){
            month = '0'+month.toString()
        }else{
            month = month.toString()
        }
        let fechaInicial = month+'-'+day+'-'+year
        year = endDate.getFullYear().toString()
        month = endDate.getMonth()+1
        day = endDate.getDate()
        if(day<10){
            day = '0'+day.toString()
        }else{
            day = day.toString()
        }
        if(month<10){
            month = '0'+month.toString()
        }else{
            month = month.toString()
        }
        let fechaFinal = month+'-'+day+'-'+year
        body.startDate = fechaInicial
        body.endDate =  fechaFinal
      }
      if(type==='bank' || (type==='' && bank.id)){
        body.bankID = bank.id
      }
      if(type==='' && bank.name===Idioma.transacciones.filtros.todos[language]){
        body.bankBypass = true
      }
      if(idRS){
        body.idRS = idRS
      }
      if(rsID.id){
        body.rsID = rsID.id
      }
        fetch(`${urlBase}/getTransactions`,{
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body)
      })
      .then((response) => response.json())
      .then((aux) => {
        let extraColumns = new Set([...Object.keys(additionalColumns)])
        let extColsActive = {}
        let colAux = []
        let newData = []
        let currContainer = {}
        let containerCount=0
        for(let i = 0;i<aux.length;i++){
          if(aux[i].isAContainer){
            currContainer[aux[i].idTransaction] = {
              'bank':aux[i].bank,
              'date':aux[i].date_d.slice(0,10),
              'id':aux[i].idTransaction,
              'context':aux[i].context,
              'reference':aux[i].references_c,
              'inflow':aux[i].inflow ,
              'outflow':aux[i].outflow,
              'category':categoriesDict[categoriesDict[aux[i].idCategory]?.idFather]?.name || '',
              'idCategory':categoriesDict[aux[i].idCategory]?.idFather || '',
              'subcategory':categoriesDict[aux[i].idCategory]?.name || '',
              'idSubCategory':aux[i].idCategory,
              'exchange':aux[i].exchange || aux[i].defaultFX,
              "updated":null,
              'accountType':aux[i].accountType==='D'?Idioma.transacciones.debito[language]:aux[i].accountType==='C' ? Idioma.transacciones.credito[language] :'NA',
              'signature':aux[i].transactionSignature,
              'notes': aux[i].notes,
              'idAccount':accountsDict[aux[i].idAccount],
              'idClientSupplier':aux[i].idClientSupplier,
              'clientSupplier':clientSupplierDict[aux[i].idClientSupplier]?.name,
              'currency': aux[i].currency,
              'rsName': aux[i].rsAlias,
              'extraText': aux[i].extraText,
              'idTopContainer': aux[i].idTopContainer,
              'isAContainer': aux[i].isAContainer
              }
            containerCount++
            continue
          }
          newData[i] = {
            'bank':aux[i].bank,
            'date':aux[i].date_d.slice(0,10),
            'id':aux[i].idTransaction,
            'context':aux[i].context,
            'reference':aux[i].references_c,
            'inflow':aux[i].inflow ,
            'outflow':aux[i].outflow,
            'category':categoriesDict[categoriesDict[aux[i].idCategory]?.idFather]?.name || '',
            'idCategory':categoriesDict[aux[i].idCategory]?.idFather || '',
            'subcategory':categoriesDict[aux[i].idCategory]?.name || '',
            'idSubCategory':aux[i].idCategory,
            'exchange':aux[i].exchange || aux[i].defaultFX,
            "updated":null,
            'accountType':aux[i].accountType==='D'?Idioma.transacciones.debito[language]:aux[i].accountType==='C' ? Idioma.transacciones.credito[language] :'NA',
            'signature':aux[i].transactionSignature,
            'notes': aux[i].notes,
            'idAccount':accountsDict[aux[i].idAccount],
            'idClientSupplier':aux[i].idClientSupplier,
            'clientSupplier':clientSupplierDict[aux[i].idClientSupplier]?.name,
            'currency': aux[i].currency,
            'rsName': aux[i].rsAlias,
            'extraText': aux[i].extraText,
            'idTopContainer': aux[i].idTopContainer,
            'isAContainer': aux[i].isAContainer,
            'container': null
            }
            if(currContainer[aux[i].idTopContainer]){
              newData[i].container=currContainer[aux[i].idTopContainer]
              currContainer[aux[i].idTopContainer]=null
            }
            let json = JSON.parse(aux[i].extraText)
            let auxExtra = [...Object.keys(additionalColumns)].concat([...Object.keys(json)])
            for(let j=0;j<auxExtra.length;j++){
              extraColumns.add(auxExtra[j])
              newData[i][auxExtra[j]] = json[auxExtra[j]] ? json[auxExtra[j]] : ''
            }
        }
        extraColumns.forEach((val1,val2,set)=>{
            colAux[colAux.length]={
                Header: additionalColumns[val1] ? additionalColumns[val1] : val1,
                accessor: val1,
                width:document.documentElement.clientWidth*.15,
                Filter: NotesColumnFilter,
                className:textNormal + ' '
            }
            extColsActive[val1] = true 
        })
        //console.log('transactions',newData)
        setContainerCounter(containerCount)
        setColumns([...initialColumns,...colAux])
        setExtraColumnsActive(extColsActive)
        setData(newData)
        setIsLoading(false)
      });
    }
    
    useEffect(()=>{
      if(rsID.id && subcategories.length!==0){
        setContextFile({'name':Idioma.transacciones.seleccionarArchivo[language]})
        setContextBank({name:Idioma.transacciones.configuracion.banco[language]})
        setContextAccount({name: Idioma.transacciones.configuracion.cuentaBanco[language]})
      }
    },[rsID])

    useEffect(()=>{
      if(account.id && subcategories.length!==0){
        setContextFile({'name':Idioma.transacciones.seleccionarArchivo[language]})
        setRSID({name:Idioma.transacciones.configuracion.empresa[language]})
        //setContextDateRange([null,null])
        //setData([])
        //setIsLoading(true)
        //getTransactions('')
      }
    },[account])
    
    useEffect(()=>{
      if(subcategories.length!==0 && (bank.id || bank.name===Idioma.transacciones.filtros.todos[language])){
        setContextFile({'name':Idioma.transacciones.seleccionarArchivo[language]})
        setRSID({name:Idioma.transacciones.configuracion.empresa[language]})
        //setContextDateRange([null,null])
        //setData([])
        //setIsLoading(true)
        //getTransactions('')
      }
    },[bank])

    useEffect(()=>{
      if(subcategories.length!==0){
        setIsLoading(true)
        getTransactions('')
      }
    },[showState])

    useEffect(() => {
      if((file.id||file.name==Idioma.transacciones.filtros.todos[language]) && subcategories.length!==0){
        setRSID({name:Idioma.transacciones.configuracion.empresa[language]})
        setRSID({name:Idioma.transacciones.configuracion.empresa[language]})
        setContextBank({name:Idioma.transacciones.configuracion.banco[language]})
        setContextAccount({name: Idioma.transacciones.configuracion.cuentaBanco[language]})
        setContextDateRange([null,null])
        //setData([])
        //setIsLoading(true)
        //getTransactions('')
      }
    },[file])

    useEffect(() => {
      if(startDate!=null && endDate!=null){
        //setData([])
        //setContextBank({name:'Banco'})
        //setContextAccount({name: Idioma.transacciones.configuracion.cuentaBanco[language]})
        setContextFile({'name':Idioma.transacciones.seleccionarArchivo[language]})
        setContextFile({'name':Idioma.transacciones.seleccionarArchivo[language]})
        //setIsLoading(true)
        //getTransactions('')
      }else if(!file.id){
        //getTransactions('')
      }
    },[dateRange])

    useEffect(() => {
      if(subcategories.length!==0 && Object.keys(accountsDict).length>0 && Object.keys(categoriesDict).length>0){
        setIsLoading(true)
        getTransactions('')
      }
    }, [subcategories,accountsDict,categoriesDict,clientSupplierDict,contextSubcategoryId,contextRSID,contextCategoriesId])

    useEffect(()=>{
      if (indexToUpdate<0 || data.length<=0) {
        isFirstRender.current = false;
        return; // 👈️ return early if initial render
      }
     
      
    },[data])

    const updateTransaction = async (index,subCategoryId) => {
        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')

       
        if(index>=0){
          let body = {
            "clientID": idClient,
            "transactions": [data[index].id],
            "categoryID": subCategoryId,
            logsInfo: {
              origin:'Transactions',
              sessionID:sessionID
            }
        }
        if(idRS){
          body.idRS=idRS
        }
            fetch(`${urlBase}/updateTransaction`,{
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            })
            .then((response) => response.json())
            .then((aux) => {
            setIndexToUpdate(-1)
            setShowTransactionsUpdated(aux.updates.length>=1)
            setUpdatedTransactions(aux.updates.length)
            if(showState==='nonClassified'){
              setData(prev => prev.filter(datum => datum.id!==data[index].id))
            }else{
              setData(current=> 
                current.map(obj => {
                  if (obj.id === data[index].id) {
                    return {...obj,updated:true}
                  }
                  return obj;
                }),
              )}
            })
            .catch(error=>{
              alert('Ha ocurrido un error')
            })
            
        }
    }

    const updateTransactionConcept = async (index,concept) => {
      let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        let body={
            "clientID": idClient,
            "transactions": [data[index].id],
            "concept": concept,
        }
        if(idRS){
            body.idRS=idRS
        }
  
        if(index>=0){
          let body = {
            "clientID": idClient,
            "transactions": [data[index].id],
            "concept": concept,
            logsInfo: {
              origin:'Transactions',
              sessionID:sessionID
            }
            }
            if(idRS){
              body.idRS = idRS
            }
            fetch(`${urlBase}/updateTransaction`,{
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            })
            .then((response) => response.json())
            .then((aux) => {
            setIndexToUpdate(-1)
            if(aux.updates.length>=1){
              newToast('success', Idioma.transacciones.transActualizadas[language], aux.updates.length)
            }
            if(aux.unaffected.length >= 1){
              newToast('error', Idioma.transacciones.transNoActualizadas[language], aux.unaffected.length)
            }
            //setShowTransactionsUpdated(aux.updates.length>=1)
            //setUpdatedTransactions(aux.updates.length)
            setUpdateCounter((prevValue)=>prevValue+1)
            })
            .catch(error=>{
              alert('Ha ocurrido un error')
            })
        }
    }
    const updateTransactionNotes = async (index,notes) => {
        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        let body = {
            "clientID": idClient,
            "transactions": [data[index].id],
            "notes":notes
        }
        if(idRS){
            body.idRS=idRS
        }
  
        if(index>=0){
          let body = {
            "clientID": idClient,
            "transactions": [data[index].id],
            "notes":notes,
            logsInfo: {
              origin:'Transactions',
              sessionID:sessionID
            }
          }
          if(idRS){
            body.idRS = idRS
          }
            fetch(`${urlBase}/updateTransaction`,{
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            })
            .then((response) => response.json())
            .then((aux) => {
            setIndexToUpdate(-1)
            if(aux.updates.length>=1){
              newToast('success', Idioma.transacciones.transActualizadas[language], aux.updates.length)
            }
            if(aux.unaffected.length >= 1){
              newToast('error', Idioma.transacciones.transNoActualizadas[language], aux.unaffected.length)
            }
            //setShowTransactionsUpdated(aux.updates.length>=1)
            //setUpdatedTransactions(aux.updates.length)
            setUpdateCounter((prevValue)=>prevValue+1)
            })
            .catch(error=>{
              alert('Ha ocurrido un error')
            })
        }
    }

    const updateTransactionExtraText = async (index,extraText,accessor) => {
      let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
 
  
        if(index>=0){
          let body = {
            "clientID": idClient,
            "transactions": [data[index].id],
            "accessor":accessor,
            "extraText":extraText,
            logsInfo: {
              origin:'Transactions',
              sessionID:sessionID
            }
          }
          if(idRS){
            body.idRS = idRS
          }
            fetch(`${urlBase}/updateTransaction`,{
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            })
            .then((response) => response.json())
            .then((aux) => {
            setIndexToUpdate(-1)
            if(aux.updates.length>=1){
              newToast('success', Idioma.transacciones.transActualizadas[language], aux.updates.length)
            }
            if(aux.unaffected.length >= 1){
              newToast('error', Idioma.transacciones.transNoActualizadas[language], aux.unaffected.length)
            }
            //setShowTransactionsUpdated(aux.updates.length>=1)
            //setUpdatedTransactions(aux.updates.length)
            setUpdateCounter((prevValue)=>prevValue+1)
            })
            .catch(error=>{
              alert('Ha ocurrido un error')
            })
        }
    }
    

    const multiTransactionUpdate = async(subCategoryId) => {
        setIsMultiUpdateLoading(true)
        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        let saveIndexes = {}
        let allTransactionIds = []
        for( const property in selectedRowIds){
          if(selectedRowIds[property]){
            allTransactionIds[allTransactionIds.length]=data[property].id
            saveIndexes[data[property].id]=property
          }
          
        }

        let body = {
            "clientID": idClient,
            "transactions": allTransactionIds,
            "categoryID": subCategoryId,
            logsInfo: {
              origin:'Transactions',
              sessionID:sessionID
            }
        }
        if(idRS){
          body.idRS = idRS
        }

        fetch(`${urlBase}/updateTransaction`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
            if(aux.updates.length>=1){
              newToast('success', Idioma.transacciones.transActualizadas[language], aux.updates.length)
            }
            if(aux.unaffected.length >= 1){
              newToast('error', Idioma.transacciones.transNoActualizadas[language], aux.unaffected.length)
            }
            //setShowTransactionsUpdated(aux.updates.length>=1)
            //setUpdatedTransactions(aux.updates.length)
            //setErrorSign(aux.unaffected.length >= 1)
            //setErrorMessage(aux.unaffected.length >= 1 ? Idioma.transacciones.transNoActualizadas[language] : '')
            //setShowTransactionsError(aux.unaffected.length >= 1)
            //setUnaffectedTransactions(aux.unaffected)
            let auxData = [...data]
            if(showState=='classified' || showState=='all'){
              for(let i=0;i<aux.updates.length;i++){
                auxData[saveIndexes[aux.updates[i]]].category = categoriesDict[categoriesDict[subCategoryId]?.idFather]?.name || '',
                auxData[saveIndexes[aux.updates[i]]].idCategory = categoriesDict[subCategoryId]?.idFather || '',
                auxData[saveIndexes[aux.updates[i]]].subcategory = categoriesDict[subCategoryId]?.name || '',
                auxData[saveIndexes[aux.updates[i]]].idSubCategory = subCategoryId
              }
              setData(auxData)
            }
            if(showState == 'nonClassified'){
              setData(prev => {
                const setClassfied =  new Set(aux.updates);
                const newArr = prev.filter(dato => !setClassfied.has(dato.id));
                return newArr;
              })
            }
            })
            .catch(error=>{
                alert('Ha ocurrido un error')
                console.log('error',error)
            setIsMultiUpdateLoading(false)
        })
        resetFilters()
        setIsMultiUpdateLoading(false)
    }

    const multiDeleteTransaction = async () => {
        setOpenSendToRecycleBinWarning(false)

        let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')

        let allTransactionSignatures = []
        let containers = new Set()
        for(let i=0;i<selectedFlatRows.length; i++){
          if(selectedFlatRows[i].original.idTopContainer){
            containers.add(selectedFlatRows[i].original.idTopContainer)
          }else{
            allTransactionSignatures.push(selectedFlatRows[i].original.signature)
          }
        }
        let body = {
          "clientID": idClient,
          "transactions": allTransactionSignatures,
          "containers": [...containers],
          logsInfo: {
            origin:'Transactions',
      sessionID:sessionID
          }
      }
      if(idRS){
        body.idRS = idRS
      }

        fetch(`${urlBase}/moveToRecycleBin`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
        setErrorMessage(aux.unaffected.length >= 1 ? Idioma.transacciones.transNoActualizadas[language] : '')
        setErrorSign(aux.unaffected.length >= 1)
        setShowTransactionsError(aux.unaffected.length >= 1)
        setUnaffectedTransactions(aux.unaffected)
        for(let i=0;i<aux.deleted.length;i++){
          setData(prev => prev.filter(datum => datum.signature!==aux.deleted[i]))
        }
        if(containers.size>0){
          getTransactions('')
        }
        setOpenDeleteTransactionsWarning(false)
        })
        .catch(error=>{
          alert('Ha ocurrido un error')
        })
    }

    const updateTransactionExchange = async (index,forex) => {
      let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')

  
        if(index>=0){
          let body = {
                    "clientID": idClient,
                    "transactions" : [data[index].id],
                    "signature": data[index].signature,
                    "exchange": forex,
                    logsInfo: {
                      origin:'Transactions',
            sessionID:sessionID
                    }
                }
                if(idRS){
                  body.idRS = idRS
                }
            fetch(`${urlBase}/updateTransaction`,{
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            })
            .then((response) => response.json())
            .then((aux) => {

            setIndexToUpdate(-1)
            if(aux.updates.length>=1){
              newToast('success', Idioma.transacciones.transActualizadas[language], aux.updates.length)
            }
            if(aux.unaffected.length >= 1){
              newToast('error', Idioma.transacciones.transNoActualizadas[language], aux.unaffected.length)
            }
            //setShowTransactionsUpdated(aux.updates.length>=1)
            //setUpdatedTransactions(aux.updates.length)
            //setErrorMessage(aux.unaffected.length >= 1 ? Idioma.transacciones.transNoActualizadas[language] : '')
            //setErrorSign(aux.unaffected.length >= 1)
            //setShowTransactionsError(aux.unaffected.length >= 1)
            //setUnaffectedTransactions(aux.unaffected)
            
            })
            .catch(error=>{
              alert('Ha ocurrido un error')
            })
        }
    }

    const updateTransactionClientSupplier = async (index,idClientSupplier) => {
      let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')

  
        if(index>=0){
          let body = {
            "clientID": idClient,
            "transactions" : [data[index].id],
            "signature": data[index].signature,
            "idClientSupplier": idClientSupplier,
            logsInfo: {
              origin:'Transactions',
    sessionID:sessionID
            }
        }
        if(idRS){
          body.idRS = idRS
        }
            fetch(`${urlBase}/updateTransaction`,{
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            })
            .then((response) => response.json())
            .then((aux) => {
            setIndexToUpdate(-1)
            if(aux.updates.length>=1){
              newToast('success', Idioma.transacciones.transActualizadas[language], aux.updates.length)
            }
            if(aux.unaffected.length >= 1){
              newToast('error', Idioma.transacciones.transNoActualizadas[language], aux.unaffected.length)
            }
            //setShowTransactionsUpdated(aux.updates.length>=1)
            //setUpdatedTransactions(aux.updates.length)
            //setErrorMessage(aux.unaffected.length >= 1 ? Idioma.transacciones.transNoActualizadas[language] : '')
            //setErrorSign(aux.unaffected.length >= 1)
            //setShowTransactionsError(aux.unaffected.length >= 1)
            //setUnaffectedTransactions(aux.unaffected)
            
            })
            .catch(error=>{
              alert('Ha ocurrido un error')
            })
        }
    }
/* //Funcion para actualizar el monto de una transaccion
    const updateTransactionAmount = async (index,inflow,outflow) => {
      let headers = new Headers()

      const bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);
      headers.append("Content-Type",'application/json')

  
      if(index>=0){
        let body = {
                "clientID": idClient,
                "transactionID": data[index].id,
                "inflow": inflow,
                "outflow": outflow,
          logsInfo: {
            origin:'Transactions',
            sessionID:sessionID
          }
            }
            if(idRS){
              body.idRS = idRS
            }
        fetch(`${urlBase}/updateSingleTransaction`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {

        setIndexToUpdate(-1)
        setShowTransactionsUpdated(aux.updates.length>=1)
        setUpdatedTransactions(aux.updates.length)
        setShowTransactionsError(aux.unaffected.length >= 1)
        setUnaffectedTransactions(aux.unaffected)
        
        })
        .catch(error=>{
          alert('Ha ocurrido un error')
        })
      }
    }*/

    const multiTransactionUpdateForex = (forex) => {
        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')

        let allTransactionIds = []
        for(let i=0;i<selectedFlatRows.length; i++){
          allTransactionIds[i]=selectedFlatRows[i].original.id
        }
        let body = {
          "clientID": idClient,
          "transactions": allTransactionIds,
          "exchange": forex,
          logsInfo: {
            origin:'Transactions',
            sessionID:sessionID
          }
        }
        if(idRS){
          body.idRS = idRS
        }

          fetch(`${urlBase}/updateTransaction`,{
              method: 'POST',
              headers: headers,
              body: JSON.stringify(body)
          })
          .then((response) => response.json())
          .then((aux) => {
            if(aux.updates.length>0){
              newToast('success', Idioma.transacciones.transActualizadas[language], aux.updates.length)
            }
            if(aux.unaffected.length >0){
              newToast('error', Idioma.transacciones.transNoActualizadas[language], aux.unaffected.length)
            }
          //setShowTransactionsUpdated(aux.updates.length>=1)
          //setUpdatedTransactions(aux.updates.length)
          //setErrorMessage(aux.unaffected.length >= 1 ? Idioma.transacciones.transNoActualizadas[language] : '')
          //setErrorSign(aux.unaffected.length >= 1)
          //setShowTransactionsError(aux.unaffected.length >= 1)
          //setUnaffectedTransactions(aux.unaffected)
          for(let i = 0;i<allTransactionIds.length;i++){
            setData(current=> 
              current.map(obj => {
                if (obj.id == allTransactionIds[i]) {
                  return {...obj,exchange:forex}
                }
                return obj;
              }),
            )
          }
          setForexValue()
          })
          .catch(error=>{
            alert('Ha ocurrido un error')
          })
            
        
    }
    
    

      const handleBankChange = (value) => {
        let flag = false
        if(bank.id == value.id){
           flag = true
        }
        setContextBank(value)
        let aux = []
        for(let i = 0;i<info[value.name]?.accounts.length;i++){
          aux[i] = {
            name:info[value.name].accounts[i].accountAlias,
            id:info[value.name].accounts[i].accountID
          }
        }
        setAccounts(aux)
        if(!flag){
          setContextAccount({name:Idioma.transacciones.configuracion.cuentaBanco[language]})
        }
      }
      
      const handleBankAddTChange = (value) => {
        let flag = false
        if(bankAddT.id == value.id){
           flag = true
        }
        setBankAddT(value)
        let aux = []
        for(let i = 0;i<info[value.name].accounts.length;i++){
          aux[i] = {
            name:info[value.name].accounts[i].accountAlias,
            id:info[value.name].accounts[i].accountID,
            minDate:new Date(info[value.name].accounts[i].initialDate)
          }
        }
        setAccountsAddT(aux)
        if(!flag){
          setAccountAddT({name:Idioma.transacciones.configuracion.cuentaBanco[language]})
        }
      }

      const handleDateAddTChange = (event) => {
        setDateAddT(event)
      }

      const handleValueAddTChange = (inflow,outflow) => {
        setInflowAddT(inflow)
        setOutflowAddT(outflow)
      }

      const handleCloseAddT = () => {
        setOpenAddTransaction(false)
        setBankAddT({name: Idioma.transacciones.configuracion.banco[language]})
        setAccountsAddT([])
        setAccountAddT({name: Idioma.transacciones.configuracion.cuentaBanco[language]})
        setDateAddT(new Date())
        setConceptAddT('')
        setInflowAddT()
        setOutflowAddT()
        setCategoryAddT()
      }

      const AddSingleTransaction = () => {
        setAddTransactionLoading(true)
        let year = dateAddT.getFullYear().toString()
        let month= dateAddT.getMonth()+1
        let day = dateAddT.getDate()
        if(day<10){1
            day = '0'+day.toString()
        }else{
            day = day.toString()
        }

        if(month<10){
            month = '0'+month.toString()
        }else{
            month = month.toString()
        }
        let fecha = year+'-'+month+'-'+day


        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        let body = {
          "clientID": idClient,
          "accountID": accountAddT.id,
          "date": fecha,
          "context": conceptAddT,
          "inflow":   parseFloat(inflowAddT) || 0,
          "outflow":  parseFloat(outflowAddT) || 0,
          "categoryID": categoryAddT,
          logsInfo: {
            origin:'Transactions',
        sessionID:sessionID
          }
      }
      if(idRS){
        body.idRS = idRS
      }

        fetch(`${urlBase}/addTransaction`,{
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
        setAddTransactionLoading(false)
        getTransactions('')
        handleCloseAddT()
        })
        .catch(error=>{
          alert('Ha ocurrido un error')
        })
      }

      const handleShowStateChange = (value) => {
        setContextShowState(value)
        setData([])
        
      }

      const handleFileChange = (value) => {
        setContextFile(value)
      }

      const handleRuleTypeChange = (value) =>{
        setRuleType(value)
      }
      const handleRuleType2Change = (value) =>{
        setRuleType2(value)
      }
      const handleRuleType3Change = (value) =>{
        setRuleType3(value)
      }

      const handleBankRuleChange = (value) => {
        setBankRule(value)
      }
      const handleRuleCategoryChange = (value) => {
        setRuleCategory(value)
        setRuleSubcategory({name:'Subcategoría'})
      }
      const handleRuleSubcategoryChange = (value => {
        setRuleSubcategory(value)
      })

      
/*
      useEffect(() => {
        handleBankChange(bank)
      },[BankOptions])*/

      

      useEffect(()=> {
        setAllFilters([])
      },[filtersActive])

      const handleDateRangeChange = (update) => {
        setContextDateRange(update)
      }

      const transactionSelect = (row) => {
        if(row.original.id == transactionId){
          setSelectedTransaction([])
          setTransactionId(null)
          setTransactionQuantities([])
        }else{
          setSelectedTransaction(row)
          setTransactionId(row.original.id)
          setTransactionQuantities(row.original.inflow > 0 ? [{value:[row.original.inflow],category:{name:'Categoría'},subcategory:{name:'Subcategoría'}},]:[{value:[row.original.outflow],category:{name:'Categoría'},subcategory:{name:'Subcategoría'}}])
        }
      }
      
      const deleteTransactionQuantity = index => e => {

        setTransactionQuantities(prev => prev.filter((datum,id) => {
          return id!==index
        }))
      }

      const updateFieldChangedValue = (index,e) => {
        let newArr = [...transactionQuantities]; // copying the old datas array
        // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
        newArr[index].value = e.target.value; // replace e.target.value with whatever you want to change it to
      
        setTransactionQuantities(newArr);
      }

      const updateFieldChangedText = index => e => {
        let newArr = [...transactionQuantities]; // copying the old datas array
        // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
        newArr[index].context = e.target.value; // replace e.target.value with whatever you want to change it to
      
        setTransactionQuantities(newArr);
      }

      const handleTransactionCategoryChange = index => value => {
        //setRuleCategory(value)
        //setRuleSubcategory({name:'Subcategoría'})
        let newArr = [...transactionQuantities]; // copying the old datas array
        // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
        newArr[index].category = value; // replace e.target.value with whatever you want to change it to
        newArr[index].subcategory = {name: Idioma.transacciones.configuracion.subcategoria[language]}
        setTransactionQuantities(newArr);

      }

      const handleTransactionSubcategoryChange = (index,event) => {
        let newArr = [...transactionQuantities]; // copying the old datas array
        // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
       
        newArr[index].subcategory = {idSubCategory: event.target.value}
        setTransactionQuantities(newArr);
      }


      const addTransactionQuantities = (selectedTransaction) => {
        let newArr = [...transactionQuantities,{context:selectedTransaction.context,value:selectedTransaction.inflow>0 ?  parseFloat(selectedTransaction.inflow-getTransactionQuantityTotal()).toFixed(2) :  parseFloat(selectedTransaction.outflow-getTransactionQuantityTotal()).toFixed(2),category:{name:'Categoría'},subcategory: {name:'Subcategoría'}}]; // copying the old datas array
        // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.

      
        setTransactionQuantities(newArr);
      }

      const getTransactionQuantityTotal = () => {
        let total=0
        for(let i=0;i<transactionQuantities.length;i++){
          total+=parseFloat(transactionQuantities[i].value) ? parseFloat(transactionQuantities[i].value) : 0
        }
        total=parseFloat(total).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          currencyDisplay: 'code'}).slice(4).replaceAll(',','')
        total=parseFloat(total)

        return total
      }

      const setTopContainer = () => {
        setSplitTransactionLoading(true)
        
        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        
        let splitInto = []
        let creditCopy = []

        for(let i=0;i<transactionQuantities.length;i++){
          splitInto[i] = {
            "context": transactionQuantities[i].context,
            "balance":0,
            "inflow": selectedTransaction.original.inflow > 0 ? transactionQuantities[i].value : 0,
            "outflow": selectedTransaction.original.inflow > 0 ? 0 : transactionQuantities[i].value,
            "categoryID": transactionQuantities[i].subcategory.idSubCategory,
            "notes": ""
          }
          if (selectedTransaction.original.accountType === Idioma.transacciones.credito[language]){
            creditCopy[i] = {
              "context": transactionQuantities[i].context,
              "balance":0,
              "inflow": selectedTransaction.original.inflow > 0 ? 0 : transactionQuantities[i].value,
              "outflow": selectedTransaction.original.inflow > 0 ? transactionQuantities[i].value : 0,
              "categoryID": transactionQuantities[i].subcategory.idSubCategory,
              "notes": ""
            }
          }
        }
        let body = {
          "clientID": idClient,
          "transactionID": selectedTransaction.original.id,
          "transactionSignature": selectedTransaction.original.signature,
          "splitInto": splitInto,
          "creditCopy": creditCopy,
          logsInfo: {
            origin:'Transactions',
      sessionID:sessionID
          }
      }
      if(idRS){
        body.idRS = idRS
      }

        fetch(`${urlBase}/setTopContainer`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
        
        setOpenTransactionModal(false)
        setSplitTransactionLoading(false)
        setData(prev => prev.filter(datum => datum.id!==transactionId))
        setSelectedTransaction([])
        setTransactionId(null)
        
        })
        .catch(error => console.log('error',error))
      }
      

      const createCellOfType = (cell,row) => {
        const [showIcons,setShowIcons] = useState(false)
        /*const [isEditingInflow,setIsEditingInflow] = useState(false)
        const [isEditingOutflow,setIsEditingOutflow] = useState(false)
        const inputInflowRef = useRef(null)
        const inputOutflowRef = useRef(null)

        const toggleEditingInflow = () => {
          setIsEditingInflow((prev)=>!prev)
          setIsEditingOutflow(false)
        }

        useEffect(()=>{
          if(isEditingInflow){
            inputInflowRef.current.focus()
          }
        },[isEditingInflow])

        const toggleEditingOutflow = () => {
          setIsEditingOutflow((prev)=>!prev)
          setIsEditingInflow(false)
        }

        useEffect(()=>{
          if(isEditingOutflow){
            inputOutflowRef.current.focus()
          }
        },[isEditingOutflow])
        const handleInflowChange = async (event) =>{
          if(cell.row.original.inflow != event.target.value){
            let index = data.indexOf(cell.row.original)
            setIndexToUpdate(index)
            setData(current =>
              current.map(obj => {
                if(obj.id === cell.row.original.id){
                  return{ ...obj,inflow:event.target.value,outflow:0}
                }
                return obj;
              }),
            )
            let resp = await updateTransactionAmount(index,event.target.value,0)
          }
          toggleEditingInflow()
        }

        const handleOutflowChange = async (event) =>{
          if(cell.row.original.outflow != event.target.value){
          let index = data.indexOf(cell.row.original)
          setIndexToUpdate(index)
          setData(current =>
            current.map(obj => {
              if(obj.id === cell.row.original.id){
                return{ ...obj,inflow:0,outflow:event.target.value}
              }
              return obj;
            }),
          )
          let resp = await updateTransactionAmount(index,0,event.target.value)
          }
          toggleEditingOutflow()
        }
        
        
        */

        const handleCategoryChange = (event) => {
          setData(current=> 
            current.map(obj => {
              if (obj.id === cell.row.original.id) {
                return {...obj,idCategory:event.idCategory,category:event.name,categoryIndex:event.index,idSubCategory:undefined,subcategory:undefined}
              }
              return obj;
            }),
          );
        }

        const handleConceptChange = async (event) =>{
            if((event.target.value !== cell.row.original.context && event.target.value.length>0  ) ){
                let index = data.indexOf(cell.row.original)
                setIndexToUpdate(index)
                setData(current =>
                    current.map(obj => {
                    if(obj.id === cell.row.original.id){
                        return{ ...obj,context:event.target.value}
                    }
                    return obj;
                    }),
                )
                let resp = await updateTransactionConcept(index,event.target.value)
            }
        }

        const handleNotesChange = async (event) =>{
            if((event.target.value !== cell.row.original.notes && (event.target.value.length>0 || (cell.row.original.notes && cell.row.original.notes.length>0)) ) ){
                let index = data.indexOf(cell.row.original)
                setIndexToUpdate(index)
                setData(current =>
                    current.map(obj => {
                    if(obj.id === cell.row.original.id){
                        return{ ...obj,notes:event.target.value}
                    }
                    return obj;
                    }),
                )
                let resp = await updateTransactionNotes(index,event.target.value)
            }        
            
        }

        const handleExtraTextChange = async (event,accessor) => {
          if((event.target.value !== cell.row.original[accessor] && (event.target.value.length>0 || (cell.row.original[accessor] && cell.row.original[accessor].length>0)) ) ){
            let index = data.indexOf(cell.row.original)
            setIndexToUpdate(index)
            setData(current =>
                current.map(obj => {
                if(obj.id === cell.row.original.id){
                    return{ ...obj,[accessor]:event.target.value}
                }
                return obj;
                }),
            )
            let resp = await updateTransactionExtraText(index,event.target.value,accessor)
        }
        }

        

        const handleExchangeChange = async (event) =>{
            if((event.target.value !== cell.row.original.exchange && (event.target.value.length>0 || (cell.row.original.exchange && cell.row.original.exchange.length>0)) ) ){
                let index = data.indexOf(cell.row.original)
                setIndexToUpdate(index)
                setData(current =>
                    current.map(obj => {
                    if(obj.id === cell.row.original.id){
                        return{ ...obj,exchange:event.target.value}
                    }
                    return obj;
                    }),
                )
                let resp = await updateTransactionExchange(index,event.target.value)
            }        
        }
    
        const handleSubcategoryChange = async (event) => {
          let index = data.indexOf(cell.row.original)
          setIndexToUpdate(index)
          setData(current=> 
            current.map(obj => {
              if (obj.id === cell.row.original.id) {
                return {...obj,category:event.target.selectedOptions[0].outerText,idSubCategory:event.target.value,subcategory:event.target.selectedOptions[0].label}
              }
              return obj;
            }),
          )
        let resp = await updateTransaction(index,event.target.value)
           
           
        }

        const handleClientSupplierChange = async (event) => {
          let index = data.indexOf(cell.row.original)
          setIndexToUpdate(index)
          setData(current=> 
            current.map(obj => {
              if (obj.id === cell.row.original.id) {
                return {...obj,idClientSupplier:event.target.value,clientSupplier:event.target.selectedOptions[0].label}
              }
              return obj;
            }),
          )
        let resp = await updateTransactionClientSupplier(index,event.target.value)
           
        }

        const copyFunction = (textToCopy) => {
          newToast('success', Idioma.transacciones.textoCopiado[language], textToCopy)
          navigator.clipboard.writeText(textToCopy)
        }

        let updatedStyle = 'px-2 rounded-button bg-transparent w-[100%]  border-neutral-1 border-1  appearance-none relative'
        let normalStyle = 'px-0 rounded-button bg-transparent w-[100%] appearance-none relative'
        let classifiedStyle = 'px-2 rounded-button bg-transparent w-[100%] appearance-none relative'
        

        if(cell.column.id === 'category'){
          return <>
          {(row.original.container && sortBy.length===0 && (data.length-containerCounter)===rows.length) && 
            <div className=' pl-2 w-[100%] font-bold invisible'>{row.original.container.category ? row.original.container.category : 'N/A'}</div>
          }
          <div className='pl-4 w-[100%]'>{cell.value}</div>
          </>
          //return <div><ClassificationDropdown className={ data[data.indexOf(row.original)].category ? 'min-w-[300px] ': ' text-transparent min-w-[300px] '} selected={data[data.indexOf(row.original)].category ? {name:data[data.indexOf(row.original)].category} : {name:'Categoria'}} setSelected={handleCategoryChange} options={row.original.inflow!=0 ? inflowCategories : outflowCategories}/></div>
        }
        if(cell.column.id === 'subcategory' && userAccess && userAccess.access3){
          return <>
          {(row.original.container && sortBy.length===0 && (data.length-containerCounter)===rows.length) && 
            <div className=' pl-2 w-[100%] flex relative font-bold invisible'>{row.original.container.subcategory ? row.original.container.subcategory : 'N/A'}</div>
          }
          <div className='pl-2 w-[100%] flex relative'>
            <select className={!row.original.subcategory ? normalStyle : row.original.updated ? updatedStyle : classifiedStyle} value={categoriesDict[row.original.idSubCategory]?.isActive ? row.original.idSubCategory : ""} onChange={(event)=>handleSubcategoryChange(event)}>
              <option value={cell.row.original.idSubCategory} selected='true' disabled>{cell.value}</option>
              {row.original.inflow!=0 ? inflowCategories.map(category=>{
                return (
                  <optgroup label={category.name}>
                    {subcategories[category.index].map(subcategory => {
                      return (
                        <option value={subcategory.idSubCategory} label={subcategory.name}>{category.name}</option>
                      )
                    })}
                  </optgroup>
                )
              }) :
              outflowCategories.map(category=>{
                return (
                  <optgroup label={category.name}>
                    {subcategories[category.index].map(subcategory => {
                      return (
                        <option value={subcategory.idSubCategory} label={subcategory.name}>{category.name}</option>
                      )
                    })}
                  </optgroup>
                )
              }) }
            </select><span className='absolute flex items-center right-[15px] top-[2px] pointer-events-none'><TrianguloAbajoLogo/></span></div>
            {/*return <div><ClassificationDropdown className={!data[data.indexOf(row.original)].subcategory ? normalStyle : row.original.updated ? updatedStyle : classifiedStyle} disabled={row.original.idCategory ? false : true} selected={data[data.indexOf(row.original)].subcategory ? {name:data[data.indexOf(row.original)].subcategory} :{'name':'Subcategoria'}} setSelected={handleSubcategoryChange} options={row.original.idCategory ? subcategories[data[data.indexOf(row.original)].categoryIndex] : [] }/></div>*/}
          </>
        }
        
        if(cell.column.id === 'clientSupplier' && userAccess && userAccess.access3){
          return <>
          {(row.original.container && sortBy.length===0 && (data.length-containerCounter)===rows.length) && 
            <div className=' pl-2 w-[100%] flex relative font-bold invisible'>{row.original.container.clientSupplier ? row.original.container.clientSupplier : 'N/A'}</div>
          }
          <div className='pl-2 w-[100%] flex relative'>
            <select className={!row.original.idClientSupplier ? normalStyle : row.original.updated ? updatedStyle : classifiedStyle} value={clientSupplierDict[row.original.idClientSupplier]?.isActive ? row.original.idClientSupplier : ""} onChange={(event)=>handleClientSupplierChange(event)}>
              <option value={cell.row.original.idClientSupplier} selected='true' disabled>{cell.value}</option>
              <optgroup label={'Clientes'}>
                {clientOptions.map(client=>{
                  return (
                  <option value={client.idClientSupplier} label={client.clientSupplierName}>{client.clientSupplierName}</option>
                  )})
                }
              </optgroup>
              <optgroup label={'Proveedores'}>
                {supplierOptions.map(supplier=>{
                  return(
                  <option value={supplier.idClientSupplier} label={supplier.clientSupplierName}>{supplier.clientSupplierName}</option>
                  )})
                }
              </optgroup>
              
              </select><span className='absolute flex items-center right-[15px] top-[2px] pointer-events-none'><TrianguloAbajoLogo/></span></div>
              </>
        }
        if(cell.column.id === 'inflow'){
          
          /*return <div className='text-right relative w-[100%] tabular-nums'>
            
            {isEditingInflow ?
            <div className='bg-white rounded-[7px] w-full'>
              <input ref={inputInflowRef} onBlur={(e)=>handleInflowChange(e)} defaultValue={cell.value} className='text-right' type='number'>
              </input>
            </div>
            :
            <span onClick={()=>toggleEditingInflow()}>{cell.value ? (parseFloat(cell.value)).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              currencyDisplay: 'code'}).slice(4) : '--'}</span>
            }
            </div>*/
            return <>
            {(row.original.container && sortBy.length===0 && (data.length-containerCounter)===rows.length) && 
            <div className=' text-right relative w-[100%] tabular-nums font-bold'>{row.original.container.inflow ? (parseFloat(row.original.container.inflow)).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              currencyDisplay: 'code'}).slice(4) : '--'}</div>
          }
            <div className='text-right relative w-[100%] tabular-nums'>
            <span>{cell.value ? (parseFloat(cell.value)).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              currencyDisplay: 'code'}).slice(4) : '--'}</span>
            </div>
            </>
        }
        if(cell.column.id === 'outflow'){
          /*return <div className='text-right relative overflow-visible w-[100%] tabular-nums '>
            {isEditingOutflow ? 
            <div className='bg-white rounded-[7px] shadow-xl w-fit border-1 px-2'>
              <input ref={inputOutflowRef} onBlur={(e)=>handleOutflowChange(e)} defaultValue={cell.value} className='text-right' type='number'>
              </input>
            </div>
            :
            <span onClick={()=>toggleEditingOutflow()}>{cell.value ? `(${(parseFloat(cell.value)).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              currencySign: "accounting",
              currencyDisplay: 'code'
              }).slice(4)})` : '--' }</span>
            }*/
          
          return <>
          {(row.original.container && sortBy.length===0 && (data.length-containerCounter)===rows.length) && 
            <div className=' text-right relative overflow-visible w-[100%] tabular-nums font-bold'>{row.original.container.outflow ? `${numberFormat===0?'(':'-'}${(parseFloat(row.original.container.outflow)).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              currencySign: "accounting",
              currencyDisplay: 'code'
              }).slice(4)}${numberFormat===0?')':''}` : '--' }</div>
          }
          <div className='text-right relative overflow-visible w-[100%] tabular-nums '>
            <span>{cell.value ? `${numberFormat===0?'(':'-'}${(parseFloat(cell.value)).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            currencySign: "accounting",
            currencyDisplay: 'code'
            }).slice(4)}${numberFormat===0?')':''}` : '--' }</span>
            </div>
          </>

        }
        if(cell.column.id === 'exchange' && userAccess && userAccess.access3){
          return<>
          {(row.original.container && sortBy.length===0 && (data.length-containerCounter)===rows.length) && 
            <div className=' pl-2 text-right w-[100%] tabular-nums font-bold'>{row.original.container.exchange ? parseFloat(row.original.container.exchange).toFixed(2).toLocaleString("en-US") : '--'}</div>
          }
          <div className='pl-2 w-[100%] truncate focus:bg-black relative ' title={cell.value}>
            
            <input className='w-full h-full appearance-none bg-transparent group text-right' placeholder='--' disabled={row.original.currency===mainCurrency} defaultValue={cell.value} onBlur={(e)=>handleExchangeChange(e)}></input>
            
            {false && <AiOutlineSave className='absolute h-5 w-5 text-black top-0 right-0 bg-main-bg'/>}
            </div>
          </> 
        }
        if(cell.column.id === 'exchange'){
          return <>
          {(row.original.container && sortBy.length===0 && (data.length-containerCounter)===rows.length) && 
            <div className=' pl-2 text-right w-[100%] tabular-nums font-bold'>{row.original.container.exchange ? parseFloat(row.original.container.exchange).toFixed(2).toLocaleString("en-US") : '--'}</div>
          }
          <div className='pl-2 text-right w-[100%] tabular-nums'>{cell.value ? parseFloat(cell.value).toFixed(2).toLocaleString("en-US") : '--'}</div>
          </>
        }
        if(cell.column.id === 'context' && userAccess && userAccess.access3){
          return <>
          {(row.original.container && sortBy.length===0 && (data.length-containerCounter)===rows.length) && 
              <div className=' pl-2 w-[100%] truncate relative font-bold'>{row.original.container.context}</div>
            }
          <div className='pl-2 w-[100%] truncate relative ' title={cell.value}>

            <input className='w-full h-full appearance-none bg-transparent group underline' defaultValue={cell.value} onBlur={(e)=>handleConceptChange(e)}></input>
            
            {false && <AiOutlineSave className='absolute h-5 w-5 text-black top-0 right-0 bg-main-bg'/>}
            </div>
          </>
        }
        if(cell.column.id === 'context'){
          return <>
            {(row.original.container && sortBy.length===0 && (data.length-containerCounter)===rows.length) && 
              <div className=' pl-2 w-[100%] truncate relative font-bold'>{row.original.container.context}</div>
            }
            <div className='pl-2 w-[100%] truncate relative ' title={cell.value}>{cell.value}</div>
          </>
        }
        if(cell.column.id === 'date'){
          return <>
          {(row.original.container && sortBy.length===0 && (data.length-containerCounter)===rows.length) && 
              <div className=' pl-2 w-[100%] truncate relative font-bold'>{moment(row.original.container.date).locale(language).format(language==='en' ?'MMM/DD/YYYY':'DD/MMM/YYYY').replace('.','')}</div>
            }
            <div className='pl-2 w-[100%] '>{moment(cell.value).locale(language).format(language==='en' ?'MMM/DD/YYYY':'DD/MMM/YYYY').replace('.','')}</div>  
          </>
        }
        if(cell.column.id === 'selection'){
          return  <>
          {(row.original.container && sortBy.length===0 && (data.length-containerCounter)===rows.length) && 
              <div className=' w-[100%] text-center invisible'>{'N/A'}</div>
            }
            <div className='w-[100%] text-center'>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
            </>
        }
        if(cell.column.id === 'bank'){
          return <>
            {(row.original.container && sortBy.length===0 && (data.length-containerCounter)===rows.length) && 
              <div className=' pl-2 text-left truncate font-bold'>{row.original.container.bank}</div>
            }
            <div className='pl-2 text-left truncate' title={cell.value}>{cell.value}</div>
            </>
        }
        if(cell.column.id === 'accountType'){
          return <>
            {(row.original.container && sortBy.length===0 && (data.length-containerCounter)===rows.length) && 
              <div className=' pl-2 w-[100%] font-bold'>{row.original.container.accountType}</div>
            }
            <div className='pl-2 w-[100%] text-left'>{cell.value}</div>
          </>
        }
        if(cell.column.id === 'notes' && userAccess && userAccess.access3){
          return <>
            {(row.original.container && sortBy.length===0 && (data.length-containerCounter)===rows.length) && 
              <div className=' pl-2 w-[100%] truncate focus:bg-black relative font-bold invisible'>{row.original.container.notes ? row.original.container.notes : 'N/A'}</div>
            }
            <div className='pl-2 w-[100%] truncate focus:bg-black relative '  title={cell.value}>
            <input className='w-full h-full appearance-none underline bg-transparent group' defaultValue={cell.value} onBlur={(e)=>handleNotesChange(e)}></input>
            
            {cell.value?.length>0 && <TbCopy onClick={()=>copyFunction(cell.value)} className='absolute h-5 w-5 text-black top-0 right-0 bg-main-bg cursor-pointer'/>}
            </div>
          </>
        }
        if(cell.column.id === 'idAccount'){
          return <>
            {(row.original.container && sortBy.length===0 && (data.length-containerCounter)===rows.length) && 
              <div className=' pl-2 w-[100%] text-left truncate font-bold'>{row.original.container.idAccount}</div>
            }
          <div className='pl-2 w-[100%] text-left truncate' title={cell.value}>{cell.value}</div>
          </>
        }
        if(cell.column.id === 'rsName'){
          return <>
            {(row.original.container && sortBy.length===0 && (data.length-containerCounter)===rows.length) && 
              <div className=' pl-2 w-[100%] text-left truncate font-bold'>{row.original.container.rsName}</div>
            }
            <div className='pl-2 w-[100%] text-left truncate' title={cell.value}>{cell.value}</div>
          </>
        }
        if(cell.column.id === 'extraText'){
          
          if(row.original.extraText){
            let json = JSON.parse(row.original.extraText)
            let aux = Object.keys(json) 
            let auxText = ``
            for(let i=0;i<aux.length;i++){
              auxText =auxText+ `${aux[i]} : ${json[aux[i]]} \n`
            } 
            return <>
            <div className='w-[100%] text-left truncate' title={'auxText'}>{auxText}</div>
            </>
          }else{
            return <>
            <div className=' w-[100%] text-left '>{cell.value}</div>
            </>    
          } 
          
        }
        
        for(const property in additionalColumns){
           if(cell.column.id===property && userAccess && userAccess.access3){
            return <>
            {(row.original.container && sortBy.length===0 && (data.length-containerCounter)===rows.length) && 
              <div className=' w-[100%] text-left font-bold invisible'>{'N/A'}</div>
            }
            <div className='pl-2 w-[100%] truncate focus:bg-black relative '  title={cell.value}>
              <input className='w-full h-full appearance-none underline bg-transparent group' defaultValue={cell.value} onBlur={(e)=>handleExtraTextChange(e,cell.column.id)}></input>
              
              {cell.value?.length>0 && <TbCopy onClick={()=>copyFunction(cell.value)} className='absolute h-5 w-5 text-black top-0 right-0 bg-main-bg cursor-pointer'/>}
              </div>
            </>
          }
        }
        return <>
            {(row.original.container && sortBy.length===0 && (data.length-containerCounter)===rows.length) && 
              <div className={row.original.container[`${cell.column.id}`]?' w-[100%] text-left pl-2 truncate font-bold':'invisible'}>{row.original.container[`${cell.column.id}`]?row.original.container[`${cell.column.id}`]:'N/A'}</div>
            }
            <div className=' w-[100%] text-left pl-2 truncate ' title={cell.value}>{cell.value}</div>
        </>
        
      }
      

      const filterTypes = React.useMemo(
        () => ({
          // Add a new fuzzyTextFilterFn filter type.
          fuzzyText: fuzzyTextFilterFn,
          // Or, override the default text filter to use
          // "startWith"
          text: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id]
              return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
          },
        }),
        []
      )

      
    
    const [columns,setColumns] = useState(React.useMemo(
        () => initialColumns,
        []
    ))

    useEffect(()=>{
        if(!rsActive || rsOptions.length==0){
            allColumns[0+hasEditionAcess].toggleHidden()
        }
        if(!bankActive){
            allColumns[1+hasEditionAcess].toggleHidden()
        }
        if(!accountActive){
            allColumns[2+hasEditionAcess].toggleHidden()
        }
        if(!dateActive){
            allColumns[3+hasEditionAcess].toggleHidden()
        }
        if(!contextActive){
            allColumns[4+hasEditionAcess].toggleHidden()
        }
        if(!inflowActive){
            allColumns[5+hasEditionAcess].toggleHidden()
        }
        if(!outflowActive){
            allColumns[6+hasEditionAcess].toggleHidden()
        }
        if(!currencyActive){
            allColumns[9+hasEditionAcess].toggleHidden()
        }
        if(!fxActive){
            allColumns[10+hasEditionAcess].toggleHidden()
        }
        if(!accountTypeActive){
            allColumns[11+hasEditionAcess].toggleHidden()
        }
        if(!clientSupplierActive){
            allColumns[12+hasEditionAcess].toggleHidden()
        }
        if(!notesActive){
            allColumns[13+hasEditionAcess].toggleHidden()
        }
        /*if(!additionalInfoActive){
            allColumns[14+hasEditionAcess].toggleHidden()
        }*/
    },[columns])
      
      const defaultColumn = React.useMemo(
        () => ({
          // Let's set up our default Filter UI
          Filter: DefaultColumnFilter,
          minWidth: 10, // minWidth is only used as a limit for resizing
        width: 100, // width is used for both the flex-basis and flex-grow
        maxWidth: 1800, // maxWidth is only used as a limit for resizing
        }),
        []
      )

      const scrollBarSize = React.useMemo(() => scrollbarWidth(), [])

      const sortees = React.useMemo(
        ()=> [
          {
            id:'index',
            desc:false
          }
        ],
        []
      )

      const sorteesTrue = React.useMemo(
        ()=> [
          {
            id:'index',
            desc:true
          }
        ],
        []
      )
    
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        totalColumnsWidth,
        prepareRow,
        setAllFilters,
        selectedFlatRows,
        allColumns,
        state,
        setSortBy,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex,pageSize, globalFilter, selectedRowIds, sortBy}
      } = useTable(
        {
          columns,
          data,
          autoResetPage: false,
          autoResetFilters: false,
          autoResetGlobalFilter: false,
          initialState: {
            pageSize:1000,
            hiddenColumns:[]
            //sortBy: sortees
          },
          defaultColumn, // Be sure to pass the defaultColumn option
          filterTypes,
          autoResetSortBy:false
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy,
        useRowSelect,
        useBlockLayout,
        useResizeColumns,
        hooks => {
          if(userAccess && userAccess.access3){
            hooks.visibleColumns.push(columns => [
              // Let's make a column for selection
              {
                id: 'selection',
                width:30,
                // The header can use the table's getToggleAllRowsSelectedProps method
                // to render a checkbox
                Header: ({ getToggleAllRowsSelectedProps }) => (
                  <div className=''>
                    <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                  </div>
                ),
                // The cell can use the individual row's getToggleRowSelectedProps method
                // to the render a checkbox
                Cell: ({ row }) => (
                  <div className=' justify-center flex text-center '>
                    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                  </div>
                ),
              },
              ...columns,
            ])
          }
          
        }
      )

      const getItemSize = React.useCallback((index) => {
        const row = rows[index];
        console.log(row, index)
        return row.original.containers ? 200:40
      },[prepareRow,data,sortBy,rows])

      const RenderRow = React.useCallback(
        (rows) => ({ index, style }) => {
          const row = rows[index];
          prepareRow(row);
          return (
            <div
            {...row.getRowProps({
              style
            })}
            >
              {row.cells.map((cell) => {
                return (
                  <div><td {...cell.getCellProps()} className='w-[100%] '>{createCellOfType(cell,row)}</td></div>
                );
              })}
            </div>
          );
        },
        [prepareRow,data,sortBy,rows]
      );

      useEffect(()=>{
        if(selectedFlatRows.length === 1){
          if(selectedFlatRows[0].original.id == transactionId){
          }else{
            setSelectedTransaction(selectedFlatRows[0])
            setTransactionId(selectedFlatRows[0].original.id)
            setTransactionQuantities(selectedFlatRows[0].original.inflow > 0 ? [{context:selectedFlatRows[0].original.context,value:[selectedFlatRows[0].original.inflow],category:{name:'Categoría'},subcategory:{name:'Subcategoría'}},]:[{value:[selectedFlatRows[0].original.outflow],category:{name:'Categoría'},subcategory:{name:'Subcategoría'}}])
            setTransactionsContainer(selectedFlatRows[0].original.idTopContainer)
          }
          let inflowTotal = {[`${Idioma.transacciones.equivalente[language]} ${mainCurrency}`]:null}
            let outflowTotal = {[`${Idioma.transacciones.equivalente[language]} ${mainCurrency}`]:null}
            for(let i=0;i<selectedFlatRows.length;i++){
              if(!inflowTotal[selectedFlatRows[i].original.currency]){
                inflowTotal[selectedFlatRows[i].original.currency] = 0
                setSelectedInflow(false)
              }else{
                setSelectedInflow(true)
              }
              if(!outflowTotal[selectedFlatRows[i].original.currency]){
                outflowTotal[selectedFlatRows[i].original.currency] = 0
                setSelectedOutflow(false)
              }else{
                setSelectedOutflow(true)
              }
              inflowTotal[selectedFlatRows[i].original.currency] = inflowTotal[selectedFlatRows[i].original.currency] + selectedFlatRows[i].original.inflow
              outflowTotal[selectedFlatRows[i].original.currency] = outflowTotal[selectedFlatRows[i].original.currency] + selectedFlatRows[i].original.outflow
              inflowTotal[[`${Idioma.transacciones.equivalente[language]} ${mainCurrency}`]] = inflowTotal[[`${Idioma.transacciones.equivalente[language]} ${mainCurrency}`]] + selectedFlatRows[i].original.inflow * (selectedFlatRows[i].original.exchange||1)
              outflowTotal[[`${Idioma.transacciones.equivalente[language]} ${mainCurrency}`]] = outflowTotal[[`${Idioma.transacciones.equivalente[language]} ${mainCurrency}`]] + selectedFlatRows[i].original.outflow * (selectedFlatRows[i].original.exchange||1)
            }
            setTotals({inflow:inflowTotal,outflow:outflowTotal})
        }else if(selectedFlatRows.length === 0){
          setTotals({})
          setForexValue('')
          setSelectedTransaction([])
          setTransactionId(null)
          setTransactionQuantities([])
          setTransactionsContainer(null)
          setSelectedInflow(false)
          setSelectedOutflow(false)
        }
        else{
            setSelectedTransaction([])
            setTransactionId(null)
            setTransactionQuantities([])
            setTransactionsContainer(null)
            let inflowTotal = {[`${Idioma.transacciones.equivalente[language]} ${mainCurrency}`]:null}
            let outflowTotal = {[`${Idioma.transacciones.equivalente[language]} ${mainCurrency}`]:null}
            for(let i=0;i<selectedFlatRows.length;i++){
              if(!inflowTotal[selectedFlatRows[i].original.currency]){
                inflowTotal[selectedFlatRows[i].original.currency] = 0
              }
              if(!outflowTotal[selectedFlatRows[i].original.currency]){
                outflowTotal[selectedFlatRows[i].original.currency] = 0
              }
              inflowTotal[selectedFlatRows[i].original.currency] = inflowTotal[selectedFlatRows[i].original.currency] + selectedFlatRows[i].original.inflow
              outflowTotal[selectedFlatRows[i].original.currency] = outflowTotal[selectedFlatRows[i].original.currency] + selectedFlatRows[i].original.outflow
              inflowTotal[[`${Idioma.transacciones.equivalente[language]} ${mainCurrency}`]] = inflowTotal[[`${Idioma.transacciones.equivalente[language]} ${mainCurrency}`]] + selectedFlatRows[i].original.inflow * (selectedFlatRows[i].original.exchange||1)
              outflowTotal[[`${Idioma.transacciones.equivalente[language]} ${mainCurrency}`]] = outflowTotal[[`${Idioma.transacciones.equivalente[language]} ${mainCurrency}`]] + selectedFlatRows[i].original.outflow * (selectedFlatRows[i].original.exchange||1)
              if(inflowTotal[selectedFlatRows[i].original.currency]){
                setSelectedInflow(true)
              }else{
                setSelectedInflow(false)
              }
              if(outflowTotal[selectedFlatRows[i].original.currency]){
                setSelectedOutflow(true)
              }else{
                setSelectedOutflow(false)
              }
            }
            setTotals({inflow:inflowTotal,outflow:outflowTotal})
        }
        //console.log('selectedFlatRows',selectedFlatRows)
        
      },[selectedFlatRows])

      const closeRecycleBin = () => {
        setDeletedData([])
        setOpenDeletedTransactions(false)
      }

      const openRecycleBin = () => {
      setOpenDeletedTransactions(true)
      setRecycleBinLoading(true)
      let headers = new Headers()
      const bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);
      headers.append("Content-Type",'application/json')
      let body = {
        "clientID": idClient,
        "currency":mainCurrency,
        logsInfo: {
          origin:'Transactions',
            sessionID:sessionID
        }
      }
      if(idRS){
        body.idRS = idRS
      }
      fetch(`${urlBase}/getDeletedTransactions`,{
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body)
      })
      .then((response) => response.json())
      .then((aux) => {
        let newData = []
        for(let i = 0;i<aux.length;i++){
          newData[i] = {
            'bank':aux[i].bank,
            'date':aux[i].date_d.slice(0,10),
            'id':aux[i].idTransaction,
            'context':aux[i].context,
            'reference':aux[i].references_c,
            'inflow':aux[i].inflow ,
            'outflow':aux[i].outflow,
            'category':categoriesDict[categoriesDict[aux[i].idCategory]?.idFather]?.name || '',
            'idCategory':categoriesDict[aux[i].idCategory]?.idFather || '',
            'subcategory':categoriesDict[aux[i].idCategory]?.name || '',
            'idSubCategory':aux[i].idCategory,
            'exchange':aux[i].exchange || aux[i].defaultFX,
            "updated":null,
            'accountType':aux[i].accountType==='D'?Idioma.transacciones.debito[language]:aux[i].accountType==='C' ? Idioma.transacciones.credito[language] :'NA',
            'signature':aux[i].transactionSignature,
            'notes': aux[i].notes,
            'idAccount':accountsDict[aux[i].idAccount],
            'idClientSupplier':aux[i].idClientSupplier,
            'clientSupplier':clientSupplierDict[aux[i].idClientSupplier]?.name,
						'currency': aux[i].currency,
            'rsName': aux[i].rsAlias,
            'deleted': aux[i].deleted.slice(0,10)
          }
        }
        setDeletedData(newData)
        setRecycleBinLoading(false)
      });
      }

      const restoreFromRecycleBin = async () => {
        let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')

        let allTransactionSignatures = []
        for(let i=0;i<selectedDeletedTransactions.length; i++){
          allTransactionSignatures[i]=selectedDeletedTransactions[i].original.signature
        }
        let body = {
          "clientID": idClient,
          "transactions": allTransactionSignatures,
          logsInfo: {
            origin:'Transactions',
      sessionID:sessionID
          }
      }
      if(idRS){
        body.idRS = idRS
      }

        fetch(`${urlBase}/restoreFromRecycleBin`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
        setErrorMessage(aux.unaffected.length >= 1 ? Idioma.transacciones.transNoActualizadas[language] : '')
        setErrorSign(aux.unaffected.length >= 1)
        setShowTransactionsError(aux.unaffected.length >= 1)
        setUnaffectedTransactions(aux.unaffected)
        for(let i=0;i<aux.restored.length;i++){
          setDeletedData(prev => prev.filter(datum => datum.signature!==aux.restored[i]))
        }
        setOpenDeleteTransactionsWarning(false)
        getTransactions('')
        })
        .catch(error=>{
          alert('Ha ocurrido un error')
        })
    }

    const emptyRecycleBin = () => {
      let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')  
        let body = {
          "clientID": idClient,
          logsInfo: {
            origin:'Transactions',
            sessionID:sessionID
          }
        }
        if(idRS){
          body.idRS = idRS
        }
        fetch(`${urlBase}/emptyRecycleBin`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
          setDeletedData([])
          setOpenDeletedTransactions(false)
        })
        .catch(error=>{
          alert('Ha ocurrido un error')
        })
    }

    const removeFromRecycleBin = () => {
      let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')

        let allTransactionSignatures = []
        for(let i=0;i<selectedDeletedTransactions.length; i++){
          allTransactionSignatures[i]=selectedDeletedTransactions[i].original.signature
        }

        let body = {
          "clientID": idClient,
          "transactions": allTransactionSignatures,
          logsInfo: {
            origin:'Transactions',
      sessionID:sessionID
          }
      }
      if(idRS){
        body.idRS = idRS
      }
        fetch(`${urlBase}/deleteTransaction`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
        setShowTransactionsError(aux.unaffected.length >= 1)
        setErrorMessage(aux.unaffected.length >= 1 ? Idioma.transacciones.transNoActualizadas[language] : '')
        setErrorSign(aux.unaffected.length >= 1)

        setUnaffectedTransactions(aux.unaffected)
        for(let i=0;i<aux.deleted.length;i++){
          setDeletedData(prev => prev.filter(datum => datum.signature!==aux.deleted[i]))
        }
        setOpenDeleteTransactionsWarning(false)
        getTransactions('')
        })
        .catch(error=>{
          alert('Ha ocurrido un error')
        })
    }

    const sendToMLModel = () => {
      setOpenSendToMLWarning(false)

      setIsAutoClassing(true)
      setAmountAutoClassing(selectedFlatRows.length)
      setShowTransactionsUpdated(true)
      let requestData = []
      let requestIndexes = []
      let transactionIDs = []
      for(let i=0;i<selectedFlatRows.length;i++){
        transactionIDs[i] = selectedFlatRows[i].original.id
        requestIndexes[i] = i
        requestData[i] = [selectedFlatRows[i].original.date,selectedFlatRows[i].original.bank,selectedFlatRows[i].original.context,selectedFlatRows[i].original.inflow,selectedFlatRows[i].original.outflow]
      }
      /**/ 
      let headers = new Headers()
      const bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);
      headers.append("Content-Type",'application/json')

      let body = {
        "clientID": idClient,
        "indexes": requestIndexes,
        "data":requestData,
        "MLUrl":MLInfo.endpoint,
        "MLKey":MLInfo.key,
        "transactions":transactionIDs,
        "accuracy":MLInfo.accuracy,
        "env": msalConfig.auth.clientId === 'a05b95e4-a7bb-415d-a5fa-47f567e5d94c' ? 'DEV' : 'BETA',
        logsInfo: {
          origin:'Transactions',
      sessionID:sessionID
        }
      }
      if(idRS){
        body.idRS = idRS
      }
      
      fetch(`${urlBase}/autoclass`,{
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body)
      })
      .then((response) => response.json())
      .then((aux) => {
        //console.log('ml',aux)
        if(aux.updates.length>0){
          //console.log('entra aqui success')
          newToast('success', Idioma.transacciones.transActualizadas[language], aux.updates.length)
        }
        if(aux.unaffected.length >0){
          newToast('warning', Idioma.transacciones.autoError[language], aux.unaffected.length)
        }
        setIsAutoClassing(false)
        setAmountAutoClassing(0)
        //setUpdatedTransactions(aux.updates.length)
        //setShowTransactionsError(aux.unaffected.length >= 1)
        //setErrorMessage(aux.unaffected.length >= 1 ? Idioma.transacciones.autoError[language] : '')
        //setErrorSign(aux.unaffected.length >= 1)
        //setUnaffectedTransactions(aux.unaffected)
        //setShowTransactionsUpdated(aux.updates.length>=1)
        setData(prev => prev.filter(datum => {
          for(let i=0;i<aux.updates.length;i++){
            if(datum.id===aux.updates[i]){
              return false
            }
          }
          return true
        }))
        
        })
      .catch(error=>{
        alert('Ha ocurrido un error')
      })/**/
    }

    const cleanTransactions = () => {
      setOpenCleanTransactionsWarning(false)

        let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        
        let allTransactionIds = []
        for(let i=0;i<selectedFlatRows.length; i++){
          allTransactionIds[i]=selectedFlatRows[i].original.id
        }

        let body = {
          "clientID": idClient,
          "transactions": allTransactionIds,
          "clear": true,
          logsInfo: {
            origin:'Transactions',
      sessionID:sessionID
          }
      }
      if(idRS){
        body.idRS = idRS
      }
        fetch(`${urlBase}/updateTransaction`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
          //console.log(toastList)
            if(aux.updates.length>=1){
              newToast('success', Idioma.transacciones.transActualizadas[language], aux.updates.length)
            }
            if(aux.unaffected.length >= 1){
              newToast('error', Idioma.transacciones.transNoActualizadas[language], aux.unaffected.length)
            }
            //setShowTransactionsUpdated(aux.updates.length>=1)
            //setUpdatedTransactions(aux.updates.length)
            //setErrorSign(aux.unaffected.length >= 1)
            //setErrorMessage(aux.unaffected.length >= 1 ? Idioma.transacciones.transNoActualizadas[language] : '')
            //setShowTransactionsError(aux.unaffected.length >= 1)
            //setUnaffectedTransactions(aux.unaffected)
            getTransactions('')
            
            })
            .catch(error=>{
                alert('Ha ocurrido un error')

        })
    }

    const contextRSIDText = () => {
      let value = ''
      if(Object.keys(rsDict).length>0){
        for(let i=0;i<contextRSID.length;i++){
          value = value + rsDict[contextRSID[i]].name + '\n'
        }
      }
      return value
    }

    const contextCategoriesIdsFilter = (titleOrCount) => {
      let value = ''
      let count = 0
      if(Object.keys(categoriesDict).length>0){
        for(let i=0;i<contextCategoriesId.length;i++){
          if(categoriesDict[contextCategoriesId[i]]){
            count++
            value = value + categoriesDict[contextCategoriesId[i]].name + '\n'
          }
          
        }
      }
      if(titleOrCount){
        return count
      }
      return value
    }

    

    const resetFilters = () => {
      setAllFilters([])
      setData([...data])
    }

    const newToast = (type, title, message) => {
      let toastProperties = []
      toastProperties = {
        'id': toastList.length+1,
        'alertType': type,
        'alertTitle': title,
        'alertMessage': message
      }
      setToastList((prev)=>[...prev, toastProperties])
    }
      
    
      // We don't want to render all of the rows for this example, so cap
      // it for this use case
      const firstPageRows = rows.slice(0, 100)
    const activeClass='bg-light-purple-3 text-white rounded-button 2xl:h-button-3 h-full text-button-2 font-bold px-[13px]'
    const unactiveClass='bg-gray-6 text-neutral-3 rounded-button 2xl:h-button-3 h-full text-button-2 font-normal px-[13px]'
      const fade='bg-gradient-to-r from-md-blue to-md-light-blue text-white text-xl font-extrabold rounded' 
      const classified = (element) => {
        if(element.subcategory.idSubCategory){
          return true
        }else{
          return false
        }
      }
      
      const breakTransactionDisabled = selectedTransaction.original ? ((selectedTransaction.original.inflow>0 ? Math.abs(selectedTransaction.original.inflow-getTransactionQuantityTotal())<0.01 : Math.abs(selectedTransaction.original.outflow-getTransactionQuantityTotal())<0.01 ) && transactionQuantities.every(classified) ) : false
        
     

      const breakTransactionStyle = breakTransactionDisabled ? '':'bg-gray-6 ' 

      if(openPreferencesModal || openRulesModal || openTransactionModal || openAddTransaction || openCleanTransactionsWarning || openDeleteTransactionsWarning || openDeletedTransactions || openFiltersModal || openSendToMLWarning || openSendToRecycleBinWarning){
          document.body.classList.add('overflow-y-hidden')
      }else{
          document.body.classList.remove('overflow-y-hidden')
      }

      const infoDict = {
        inflow: Idioma.iconI.abono[language],
        outflow: Idioma.iconI.cargo[language],
        category: Idioma.iconI.categoria[language],
        subcategory: Idioma.iconI.subcategoria[language],
        exchange: Idioma.iconI.fx[language],

      }

    const CreateInputHandler = ({maxValue,value,valueSetter,index}) => {
      const [isEditing,setIsEditing] = useState(false)
      const inputRef = useRef(null)

      const toggleEditing = () => {
        setIsEditing((prev)=>!prev)
      }

      const handleValueChange = (index,e) => {
        valueSetter(index,e)
        toggleEditing()
      }

      useEffect(()=>{
        if(isEditing){
            inputRef.current.focus()
        }
        },[isEditing])

      return (
      <div className='col-start-1 col-end-4 overflow-clip 3xl:w-48'>
        <label className='text-[10px]'>{Idioma.transacciones.desgloseTransaccion.cantidad[language]}</label>
        {isEditing ? 
        
        <input className='bg-gray-6 w-full rounded-button border-1 px-2 py-2 mb-2 ' ref={inputRef} max={maxValue} step='.01' defaultValue={value} onBlur={(e)=>handleValueChange(index,e)} type='number' placeholder={Idioma.transacciones.desgloseTransaccion.monto[language]}></input>
        :
        <div className='bg-gray-6 w-full rounded-button border-1 px-2 py-2 mb-2' onClick={()=>toggleEditing()}>{value ? (parseFloat(value)).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          currencyDisplay: 'code'}).slice(4) : '--'}</div>
      }
      </div> 
      )
    }

    const ToggleExtraCol = ({extraCol,index}) => {
        const toggleFunc = () => {
            
            setExtraColumnsActive({...extraColumnsActive,[extraCol.accessor]:!extraColumnsActive[extraCol.accessor]})
        }
        return <div   className='text-p5-2xl 2xl:text-p5-2xl flex justify-between items-center'>
            <p>{extraCol.Header}</p>
            <div>
                <label for={`default-toggle-${extraCol.accessor}`} class={"flex relative items-center cursor-pointer "}>
                    <input type="checkbox" {...allColumns[14+index+hasEditionAcess].getToggleHiddenProps()} checked={extraColumnsActive[extraCol.accessor]} onClick={()=>toggleFunc()}  id={`default-toggle-${extraCol.accessor}`} class="sr-only peer"/>
                    <div class={Styles.toggleSwitch}></div>
                </label>
            </div>
        </div>
    }
    return (
        <div className='pl-[5%] 2xl:mt-6 mr-10 relative text-[12px] 2xl:text-[16px]'>
          <MessageHandler toastList={toastList} setToastList={setToastList}/>

          {openPreferencesModal && 
            <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
              <div onClick={()=>setOpenPreferencesModal(false)} className={Styles.modalBackground}>
              </div>
              <div className={Styles.defaultModal + ' overflow-y-scroll'}>
              <div className='2xl:w-[400px] w-[400px] h-[60vh] relative'>
                <p className={Styles.modalTitle}><SettingsLogo fill='#ffffff'/> <span className='ml-[15px] 2xl:ml-[15px]'>{Idioma.transacciones.modales.favoritos[language]}</span></p>
                

                <div className='flex flex-col gap-y-[15px] pb-[40px]'>
                {rsOptions.length>0 && <div className='text-p5-2xl 2xl:text-p5-2xl flex justify-between items-center'>
                    <p>{Idioma.transacciones.configuracion.empresa[language]}</p>
                    <div>
                      <label for="default-toggle-RS" class={"flex relative items-center cursor-pointer "}>
                          <input type="checkbox" {...allColumns[0+hasEditionAcess].getToggleHiddenProps()} checked={rsActive} onClick={()=>{setRSActive((prev) => !prev)}}  id="default-toggle-RS" class="sr-only peer"/>
                          <div class={Styles.toggleSwitch}></div>
                      </label>
                    </div>
                  </div>}
                  <div className='text-p5-2xl 2xl:text-p5-2xl flex justify-between items-center'>
                    <p>{Idioma.transacciones.configuracion.banco[language]}</p>
                    <div>
                      <label for="default-toggle-Banco" class={"flex relative items-center cursor-pointer "}>
                          <input type="checkbox" {...allColumns[1+hasEditionAcess].getToggleHiddenProps()} checked={bankActive} onClick={()=>{setBankActive((prev) => !prev)}}  id="default-toggle-Banco" class="sr-only peer"/>
                          <div class={Styles.toggleSwitch}></div>
                      </label>
                    </div>
                  </div>
                  <div className='text-p5-2xl 2xl:text-p5-2xl flex justify-between items-center'>
                    <p>{Idioma.transacciones.configuracion.cuentaBanco[language]}</p>
                    <div>
                      <label for="default-toggle-Cuenta" class={"flex relative items-center cursor-pointer "}>
                          <input type="checkbox" {...allColumns[2+hasEditionAcess].getToggleHiddenProps()} checked={accountActive} onClick={()=>{setAccountActive((prev) => !prev)}}  id="default-toggle-Cuenta" class="sr-only peer"/>
                          <div class={Styles.toggleSwitch}></div>
                      </label>
                    </div>
                  </div>
                  <div className='text-p5-2xl 2xl:text-p5-2xl flex justify-between items-center'>
                    <p>{Idioma.transacciones.configuracion.fecha[language]}</p>
                    <div>
                      <label for="default-toggle-Fecha" class={"flex relative items-center cursor-pointer "}>
                          <input type="checkbox" {...allColumns[3+hasEditionAcess].getToggleHiddenProps()} checked={dateActive} onClick={()=>{setDateActive((prev) => !prev)}}  id="default-toggle-Fecha" class="sr-only peer"/>
                          <div class={Styles.toggleSwitch}></div>
                      </label>
                    </div>
                  </div>
                  <div className='text-p5-2xl 2xl:text-p5-2xl flex justify-between items-center'>
                    <p>{Idioma.transacciones.configuracion.concepto[language]}</p>
                    <div>
                      <label for="default-toggle-Concepto" class={"flex relative items-center cursor-pointer "}>
                          <input type="checkbox" {...allColumns[4+hasEditionAcess].getToggleHiddenProps()} checked={contextActive} onClick={()=>{setContextActive((prev) => !prev)}}  id="default-toggle-Concepto" class="sr-only peer"/>
                          <div class={Styles.toggleSwitch}></div>
                      </label>
                    </div>
                  </div>
                  <div className='text-p5-2xl 2xl:text-p5-2xl flex justify-between items-center'>
                    <p>{Idioma.transacciones.configuracion.abono[language]}</p>
                    <div>
                      <label for="default-toggle-Abono" class={"flex relative items-center cursor-pointer "}>
                          <input type="checkbox" {...allColumns[5+hasEditionAcess].getToggleHiddenProps()} checked={inflowActive} onClick={()=>{setInflowActive((prev) => !prev)}}  id="default-toggle-Abono" class="sr-only peer"/>
                          <div class={Styles.toggleSwitch}></div>
                      </label>
                    </div>
                  </div>
                  <div className='text-p5-2xl 2xl:text-p5-2xl flex justify-between items-center'>
                    <p>{Idioma.transacciones.configuracion.cargo[language]}</p>
                    <div>
                      <label for="default-toggle-Cargo" class={"flex relative items-center cursor-pointer "}>
                          <input type="checkbox" {...allColumns[6+hasEditionAcess].getToggleHiddenProps()} checked={outflowActive} onClick={()=>{setOutflowActive((prev) => !prev)}}  id="default-toggle-Cargo" class="sr-only peer"/>
                          <div class={Styles.toggleSwitch}></div>
                      </label>
                    </div>
                  </div>

                  <div className='text-p5-2xl 2xl:text-p5-2xl flex justify-between items-center'>
                    <p>{Idioma.transacciones.configuracion.moneda[language]}</p>
                    <div>
                      <label for="default-toggle-Moneda" class={"flex relative items-center cursor-pointer "}>
                          <input type="checkbox" {...allColumns[9+hasEditionAcess].getToggleHiddenProps()} checked={currencyActive} onClick={()=>{setCurrencyActive((prev) => !prev)}}  id="default-toggle-Moneda" class="sr-only peer"/>
                          <div class={Styles.toggleSwitch}></div>
                      </label>
                    </div>
                  </div>

                  <div className='text-p5-2xl 2xl:text-p5-2xl flex justify-between items-center'>
                    <p>{Idioma.transacciones.configuracion.fx[language]}</p>
                    <div>
                      <label for="default-toggle-FX" class={"flex relative items-center cursor-pointer "}>
                          <input type="checkbox" {...allColumns[10+hasEditionAcess].getToggleHiddenProps()} checked={fxActive} onClick={()=>{setFXActive((prev) => !prev)}}  id="default-toggle-FX" class="sr-only peer"/>
                          <div class={Styles.toggleSwitch}></div>
                      </label>
                    </div>
                  </div>

                  <div className='text-p5-2xl 2xl:text-p5-2xl flex justify-between items-center'>
                    <p>{Idioma.transacciones.configuracion.creditoDebito[language]}</p>
                    <div>
                      <label for="default-toggle-CD" class={"flex relative items-center cursor-pointer "}>
                          <input type="checkbox" {...allColumns[11+hasEditionAcess].getToggleHiddenProps()} checked={accountTypeActive} onClick={()=>{setAccountTypeActive((prev) => !prev)}}  id="default-toggle-CD" class="sr-only peer"/>
                          <div class={Styles.toggleSwitch}></div>
                      </label>
                    </div>
                  </div>
                  <div className='text-p5-2xl 2xl:text-p5-2xl flex justify-between items-center'>
                    <p>{Idioma.transacciones.configuracion.clientesProvedores[language]}</p>
                    <div>
                      <label for="default-toggle-CP" class={"flex relative items-center cursor-pointer "}>
                          <input type="checkbox" {...allColumns[12+hasEditionAcess].getToggleHiddenProps()} checked={clientSupplierActive} onClick={()=>{setClientSupplierActive((prev) => !prev)}}  id="default-toggle-CP" class="sr-only peer"/>
                          <div class={Styles.toggleSwitch}></div>
                      </label>
                    </div>
                  </div>
                  <div className='text-p5-2xl 2xl:text-p5-2xl flex justify-between items-center'>
                    <p>{Idioma.transacciones.configuracion.notas[language]}</p>
                    <div>
                      <label for="default-toggle-Notas" class={"flex relative items-center cursor-pointer "}>
                          <input type="checkbox" {...allColumns[13+hasEditionAcess].getToggleHiddenProps()} checked={notesActive} onClick={()=>{setNotesActive((prev) => !prev)}}  id="default-toggle-Notas" class="sr-only peer"/>
                          <div class={Styles.toggleSwitch}></div>
                      </label>
                    </div>
                  </div>
                  {/*<div className='text-p5-2xl 2xl:text-p5-2xl flex justify-between items-center'>
                    <p>{Idioma.transacciones.configuracion.informacionAdicional[language]}</p>
                    <div>
                      <label for="default-toggle-info-adicional" class={"flex relative items-center cursor-pointer "}>
                          <input type="checkbox" {...allColumns[14+hasEditionAcess].getToggleHiddenProps()} checked={additionalInfoActive} onClick={()=>{setAdditionalInfoActive((prev) => !prev)}}  id="default-toggle-info-adicional" class="sr-only peer"/>
                          <div class={Styles.toggleSwitch}></div>
                      </label>
                    </div>
                  </div>*/}
                  {columns.slice(14).map((extraCol,index)=>(
                    <ToggleExtraCol extraCol={extraCol} index={index}/>
                  ))}
                </div>
                {/*<p className='text-xl text-md-gray mt-1 font-bold text-left mb-2'>Filas</p>
                <select
                  className='w-[100%] py-2 pl-4 bg-md-table-bg rounded-md border-1 shadow-sm mb-4 text-md-gray'
                  defaultValue={'Numero de transacciones visibles'}
                  value={pageSize}
                  onChange={e => {
                    setPageSize(Number(e.target.value))
                  }}
                >
                  {[10, 50, 100, 500].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                  </select>
                <div className='border-b-1 mt-1 w-100% border-md-divider'></div>*/}
                {/*<div className='grid grid-cols-4 text-[12px] 2xl:text-[16px] items-center 2xl:gap-y-4 gap-y-2 2xl:mt-8 mt-3'>
                  {rsOptions.length>0 && 
                  <>
                  <p className='font-bold text-left mr-8 col-start-1'>Empresa</p>
                  <Dropdown
                          options={rsOptions}
                          selected={rsID}
                          setSelected={setRSID}
                          className={'w-full col-span-3 bg-gray-6 rounded-md'}
                      /></>}
                  <p className='font-bold text-left mr-8 col-start-1'>Banco</p>
                  <Dropdown
                          options={[{'name': Idioma.transacciones.filtros.todos[language]},...BankOptions]}
                          selected={bank}
                          setSelected={handleBankChange}
                          className={'w-full col-span-3 bg-gray-6 rounded-md'}
                      />
                  <p className='font-bold text-left mr-8 col-start-1'>Cuenta</p>
                  <Dropdown
                      options={accounts}
                      selected={account}
                      setSelected={setContextAccount}
                      className={' w-full col-span-3 bg-gray-6 rounded-md '}
                  />
                  
                  <p className='font-bold text-left mr-8 col-start-1'>Archivo</p>
                  <FileDropdown //aqui va dropdown de files
                      options={filesData}
                      selected={file}
                      setSelected={handleFileChange}
                      className={'  w-full col-span-3 bg-gray-6 rounded-md'}
                  />
                  <p className='font-bold text-left mr-8 col-start-1'>Fecha</p>
                  
                  <div className='col-span-3'>
                      <DatePicker
                      onChange={(update) => {
                        handleDateRangeChange(update);
                      }}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      fixedHeight
                      className='bg-gray-6 w-full rounded-[7px] border-1 pl-6 py-2 '
                      showYearDropdown
                      dropdownMode="select"
                      isClearable
                      dateFormat={Idioma.formatoFecha[language]}
                    />
                    </div>
                  <button disabled={isLoading} className={`${isLoading ? 'bg-gray-6 text-md-gray' : 'bg-[#9191F4] text-white'} rounded-md text-center  px-2 mt-1 py-2 font-bold col-span-4`} onClick={()=>getTransactions('')}>Aplicar</button>
                </div>*/}
                <span onClick={()=>setOpenPreferencesModal(false)} className={Styles.modalXmark}><XMark/></span>
                </div>
                {/*<div className='flex justify-center items-center mt-4'>
                  <button className='bg-md-blue text-md-white py-2 px-4 mr-10 w-1/3 rounded-full' onClick={()=>{setOpenPreferencesModal(false)}}>Guardar cambios</button>
                  <button className='bg-md-table-bg text-md-gray border-1 shadow-sm py-2 px-4 ml-10 w-1/3 rounded-full' onClick={()=>{setOpenPreferencesModal(false)}}>Cancelar</button>
                    </div>*/}
              </div>
            </div>}
            {openFiltersModal && 
            <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
              <div onClick={()=>setOpenFiltersModal(false)} className={Styles.modalBackground}>
              </div>
              <div className={Styles.defaultModal}>
              <div className='w-full h-full relative'>
              <p className={Styles.modalTitle}><FiltersLogo fill='#ffffff'/> <span className='ml-[15px] 2xl:ml-[15px]'>{Idioma.transacciones.modales.filtros[language]}</span></p>
                <div className='grid grid-cols-4 text-[12px] 2xl:text-p8-2xl items-center 2xl:gap-y-[15px] gap-y-2 mt-[40px] w-[400px]'>
                  {rsOptions.length>0 && !idRS && 
                  <>
                  <p className='font-bold text-left col-start-1 text-p5-2xl'>{Idioma.transacciones.configuracion.empresa[language]}</p>
                  <div className='col-span-3'>
                  <DinamicDropdown
                          options={[{'name': Idioma.transacciones.filtros.todos[language]},...rsOptions]}
                          selected={rsID}
                          setSelected={setRSID}
                          className={'w-full py-2 pl-3 pr-10'}
                      />
                  </div>
                  </>}
                  <p className='font-bold text-left col-start-1 text-p5-2xl'>{Idioma.transacciones.configuracion.banco[language]}</p>
                  <div className='col-span-3'>
                    <DinamicDropdown
                            options={[{'name': Idioma.transacciones.filtros.todos[language]},...BankOptions]}
                            selected={bank}
                            setSelected={handleBankChange}
                            className={'w-full py-2 pl-3 pr-10'}
                        />
                  </div>
                  <p className='font-bold text-left col-start-1 text-p5-2xl'>{Idioma.transacciones.configuracion.cuentaDrop[language]}</p>
                  <div className='col-span-3'>
                  <DinamicDropdown
                      options={accounts}
                      selected={account}
                      setSelected={setContextAccount}
                      className={' w-full py-2 pl-3 pr-10'}
                  />

                  </div>
                  <div className='w-full border-b-1 border-neutral-1 col-start-1 col-span-4'></div>
                  <p className='font-bold text-left col-start-1 text-p5-2xl'>{Idioma.transacciones.configuracion.archivoDrop[language]}</p>
                  <div className='col-span-3'>
                  <DinamicDropdown //aqui va dropdown de files
                      options={[{'name': Idioma.transacciones.filtros.todos[language]},...filesData]}
                      selected={file}
                      setSelected={handleFileChange}
                      className={'  w-full py-2 pl-3 pr-10'}
                      percentage={true}
                  /></div>
                  <div className='w-full border-b-1 border-neutral-1 col-start-1 col-span-4'></div>

                  <p className='font-bold text-left col-start-1 text-p5-2xl'>{Idioma.transacciones.configuracion.fecha[language]}</p>
                  
                  <div className='col-span-3'>
                      <DatePicker
                      onChange={(update) => {
                        handleDateRangeChange(update);
                      }}
                      locale={language}
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText='00/00/0000'
                      selectsRange
                      fixedHeight
                      className='bg-gray-6 placeholder:text-neutral-3 w-full rounded-button pl-6 py-2'
                      showYearDropdown
                      dropdownMode="select"
                      isClearable
                      dateFormat={Idioma.formatoFecha[language]}
                    />
                  </div>
                  <div className='col-span-4 flex justify-end gap-x-[15px] mt-[25px]'>
                    <button disabled={isLoading} className={isLoading ? Styles.saveButtonDisabled : Styles.saveButton} onClick={()=>getTransactions('')}>
                        {Idioma.transacciones.configuracion.aplicarBoton[language]}
                    </button>
                    <button onClick={()=>setOpenFiltersModal(false)} className={Styles.cancelButton}>
                        {Idioma.transacciones.anadirTransaccionModal.cancelar[language]}
                    </button>
                  </div>
                </div>
                <span  onClick={()=>setOpenFiltersModal(false)} className={Styles.modalXmark}><XMark/></span>
                </div>
                {/*<div className='flex justify-center items-center mt-4'>
                  <button className='bg-md-blue text-md-white py-2 px-4 mr-10 w-1/3 rounded-full' onClick={()=>{setOpenPreferencesModal(false)}}>Guardar cambios</button>
                  <button className='bg-md-table-bg text-md-gray border-1 shadow-sm py-2 px-4 ml-10 w-1/3 rounded-full' onClick={()=>{setOpenPreferencesModal(false)}}>Cancelar</button>
                    </div>*/}
              </div>
            </div>}
          {openRulesModal && 
            <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
              <div onClick={()=>setOpenRulesModal(false)} className=' fixed top-0 right-0 left-[15%] bottom-0 w-screen z-10 h-screen opacity-50 bg-md-gray'>
              </div>
              <div className='bg-white z-10 absolute top-[calc(40%)] left-[35%] px-[14px] mt-10 py-[28px] max-w-[600px] min-w-[600px] rounded-main -translate-x-[50%] -translate-y-[50%]'>
                <p className='text-xl text-md-gray font-bold text-left mb-2'>Generar Reglas</p>
                <p className=' w-100%  text-md-black-text pb-3 mb-2 invisible' >Lorem ipsum dolor sit amet consectetur adipisicing</p>
                <div className='border-b-1 w-100% border-md-divider mb-2'></div>
                <p className='text-xl text-md-gray font-bold text-left mb-1'>Nombre de la regla</p>
                <input className='bg-main-bg w-[100%] rounded-button border-1 pl-2 py-2 mb-2' type='text' value={ruleName} onChange={(e)=>{setRuleName(e.target.value)}} placeholder='Nombre de regla'></input>
                <p className='text-xl text-md-gray font-bold text-left mb-2'>Se aplica a las transacciones que son...</p>
                <div className='flex justify-between items-center mb-2'>
                    <Dropdown
                      options={[{name:'Cargo'},{name:'Abono'}]}
                      selected={ruleType}
                      setSelected={handleRuleTypeChange}
                      className=' bg-main-bg border-1  w-[100%] text-md-gray rounded-button '
                    />
                    <p className='text-md-gray mx-4'>En</p>
                    <Dropdown
                      options={BankOptions}
                      selected={bankRule}
                      setSelected={handleBankRuleChange}
                      className=' bg-main-bg border-1  w-[100%] text-md-gray rounded-button '
                    />
                </div>
                <p className='text-md-gray mb-2'>Cuando</p>
                <div className='flex mb-2'>
                  <Dropdown
                      options={[{name:'Concepto'},{name:'Monto'}]}
                      selected={ruleType2}
                      setSelected={handleRuleType2Change}
                      className=' bg-main-bg border-1  w-[100%] mr-1 text-md-gray rounded-button '
                  />
                  <Dropdown
                    options={[{name:'Contiene'},{name:'No Contiene'},{name:'Es exacto'}]}
                    selected={ruleType3}
                    setSelected={handleRuleType3Change}
                    className=' bg-main-bg border-1  w-[100%] ml-1 text-md-gray rounded-button '
                  />
                </div>
                <input className='bg-main-bg w-[100%] rounded-button border-1 pl-2 py-2  mb-2' type='text' value={ruleText} onChange={(e)=>{setRuleText(e.target.value)}} placeholder='Introducir Regla'></input>
                <div className='items-center flex mt-2 mb-4'>
                  <button className='rounded-full border-1  text-md-gray py-2 px-4 bg-main-bg'>
                    Probar regla
                  </button>
                  <p className=' w-100%  text-md-gray ml-2 invisible'>Lorem ipsum dolor sit amet consectetur adipisicing</p>
                </div>
                <p className='text-xl text-md-gray font-bold text-left mb-2'>Luego asignar</p>
                <div className='flex mb-2'>
                  <div className='w-[100%] mr-1'>
                    <p className='text-md-black-text'>Categoría</p>
                    <Dropdown
                      selected={ruleCategory}
                      options={categories}
                      setSelected={handleRuleCategoryChange}
                      className=' bg-main-bg border-1  rounded-button text-md-gray '
                    />
                  </div>
                  <div className='w-[100%] ml-1'>
                  <p className='text-md-black-text'>Subcategoría</p>
                    <Dropdown
                      selected={ruleSubcategory}
                      options={ruleCategory.index>=0 ?  subcategories[ruleCategory.index] : []}
                      setSelected={handleRuleSubcategoryChange}
                      className=' bg-main-bg border-1  rounded-button text-md-gray '
                    />
                  </div>
                </div>
                <div className='border-b-1 w-100% border-md-divider mb-2'></div>
                <div className='flex justify-between mx-8 mt-8'>
                  <button className='rounded-full bg-md-blue text-md-white py-2 px-4'>
                    Guardar cambios
                  </button>
                  <button className='rounded-full bg-main-bg border-1  text-md-gray py-2 px-4' onClick={()=>{setOpenRulesModal(false)}}>
                    Cancelar
                  </button>
                  <button onClick={()=>{navigate('/rules')}} className='rounded-full bg-main-bg border-1  text-md-gray py-2 px-4'>
                    Ver todas las reglas
                  </button>
                  


                </div>
                
              </div>
            </div>}
            {openTransactionModal && 
            <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
              <div onClick={()=>setOpenTransactionModal(false)} className={Styles.modalBackground}>
              </div>
              <div className={Styles.defaultModal}>
                <div className='w-[950px] max-h-[90vh] relative'></div>
                <div className='flex justify-between relative'>
                <span onClick={()=>setOpenTransactionModal(false)} className={Styles.modalXmark}><XMark/></span>

                  <p className='text-[20px] 2xl:text-[24px] flex font-bold text-left mb-2'><ScissorsLogo/><span className='ml-8'>{Idioma.transacciones.desgloseTransaccion.tituloModal[language]}</span></p>
                

                </div>
                <div className='flex w-full'>
                  <div className='w-1/3 inline-flex items-center mr-2'>
                    
                    <div className=' grid grid-cols-2 mb-2 gap-y-2 gap-x-4'>
                    <p className='text-xl text-[12px] 2xl:text-h10-2xl font-bold col-span-2 text-left mb-2'>{Idioma.transacciones.desgloseTransaccion.informacion[language]}</p>
                      <p className='text-[10px] 2xl:text-p8-2xl font-bold '>{Idioma.transacciones.configuracion.banco[language]}</p>
                      <p className='text-[10px] 2xl:text-p8-2xl '>{ selectedTransaction.original.bank || 'placeholder'}</p>
                      <p className='text-[10px] 2xl:text-p8-2xl font-bold '>{Idioma.transacciones.configuracion.fecha[language]}</p>
                      <p className='text-[10px] 2xl:text-p8-2xl '>{ selectedTransaction.original.date || 'placeholder'}</p>
                      <p className='text-[10px] 2xl:text-p8-2xl font-bold '>{Idioma.transacciones.configuracion.concepto[language]}</p>
                      <p className='text-[10px] 2xl:text-p8-2xl  '>{ selectedTransaction.original.context || 'placeholder'}</p>
                      <p className='text-[10px] 2xl:text-p8-2xl font-bold '>{ selectedTransaction.original.inflow>0 ? Idioma.transacciones.configuracion.abono[language] : Idioma.transacciones.configuracion.cargo[language]}</p>


            
                      <p className='text-[10px] 2xl:text-p8-2xl tabular-nums'>{ selectedTransaction.original.inflow>0 ?  selectedTransaction.original.inflow.toLocaleString('en-us', {
            style: "currency",
            currency: "USD",
            currencyDisplay: 'code'}).slice(4) : `${numberFormat===0?'(':'-'} ${selectedTransaction.original.outflow.toLocaleString('en-us', {
              style: "currency",
              currency: "USD",
              currencyDisplay: 'code'}).slice(4)}${numberFormat===0?')':''}`}</p>
                    </div>

                  </div>
                  <div className='min-h-[100%] mt-8 border-l-1 border-black'></div>
                  <div className='w-2/3 pt-8 pl-8'>
                     
                    <div className='flex items-center justify-between'>
                      <p className='text-[12px] 2xl:text-[16px] font-bold text-left mb-2'>{Idioma.transacciones.desgloseTransaccion.dividir[language]}</p><p className='text mb-2'> <span className='font-bold'>{Idioma.transacciones.desgloseTransaccion.porAsignar[language]}:</span> { selectedTransaction.original.inflow>0 ?  (selectedTransaction.original.inflow-getTransactionQuantityTotal()).toFixed(2) :  (selectedTransaction.original.outflow-getTransactionQuantityTotal()).toFixed(2)}</p>
                    </div>
                    <div className='max-h-[40vh] min-h-[40vh] overflow-scroll text-[12px] 2xl:text-[16px]'>
                      
                      {transactionQuantities.map((datum,index) => {
                        return(
                        <div className='grid grid-cols-8 gap-x-4 items-center'>
                          {/*<div className='col-start-1 col-end-4'>
                            <label className='text-[10px]'>{Idioma.transacciones.desgloseTransaccion.cantidad[language]}</label>
                            <input className='bg-gray-6 w-[100%] rounded-button border-1 px-2 py-2 mb-2' max={selectedTransaction.original.inflow>0 ? selectedTransaction.original.inflow :selectedTransaction.original.outflow} step='.01' value={datum.value} onChange={updateFieldChangedValue(index)} type='number' placeholder={Idioma.transacciones.desgloseTransaccion.monto[language]}></input>
                          </div>*/}
                          <CreateInputHandler key={index} maxValue={selectedTransaction.original.inflow>0 ? selectedTransaction.original.inflow :selectedTransaction.original.outflow} value={datum.value} valueSetter={updateFieldChangedValue} index={index}/>
                        <div className='col-start-4 col-end-8'>
                          <label className='text-[10px]'>{Idioma.transacciones.configuracion.subcategoria[language]}</label>
                          <select key={index} className=' bg-gray-6 appearance-none pl-4 py-2 h-full rounded-button col-span-3 w-full mb-2' value={datum.subcategory.idSubCategory} id="multiTransaction-id" onChange={(event)=>handleTransactionSubcategoryChange(index,event)}>
                            <option disabled selected>{Idioma.transacciones.modales.seleccionarSubcategoria[language]}</option>
                            {selectedTransaction.original.inflow>0 ? inflowCategories.map(category=>{
                              return (
                                <optgroup label={category.name}>
                                  {subcategories[category.index].map(subcategory => {
                                    return (
                                      <option value={subcategory.idSubCategory} label={subcategory.name}>{category.name}</option>
                                    )
                                  })}
                                </optgroup>
                              )
                            }) :
                            outflowCategories.map(category=>{
                              return (
                                <optgroup label={category.name}>
                                  {subcategories[category.index].map(subcategory => {
                                    return (
                                      <option value={subcategory.idSubCategory} label={subcategory.name}>{category.name}</option>
                                    )
                                  })}
                                </optgroup>
                              )
                            }) }
                          </select>
                        </div>
                        <div className=' row-span-2 col-end-9 flex-col flex items-center justify-center h-full'>
                          <label className='text-[10px] invisible'>{Idioma.transacciones.desgloseTransaccion.borrar[language]}</label>
                          <span onClick={deleteTransactionQuantity(index)} className='cursor-pointer font-bold '><ThrashCanLogo/></span>
                        </div>
                        <div className='col-start-1 col-end-8'>
                          <label className='text-[10px]'>{Idioma.transacciones.configuracion.concepto[language]}</label>
                          <input className='bg-gray-6 w-[100%] rounded-button border-1 px-2 py-2 mb-4' value={datum.context} onChange={updateFieldChangedText(index)} type='text' placeholder={Idioma.transacciones.configuracion.concepto[language]}></input>
                        </div>
                        </div>
                        )
                      })}
                    </div>
                    <button className='flex align-middle items-center mb-2' onClick={()=>{addTransactionQuantities(selectedTransaction.original)}}>
                      <PlusCircleIcon className='h-6 w-6 mr-2'/>
                      {Idioma.transacciones.desgloseTransaccion.agregarMas[language]}
                    </button>
                  </div>

                </div>
              
              <div className='flex justify-end items-center mt-[40px] gap-x-[15px]'>
                  <button className={breakTransactionDisabled && !splitTransactionLoading ?  Styles.saveButton : Styles.saveButtonDisabled} disabled={!breakTransactionDisabled  || splitTransactionLoading} onClick={()=>{;setTopContainer()}}>{splitTransactionLoading ? Idioma.transacciones.desgloseTransaccion.cargando[language]:Idioma.transacciones.desgloseTransaccion.guardar[language]}</button>
                  <button className={Styles.cancelButton} onClick={()=>{setOpenTransactionModal(false)}}>{Idioma.transacciones.desgloseTransaccion.cancelar[language]}</button>
                </div>
              </div>
            </div>}
            {openDeleteTransactionsWarning && 
            <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1001] h-screen'>
              <div onClick={()=>setOpenDeleteTransactionsWarning(false)} className="fixed top-0 right-0 left-[0%] bottom-0 w-screen z-10 h-screen opacity-[.74] backdrop-brightness-[.15] bg-gray-6 backdrop-blur-[44px]">
              </div>
              <div className='bg-white z-10 absolute top-[calc(40%)] left-[35%] px-[14px] mt-10 py-[28px] w-[500px] rounded-main -translate-x-[50%] -translate-y-[50%]'>
                <p className=' text-h7-2xl text-center'>{Idioma.transacciones.modalPapelera.eliminarPapeleraTitulo[language]}</p>
                <p className=' text-p5-2xl text-center'>{Idioma.transacciones.modalPapelera.eliminarPapeleraTexto1[language]}{selectedDeletedTransactions.length} {Idioma.transacciones.modalPapelera.eliminarPapeleraTexto2[language]}</p>
                <div className='flex justify-center items-center mt-[20px]'>
                  <button className='py-2 px-4 ml-2 rounded-button underline text-b4-2xl font-bold' onClick={()=>{setOpenDeleteTransactionsWarning(false)}}>{Idioma.transacciones.desgloseTransaccion.cancelar[language]}</button>
                  <button className={`py-2 px-4 rounded-button text-white bg-purple-1 text-b4-2xl font-bold`} onClick={()=>removeFromRecycleBin()}>{Idioma.subirTransacciones.eliminar[language]}</button>
                </div>
              </div>
            </div>}
            {openAddTransaction && 
            <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
              <div onClick={()=>handleCloseAddT()} className={Styles.modalBackground}>
              </div>
              <div className="bg-white p-[40px] z-10 absolute top-[40%] mt-20  left-[42.5%] shadow-medium-elevation rounded-main -translate-x-[50%] -translate-y-[50%]">
              <div className='2xl:w-[27rem] w-[24rem] h-full relative'>
                <p className='text-[20px] 2xl:text-h6-2xl font-bold text-left flex items-center overflow-visible'><AddSingleTransactionLogo fill='#ffffff' className='overflow-visible'/> <span className='ml-[15px]'>{Idioma.transacciones.anadirTransaccionModal.tituloModal[language]}</span></p>
                <div className='grid grid-cols-3 mt-8 2xl:gap-y-4 gap-y-2 items-center'>
                  <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.anadirTransaccionModal.banco[language]}</p>
                  <Dropdown
                      options={BankOptions}
                      selected={bankAddT}
                      setSelected={handleBankAddTChange}
                      className={'w-full col-span-2 text-[10px] 2xl:text-p8-2xl bg-white rounded-button border-1 border-neutral-1'}
                  />
                  <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.anadirTransaccionModal.cuenta[language]}</p>
                  <Dropdown
                      options={accountsAddT}
                      selected={accountAddT}
                      setSelected={setAccountAddT}
                      className={' w-full col-span-2 text-[10px] 2xl:text-p8-2xl bg-white rounded-button border-1 border-neutral-1 '}
                  />
                  <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.anadirTransaccionModal.fecha[language]}</p>
                  <div className='col-span-2'>
                    <DatePicker 
                    className={'h-8 pl-6 text-[10px] 2xl:text-p8-2xl bg-white rounded-button border-1 border-neutral-1 mb-2'} 
                    selected={dateAddT} 
                    locale={language}
                    onChange={(date) => handleDateAddTChange(date)}
                    minDate={minDateAddT}
                    fixedHeight
                    dateFormat={Idioma.formatoFecha[language]}
                    />
                  </div>
                  <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.anadirTransaccionModal.concepto[language]}</p>
                  <input className='col-span-2 pl-6 bg-white border-1 border-neutral-1 w-[100%] text-[10px] 2xl:text-p8-2xl rounded-button px-2 py-2' value={conceptAddT} onChange={(e)=>setConceptAddT(e.target.value)} placeholder={Idioma.transacciones.anadirTransaccionModal.concepto[language]}></input>
                  <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.anadirTransaccionModal.abono[language]}</p>
                  <input className='col-span-2 pl-6 bg-white border-1 border-neutral-1 w-[100%] text-[10px] 2xl:text-p8-2xl rounded-button px-2 py-2' type='number' value={inflowAddT} onChange={(e)=>handleValueAddTChange(e.target.value,'')} placeholder='--'></input>
                  <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.anadirTransaccionModal.cargo[language]}</p>
                  <input className='col-span-2 pl-6 bg-white border-1 border-neutral-1 w-[100%] text-[10px] 2xl:text-p8-2xl rounded-button px-2 py-2' type='number' value={outflowAddT} onChange={(e)=>handleValueAddTChange('',e.target.value)} placeholder='--'></input>
                  <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.anadirTransaccionModal.subCategoria[language]}</p>
                  <div className='col-span-2 relative flex'>
                    <select className=' pl-6 bg-white border-1 border-neutral-1 w-[100%] text-[10px] 2xl:text-p8-2xl rounded-button px-2 py-2 appearance-none' id="multiTransaction-id" value={categoryAddT} onChange={event=>setCategoryAddT(event.target.value)}>
                      <option disabled selected>{Idioma.transacciones.modales.seleccionarSubcategoria[language]}</option>
                      {categories.map(category=>{
                        return (
                          <optgroup label={category.name}>
                            {subcategories[category.index].map(subcategory => {
                              return (
                                <option value={subcategory.idSubCategory}>{subcategory.name}</option>
                              )
                            })}
                          </optgroup>
                        )
                      })}
                    </select><span className='absolute flex items-center right-2 h-full pointer-events-none'><TrianguloAbajoLogo/></span>
                  </div>
                </div>
                <div className='flex justify-end items-center mt-8'>
                  <button className={` font-bold text-b3-2xl py-2 px-4 w-fit rounded-button ${AddTransactionDisabled || addTransactionLoading ? 'bg-gray-6 ':'bg-light-purple-3'}`} disabled={AddTransactionDisabled  || addTransactionLoading} onClick={()=>{AddSingleTransaction()}}>{addTransactionLoading ?  Idioma.transacciones.desgloseTransaccion.cargando[language]:Idioma.transacciones.desgloseTransaccion.guardar[language]}</button>
                  <button className='bg-secondary font-bold text-b3-2xl py-2 px-4 w-fit ml-2 rounded-button text-white' onClick={()=>{handleCloseAddT()}}>{Idioma.transacciones.anadirTransaccionModal.cancelar[language]}</button>
                </div>
                <span onClick={()=>handleCloseAddT()} className={Styles.modalXmark}><XMark/></span>
                </div>
              </div>
            </div>}
            {openDeletedTransactions && 
              <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
              <div onClick={()=>closeRecycleBin()} className={Styles.modalBackground}>
              </div>
              <div className={Styles.defaultModal}>
                <div className='2xl:w-[1300px] w-[900px] relative'>
                <span onClick={()=>closeRecycleBin()} className={Styles.modalXmark}><XMark/></span>

                  <div className='flex items-center mb-[15px]'>
                    <span className='w-[45px]'>
                     <WarningLogo/>
                    </span>
                    <p className='text-[30px] 2xl:text-h5-2xl font-bold'>{Idioma.transacciones.modalPapelera.transaccionesEliminadas[language]}</p>
                  </div>
                  <div className='flex ml-[45px] mb-[20px]'>
                    <p>{Idioma.transacciones.modalPapelera.avisoTransaccionesPapelera[language]}</p>
                    <button disabled={deletedData.length===0} className={`  ${deletedData.length===0 ? ' ':'text-light-purple-3 underline'}`} onClick={()=>emptyRecycleBin()}>{Idioma.transacciones.modalPapelera.botonVaciarPapelera[language]}</button>
                  </div>
                  <DeletedTransactionsTable columns={columns} data={deletedData} recycleBinLoading={recycleBinLoading} setSelectedDeletedTransactions={setSelectedDeletedTransactions}/>
                  
                  <div className='flex items-center justify-between mt-[15px]' >
                    <button onClick={()=>restoreFromRecycleBin()} className='' disabled={selectedDeletedTransactions.length==0} title={Idioma.transacciones.modalPapelera.restaurar[language]}>
                      <RestoreRBinLogo fill={selectedDeletedTransactions.length==0 && '#ADADAD'}/>
                    </button>
                    <p className={` text-neutral-3 text-center ${selectedDeletedTransactions.length>0 ? '':'invisible'}`}>{selectedDeletedTransactions.length} {Idioma.transacciones.transaccionesSeleccionadas[language]}</p>
                    <button onClick={()=>setOpenDeleteTransactionsWarning(true)} disabled={selectedDeletedTransactions.length==0} className={`${selectedDeletedTransactions.length==0 ? 'bg-gray-6 text-neutral-3':'bg-purple-1 text-white'}  font-bold py-2 px-4 ml-2 rounded-button `}>{Idioma.transacciones.modalPapelera.eliminarDefinitivo[language]}</button>
                  </div>
                  </div>
              </div>
              
            </div>}
            {openSendToRecycleBinWarning && 
            <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1001] h-screen'>
              <div onClick={()=>setOpenSendToRecycleBinWarning(false)} className="fixed top-0 right-0 left-[0%] bottom-0 w-screen z-10 h-screen opacity-[.74] backdrop-brightness-[.15] bg-gray-6 backdrop-blur-[44px]">
              </div>
              <div className='bg-white z-10 absolute top-[calc(40%)] left-[35%] px-[14px] mt-10 py-[28px] w-[500px] rounded-main -translate-x-[50%] -translate-y-[50%]'>
                <p className=' text-h7-2xl text-center'>{Idioma.transacciones.modalPapelera.eliminarPapeleraTitulo[language]}</p>
                <p className=' text-p5-2xl text-center'>{Idioma.transacciones.modales.mandarAPapeleraTexto1[language]}{selectedFlatRows.length} {Idioma.transacciones.modales.mandarAPapeleraTexto2[language]}</p>
                <div className='flex justify-center items-center mt-[20px]'>
                  <button className='py-2 px-4 ml-2 rounded-button underline text-b4-2xl font-bold' onClick={()=>{setOpenSendToRecycleBinWarning(false)}}>{Idioma.transacciones.desgloseTransaccion.cancelar[language]}</button>
                  <button className={`py-2 px-4 rounded-button text-white bg-purple-1 text-b4-2xl font-bold`} onClick={()=>multiDeleteTransaction()}>{Idioma.subirTransacciones.eliminar[language]}</button>
                </div>
              </div>
            </div>}
            {openSendToMLWarning && 
            <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1001] h-screen'>
              <div onClick={()=>setOpenSendToMLWarning(false)} className="fixed top-0 right-0 left-[0%] bottom-0 w-screen z-10 h-screen opacity-[.74] backdrop-brightness-[.15] bg-gray-6 backdrop-blur-[44px]">
              </div>
              <div className='bg-white z-10 absolute top-[calc(40%)] left-[35%] px-[14px] mt-10 py-[28px] w-[500px] rounded-main -translate-x-[50%] -translate-y-[50%]'>
                <p className=' text-h7-2xl text-center'>{Idioma.transacciones.modales.mandarAMLTitulo[language]}</p>
                <p className=' text-p5-2xl text-center'>{Idioma.transacciones.modales.mandarAMLTexto1[language]}{selectedFlatRows.length} {Idioma.transacciones.modales.mandarAMLTexto2[language]}</p>
                <div className='flex justify-center items-center mt-[20px]'>
                  <button className='py-2 px-4 ml-2 rounded-button underline text-b4-2xl font-bold' onClick={()=>{setOpenSendToMLWarning(false)}}>{Idioma.transacciones.desgloseTransaccion.cancelar[language]}</button>
                  <button className={`py-2 px-4 rounded-button text-white bg-purple-1 text-b4-2xl font-bold`} onClick={()=>sendToMLModel()}>{Idioma.transacciones.modales.continuar[language]}</button>
                </div>
              </div>
            </div>}
            {openCleanTransactionsWarning && 
            <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1001] h-screen'>
              <div onClick={()=>setOpenCleanTransactionsWarning(false)} className="fixed top-0 right-0 left-[0%] bottom-0 w-screen z-10 h-screen opacity-[.74] backdrop-brightness-[.15] bg-gray-6 backdrop-blur-[44px]">
              </div>
              <div className='bg-white z-10 absolute top-[calc(40%)] left-[35%] px-[14px] mt-10 py-[28px] w-[500px] rounded-main -translate-x-[50%] -translate-y-[50%]'>
                <p className=' text-h7-2xl text-center'>{Idioma.transacciones.modales.limpiarTransaccionTitulo[language]}</p>
                <p className=' text-p5-2xl text-center'>{Idioma.transacciones.modales.limpiarTransaccionTexto1[language]}{selectedFlatRows.length} {Idioma.transacciones.modales.limpiarTransaccionTexto2[language]}</p>
                <div className='flex justify-center items-center mt-[20px]'>
                  <button className='py-2 px-4 ml-2 rounded-button underline text-b4-2xl font-bold' onClick={()=>{setOpenCleanTransactionsWarning(false)}}>{Idioma.transacciones.desgloseTransaccion.cancelar[language]}</button>
                  <button className={`py-2 px-4 rounded-button text-white bg-purple-1 text-b4-2xl font-bold`} onClick={()=>cleanTransactions()}>{Idioma.transacciones.modales.continuar[language]}</button>
                </div>
              </div>
            </div>}
            
          {/*<a href='/subcategories'>Subcategories settings</a>*/}
          <div className='h-[100%]  overflow-y-scroll min-h-[70vh] overflow-x-hidden'>
            <div className=' flex justify-between '>
              
              <div className='flex justify-start mb-4 gap-x-4 h-full'>
                {userAccess && userAccess.access3 && <div onClick={()=>navigate("/upload")} className='bg-light-purple-3 overflow-visible cursor-pointer items-center font-bold rounded-button flex justify-center px-[15px] 2xl:py-4 py-2 text-[18px] 2xl:text-button-1 h-button-1 '>
                  <span className='2xl:scale-100'><TransaccionesLogoNotAnimated fill="#000"/></span>
                  <p className='pl-[15px]'>{Idioma.transacciones.subirTransacciones[language]}</p>
                </div>}
                
              </div>
              <div className=' mr-2 mb-1 justify-end'>
                <div className='flex justify-end'>
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                /></div>
                <div className='my-auto 2xl:h-button-3 h-button-3 overflow-visible'>
                {categories.length>0 && subcategories.length>0 && Object.keys(selectedRowIds).length>= 1 && !(selectedInflow>0 && selectedOutflow>0) &&
                      <div className='overflow-visible py-2'>
                        <div className='flex justify-end relative items-center'>
                          <button className={` p-4 py-2 h-button-3 mr-[15px] rounded-button text-button-3 w-fit ${selectedSubcategory ? 'bg-light-purple-3 cursor-pointer text-white font-bold' :'bg-gray-6 text-neutral-3 cursor-default'}`} disabled={isMultiUpdateLoading} onClick={()=>multiTransactionUpdate(selectedSubcategory)}>{isMultiUpdateLoading ? <Loading height='5' width='5'/> : Idioma.transacciones.aplicarClasificacion[language] }</button>
                          <MultiTransactionLogo fill={"#000"}/>
                          <div className={'absolute flex items-center right-2 pointer-events-none'}>
                            <TrianguloAbajoLogo fill={'#B4B4B4'}/>
                          </div>
                          <select className=' py-2 px-2 rounded-button appearance-none font-bold w-[270px] option:bg-gray-6 text-h9-2xl' id="multiTransaction-id" value={selectedSubcategory} onChange={event=>setSelectedSubcategory(event.target.value)}>
                            <option className='hover:bg-gray-6 appearance-none text-p9-2xl' disabled selected>{Idioma.transacciones.clasificacionMultiple[language]}</option>
                            {categories.map(category=>{
                              return (
                                <optgroup className='text-h12-2xl' label={category.name}>
                                  {subcategories[category.index].map(subcategory => {
                                    return (
                                      <option className='hover:bg-gray-6 appearance-none text-p9-2xl' value={subcategory.idSubCategory}>{subcategory.name}</option>
                                    )
                                  })}
                                </optgroup>
                              )
                            })}
                          </select>
                        </div>
                      
                      </div>
                      }
                      </div>
              </div>
            </div>
            
              {/*<div className='ml-1 min-w-[400px] max-w-[400px]'>
                <FileDropdown //aqui va dropdown de files
                  options={filesData}
                  selected={file}
                  setSelected={handleFileChange}
                  className={' mb-2 border-gray-300 border-b-2'}
                />
              </div>*/}
                    
                    <div className='flex justify-between items-center px-2 2xl:py-2 py-0 bg-gray-6 w-full overflow-x-auto thin-scrollbar rounded-main text-[14px] 2xl:text-[18px] mt-[6px]'>
                     <div className='flex items-center gap-x-[12px]'>
                     {userAccess && userAccess.access3 && <>
                      <button onClick={()=>setOpenAddTransaction(true)} title={Idioma.transacciones.anadirTransaccionModal.tituloModal[language]} className='hover:bg-white p-[4px] rounded-button flex justify-center items-center scale-100'>
                        <AddSingleTransactionLogo/>
                      </button>
                     
                      {/*<button onClick={()=>setOpenRulesModal(true)} title='Generar reglas' className='hover:bg-white px-2  rounded-[7px]'>
                        <PencilSquareIcon className='h-8 w-8 text-md-blue'/>
              </button>*/}
                      
                      {/*
                      <button onClick={()=>setFiltersActive(prev => !prev)} title='Activar/Desactivar filtros' className={filtersActive ? 'hover:bg-white px-2 rounded-[7px] text-secondary flex justify-between items-center':'hover:bg-white px-2 rounded-[7px] flex justify-between items-center'}>
                        <PreferencesLogo/>
                        <span className='pl-2'>{filtersActive ? 'Desactivar filtros':'Activar filtros'}</span>
                      </button>*/}
                      <button onClick={()=>setOpenTransactionModal(true)} title={transactionId && !transactionsContainer ? Idioma.transacciones.desgloseTransaccion.tituloModal[language] :Idioma.transacciones.desgloseTransaccion.seleccioneTransaccion[language]} disabled={!transactionId || transactionsContainer} className={`${transactionId && !transactionsContainer ? 'hover:bg-white':''} p-[4px] rounded-button flex justify-between items-center`}>
                        <ScissorsLogo className='' fill={transactionId && !transactionsContainer ? '#000': '#adadad'} />
                      </button>
                      <button onClick={()=>setOpenSendToRecycleBinWarning(true)} disabled={Object.keys(selectedRowIds).length== 0} title={Idioma.transacciones.eliminarTransaccion[language]} className={`flex items-center justify-center rounded-button p-[4px] ${Object.keys(selectedRowIds).length>= 1 ? 'hover:bg-white' : ''}`}>
                        <ThrashCanLogo fill={Object.keys(selectedRowIds).length>= 1 ? '#000':'#adadad'}/>
                      </button>
                      </>}
                      <button onClick={()=>setOpenPreferencesModal(true)} title={Idioma.transacciones.editarPreferencias[language]} className={`hover:bg-white p-[4px] rounded-button flex justify-between items-center`}>
                        <SettingsLogo/>
                      </button>
                      <button onClick={()=>setOpenFiltersModal(true)} title={Idioma.transacciones.editarFiltros[language]} className={`hover:bg-white p-[4px] rounded-button flex justify-between items-center ${isLoading===false && data.length===0 && 'animate-pulsebg'}`}>
                        <FiltersLogo/>
                      </button>
                      {userAccess && userAccess.access3 && MLInfo.endpoint && showState==='nonClassified' && <button onClick={()=>setOpenSendToMLWarning(true)} disabled={Object.keys(selectedRowIds).length== 0} title={Idioma.transacciones.autoclasificar[language]} className={` flex items-center rounded-button p-[4px] ${Object.keys(selectedRowIds).length>= 1 ? 'hover:bg-white' : ''}`}>
                        {isAutoClassing ? <Loading/>:<AutoclassLogo fill={Object.keys(selectedRowIds).length== 0 ? "#adadad":"#000"}/>}
                      </button>}
                      {userAccess && userAccess.access3 && <button onClick={()=>setOpenCleanTransactionsWarning(true)} disabled={Object.keys(selectedRowIds).length== 0} title={Idioma.transacciones.limpiarTransacciones[language]} className={` flex items-center rounded-button p-[4px] ${Object.keys(selectedRowIds).length>= 1 ? 'hover:bg-white' : ''}`}>
                        <CleaningLogo fill={Object.keys(selectedRowIds).length== 0 ? "#adadad":"#000"}/>
                      </button>}
                      <DynamicExcelExport excelData={selectedFlatRows.length === 0 ? rows:selectedFlatRows} fileName={'Export'} title={Idioma.transacciones.exportarExcel[language]} disabled={rows.length==0} language={language} columns={columns}/>
                      
                     </div>
                     
                      {/*<input value={forexValue} onChange={e=>setForexValue(e.target.value)}  type='number' className=' appearance-none border-1 border-md-gray rounded-[7px] mr-1 w-64' placeholder={Object.keys(selectedRowIds).length== 0 ? 'selecciona una transaccion': 'introduce la tasa de cambio'} disabled={Object.keys(selectedRowIds).length== 0}></input>
                      {<button onClick={()=>multiTransactionUpdateForex(forexValue)} className={forexValue ? '  font-bold rounded-full text-white text-xl bg-md-blue px-2': ' invisible font-bold rounded-full text-white text-xl bg-md-blue px-2 '} disabled={!forexValue}>Establecer</button>}         */ }      
                      
                      <div className='bg-gray-6 overflow-visible flex justify-end rounded-main h-8 px-4  right-2'>
                        
                      
                        <div className='flex items-center justify-center gap-x-[14px]'>
                            <button className={showState=='nonClassified'?activeClass:unactiveClass} onClick={()=>handleShowStateChange('nonClassified')}>{Idioma.transacciones.showState.nonClassified[language]}</button>
                            <button className={showState=='classified'?activeClass:unactiveClass} onClick={()=>handleShowStateChange('classified')}>{Idioma.transacciones.showState.classified[language]}</button>
                            <button className={showState=='auto'? activeClass:unactiveClass} onClick={()=>handleShowStateChange('auto')}>{Idioma.transacciones.showState.auto[language]}</button>
                            <button className={showState=='divided'? activeClass:unactiveClass} onClick={()=>handleShowStateChange('divided')}>{Idioma.transacciones.showState.divided[language]}</button>
                            {userAccess.access7 && <button className={showState=='synced'? activeClass:unactiveClass} onClick={()=>handleShowStateChange('synced')}>{Idioma.transacciones.showState.synced[language]}</button>}
                            <button className={showState=='all'? activeClass:unactiveClass} onClick={()=>handleShowStateChange('all')}>{Idioma.transacciones.showState.all[language]}</button>
                        </div>
                      </div>
                      
                    </div>
                    

                    {/*<div className='flex'>
                    <p className='mr-2 p-3'>Clasificadas/No Clasificadas</p>
                    <div className='p-3'>
                        <label for="default-toggle2" class="inline-flex relative items-center cursor-pointer">
                            <input type="checkbox" checked={active} onClick={()=>handleActiveChange()}  id="default-toggle2" class="sr-only peer"/>
                            <div class="w-11 h-6 rounded-full peer bg-md-gray peer-checked:bg-gradient-to-r from-md-blue to-md-light-blue peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-main-bg dark:after:bg-md-white  after:rounded-full after:h-5 after:w-5 after:transition-all "></div>
                        </label>
                    </div>
                    </div>*/}
                    <div className='flex mt-2'>
                      {contextSubcategoryId>0 && 
                      <div className='items-center flex px-2 rounded-button bg-white  2xl:text-p8-2xl text-[12px] border-1 border-neutral-1 w-fit'>
                        <p className='text-clip'>{Idioma.transacciones.filtradoPor[language]}: <span className='font-bold'>{categoriesDict[contextSubcategoryId]?.name}</span></p> 
                        <AiOutlineCloseCircle onClick={()=>setContextSubcategoryId()} className='text-neutral-1 pl-1 h-6 w-6 cursor-pointer'/>
                      </div>}
                      {contextCategoriesId.length>0 && 
                        <div title={contextCategoriesIdsFilter()} className='items-center flex px-2 ml-1 rounded-button bg-white  2xl:text-p8-2xl text-[12px] border-1 border-neutral-1 w-fit '>
                          <p className='text-clip w-48'>{Idioma.transacciones.filtradoPor[language]} <span className='font-bold'>{contextCategoriesIdsFilter(1)}</span> {Idioma.transacciones.categorias[language]}</p> 
                          <AiOutlineCloseCircle onClick={()=>setContextCategoriesIds([])} className='text-neutral-1 pl-1 h-6 w-6 cursor-pointer'/>
                        </div>
                      }
                      {contextRSID.length>0 && 
                        <div title={contextRSIDText()} className='items-center flex px-2 ml-1 rounded-button bg-white  2xl:text-p8-2xl text-[12px] border-1 border-neutral-1 w-fit '>
                          <p className='text-clip w-48'>{Idioma.transacciones.filtradoPor[language]} <span className='font-bold'>{contextRSID.length}</span> {Idioma.transacciones.empresas[language]}</p> 
                          <AiOutlineCloseCircle onClick={()=>setContextRSID([])} className='text-neutral-1 pl-1 h-6 w-6 cursor-pointer'/>
                        </div>
                      }
                    </div>
                <div className=' block max-w-[100%] overflow-x-scroll overflow-y-scroll thin-scrollbar'>
                <table {...getTableProps()} className=' dark:bg-secondary-dark-bg dark:text-white rounded-main w-fit border-spacing-0  '>
                  <thead className=' text-center sticky top-0 z-30 h-10 rounded-main text-[15px] '>
                  {/*<tr>
                      <th
                        colSpan={visibleColumns.length-4}
                        style={{
                          textAlign: 'left',
                        }}
                        className='bg-main-bg dark:bg-main-dark-bg '
                      >
                        <GlobalFilter
                          preGlobalFilteredRows={preGlobalFilteredRows}
                          globalFilter={state.globalFilter}
                          setGlobalFilter={setGlobalFilter}
                        />
                      </th>
                      <th className='bg-md-table-bg dark:bg-main-dark-bg flex'>
                        <Dropdown
                          options={BankOptions}
                          selected={bank}
                          setSelected={handleBankChange}
                          className={'w-[300px]'}
                      />
                      <Dropdown
                          options={accounts}
                          selected={account}
                          setSelected={setAccount}
                      />
                      </th>
                      <th className=' text-right bg-main-bg dark:bg-main-dark-bg' colSpan={3}>
                      <p className='ml-2'>Clasificadas/No Clasificadas</p>
                      <label for="default-toggle2" class="inline-flex relative items-center cursor-pointer">
                            <input type="checkbox" checked={active} onClick={()=>handleActiveChange()}  id="default-toggle2" class="sr-only peer"/>
                            <div class="w-11 h-6 rounded-full peer bg-md-gray peer-checked:bg-gradient-to-r from-md-blue to-md-light-blue peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-main-bg dark:after:bg-md-white  after:rounded-full after:h-5 after:w-5 after:transition-all "></div>
                        </label>
                      </th>
                      <th className='bg-main-bg dark:bg-main-dark-bg'>
                        
                      </th>
                      
                    </tr>*/}
                      {/*
                        {headerGroups.map(headerGroup => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                              <th {...column.getHeaderProps()} className='bg-main-bg dark:bg-main-dark-bg center'>
                                
                                <div>{column.canFilter ? column.render('Filter') : null}</div>
                              </th>
                              
                            ))}
                            <th className='bg-md-table-bg dark:bg-main-dark-bg'> <Link to='/subcategories'><Cog8ToothIcon className='h-8 w-8 text-md-gray dark:text-md-white'/></Link> </th>
                          </tr>
                        ))}
                    */ }
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()} className={' py-2 '}>
                        {headerGroup.headers.map(column => (
                          <th {...column.getHeaderProps()} className={column.className + ' hover:text-light-purple-3  '}>
                             {/* Render the columns filter UI */}
                            {<div className='text-[10px] mb-2 '>{column.canFilter && column.render('Filter')}</div>}
                            {column.id=='selection' ?
                            <>
                            <button title={Idioma.transacciones.filtros.eliminarFiltros[language]} className={` cursor-pointer scale-[.80] -mt-2 mb-3 rounded-full ${isLoading===false && rows.length===0 && data.length!==rows.length && 'animate-pulsebg'}`} onClick={()=>resetFilters()}><ResetLogo/></button>
                              {column.render('Header')} </>:
                              column.id=='index' ? 
                              <p className='text-[12px] 2xl:text-[16px]'>{column.Header}
                              </p> :
                              <p className={`flex relative text-[12px] 2xl:text-[16px] items-center cursor-pointer ${column.id=='inflow'||column.id=='outflow'||column.id=='exchange'?' flex justify-end':'justify-between pl-2'}`} onClick={()=>{
                                const desc =
                                column.isSortedDesc === true ?
                                undefined :
                                column.isSortedDesc === false ?
                                true :
                                false ;
                                if(desc===undefined){
                                  setSortBy([/*...sortees*/])
                                }else if(desc===true){
                                  setSortBy([{id:column.id,desc:desc}/*,...sorteesTrue*/])
                                }else{
                                  setSortBy([{id:column.id,desc:desc}/*,...sortees*/])
                                }
                                
                                
                              }} ><span className=' relative'><span className='absolute top-0 -left-3'>{infoDict[column.id] && <InfoI texto={infoDict[column.id]} popDirection={false} popXDirection={column.id==='exchange'?'left':'right'}/>}</span>{column.Header}</span>
                              <span className={`${column.isSorted || column.isSortedDesc ? 'mx-[15px]':''}`}>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? <TrianguloAbajoLogo/>
                                  : <div className='rotate-180'><TrianguloAbajoLogo/></div>
                                : <></>}
                                
                                
                            </span></p>
                            }
                            
                            {column.id != 'selection' &&
                                <div
                                {...column.getResizerProps()}
                                className={` inline-block bg-neutral-1 w-[4px] h-[32px] absolute right-2 top-0 translate-x-[50%] z-1 touch-none ${
                                  column.isResizing ? 'isResizing' : ''
                                }`}
                              />
                                }
                          </th>
                        ))}
                        
                      </tr>
                    ))}
                    
                  </thead>
                  <tbody {...getTableBodyProps()} className=' 2xl:text-p6-2xl text-[12px] relative'>
                  {isLoading ? 
                  <>
                    <tr className='relative'>
                        <p className='absolute w-[100%] text-neutral-3 text-center mt-[60px] text-lg'>{Idioma.transacciones.buscando[language]}...</p>
                    </tr>
                  </> :
                  file.id || bank.id || (startDate!=null && endDate!=null) ? 
                    (data.length==0 ?
                    <>
                      <tr className='relative'>
                          <p className='absolute w-[100%] text-neutral-3 text-center mt-[60px] text-lg'>{Idioma.transacciones.noTransacciones[language]}</p>
                      </tr>
                    </> :<></> ): data.length>0 ? 
                    <></> :
                    <>
                      <tr className='relative'>
                          <p className='absolute w-[100%] text-neutral-3 text-center mt-[60px] text-lg'>{Idioma.transacciones.usarFiltros[language]}</p>
                      </tr>
                    </>
                    }  
                    
                    <VariableSizeList
                  className='w-[calc(100%+10px)] overflow-y-hidden thin-scrollbar absolute 2xl:text-[12px] text-[10px] mt-4'
                      height={document.documentElement.clientHeight < 900 ? document.documentElement.clientHeight*.35 :document.documentElement.clientHeight*.40 }
                      itemCount={rows.length}
                      itemSize={getItemSize}
                    >
                      {RenderRow(rows)}
                    </VariableSizeList>
                      
                                 {/*    
                    {rows.map((row, i) => {
                      prepareRow(row)
                      return (
                        <tr {...row.getRowProps()} className={transactionId==row.original.id ? 'bg-md-white' : 'group hover:bg-md-white'} onClick={()=>transactionSelect(row)}>
                          {row.cells.map(cell => {
                            return <td {...cell.getCellProps()}>{createCellOfType(cell,row)}</td>
                          })}
                          {/*<td className=' bg-main-bg dark:bg-secondary-dark-bg'>
                          <div className=' group-hover:visible text-md-light-blue invisible dark:text-md-white'>
                            <button>
                              <Scissors className='h-8 w-8 '/>
                            </button>
                          </div>
                        </td>}
                        </tr>
                      )
                    })} */}
                  </tbody>
                </table>
                </div>
              </div>

              {userAccess && userAccess.access3 && 
              <>
              <div className='flex justify-between items-start 2xl:mt-[60px] mt-[30px]  mb-2'>
                <button onClick={()=>openRecycleBin(true)} className='flex  items-center'><ThrashCanLogo fill="#000"/><p className='2xl:text-[18px] ml-1 underline -mb-0.5 font-normal'>{Idioma.transacciones.papelera[language]}</p></button>
                <div className='text-right mr-12 text-neutral-3 text-p9-2xl w-1/2'>
                  {Object.keys(totals).map((total)=>(
                    <>
                      {Object.keys(totals[total]).map((totalCurrency)=>(
                        <>
                          {
                          `Total ${total==='inflow'? Idioma.transacciones.configuracion.abono[language]:Idioma.transacciones.configuracion.cargo[language]} ${totalCurrency}: ${total==='outflow' && numberFormat===0 ? '(' :total==='outflow' && numberFormat!==0 ?  '-' :''}${totals[total][totalCurrency]!=0 ? totals[total][totalCurrency].toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            currencySign: "accounting",
                            currencyDisplay: 'code'
                          }).slice(4):'--'}${total==='outflow' && numberFormat===0 ? ')' : ''} `}<br/>
                        </>
                      ))}    
                    </>
                        ))}
                  {Object.keys(selectedRowIds).length>=1 && <p> {Object.keys(selectedRowIds).length} {Idioma.transacciones.transaccionesSeleccionadas[language]}</p>}
                        
                </div>
                </div>
                </>}
                <div className='absolute bottom-[0px] -right-[40px] z-[101] bg-white'>
                    {/*showTransactionsUpdated && 
                      <>
                      {isAutoClassing ? 
                        <MessageToast type='warning' setSign={setShowTransactionsUpdated} title={Idioma.transacciones.procesoAutoclass[language]} message={amountAutoClassing} />
                        :
                        <MessageToast type='success' setSign={setShowTransactionsUpdated} title={Idioma.transacciones.transActualizadas[language]} message={updatedTransactions} />
                      }
                      </>
                    }
                  {errorSign &&  <MessageToast type="error" title={Idioma.errorAlgoSalioMal[language]} message={errorMessage} setSign={setErrorSign}/>*/}
                </div>
        </div>
    )
}

export default ClassificationScreen