import React from "react"

const ChromeLogo = () => {
    return(
        <svg width="30" height="30" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <title/>
            <g id="Chrome">
                <path d="M6.42,10.33a.5.5,0,0,0,.43.25h0a.5.5,0,0,0,.43-.32A5,5,0,0,1,12,7H22.1a.5.5,0,0,0,.44-.74A12,12,0,0,0,3.13,3.94a.5.5,0,0,0-.06.59Z" fill="#707070"/>
                <path d="M23.43,8.35A.5.5,0,0,0,23,8H16.24a.5.5,0,0,0-.39.82,5,5,0,0,1,.48,5.67l0,.05-5,8.7a.5.5,0,0,0,.42.75H12A12,12,0,0,0,23.43,8.35Z" fill="#707070"/>
                <path d="M13.32,17.13a.5.5,0,0,0-.49-.21A5,5,0,0,1,12,17a5.08,5.08,0,0,1-4.33-2.5l-5-8.73a.5.5,0,0,0-.43-.25h0a.5.5,0,0,0-.43.24,12,12,0,0,0,7.68,18h.11a.5.5,0,0,0,.43-.25l3.36-5.82A.5.5,0,0,0,13.32,17.13Z" fill="#707070"/>
                <circle cx="12" cy="12" r="4" fill="#707070"/>
            </g>
        </svg>
    )
}

export default ChromeLogo