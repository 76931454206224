const RedTriangle = () => {
    return (
        <div>
            <div className="relative">
        <svg id="Grupo_490" data-name="Grupo 490" xmlns="http://www.w3.org/2000/svg" width="15.162" height="13.478" viewBox="0 0 15.162 13.478" className="animate-ping absolute">
            <g id="Grupo_487" data-name="Grupo 487" transform="translate(0.004)">
                <g id="Grupo_486" data-name="Grupo 486" clip-path="url(#clip-path)">
                    <path id="Trazado_719" data-name="Trazado 719" d="M7.564,13.475H1.125a1.634,1.634,0,0,1-.405-.036A.964.964,0,0,1,.141,12c.351-.631.716-1.255,1.076-1.882L6.686.58A1,1,0,0,1,7.554,0a.957.957,0,0,1,.9.536q.773,1.347,1.545,2.7,2.488,4.338,4.978,8.676a1.072,1.072,0,0,1,.145.869.954.954,0,0,1-.838.691c-.094.008-.19.009-.285.009H7.564M6.58,6.022h0V7.96a1.259,1.259,0,0,0,.028.3A1,1,0,0,0,7.664,9a.972.972,0,0,0,.917-.975c0-1.327,0-2.654,0-3.982a.96.96,0,0,0-.02-.211,1,1,0,0,0-1.108-.76.97.97,0,0,0-.869.961c-.006.663,0,1.327,0,1.991M6.5,10.8a1.086,1.086,0,1,0,1.1-1.07A1.086,1.086,0,0,0,6.5,10.8" transform="translate(-0.004 0)" fill="#ff003f" />
                </g>
            </g>
            <g id="Grupo_489" data-name="Grupo 489">
                <g id="Grupo_488" data-name="Grupo 488" clip-path="url(#clip-path-2)">
                    <path id="Trazado_720" data-name="Trazado 720" d="M85.483,42.732h0V44.67a1.25,1.25,0,0,0,.028.3,1,1,0,0,0,1.056.741.972.972,0,0,0,.917-.975c0-1.327,0-2.654,0-3.982a.96.96,0,0,0-.02-.211,1,1,0,0,0-1.108-.76.969.969,0,0,0-.869.961c-.006.663,0,1.327,0,1.991" transform="translate(-78.903 -36.71)" fill="#fff" />
                    <path id="Trazado_721" data-name="Trazado 721" d="M84.48,127.487a1.086,1.086,0,1,0,1.1-1.07,1.086,1.086,0,0,0-1.1,1.07" transform="translate(-77.977 -116.686)" fill="#fff" />
                </g>
            </g>
        </svg>
            </div>
            <div>
        <svg id="Grupo_490" data-name="Grupo 490" xmlns="http://www.w3.org/2000/svg" width="15.162" height="13.478" viewBox="0 0 15.162 13.478" className="">
            <g id="Grupo_487" data-name="Grupo 487" transform="translate(0.004)">
                <g id="Grupo_486" data-name="Grupo 486" clip-path="url(#clip-path)">
                    <path id="Trazado_719" data-name="Trazado 719" d="M7.564,13.475H1.125a1.634,1.634,0,0,1-.405-.036A.964.964,0,0,1,.141,12c.351-.631.716-1.255,1.076-1.882L6.686.58A1,1,0,0,1,7.554,0a.957.957,0,0,1,.9.536q.773,1.347,1.545,2.7,2.488,4.338,4.978,8.676a1.072,1.072,0,0,1,.145.869.954.954,0,0,1-.838.691c-.094.008-.19.009-.285.009H7.564M6.58,6.022h0V7.96a1.259,1.259,0,0,0,.028.3A1,1,0,0,0,7.664,9a.972.972,0,0,0,.917-.975c0-1.327,0-2.654,0-3.982a.96.96,0,0,0-.02-.211,1,1,0,0,0-1.108-.76.97.97,0,0,0-.869.961c-.006.663,0,1.327,0,1.991M6.5,10.8a1.086,1.086,0,1,0,1.1-1.07A1.086,1.086,0,0,0,6.5,10.8" transform="translate(-0.004 0)" fill="#ff003f" />
                </g>
            </g>
            <g id="Grupo_489" data-name="Grupo 489">
                <g id="Grupo_488" data-name="Grupo 488" clip-path="url(#clip-path-2)">
                    <path id="Trazado_720" data-name="Trazado 720" d="M85.483,42.732h0V44.67a1.25,1.25,0,0,0,.028.3,1,1,0,0,0,1.056.741.972.972,0,0,0,.917-.975c0-1.327,0-2.654,0-3.982a.96.96,0,0,0-.02-.211,1,1,0,0,0-1.108-.76.969.969,0,0,0-.869.961c-.006.663,0,1.327,0,1.991" transform="translate(-78.903 -36.71)" fill="#fff" />
                    <path id="Trazado_721" data-name="Trazado 721" d="M84.48,127.487a1.086,1.086,0,1,0,1.1-1.07,1.086,1.086,0,0,0-1.1,1.07" transform="translate(-77.977 -116.686)" fill="#fff" />
                </g>
            </g>
        </svg>
            </div>
        </div>
    );
}

export default RedTriangle;