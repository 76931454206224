import React, { useEffect, useState } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { urlBase } from '../authConfig';

const EditableText = ( { initialText, date, id, upload,reload, diff } ) => {
    const [ isEditing, setIsEditing ] = useState(false);
    const [ text, setText ] = useState();
    const { accessToken, idClient, sessionID} = useStateContext();

    useEffect(() => {
        if(!reload){
            setText(initialText===null ? '' : initialText)
        }
    }), [initialText]

    const uploadData = () => {

        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');

        let body = {
            clientID: idClient,
            accountID: id,
            date_d: date,
            expectedBalance: text,
			logsInfo: {
			  origin:'Checker',
			  sessionID:sessionID 
			}
        }

        fetch(`${urlBase}/updateExpectedBalance`, {
            method: 'POST',
            headers: headers, 
            body: JSON.stringify(body)
        }).then(res => res.json())
        .then(( aux ) => {
            if(aux.message === 'Se actualizo el saldo con exito') {
                upload();
            }
        }).catch(err => console.log('Error: ', err));
    }

    const handleDoubleClick = () => {
        if( text === 'disabled' ){
            return;
        }
        setIsEditing(true);
    }

    const handleChange = ( event ) => {
        setText(event.target.value);
    }

    const handleBlur = () => {
        setIsEditing(false);
        uploadData();
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setIsEditing(false);
            uploadData();
        }
    };

    const textColor = () => {
        if(diff){
            if(diff==='check'){
                return 'text-green-1'
            }else if(diff==='disabled'){
                return 'text-neutral-1'
            }else{
                return 'text-red-1'
            }
        }else{
            return 'text-gray-400'
        }
    }

    return(
        <div onDoubleClick={handleDoubleClick} className={' text-center'}>
            {isEditing ? (
                <input 
                    type='text'
                    value={text}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    className='w-14 border-black'
                    autoFocus
                />
            ) : (
                <span className={`2xl:text-p9-2xl ${textColor()} `}>
                    {text === 'disabled' ? 'N/A' : text === '' ? 'Editar' : 
                        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(text))}
                </span>
            ) }
        </div>
    );
}

export default EditableText;