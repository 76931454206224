let timeoutId = null;
let intervalId = null;

export const startInactiviryTimer = (onInactive, timeout = 900000) => {
    let remainingTime = timeout;
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
        onInactive();
    }, timeout);


    intervalId = setInterval(() => {
        remainingTime -= 1000;
        if (remainingTime <= 0) {
            clearInterval(intervalId);
        }
    }, 1000);
}

export const resetInactivityTimer = (onInactive, timeout = 900000) => {
    startInactiviryTimer(onInactive, timeout);
}

export const addActivityListeners = (onActive, timeout = 900000) => {
    const resetHandler = () => resetInactivityTimer(onActive, timeout);

    window.addEventListener('mousemove', resetHandler);
    window.addEventListener('mousedown', resetHandler);
    window.addEventListener('keypress', resetHandler);
    window.addEventListener('keypress', resetHandler);
    window.addEventListener('scroll', resetHandler);

    startInactiviryTimer(onActive, timeout)
}

export const removeActivityListeners = () => {
    window.removeEventListener('mousemove', resetInactivityTimer);
    window.removeEventListener('mousedown', resetInactivityTimer);
    window.removeEventListener('keypress', resetInactivityTimer);
    window.removeEventListener('keypress', resetInactivityTimer);
    window.removeEventListener('scroll', resetInactivityTimer);
    clearTimeout(timeoutId);
}